import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {Button} from '@partssourceinc/react-ui-core';

import 'less/gridpagination.less';

export class GridPaginationMobile extends Component {
    static displayName = 'Paginator';
    static propTypes = {

        totalRecords: PropTypes.number.isRequired,
        pageSize: PropTypes.number.isRequired,
        onPageSizeChange: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            recordsPerPage: props.pageSize,
        };
    }

    loadMore() {
        const {pageSize, totalRecords, onPageSizeChange} = this.props;
        const {recordsPerPage} = this.state;

        let newRecordsPerPage;
        if (recordsPerPage + pageSize > totalRecords) {
            newRecordsPerPage = totalRecords;
        } else {
            newRecordsPerPage = recordsPerPage + pageSize;
        }
        this.setState({recordsPerPage: newRecordsPerPage});
        onPageSizeChange(newRecordsPerPage);
    }

    render() {

        const {totalRecords} = this.props;
        const {recordsPerPage} = this.state;

        return (
            <div className="grid-pagination" style={{width: '100%', marginTop: '20px'}}>

                {recordsPerPage < totalRecords ?

                    <Button style={{marginLeft: 'auto', marginRight: 'auto', display: 'block', cursor: 'pointer'}}
                        onClick={::this.loadMore}
                        className="button button-primary button button-secondary"
                    >
                        {' '}
                        MORE RESULTS{' '}
                    </Button> : ''}
            </div>);
    }
}

