import _ from 'lodash';
import {Calendar} from 'primereact/components/calendar/Calendar';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';

import 'less/datepicker.less';
import 'less/grid/DataTable.less';
import 'less/grid/grid.less';
import 'less/grid/MultiSelect.less';
import 'less/grid/primereact.less';
import 'less/grid/theme.less';
import 'less/multiGrid.less';

export default class Datepicker extends Component {
    static propTypes = {
        value: PropTypes.object,
        placeholder: PropTypes.object,
        minDate: PropTypes.object,
        maxDate: PropTypes.object,
    };
    
    constructor(props) {
        super(props);
        this.state = {
            date: props.value,
        };
    }
    
    static propTypes = {
        placeholder: PropTypes.string,
        minDate: PropTypes.string,
        maxDate: PropTypes.string,
        value: PropTypes.string,
        showError: PropTypes.bool,
    };
    
    handleDateChange(e) {
        _.invoke(this.props, 'onChange', e.value, {...this.props});
    }
    
    render () {
        const {placeholder, minDate, maxDate, value, showError} = this.props;
    
        return (<div className={showError ? 'datepicker error' : 'datepicker'} >
            <Calendar placeholder={placeholder} onChange={(e) => this.handleDateChange(e)} minDate={minDate} maxDate={maxDate}
                value={value} showIcon={true}
            />        
        </div>);
    }
}