import PropTypes from 'prop-types';
import axios from 'axios';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import {Popup} from '@partssourceinc/react-ui-core';
import {ProductCarousel} from '@partssourceinc/react-ui-core';
import {ProductImage} from '@partssourceinc/react-ui-core';
import * as UserStore from 'stores/User';
import {NavLink} from 'react-router-dom';
import {formatMoney, getLoanerString} from 'utility';
import {getPartCondition, getPurchaseChoice} from 'productUtility';

import 'less/addtocartconfirmation.less';
import 'less/carousel/carousel.less';

@withRouter
@connect(state => ({user: state.user}), UserStore.actionCreators)
export default class AddToCartConfirmation extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        option: PropTypes.object.isRequired,
        quantity: PropTypes.number.isRequired,
        onCancel: PropTypes.func,
        onConfirm: PropTypes.func,
        show: PropTypes.bool,
        isQuote: PropTypes.bool,
        checkAssetValidation: PropTypes.bool,
        outrightListPrice: PropTypes.number,
        confirmButtonText: PropTypes.string,
        cancelButtonText: PropTypes.string,
        user: UserStore.StateShape,
        ...UserStore.ActionShape,
        ...ReactRouterPropTypes,
    };
  
    static defaultProps = {
        show: false,
        quantity: 1,
        isQuote: false,
        checkAssetValidation: false,
        outrightListPrice: null,
        confirmButtonText: 'View Cart',
        cancelButtonText: 'Continue Shopping',
    };
  
    constructor(props) {
        super(props);
  
        this.state = {
            showPopup: props.show || false,
            recommendedProducts: [],
        };
        this.handleOnCancel = this.handleOnCancel.bind(this);
        this.handleOnConfirm = this.handleOnConfirm.bind(this);
    }
  
    componentWillReceiveProps(nextProps) {
        if (nextProps.show) {
            this.setState({
                showPopup: true,
            });
        } else {
            this.setState({showPopup: false});
        }
    }
  
    closePopup() {
        const {onCancel} = this.props;
        onCancel();
    }
  
    handleOnCancel() {
        const {onCancel} = this.props;
        this.setState({showPopup: false});
  
        if (onCancel) {
            onCancel();
        }
    }
  
    handleOnConfirm() {
        const {onConfirm, history, isQuote, product, hasOnSiteService} = this.props;
        this.setState({showPopup: false});
        if (product && product?.serviceUpsell && hasOnSiteService){
            history.push('/request-service');
        } else if (onConfirm) {
            onConfirm(isQuote);            
        } else {
            history.push('/cart');
        }
    }

    renderForCrossSell = () => {
        return (
            <React.Fragment>
                <div className="cart-confirmation_header">
                    <h2>Recommended for you</h2>
                </div>
                <div className="cart-confirmation_item">
                    <div className="cart-confirmation_item_image">
                        <img
                            src="/images/onSiteMapService.png"
                            alt="Service Repair"
                            style={{width: 100}}
                        />
                    </div>
                    <div className="cart-confirmation_item_description">
                        <span className="onSite-title">
                The PartsSource On-Site Service Network driven by PRECISION
                Procurement ® is now available
                        </span>
                    </div>
                    <div style={{float: 'right'}}>
                        <NavLink to="/shop/on-site-service">LEARN MORE</NavLink>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    showCrossSell = () => {
        const {user: {settings: {enableOnSiteService}}, hasOnSiteService, product: {serviceUpsell, serviceCrossSell}} = this.props;

        if (serviceCrossSell && !serviceUpsell && !hasOnSiteService && enableOnSiteService)
            return true;
        else if (!serviceCrossSell && serviceUpsell && !hasOnSiteService && enableOnSiteService)
            return true;
        else if (!serviceCrossSell && serviceUpsell && hasOnSiteService)
            return false;
        else if (serviceUpsell && serviceCrossSell && hasOnSiteService)
            return false;
        else if (serviceCrossSell && serviceUpsell && !hasOnSiteService && enableOnSiteService)
            return true;
        return false;
    }
  
    render() {
        const {
            user: {settings: {hidePricing}},
            quantity,
            product,
            option,
            isQuote,
            checkAssetValidation,
            outrightListPrice,
            confirmButtonText,
            cancelButtonText,
            isList,
            listName,
            existsInList,
            multipleProducts,
            hasOnSiteService,
            isRSA,
            onSiteService,
            isQuickOrder,
            hasLoanerAdded,
            addLoaner,
        } = this.props;

        const {showPopup, recommendedProducts} = this.state;
        const existingItemTitle = (<div className="slick-title"><p>This item was already in <span>{listName}</span></p>
            <span>It&apos;s been moved to the top of the list</span></div>)
        const multipleProductsTitle = <div className="slick-title"><p>{quantity} Item(s) Added to Cart</p></div>;

        const isServiceQuote = isQuote && product?.productTypeId && product?.productTypeId === 17;

        const itemAddedText = option && option.purchaseChoice === 2 ? '1 Exchange Item(s) Added to Cart' : 'Item(s) Added to Cart';

        const title = isQuote
            ? `${quantity} Item(s) Submitted for Pricing & Availability`
            : isList && !existsInList
                ? <div className="slick-title"><p>1 Item Added to <span>{listName}</span></p></div>
                : isList && existsInList
                    ? existingItemTitle
                    : multipleProducts
                        ? multipleProductsTitle
                        : itemAddedText;
      
        let displayPrice = (isQuote || multipleProducts) ? 0 : 
            ((checkAssetValidation && outrightListPrice) ? outrightListPrice : option.price);
        const showRecommendations = option != null;

        const pnDisplay = product && product.displayPartNumber && (
            <div style={{marginTop: '10px', lineHeight: '20px'}}>
                <div><span><span>Item #: </span>{product.displayPartNumber}</span></div>
                <div><span><span>Condition: </span>
                    <span style={{fontWeight: 600}}>
                        {option && option.purchaseChoice === 2 && <img src="/images/exchange.svg" alt="Exchange Item" style={{height: '15px', padding: '0 2px'}} />}
                        {option && option.lineItemCondition ? getPartCondition(option.lineItemCondition) : null}
                        {option && !option.isRepairOption && option.purchaseChoice ? (<span>{' '}{getPurchaseChoice(option.purchaseChoice)}</span>) : null}
                    </span>
                </span></div>
            </div>
        );

        if (!isQuote && !isList) {
            let bulkPricingSchedule = option && option.bulkPricingSchedule
                && option.bulkPricingSchedule.filter(bp => bp.tierPrice < option.price);
            if (bulkPricingSchedule != null) {
                let bulkPrice = bulkPricingSchedule.filter(x =>
                    x.minQtyRange <= quantity && (x.maxQtyRange >= quantity || x.minQtyRange === x.maxQtyRange));
                if (bulkPrice.length > 0) displayPrice = bulkPrice[0].tierPrice;
            }
        }

        const request = {
            start: 0,
            limit: 50,
            query: null,
        };
        if (showRecommendations && recommendedProducts.length == 0)
            axios.get(`/CatalogService/api/v1/similar/${product?.id}`, request).then(response => this.setState({recommendedProducts: response.data.products}));

        let partImage = product && product?.images && product?.images.length > 0 ? product?.images[0].thumbnail : null
        
        if (option && option.images && option.images[0].image177)
            partImage = option.images[0].image177;

        let confirmBtnTxt = (product && product?.serviceUpsell && hasOnSiteService)? 'BEGIN YOUR ON-SITE SERVICE': confirmButtonText;
        return (
            <Popup
                className="cart-confirmation_popup"
                show={showPopup}
                disableClose={false}
                onCancel={this.handleOnCancel}
                onConfirm={this.handleOnConfirm}
                cancelText={cancelButtonText}
                confirmText={isList ? 'View Your List' : confirmBtnTxt}
            >
                <div className="cart-confirmation">
                    <div className="cart-confirmation_header">
                        <h2>
                            {hasOnSiteService
                                ? `${isQuote && !isServiceQuote ? `${quantity} Item(s) Submitted for Pricing and Availability` : title} `
                                : title}
                        </h2>
                    </div>
                    {!multipleProducts && !isServiceQuote && (
                        <div className="cart-confirmation_item">
                            <div className="cart-confirmation_item_image">
                                <ProductImage
                                    url={partImage}
                                    style={{width: '100px'}}
                                />
                            </div>

                            {!multipleProducts && !isServiceQuote && (
                                <div className="cart-confirmation_item_description">
                                    <a>{product?.description}</a>
                                    {pnDisplay}
                                </div>
                            )}

                            {!isQuote && !hidePricing && !isList && !multipleProducts && (
                                <div className="cart-confirmation_item_price">
                                    <span className="lbl-bold">
                                        {formatMoney(displayPrice)}{' '}
                                    </span>
                                    <span className="lbl-subtle"> {option.uomCode}</span>
                                </div>
                            )}

                            {!isList && !multipleProducts && !isServiceQuote && (
                                <div className="cart-confirmation_item_quantity">
                                    <span>Qty: </span>
                                    <span className="lbl-bold">{quantity}</span>
                                </div>
                            )}
                        </div>
                    )}
                    {!multipleProducts && !isServiceQuote && (hasLoanerAdded || addLoaner) && (
                        <div className="cart-confirmation_loaner">
                            <div className="cart-confirmation_item_image">
                                <ProductImage
                                    url={partImage}
                                    style={{width: '100px'}}
                                />
                            </div>

                            {!multipleProducts && !isServiceQuote && (
                                <div className="cart-confirmation_item_description">
                                    <a>{product?.description + getLoanerString()}</a>
                                    {pnDisplay}
                                </div>
                            )}

                            {!isList && !multipleProducts && !isServiceQuote && (
                                <div className="cart-confirmation_item_quantity">
                                    <span>Qty: </span>
                                    <span className="lbl-bold">{quantity}</span>
                                </div>
                            )}
                        </div>
                    )}

                    {hasOnSiteService && (
                        <React.Fragment>
                            {!isQuote && (
                                <div className="cart-confirmation_header">
                                    <h2>1 Item Submitted for Pricing and Availability</h2>
                                </div>
                            )}
                            <div className="cart-confirmation_item">
                                <div className="cart-confirmation_item_image">
                                    <img
                                        src="/images/icn_repair.png"
                                        alt="Service Repair"
                                        style={{width: 60, marginLeft: 20}}
                                    />
                                </div>
                                <div className="cart-confirmation_item_description">
                                    <a>{isRSA ? product?.description : 'On-Site Service'}</a>
                                    <br />
                                    <span> {pnDisplay}</span>
                                </div>
                            </div>
                        </React.Fragment>
                    )}

                    {!isQuickOrder && this.showCrossSell() ? (
                        this.renderForCrossSell()
                    ) : (
                        <React.Fragment>
                            {showRecommendations && recommendedProducts.length > 0 && (
                                <ProductCarousel
                                    className="carousel-image"
                                    products={recommendedProducts}
                                    onProductSelected={this.handleOnCancel}
                                    title={'Recommended For You'}
                                    carouselType={'also-bought'}
                                    maxItems={3}
                                />
                            )}
                        </React.Fragment>
                    )}
                </div>
            </Popup>
        );
    }
}
