import moment from 'moment';
export const defaultFilters = [
    {
        jaql: {
            dim: '[OEMS.COMPANY_NAME]',
            title: 'Filter by OEM',
            datatype: 'text',
            merged: true,
            filter: {
                all: true,
                explicit: false,
                multiSelection: true
            },
        },
    },
    {
        jaql: {
            dim: '[MODALITIES.MODALITY_DESCRIPTION]',
            title: 'Filter by Modality',
            datatype: 'text',
            merged: true,
            filter: {
                all: true,
                explicit: false,
                multiSelection: true
            },
        },
    },
    {
        jaql: {
            dim: '[CUSTOMERS.COMPANY_NAME]',
            title: 'Facility',
            datatype: 'text',
            merged: true,
            filter: {
                all: true,
                explicit: false,
                multiSelection: true
            },
        },
    },
    {
        jaql: {
            dim: '[N_REQUESTORS.REQUESTOR_NAME]',
            title: 'Filter by Person',
            datatype: 'text',
            merged: true,
            filter: {
                all: true,
                explicit: false,
                multiSelection: true
            },
        },
    },
    {
        jaql: {
            dim: '[CALENDAR.DATE_KEY (Calendar)]',
            title: 'Date Range',
            datatype: 'datetime',
            merged: true,
            level: 'days',
            filter: {
                from: moment().subtract(366, 'days').format('MM/DD/YYYY'),
                to: moment().subtract(1, 'days').format('MM/DD/YYYY'),
            },
        },
    },
    {
        jaql: {
            dim: '[CUSTOMERS.LVL1_COMPANY_ID]',
            title: 'Filter by Facility',
            datatype: 'text',
            merged: true,
            filter: {
                all: true,
                explicit: false,
                multiSelection: true
            },
        },
    }
];
