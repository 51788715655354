import React, {useState} from 'react';
import styled from 'styled-components';
import {brandFont} from '@partssourceinc/react-ui-core';

const QtySelectField = styled.select`
    ${brandFont('14px')};
    height: 40px;
    border-radius: 3px;
    padding: 10px;
    width: 60px;
`;

const RepairQuantitySelector = (props) => {
    const {disabled, tabIndex, className, onChange, style, selectedValue, ...rest} = props;

    const [value, setValue] = useState(selectedValue);
                
    const handleChange = (e) => {
                
        const targetValue = e.target.value;
          
        if (targetValue !== '' && (targetValue < 1 || targetValue > 9999)) {
            e.preventDefault();
            return;
        }
  
        setValue(targetValue);   

        if (typeof(onChange) === 'function') {
            onChange(e, {...props, value: e.target.value})
        }
    }

    let options = []
    
    for (let i = 1; i < 11; i++) {
        options.push(i);
    }
       
    return (<div className={className ? ' ' + className : ''} >
        <QtySelectField tabIndex={tabIndex ? tabIndex : 0}
            style={style}
            disabled={disabled} {...rest}
            onChange={handleChange}
            value={selectedValue}>
            {(options || []).map(x =>
                <option key={x} value={x}>{x === 10 ? `${x}+` : x}</option>
            )}
        </QtySelectField>
    </div>);
};

export default RepairQuantitySelector;
