import axios from 'axios';
import {getCookie, logEventVerbose} from 'utility';
import {esdPresentedPart, esdPresentedRepair, getPartCondition, getPurchaseChoice, getLineItemWarranty} from 'productUtility';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

const defaultProductState = {
    product: {},
    originalProduct: {}, // Used in switching between cross sell product and the original one
    options: [],
    crossSellProducts: [],
    alsoBoughts: [],
    selectedOption: {},
    outrightListPrice: null,
    isBusy: true,
    showAlternativeOptions: false,
    oemPrice: null,
    oemPriceDate: null,
    hasOemContract: false,
    rfqRuleId: null,
    rfqRuleName: null,    
    selectedVariantId: null,
    displayOptions: [],
};

const OptionShape = PropTypes.shape({
    psPartNumber: PropTypes.string,
    fakeRefurb: PropTypes.bool,
    displayToCustomer: PropTypes.number,
    exclusiveOemCatalogDisplay: PropTypes.bool,
    isReturnable: PropTypes.bool,
    isSmartPriceOnly: PropTypes.bool,
    isSmartSourced: PropTypes.bool,
    leadTimeDays: PropTypes.number,
    lineItemCondition: PropTypes.number,
    lineItemWarranty: PropTypes.number,
    notes: PropTypes.string,
    oemItemIdWithCondition: PropTypes.string,
    vendorItemNumber: PropTypes.string,
    price: PropTypes.number,
    purchaseChoice: PropTypes.number,
    shipCutoffUtc: PropTypes.string,
    supplierId: PropTypes.number,
    vendorId: PropTypes.number,
    backorderDateEta: PropTypes.string,
    inventory: PropTypes.number,
    unitOfMeasurement: PropTypes.string,
    isUserFavoritePart: PropTypes.bool,
    favoritePartId: PropTypes.number,
    bulkPricingSchedule: PropTypes.array, // TODO
    hazMatId: PropTypes.number,
    loanerAvailable: PropTypes.bool,
    variantId: PropTypes.string,
    vendorFieldDefinitions: PropTypes.arrayOf(PropTypes.shape({
        fieldDefinitionUid: PropTypes.string,
        description: PropTypes.string,
        prompt: PropTypes.string,
        isDefault: PropTypes.bool,
        isRequired: PropTypes.bool,
        isActive: PropTypes.bool,
        fieldName: PropTypes.string,
        groupId: PropTypes.number,
        companyId: PropTypes.number,
        companyName: PropTypes.string,
        defaultValue: PropTypes.string,
        validationExpression: PropTypes.string,
        sortOrder: PropTypes.number,
        usage: PropTypes.number,
        fieldType: PropTypes.number,
    })),
    attributes: PropTypes.arrayOf(PropTypes.shape({
        purpose: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.string,
    })),
    badges: PropTypes.string,
    certifications: PropTypes.any, // TODO
    showCustomCatalogMsg: PropTypes.bool,
    isProStockItem: PropTypes.bool,
    formularySetting: PropTypes.shape({
        isOutrightFormularyOption: PropTypes.bool,
        isExchangeFormularyOption: PropTypes.bool,
        rootCompanyId: PropTypes.number,
        facilityId: PropTypes.number,
        vendorItemNumber: PropTypes.any, // TODO
        conditionId: PropTypes.number,
        condition: PropTypes.number,
        showBadge: PropTypes.bool,
        showCompanyLogo: PropTypes.bool,
        showAdditionalOptionsLink: PropTypes.bool,
        suppressOption: PropTypes.bool,
        approvalRequired: PropTypes.bool,
        approvalLimit: PropTypes.number,
        formularyRuleName: PropTypes.string,
        hasUnvalidatedContract: PropTypes.bool,
        contractPrice: PropTypes.number,
    }),
    isFormularyOption: PropTypes.bool,
});

export const StateShape = PropTypes.shape({
    product: PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        syncKey: PropTypes.number,
        categoryRoots: PropTypes.arrayOf(PropTypes.string),
        description: PropTypes.string,
        longDescription: PropTypes.string,
        displayPartNumber: PropTypes.string,
        keywords: PropTypes.arrayOf(PropTypes.string),
        manufacturer: PropTypes.string,
        manufacturerId: PropTypes.number,
        models: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        oemCompatibleParts: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
        equivalentParts: PropTypes.arrayOf(PropTypes.string),
        oemSeo: PropTypes.string,
        partNumber: PropTypes.string,
        thumbnailImage: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.shape({
            conditionId: PropTypes.number,
            purchaseChoiceId: PropTypes.number,
            psPartNumber: PropTypes.string,
        })),
        partImages: PropTypes.arrayOf(PropTypes.string),
        altText: PropTypes.objectOf(PropTypes.string),
        images: PropTypes.object,
        oemInvItemId: PropTypes.number,
        productTypeId: PropTypes.number,
        productOverview: PropTypes.object,
        modalityId: PropTypes.number,
        weight: PropTypes.number,
        disableSendToSourcing: PropTypes.bool,
        oemItemNumber: PropTypes.string,
        equivalentPartNumber: PropTypes.string,
        hazmatTypeId: PropTypes.number,
        productNumber: PropTypes.string,
        hash: PropTypes.string,
        researchData: PropTypes.object,
        callForQuote: PropTypes.bool,
        researchRedisId: PropTypes.string,
    }),
    options: PropTypes.arrayOf(OptionShape),
    selectedOption: OptionShape,
    displayOptions: PropTypes.arrayOf(OptionShape),
    selectedVariantId: PropTypes.string,
    outrightListPrice: PropTypes.number,
    isBusy: PropTypes.bool,
    showAlternativeOptions: PropTypes.bool,
    oemPrice: PropTypes.number,
    oemPriceDate: PropTypes.object,
    hasOemContract: PropTypes.bool,
    hideNonFormularyOptions: PropTypes.bool,
    nonFormularyOptionsNeedApproval: PropTypes.bool,
    rfqRuleId: PropTypes.number,
    rfqRuleName: PropTypes.string,
    loanerAvailable: PropTypes.bool,
});

export const actionCreators = {
    loadAlsoBoughts: () => loadAlsoBoughts(),
    loadProduct: (oemName, partNumber, psPartNumber, vrnt, isSSR) => loadProduct(oemName, partNumber, psPartNumber, vrnt, isSSR),
    logProduct: (referer, hasRequestQuote) => logProduct(referer, hasRequestQuote),
    selectCrossSellItem: (item) => selectCrossSellItem(item),
    selectOriginalItem: () => selectOriginalItem(),
    removeProductFromCache: () => removeProductFromCache(),
    selectOption: (selectedOptionIndex) => selectOption(selectedOptionIndex),
    resetProduct: () => resetProduct(),
    validateContract: (oemName, partNumber, psPartNumber, validatedContractId) => validateContract(oemName, partNumber, psPartNumber, validatedContractId),
    setContractOptionVendorFieldValue: (fieldToSetFieldDefinitionUid, newValue) => setContractOptionVendorFieldValue(fieldToSetFieldDefinitionUid, newValue),
    selectVariant: (request) => selectVariant(request),
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

function logProduct(referer, facility) {
    return (dispatch, getState) => {
        const {currentProduct, system} = getState();
        
        let hideRequestQuote = facility && facility.facilityId > 0 && (!facility.showTfyp || (facility.rfqManagement === 4 && currentProduct.options.filter(x => x.isBelowRfqLimit).length > 0));

        let logDataDetails = [
            {name: 'OEM Number', value: currentProduct.product.oemItemNumber},
            {name: 'Selected Option', value: _.isEmpty(currentProduct.selectedOption.psPartNumber) ? 'No Selected Option available' : currentProduct.selectedOption.psPartNumber},
        ];

        // lead time 
        if (currentProduct.selectedOption.esdPresented) {
            logDataDetails.push({name: 'ESD Presented', value: 'Yes'});
            logDataDetails.push({name: 'Value Presented', value: currentProduct.selectedOption.esdPresented});
        } else {
            logDataDetails.push({name: 'ESD Presented', value: 'No'});
        }

        logDataDetails.push({name: 'Variant Product', value: currentProduct.product.isVariantProduct});
        logDataDetails.push({name: 'Variant Ref', value: currentProduct.product.defaultVariant ? true : false});
        
        let conditionNumber = 1;
        currentProduct.options.forEach(option => {
            logDataDetails.push({name: `Condition ${conditionNumber} PS Number`, value: _.isEmpty(option.psPartNumber) ? 'No PS part number' : option.psPartNumber});
            logDataDetails.push({name: `Condition ${conditionNumber} V-String`, value: _.isEmpty(option.vendorItemNumber) ? 'No Vendor item number' : option.vendorItemNumber});
            logDataDetails.push({name: `Condition ${conditionNumber} Price`, value: +(option.price || 0).toFixed(2)});

            if (option.isProStockItem) {
                logDataDetails.push({name: `Condition ${conditionNumber} Guaranteed Option`, value: option.psPartNumber === null ? 'No PS part number' : option.psPartNumber});
            }

            if (option.isFormularyOption) {
                logDataDetails.push({name: `Condition ${conditionNumber} Formulary Option`, value: option.psPartNumber === null ? 'No PS part number' : option.psPartNumber});
            }

            if (option.fakeRefurb && !hideRequestQuote) {
                logDataDetails.push({name: 'Fake Refurb Option', value: true});
            }

            if ((option.formularySetting || {}).formularyRuleName) {
                let fs = option.formularySetting;
                let displayType = '';
                if (fs.showBadge) displayType += 'Badging ';
                if (fs.suppressionBehavior === 1) displayType += 'Hiding ';
                if (fs.suppressionBehavior === 2) displayType += 'Suppressed ';
                if (fs.approvalRequired) displayType += 'Approval ';

                logDataDetails.push({name: `Condition ${conditionNumber} Formulary Rule Name`, value: fs.formularyRuleName});
                logDataDetails.push({name: `Condition ${conditionNumber} Formulary Rule Type`, value: displayType.trim()});
                logDataDetails.push({name: `Condition ${conditionNumber} Contract Rule Name`, value: fs.contractRuleName});                
            }

            const hasPhoto = (option.images || []).length > 0 && option.images[0].image.indexOf('Catalog-Fallback-Image') === -1;
            logDataDetails.push({name: `Condition ${conditionNumber} Photo shown`, value: hasPhoto});

            conditionNumber++;
        });

        if (!hideRequestQuote && currentProduct.options && currentProduct.options.length > 1) {
            logDataDetails.push({name: 'Request Additional Options', value: true});
        } else {
            logDataDetails.push({name: 'Request Additional Options', value: false});
        }

        if (currentProduct.selectedOption && currentProduct.selectedOption.price && currentProduct.selectedOption.price > 0) {
            logDataDetails.push({name: 'Add to Cart present', value: true});
        } else {
            logDataDetails.push({name: 'Add to Cart present', value: false});
        }

        logDataDetails.push({name: 'id_ins', value: getCookie('id_ins')});
        logDataDetails.push({name: 'Referer', value: referer});

        logDataDetails.push({name: 'Ecommerce description shown', value: currentProduct.product.hasEcommTitle});

        if (currentProduct.selectedOption.attributes) {
            const technicalAttributeCount = currentProduct.selectedOption.attributes.filter(o => o.purpose === 'TECHNICAL SPEC' && o.name.toLowerCase() !== 'prop65c' && o.name.toLowerCase() !== 'prop65r').length;
            logDataDetails.push({name: 'Number Tech Specs', value: technicalAttributeCount});

            const numberOfFeatures = currentProduct.selectedOption.attributes.filter(o => o.purpose === 'FEATURE').length;
            logDataDetails.push({name: 'Number Features', value: numberOfFeatures});
        }

        logDataDetails.push({name: 'Overview present', value: currentProduct.product.productOverview !== undefined});
        const numberOfModels = currentProduct.product.models != null ? Object.keys(currentProduct.product.models).length : 0
        logDataDetails.push({name: 'Number of fitments', value: numberOfModels});
        logDataDetails.push({name: 'Long description present', value: currentProduct.product.longDescription !== undefined});

        if (currentProduct.rfqRuleId) {        
            logDataDetails.push({name: 'RFQ_RULE_ID', value: currentProduct.rfqRuleId});
            logDataDetails.push({name: 'RFQ_RULE_NAME', value: currentProduct.rfqRuleName});

            logDataDetails.push({name: 'RFQ_REMOVES_ABILITY_TO_RFQ', value: hideRequestQuote});
            logDataDetails.push({name: 'RFQ_REMOVES_REFURB', value: hideRequestQuote && currentProduct.options.filter(x => x.fakeRefurb).length > 0});
            logDataDetails.push({name: 'RFQ_ITEMS_NOT_AVAILABLE', value: currentProduct.options.length == 1 && currentProduct.options[0].showCustomCatalogMsg && currentProduct.options[0].notes == 'This item is currently unavailable.'});
        }

        logEventVerbose('PRODUCT VIEW', logDataDetails);
    }
}

function selectOption(selectedOptionIndex) {
    return (dispatch) => {
        dispatch({type: 'SELECT_OPTION', selectedOptionIndex: selectedOptionIndex});
    };
}

function selectVariant(request) {
    return (dispatch, getState) => {        
        const bound = {
            request: () => dispatch({type: 'SELECT_VARIANT_REQ'}),
            response: (response) => dispatch({type: 'SELECT_VARIANT_RESP', response: response}),
            error: (error) => dispatch({type: 'SELECT_VARIANT_ERR', response: error}),
        };

        bound.request();

        axios.post(`/CatalogService/api/v1/product/variants`, request).then(bound.response).catch(bound.error);
    }
}

function validateContract(oemName, partNumber, psPartNumber, validatedContractId) {
    return (dispatch, getState) => {
        const {network: {isLoggedIn}, user: {facility}, user, currentProduct: {options}, system: {siteSettings: {hideNoEsdMessaging}}} = getState();
        const facilityId = !isLoggedIn ? 38451 : facility.facilityId > 0 ? facility.facilityId : user.facilities[0].facilityId;

        const bound = {
            request: () => dispatch({type: 'LOAD_PRODUCT_REQ'}),
            response: (response) => dispatch({type: 'LOAD_PRODUCT_RESP', response: response, psPartNumber: psPartNumber, user: user, hideNoEsdMessaging}),
            error: (error) => dispatch({type: 'LOAD_PRODUCT_ERR', response: error}),
        };

        bound.request();

        return axios.get(`/CatalogService/api/v1/product/${oemName}/${partNumber}?customerId=${facilityId}&contractValidated=${true}&validatedContractId=${validatedContractId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function removeProductFromCache() {
    return (dispatch, getState) => {
        const {currentProduct: {product}} = getState();
        axios.post(`/CatalogService/api/v1/remove/cache/${product.researchRedisId}`);
    }
}

export function loadAlsoBoughts() {
    return (dispatch, getState) => {
        const {currentProduct: {product}} = getState();
        const request = {
            start: 0,
            limit: 50,
            query: null,
        };

        const bound = {
            request: () => dispatch({type: 'LOAD_ALSO_BOUGHTS_REQ'}),
            response: (response) => dispatch({type: 'LOAD_ALSO_BOUGHTS_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_ALSO_BOUGHTS_ERR', response: error}),
        };

        bound.request();

        return axios.get(`/CatalogService/api/v1/similar/${product.id}`, request).then(bound.response).catch(bound.error);
    }
}

export function loadProduct(oemName, partNumber, psPartNumber, vrnt, isSSR) {
    return (dispatch, getState) => {
        const {network: {isLoggedIn}, user: {facility}, user, system: {siteSettings: {hideNoEsdMessaging}}} = getState();
        const facilityId = !isLoggedIn ? 38451 : facility.facilityId > 0 ? facility.facilityId : user.facilities[0].facilityId;

        const bound = {
            request: () => dispatch({type: 'LOAD_PRODUCT_REQ'}),
            response: (response) => dispatch({type: 'LOAD_PRODUCT_RESP', response: response, psPartNumber: psPartNumber, user: user, hideNoEsdMessaging}),
            error: (error) => dispatch({type: 'LOAD_PRODUCT_ERR', response: error}),
        };

        bound.request();

        let url = `/CatalogService/api/v1/product/${oemName}/${partNumber}?customerId=${facilityId}`;
        if (vrnt) url += `&vstring=${vrnt}`
        if (isSSR) url += `&isSSR=true`;

        return axios.get(url)
            .then(bound.response)
            .catch(bound.error);
    };
}

function resetProduct() {
    return dispatch => dispatch({type: 'RESET_PRODUCT'});
}

export function selectCrossSellItem(item) {
    return (dispatch) => {
        dispatch({type: 'SET_CROSS_SELL', crossSellItem: item});
    };
}

export function selectOriginalItem() {
    return (dispatch) => 
        dispatch({type: 'SET_ORIGINAL_PRODUCT'});
}

export function setContractOptionVendorFieldValue(fieldToSetFieldDefinitionUid, newValue) {
    return (dispatch) => 
        dispatch({type: 'SET_CONTRACT_OPTION_VENDOR_FIELD_VALUE', 
            fieldToSetFieldDefinitionUid: fieldToSetFieldDefinitionUid, 
            newValue: newValue});
}

export const reducer = (state = _.cloneDeep(defaultProductState), action = null) => {
    switch (action.type) {
        case 'LOAD_PRODUCT_REQ':
        case 'LOAD_ALSO_BOUGHTS_REQ': {
            return {...state, isBusy: true};
        }
        case 'LOAD_ALSO_BOUGHTS_RESP': {
            const {response} = action;
            return {...state, alsoBoughts: response.data.products, isBusy: false};
        }
        case 'SET_CROSS_SELL': {
            const {crossSellItem} = action;
            const originalProduct = state.product;
            const product = _.assign({}, state.product, crossSellItem);
            if (Object.keys(state.originalProduct).length === 0)
                return {...state, product, originalProduct}
            else 
                return {...state, product}
        }
        case 'SET_ORIGINAL_PRODUCT': {
            const product = state.originalProduct;
            return {...state, product}
        }
        case 'LOAD_PRODUCT_RESP': {
            let {psPartNumber, user, hideNoEsdMessaging} = action;
            let {showAlternativeOptions} = state;
         
            let {product, options, researchRedisId: redisId, outrightListPrice, notAvailable, crossSellProducts, rfqRuleId, rfqRuleName, variantOptions} = action.response.data; 
            let hasNonFormularyOptions = options.some(x => !x.isFormularyOption);
            let hideNonFormularyOptions = hasNonFormularyOptions && options.some(x => x.formularySetting.showAdditionalOptionsLink);
            let nonFormularyOptionsNeedApproval = hasNonFormularyOptions && options.some(x => x.formularySetting.approvalRequired);
            let sendForStockCheck = options.some(x => x.formularySetting && x.formularySetting.sendForStockCheck);
            let noFormularyRule = !options.some(x => (x.formularySetting || {}).formularyRuleName);
            let selectedOptionIndex = 0;
            
            let pplOption = options.some(x => x.lineItemCondition===40);

            product.researchRedisId = redisId;
            product.notAvailable = notAvailable;

            if (product.callForQuote && !user.facility.isSmartPriceOnly && 
                !options.some(x => x.isSmartPriceOnly) && user.settings.isEQuoteEnabled && 
                options.every(x => x.lineItemCondition !== 0) && !options.some(x => x.lineItemCondition === 2) && 
                (noFormularyRule || sendForStockCheck)) {
                if (product.isContentExclusive)
                    options.push({
                        displayToCustomer: 0, 
                        lineItemCondition: 2, 
                        purchaseChoice: 1, 
                        fakeRefurb: true,
                        sendForStockCheck,
                        description: product.description,
                        images: [{
                            image: 'https://dam.partssource.com/m/3ec994b8367d4a93/Web_447_w-Catalog-Fallback-Image.jpg',
                            image177: 'https://dam.partssource.com/m/3ec994b8367d4a93/Web_447_w-Catalog-Fallback-Image.jpg',
                            image300: 'https://dam.partssource.com/m/3ec994b8367d4a93/Web_447_w-Catalog-Fallback-Image.jpg',
                            thumbnail: 'https://dam.partssource.com/m/3ec994b8367d4a93/Web_447_w-Catalog-Fallback-Image.jpg',
                            
                        }],
                    });
                else
                    options.push({
                        displayToCustomer: 0, 
                        lineItemCondition: 2, 
                        purchaseChoice: 1, 
                        fakeRefurb: true,
                        sendForStockCheck,
                        description: product.description,
                        longDescription: product.longDescription,
                        productOverview: product.productOverview,
                        images: product.images,
                    });
            }
            
            const isContentExclusive = product.isContentExclusive;
            const isRequestQuoteProduct = options.length === 0;
            if (options.length === 0) options.push({
                description: product.description,
                longDescription: isContentExclusive ? '' : product.longDescription,
                productOverview: isContentExclusive ? '' : product.productOverview,
                images: isContentExclusive ? [{
                    image: 'https://dam.partssource.com/m/3ec994b8367d4a93/Web_447_w-Catalog-Fallback-Image.jpg',
                    image177: 'https://dam.partssource.com/m/3ec994b8367d4a93/Web_447_w-Catalog-Fallback-Image.jpg',
                    image300: 'https://dam.partssource.com/m/3ec994b8367d4a93/Web_447_w-Catalog-Fallback-Image.jpg',
                    thumbnail: 'https://dam.partssource.com/m/3ec994b8367d4a93/Web_447_w-Catalog-Fallback-Image.jpg',
                    
                }] : product.images,
            });

            let nonZeroPricedOptions = options.filter(x => x.price && x.price !== 0 && !x.isRepairCrossSell);
            let nonZeroPricedCrossSells = options.filter(x => x.price && x.price !== 0 && x.isRepairCrossSell);
            let zeroPricedOptions = options.filter(x => !x.price || x.price === 0);

            nonZeroPricedOptions = nonZeroPricedOptions.sort((a, b) => {
                if (a.isContractProOption && !b.isContractProOption) {
                    return -1;
                } else {
                    if (b.isContractProOption && !a.isContractProOption) {
                        return 1;
                    } else {
                        if (a.displayToCustomer < b.displayToCustomer) {
                            return -1;
                        } else {
                            if (b.displayToCustomer > a.displayToCustomer) {
                                return 1;
                            } else {
                                return (a.price <= b.price ? -1 : 1);
                            }
                        }
                    }
                }
            });

            nonZeroPricedCrossSells = nonZeroPricedCrossSells.sort((a, b) => {
                if (a.isContractProOption && !b.isContractProOption) {
                    return -1;
                } else {
                    if (b.isContractProOption && !a.isContractProOption) {
                        return 1;
                    } else {
                        if (a.displayToCustomer < b.displayToCustomer) {
                            return -1;
                        } else {
                            if (b.displayToCustomer > a.displayToCustomer) {
                                return 1;
                            } else {
                                return (a.price <= b.price ? -1 : 1);
                            }
                        }
                    }
                }
            });

            options = _.concat(nonZeroPricedOptions, nonZeroPricedCrossSells, zeroPricedOptions);
            if (pplOption)
                options = _.concat(options.filter(x => x.lineItemCondition===40), options.filter(x => x.lineItemCondition!==40))

            const displayOptions = variantOptions.length > 0 ? variantOptions : options;

            if (psPartNumber) {
                let newIndex = 0;
                let selectedOption = {};

                // TODO: Follow-up to see what is needed for variants
                if (product.isVariantProduct && variantOptions.length > 0) {
                    displayOptions.forEach(o => {                       
                        const variantOption = options.find(x => 
                            ((x.variantId === o.selectedVariantId) || 
                             (o.selectedVariantId === '' && x.variantId === '' && x.purchaseChoice === o.purchaseChoice && x.lineItemCondition === o.lineItemCondition)) 
                             && x.psPartNumber === o.psPartNumber);
                        o.option = variantOption || null;
                        o.isSelected = o.psPartNumber.toLowerCase() === psPartNumber.toLowerCase();
                        if (o.isSelected) selectedOption = variantOption || o;
                    });
                } else {
                    if (options.some(o => o.isContractProOption)) {
                        newIndex = options.findIndex(o => o.isContractProOption);
                    } else {
                        newIndex = options.findIndex(o => (o.psPartNumber || '').toLowerCase() === psPartNumber.toLowerCase());
                    }
                    selectedOptionIndex = newIndex >= 0 ? newIndex : 0;
                    selectedOption = options[selectedOptionIndex];
                    selectedOption.isSelected = true;
                    selectedOption.esdPresented = selectedOption.purchaseChoice === 3 ? esdPresentedRepair(selectedOption.leadTimeDays) : esdPresentedPart(selectedOption.estimatedShipDate, selectedOption.backorderDateEta, selectedOption.shipCutoffUtc, hideNoEsdMessaging);
    
                    if (!showAlternativeOptions && selectedOption.displayToCustomer === 2)
                        showAlternativeOptions = true;    
                }

                if (isRequestQuoteProduct) selectedOption = options[0];
                
                return {...state, product, crossSellProducts, options, selectedOption, showAlternativeOptions, outrightListPrice, hideNonFormularyOptions, nonFormularyOptionsNeedApproval, rfqRuleId, rfqRuleName, displayOptions, isBusy: false}; 
            } else {
                 
                if (product.isVariantProduct && variantOptions.length > 0) {
                    let selectedOption = {};

                    displayOptions.forEach(o => {                       
                        const variantOption = options.find(x => 
                            ((x.variantId === o.selectedVariantId) || 
                             (o.selectedVariantId === '' && x.variantId === '' && x.purchaseChoice === o.purchaseChoice && x.lineItemCondition === o.lineItemCondition)) 
                             && x.psPartNumber === o.psPartNumber);
                        o.option = variantOption || null;
                        if (o.isSelected) selectedOption = variantOption || o;
                    });
                    if (isRequestQuoteProduct) selectedOption = options[0];
                    return {...state, product, options, crossSellProducts, selectedOption, outrightListPrice, hideNonFormularyOptions, nonFormularyOptionsNeedApproval, rfqRuleId, rfqRuleName, displayOptions, isBusy: false}; 
                } else {
                    selectedOptionIndex = options.some(o => (pplOption && o.lineItemCondition===40 && (((!o.formularySetting || !o.formularySetting.formularyId)) 
                            || (o.formularySetting && o.formularySetting.formularyId && o.isFormularyOption))))
                        ? options.findIndex(o => (((!o.formularySetting || !o.formularySetting.formularyId) && o.lineItemCondition===40) 
                            || (o.formularySetting && o.formularySetting.formularyId && o.isFormularyOption && o.lineItemCondition===40)))
                        : (options.some(x => x.isContractProOption) 
                            ? options.findIndex(x => x.isContractProOption) 
                            : (options.some(x => x.isFormularyOption) 
                                ? options.findIndex(x => x.isFormularyOption) 
                                : 0));

                    let selectedOption = options[selectedOptionIndex];
                    selectedOption.isSelected = true;
                    selectedOption.esdPresented = selectedOption.purchaseChoice === 3 ? esdPresentedRepair(selectedOption.leadTimeDays) : esdPresentedPart(selectedOption.estimatedShipDate, selectedOption.backorderDateEta, selectedOption.shipCutoffUtc, hideNoEsdMessaging);
                    return {...state, product, options, crossSellProducts, selectedOption, outrightListPrice, hideNonFormularyOptions, nonFormularyOptionsNeedApproval, rfqRuleId, rfqRuleName, displayOptions, isBusy: false}; 
                }                                             
            }
        }
        case 'SELECT_OPTION': {
            const {selectedOptionIndex} = action;
            const {displayOptions, options} = state;

            let currentOption = displayOptions.find(o => o.isSelected);
            currentOption.isSelected = false;

            let selectedOption = displayOptions[selectedOptionIndex];
            selectedOption.isSelected = true;

            if (selectedOption.selectedVariantId) {                                
                selectedOption = options.find(x => x.variantId === selectedOption.selectedVariantId && x.psPartNumber === selectedOption.psPartNumber) || selectedOption;                
            }

            if (selectedOption.variants && selectedOption.variants.length === 0) {
                selectedOption = selectedOption.option;
            }

            return {...state, selectedOption, displayOptions};
        }
        case 'RESET_PRODUCT': {
            return {...state, product: {}, options: [], selectedOption: {}, originalProduct: {}, displayOptions: [], selectedVariantId: null};
        }
        case 'SELECT_VARIANT_RESP': {            
            let {displayOptions, options, product} = state;
            const {response} = action
            const {data: {displayPartNumber, variants, selectedVariantId}} = response;            
            let displayOptionIndex = displayOptions.findIndex(o => o.isSelected);
            displayOptions[displayOptionIndex].variants = variants;
            displayOptions[displayOptionIndex].selectedVariantId = selectedVariantId;

            const variantOption = options.find(x => x.variantId === selectedVariantId && x.psPartNumber === displayOptions[displayOptionIndex].psPartNumber);
            const selectedOption = variantOption || displayOptions[displayOptionIndex];

            displayOptions[displayOptionIndex].option = variantOption || null;

            // Only set the original part number once
            if (!product.originalDisplayPartNumber) product.originalDisplayPartNumber = product.displayPartNumber;
            product.displayPartNumber = displayPartNumber ? displayPartNumber : product.originalDisplayPartNumber;

            return {...state, displayOptions, selectedOption, product};
        }
        case 'LOAD_PRODUCT_ERROR':
        case 'LOAD_ALSO_BOUGHTS_ERR': {
            return {...state, isBusy: false};
        }
        case 'SET_CONTRACT_OPTION_VENDOR_FIELD_VALUE': {
            const {fieldToSetFieldDefinitionUid, newValue} = action;
            let {options} = state;
            let contractOption = options.find(o => o.isContractProOption = true);

            if (contractOption && contractOption.vendorFieldDefinitions) {
                for (let i = 0; i < contractOption.vendorFieldDefinitions.length; i++) {
                    let item = contractOption.vendorFieldDefinitions[i];
                    
                    if (item.fieldDefinitionUid === fieldToSetFieldDefinitionUid) {
                        item.value = newValue;
                        break;
                    }
                }
            }

            return {...state, options};
        }
    }

    return state;
};
