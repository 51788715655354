import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {getLocationQuery} from 'utility';

export const defaultParamsState = {
    model: null,
    manufacturer: null,
    utm_source: null,
    e: null,
    /**
     * Work Order - The field Value version on the line item / field values.
    */
    wo: null,
    /**
    *Work Order - also known as Customer Line Key
    */
    wo_id: null,
    asset: null,
    serial: null,
    notes: null,
    ak1: null,
    ak2: null,
    /**
    * If the sign in is from lawson
    */
    fromlawson: null,
    /**
    * Used by some integrations to avoid double encoding ak1 & ak2 keys
    */
    punchoutAuth: null,
    referer: null,
    resources: null,
    s_kwcid: null,
};

export const StateShape = PropTypes.shape({
    utm_source: PropTypes.string,
    utm_medium: PropTypes.string,
    utm_campaign: PropTypes.string,
    utm_term: PropTypes.string,
    e: PropTypes.string,
    utm_content: PropTypes.string,
    wo: PropTypes.string,
    wo_id: PropTypes.string,
    asset: PropTypes.string,
    serial: PropTypes.string,
    notes: PropTypes.arrayOf(PropTypes.string),
    ak1: PropTypes.string,
    ak2: PropTypes.string,
    fromlawson: PropTypes.bool,
    punchoutAuth: PropTypes.string,
    referer: PropTypes.string,
    resources: PropTypes.object,
    ctm: PropTypes.string,
    s_kwcid: PropTypes.string,
});

const WORK_ORDER_KEY = '22222222-2222-2222-2222-222222222222';
const ASSET_KEY = '11111111-1111-1111-1111-111111111111';
const SERIAL_KEY = '44444444-4444-4444-4444-444444444444';

export const actionCreators = {
    setParams: (location, history, referer) => setParams(location, history, referer),
    setResources: (resources) => setResources(resources),
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

function setParams(location, history, referer) {
    return (dispatch) => {
        dispatch({type: 'ROUTE_CHANGE', location: location, history: history, referer: referer});
    };
}

function setResources(resources) {
    return (dispatch) => {
        dispatch({type: 'SET_RESOURCES', resources: resources});
    };
}

export function reducer(state = _.cloneDeep(defaultParamsState), action) {
    if (action.type === 'ROUTE_CHANGE') {
        const {location, history, referer} = action;
        let rewrite = false;
        let params = getLocationQuery(location);
        
        // let eParam redirect for changepassword happen in UI so the login doesn't flash
        const hasEParam = params.e ? true : false;

        if (params.endpoint || params.token) {
            delete params.endpoint;
            delete params.token;
        }

        if (params.model) {
            delete params.model;
            delete params.manufacturer;
            rewrite = true;
        }

        if (params.wo_id) {
            sessionStorage.wo_id = params.wo_id;
        }

        Object.keys(params).forEach(key => {
            if (key in state) {
                state[key] = params[key];
                delete params[key];
                rewrite = true;
            }
        });
        state.referer = referer;

        if (hasEParam) {
            rewrite = false;
        }

        if (!rewrite) {
            return {...state};
        } else if (state.wo || state.asset || state.serial) {

            let storageData = [
                {
                    fieldType: 1,
                    fieldUid: '11111111-1111-1111-1111-111111111111',
                    isDefault: true,
                    isRequired: false,
                    lineItemId: -1,
                    orderId: -1,
                    prompt: 'Asset ID',
                    value: state.asset,
                },
                {
                    fieldType: 1,
                    fieldUid: '22222222-2222-2222-2222-222222222222',
                    isDefault: true,
                    isRequired: false,
                    lineItemId: -1,
                    orderId: -1,
                    prompt: 'Work Order',
                    value: state.wo,
                },
                {
                    fieldType: 1,
                    fieldUid: '44444444-4444-4444-4444-444444444444',
                    isDefault: true,
                    isRequired: false,
                    lineItemId: -1,
                    orderId: -1,
                    prompt: 'Equipment Serial #',
                    value: state.serial,
                },
            ];

            sessionStorage.urlFields = JSON.stringify(storageData);
        }

        if (Object.keys(params).length) {
            let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
            history.replace(`${location.pathname}?${queryString}`);
        } else {
            history.replace(`${location.pathname}`);
        }

        return {...state};
    } else if (action.type === 'SET_RESOURCES') {
        const {resources} = action;
        state.resources = resources;
        return {...state}
    }

    return {...state};
}
