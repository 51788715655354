import axios from 'axios';
import PropTypes from 'prop-types';
import * as React from 'react';
import {LazyList, TextField} from '@partssourceinc/react-ui-core';
import styled, {css} from 'styled-components';
import $ from 'jquery';

export const ApiSelectType = {
    Facility: 'Facility',
    Contact: 'Contact',
};

const brandFont = fontSize => `
    font-family: "Source Sans Pro";
    font-weight: 400;
    font-size: ${fontSize};
`;

const ValidationMessage = styled.span`
    margin-left: 10px;
    color: #FF0000; 
    ${brandFont('12px')};
`;

export default class TextFieldApiSelect extends React.Component {
    static propTypes = {
        rowHeight: PropTypes.number,
        keyField: PropTypes.string,
        valueField: PropTypes.string,
        onSelect: PropTypes.func,
        label: PropTypes.string,
        placeHolder: PropTypes.string,
        autoExpand: PropTypes.bool,
        autoFocus: PropTypes.bool,
        disableClear: PropTypes.bool,
        // selectType: PropTypes.oneOf(Object.keys.ApiSelectType),
        selectedItem: PropTypes.any,
        errorMessage: PropTypes.string,
        showErrorMessage: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.node = React.createRef();

        const selectedItem = props.selectedItem ? props.selectedItem : {};
        const searchTerm = props.selectedItem ? selectedItem[props.valueField] : '';
        const listItems = [];
        if (props.selectedItem)
            listItems.push(props.selectedItem);

        this.state = {
            listViewOpen: false,
            searchTerm,
            selectedItem,
            listItems: listItems,
        };
    }

    componentDidMount() {
        const {autoExpand} = this.props;

        if (this.node) {
            this.textInput.current = this.node.childNodes[0].childNodes[0].childNodes[1]
        }

        if (autoExpand) {
            this.openListView();
        }
        if (this.textInput && this.textInput.autoFocus === true) {
            this.textInput.focus();
        }
    }

    componentDidUpdate(prevPros) {
        const {selectedItem, valueField} = this.props;
        if (prevPros.selectedItem !== selectedItem) {
            this.setState({selectedItem, searchTerm: selectedItem[valueField]});
        }
    }

    openListView() {
        if (this.textInput) {
            $(this.node).addClass('open');
            this.setState({listViewOpen: true, searchTerm: ''});
        }        
    }

    clearListView() {
        const {autoExpand} = this.props;
        if (!autoExpand) {
            $(this.node).removeClass('open');
            this.setState({listViewOpen: false, searchTerm: '', selectedItem: {}});
        } else {
            this.setState({searchTerm: '', selectedItem: {}});
        }
    }

    toggleListView(event) {
        const {autoExpand} = this.props;
        if (!autoExpand) {
            event.stopPropagation();
            const {valueField} = this.props;
            const {listViewOpen, selectedItem} = this.state;
            let searchTerm = listViewOpen ? selectedItem[valueField] || '' : '';
            $(this.node).toggleClass('open');
            this.setState({listViewOpen: !listViewOpen, searchTerm: searchTerm});
        }
    }

    handleSearch() {
        const {selectType} = this.props;
        const {searchTerm: oldTerm} = this.state;
        
        const searchTerm = this.textInput.current.value;

        if (searchTerm) {
            this.setState({searchTerm: searchTerm});

            switch (selectType) {
                case ApiSelectType.Facility:
                    axios.get(`/SettingsService/api/v1/facility/search/${searchTerm}/true`).then(x => {
                        if (oldTerm !== searchTerm)
                            this.setState({listItems: x.data});
                    });
                    break;
                case ApiSelectType.Contact:
                    axios.get(`/SettingsService/api/v1/contact/search/${searchTerm}`).then(x => {
                        if (oldTerm !== searchTerm)
                            this.setState({listItems: x.data});
                    });
                    break;
            }
        } else {
            this.setState({listItems: [], searchTerm: searchTerm});
        }
    }

    onSelect(item, event) {
        event.stopPropagation();
        const {valueField, onSelect} = this.props;
        this.setState({searchTerm: item[valueField], selectedItem: item, listViewOpen: false});
        $(this.node).toggleClass('open');
        onSelect(item);
    }

    onClearSelection(event) {
        const {autoExpand, onSelect} = this.props;
        event.stopPropagation();

        if (!autoExpand) {
            this.setState({searchTerm: '', selectedItem: {}, listViewOpen: false});
            $(this.node).toggleClass('open');
            onSelect({});
        } else {
            this.setState({searchTerm: '', selectedItem: {}});
        }
    }

    render() {
        const {listViewOpen, searchTerm, listItems} = this.state;
        const {rowHeight, keyField, valueField, label, placeHolder, autoExpand, autoFocus, disableClear, showErrorMessage, errorMessage} = this.props;

        return (<div className="select-text-field" onClick={::this.toggleListView} ref={(node) => {
            this.node = node; 
        }}>
            <TextField showErrorMessage={showErrorMessage} onChange={::this.handleSearch} autoFocus={autoFocus} tabIndex={0} label={label} placeholder={placeHolder} text={searchTerm} ref={(input) => {
                this.textInput = input; 
            }} />
            {!disableClear && <i className="fa fa-times-circle" aria-hidden="true" onClick={::this.onClearSelection} />}
            {searchTerm && <LazyList rowHeight={rowHeight} listItems={listItems} onSelect={::this.onSelect} keyField={keyField} valueField={valueField} searchTerm={searchTerm} />}
            {listViewOpen && !autoExpand && <div className="transparent-overlay" onClick={::this.toggleListView} />}
            {(showErrorMessage && errorMessage) && <ValidationMessage>{errorMessage}</ValidationMessage>}
        </div>);
    }
}
