import {Client} from '@adzerk/decision-sdk';
import _ from 'lodash';
import PropTypes from 'prop-types'

const GET_DECISIONS_REQ = 'GET_DECISIONS_REQ';
const GET_DECISIONS_RESP = 'GET_DECISIONS_RESP';
const GET_DECISIONS_ERR = 'GET_DECISIONS_ERR';

const defaultKevelState = {
    isLoading: false,
};

export const actionCreators = {
    getDecisions: (zoneIds, adTypeIds, keywords) => getDecisions(zoneIds, adTypeIds, keywords),
    fireClickImpression: (url) => fireClickImpression(url),
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

export function fireClickImpression(url) {
    return (dispatch, getState) => {
        let host = process.env.REACT_APP_KEVEL_HOST;

        if (!host) return;

        let clientParams = {networkId: process.env.REACT_APP_KEVEL_NETWORK_ID, siteId: process.env.REACT_APP_KEVEL_SITE_ID};
        clientParams.host = host;
        let client = new Client(clientParams);
        client.pixels.fire({url: url});
    }
}

export function getDecisions(zoneIds, adTypeIds, keywords) {
    return async (dispatch, getState) => {
        const {
            network: {isLoggedIn, tokenInfo: {userId}},
        } = getState();
        try {

            await dispatch({type: GET_DECISIONS_REQ});

            let request = {placements: []};
            zoneIds.forEach((zoneId) => {
                request.placements.push({divName: `div${zoneId}`, adTypes: adTypeIds, zoneIds: [zoneId]})
            });
            if (isLoggedIn) {
                request.user = {key: (userId || '').toString()};
            }
            if (keywords && keywords.length > 0) {
                request.keywords = keywords;
            }

            let host = process.env.REACT_APP_KEVEL_HOST;

            if (!host) return;

            let clientParams = {networkId: process.env.REACT_APP_KEVEL_NETWORK_ID, siteId: process.env.REACT_APP_KEVEL_SITE_ID};
            clientParams.host = host;
            
            let client = new Client(clientParams);

            let response = await client.decisions.get(request);
            let decisions = [];

            zoneIds.forEach((zoneId) => {
                const zoneName = `div${zoneId}`;
                if (zoneName in response.decisions) {
                    const [decision] = response.decisions[zoneName];

                    if (decision) {
                        const [content] = decision.contents;
                        decisions.push({
                            zoneId,
                            impressionUrl: decision.impressionUrl,
                            clickUrl: decision.clickUrl,
                            content: content && content.data && content.data.imageUrl && `<img src="${content.data.imageUrl}" />` || '',
                        });
                    }
                }
            });

            await dispatch({type: GET_DECISIONS_RESP});

            return decisions;
        } catch (e) {
            dispatch({type: GET_DECISIONS_ERR});
            console.log(e);
            return [];
        }
    };
}

export const reducer = (state = _.cloneDeep(defaultKevelState), action = null) => {
    switch (action.type) {
        case GET_DECISIONS_REQ: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case GET_DECISIONS_RESP: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case GET_DECISIONS_ERR: {
            return state;
        }
    }

    return state;
};
