import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import Cookies from 'js-cookie';

export const GET_ACTIVE_LIST_ITEMS_REQUEST = 'GET_ACTIVE_LIST_ITEMS_REQUEST';
export const GET_ACTIVE_LIST_ITEMS_RESPONSE = 'GET_ACTIVE_LIST_ITEMS_RESPONSE';
export const GET_ACTIVE_LIST_ITEMS_ERROR = 'GET_ACTIVE_LIST_ITEMS_ERROR';

export const MobileMenu = {
    Main: 'Main',
    Departments: 'Departments',
    Solutions: 'Solutions',
    Pro: 'PRO',
    UpdatesPanel: 'UpdatesPanel',
    None: '',
};

const defaultUserState = {
    isBusy: false,
    unconfirmedEmail: false,
    loginUrlReferrer: '',
    expiredUserName: '',
    identity: null,
    activeRoles: [],
    contract: {},
    quotes: [],
    orders: [],
    serviceOrders: [],
    ordersFilters: [],
    ordersPredefinedFilters: [],
    facility: {},
    facilities: [],
    companyFields: [],
    settings: {},
    lists: [],
    listItems: [],
    widgets: [],
    portals: [],
    widgetsToPrint: [],
    dashboardViewSettings: {
        selectedModalities: { '-1': 'All Modalities' },
        selectedCategories: { '-1': 'All Categories' },
        selectedFacilities: { '-1': 'All Facilities' },
        selectedRequesters: { '-1': 'All Requesters' },
    },
    info: {
        firstName: '',
        lastName: '',
        privacyPolicyAccepted: true,
        privacyPolicyAcceptedTimeStamp: moment().toDate(),
        termsOfUsePolicyAccepted: true,
        termsOfUsePolicyAcceptedTimeStamp: moment().toDate(),
        termsAndConditionsPolicyAccepted: true,
        termsAndConditionsPolicyAcceptedTimeStamp: moment().toDate(),
        accessibilityPolicyAccepted: true,
        accessibilityPolicyAcceptedTimeStamp: moment().toDate(),
    },
    psPlus: {
        program: {
            programId: '',
            companyId: '',
        },
        facilities: [],
    },
    acceptedProp65Warning: false,
    companyLogo: null,
    preferredLogo: "/images/icn-ribbon-2.png",
    quotesColumns: [
        { field: 'lineItemId', header: 'Ref #', sortable: 'custom', filter: true, visible: true, order: 6, type: 'number' },
        { field: 'partNumber', header: 'Item #', sortable: true, filter: false, visible: true, order: 1 },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 2 },
        { field: 'facility', header: 'Facility', sortable: true, filter: true, visible: true, order: 3 },
        { field: 'requester', header: 'Requester', sortable: true, filter: false, visible: true, order: 4 },
        { field: 'groupName', header: 'Status', sortable: true, filter: false, visible: true, order: 5 },
        { field: 'priority', header: 'Priority', sortable: true, filter: false, visible: true, order: 0 },
        { field: 'created', header: 'Created', sortable: 'custom', filter: false, visible: true, order: 7, type: 'date' },
        { field: 'inCart', header: 'In Cart', sortable: true, filter: false, visible: true, order: 8, template: 'checkMark' },
    ],
    onSiteServiceColumns: [
        { field: 'created', header: 'Created', sortable: 'custom', filter: true, visible: true, order: 0, type: 'date' },
        { field: 'workOrderNumber', header: 'Work Order', sortable: true, filter: true, visible: true, order: 1 },
        { field: 'facility', header: 'Facility', sortable: true, filter: true, visible: true, order: 2 },
        { field: 'serviceInfo.serviceType', header: 'Service Type', sortable: true, filter: true, visible: true, order: 3, isNested: true },
        { field: 'manufacturer', header: 'OEM', sortable: true, filter: true, visible: true, order: 4 },
        { field: 'assetId', header: 'Asset ID', sortable: true, filter: false, visible: true, order: 5 },
        { field: 'priority', header: 'Priority', sortable: true, filter: false, visible: true, order: 6 },
        { field: 'status', header: 'Status', sortable: true, filter: false, visible: true, order: 7 },
        { field: 'lineItemId', header: 'Ref #', sortable: 'custom', filter: true, visible: true, order: 8, type: 'number' },
        { field: 'poNumber', header: 'PO #', sortable: true, filter: true, visible: false, order: 9 },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: false, order: 10 },
        { field: 'requester', header: 'Requester', sortable: true, filter: false, visible: false, order: 11 },
        { field: 'orderId', header: 'Order Number', sortable: true, filter: false, visible: false, order: 13 },
        { field: 'partNumber', header: 'Item #', sortable: true, filter: false, visible: false, order: 14 },
        { field: 'equipmentSerialNumber', header: 'Equip Serial', sortable: true, filter: false, visible: false, order: 15 },
        { field: 'account', header: 'Account', sortable: true, filter: false, visible: false, order: 16 },
        { field: 'costCenter', header: 'Cost Center', sortable: true, filter: false, visible: false, order: 17 },
        { field: 'model', header: 'Model', sortable: true, filter: false, visible: false, order: 18 },
        { field: 'unitPrice', header: 'Price', sortable: 'custom', filter: false, visible: false, order: 19, type: 'number' },
        { field: 'invoiceNumber', header: 'Invoice #', sortable: true, filter: false, visible: false, order: 20, template: 'invoiceNumber' },
        { field: 'hasServiceContract', header: 'Contract', sortable: true, filter: false, visible: true, order: 21 },
        { field: 'fsrDocs', header: 'FSR Documentation', sortable: false, filter: false, visible: true, order: 22, template: 'fsrDoc' },
    ],

    repairsColumns: [
        { field: 'lineItemId', header: 'Ref. #', sortable: 'custom', filter: true, visible: true, order: 0, type: 'number' },
        { field: 'partNumber', header: 'Item #', sortable: true, filter: false, visible: true, order: 1 },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 2 },
        { field: 'facility', header: 'Facility', sortable: true, filter: true, visible: true, order: 3 },
        { field: 'requester', header: 'Requester', sortable: true, filter: false, visible: true, order: 4 },
        { field: 'groupName', header: 'Status', sortable: true, filter: false, visible: true, order: 5 },
        { field: 'priority', header: 'Priority', sortable: true, filter: false, visible: true, order: 6 },
        { field: 'hasRgaDocumentation', header: 'Return Labels & Document', sortable: true, filter: false, visible: true, order: 7, allowedTabs: ['initiated'] },
        { field: 'serialNumber', header: 'Serial Number', sortable: true, filter: false, visible: true, order: 8 },
        { field: 'trackingNumber', header: 'Tracking #', sortable: true, filter: false, visible: false, order: 9, template: 'trackingNumber' },
        { field: 'created', header: 'Created', sortable: 'custom', filter: false, visible: false, order: 10, type: 'date' },
        { field: 'poNumber', header: 'PO #', sortable: true, filter: true, visible: true, order: 11 },
    ],
    ordersColumns: [
        { field: 'lineItemId', header: 'Ref #', sortable: 'custom', filter: true, visible: true, order: 0, type: 'number' },
        { field: 'poNumber', header: 'PO #', sortable: true, filter: true, visible: true, order: 1 },
        { field: 'orderId', header: 'Order #', sortable: 'custom', filter: true, visible: true, order: 2, type: 'number' },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 3 },
        { field: 'facility', header: 'Facility', sortable: true, filter: false, visible: true, order: 4 },
        { field: 'requester', header: 'Requester', sortable: true, filter: false, visible: true, order: 5 },
        { field: 'dateCreated', header: 'Created', sortable: 'custom', filter: false, visible: true, order: 6, type: 'date' },
        { field: 'checkedIn', header: 'Checked In', sortable: true, filter: false, visible: true, order: 7 },
        { field: 'groupName', header: 'Status', sortable: true, filter: false, visible: true, order: 8 },
        { field: 'priority', header: 'Priority', sortable: true, filter: false, visible: true, order: 9 },
        { field: 'account', header: 'Account', sortable: true, filter: false, visible: false, order: 10 },
        { field: 'costCenter', header: 'Cost Center', sortable: true, filter: false, visible: false, order: 11 },
        { field: 'equipmentSerialNumber', header: 'Equip Serial', sortable: true, filter: false, visible: false, order: 12 },
        { field: 'invoiceNumber', header: 'Invoice #', sortable: true, filter: false, visible: false, order: 13, template: 'invoiceNumber' },
        { field: 'coreSerial', header: 'Item/Core Serial', sortable: true, filter: false, visible: false, order: 14 },
        { field: 'manufacturer', header: 'Manufacturer', sortable: true, filter: false, visible: false, order: 15 },
        { field: 'model', header: 'Model', sortable: true, filter: false, visible: false, order: 16 },
        { field: 'trackingNumber', header: 'Tracking #', sortable: true, filter: false, visible: true, order: 17, template: 'trackingNumber' },
        { field: 'workOrder', header: 'Work Order', sortable: true, filter: false, visible: false, order: 18 },
        { field: 'partNumber', header: 'Item #', sortable: true, filter: false, visible: false, order: 19 },
        { field: 'quantity', header: 'Qty', sortable: 'custom', filter: false, visible: false, order: 20, type: 'number' },
        { field: 'estimatedDelivery', header: 'Scheduled Delivery', sortable: true, filter: false, visible: true, order: 21, template: 'estimatedDelivery' },
        { field: 'estimatedShipDate', header: 'Estimated Ship Date', sortable: true, filter: false, visible: true, order: 22, type: 'date', template: 'date' },
        { field: 'shippingMethod', header: 'Ship Method', sortable: true, filter: false, visible: false, order: 23 },
        { field: 'unitPrice', header: 'Price', sortable: 'custom', filter: false, visible: false, order: 24, type: 'number' },
        { field: 'extendedPrice', header: 'Extended Price', sortable: 'custom', filter: false, visible: false, order: 25, type: 'number' },
        { field: 'assetId', header: 'Asset ID', sortable: true, filter: false, visible: false, order: 26 },
        { field: 'oemPrice', header: 'OEM Price', sortable: true, filter: false, visible: false, order: 27, type: 'number', template: 'money' },
        { field: 'condition', header: 'Condition Code', sortable: true, filter: false, visible: false, order: 28 },
        { field: 'PODLetter', header: 'Proof of Delivery Document', sortable: true, filter: false, visible: false, order: 29, template: 'podLetter' },
        { field: 'hasCreditMemo', header: 'Credit Memo', sortable: false, filter: false, visible: true, order: 30, template: 'creditMemoDoc' },
    ],
    myOrdersColumns: [
        {field: 'orderId', header: 'Order #', sortable: 'custom', filter: true, visible: true, order: 0, type: 'number'},
        {field: 'poNumber', header: 'PO #', sortable: true, filter: true, visible: true, order: 1},
        {field: 'lineItemId', header: 'Ref #', sortable: 'custom', filter: false, visible: true, order: 2, type: 'number'},
        {field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 3},
        {field: 'facility', header: 'Facility', sortable: true, filter: false, visible: true, order: 4},
        {field: 'requester', header: 'Requester', sortable: true, filter: false, visible: true, order: 5},
        {field: 'created', header: 'Created', sortable: 'custom', filter: false, visible: true, order: 6, type: 'date'},
        {field: 'priority', header: 'Priority', sortable: true, filter: false, visible: true, order: 7},
        {field: 'partNumber', header: 'Item #', sortable: true, filter: false, visible: false, order: 8},
        {field: 'quantity', header: 'Qty', sortable: true, filter: false, visible: false, order: 9, type: 'number'},
        {field: 'estimatedDelivery', header: 'Scheduled Delivery', sortable: true, filter: false, visible: false, order: 10, template: 'estimatedDelivery'},
        {field: 'estimatedShipDate', header: 'Estimated Ship Date', sortable: true, filter: false, visible: true, order: 11, template: 'date'},
        {field: 'carrierPriority', header: 'Ship Method', sortable: true, filter: false, visible: false, order: 12},
        {field: 'trackingNumber', header: 'Tracking Number', sortable: true, filter: false, visible: true, order: 13, template: 'trackingNumber'},
        {field: 'groupName', header: 'Status', sortable: true, filter: false, visible: true, order: 14},
        {field: 'equipmentSerialNumber', header: 'Equip Serial', sortable: true, filter: false, visible: false, order: 15},
        {field: 'invoiceNumber', header: 'Invoice #', sortable: true, filter: false, visible: false, order: 16, template: 'invoiceNumber'},
        {field: 'coreSerial', header: 'Item/Core Serial', sortable: true, filter: false, visible: false, order: 17},
        {field: 'account', header: 'Account', sortable: true, filter: false, visible: false, order: 18},
        {field: 'costCenter', header: 'Cost Center', sortable: true, filter: false, visible: false, order: 19},
        {field: 'modelNumber', header: 'Model', sortable: true, filter: false, visible: false, order: 20},
        {field: 'price', header: 'Price', sortable: 'custom', filter: false, visible: false, order: 21, type: 'number', template: 'money'},
        {field: 'extendedPrice', header: 'Extended Price', sortable: 'custom', filter: false, visible: false, order: 22, type: 'number', template: 'money'},
        {field: 'workOrderNumber', header: 'Work Order', sortable: true, filter: false, visible: false, order: 23},
        {field: 'checkedIn', header: 'Checked In', sortable: true, filter: false, visible: false, order: 24},
        {field: 'manufacturer', header: 'Manufacturer', sortable: true, filter: false, visible: false, order: 25},
        {field: 'assetId', header: 'Asset ID', sortable: true, filter: false, visible: false, order: 26},
        {field: 'oemPrice', header: 'OEM Price', sortable: true, filter: false, visible: false, order: 27, type: 'number', template: 'money'},
        {field: 'condition', header: 'Condition Code', sortable: true, filter: false, visible: false, order: 28},
        {field: 'PODLetter', header: 'Proof of Delivery Document', sortable: true, filter: false, visible: false, order: 29, template: 'podLetter'},
        {field: 'hasCreditMemo', header: 'Credit Memo', sortable: false, filter: false, visible: true, order: 30, template: 'creditMemoDoc'},
        {field: 'initiateReturn', header: 'Return', sortable: false, filter: false, visible: true, order: 31, template: 'initiateLink', allowedTabs: ['shipped', 'delivered']},
    ],
    awaitingPoColumns: [
        { field: 'orderId', header: 'Order #', sortable: 'custom', filter: true, visible: true, order: 0, type: 'number' },
        { field: 'lineItemId', header: 'Ref #', sortable: 'custom', filter: false, visible: true, order: 1, type: 'number' },
        { field: 'partNumber', header: 'Item #', sortable: true, filter: true, visible: true, order: 2 },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 3 },
        { field: 'condition', header: 'Condition', sortable: true, filter: false, visible: true, order: 4 },
        { field: 'quantity', header: 'Qty', sortable: 'custom', filter: false, visible: true, order: 5, type: 'number' },
        { field: 'facility', header: 'Facility', sortable: true, filter: false, visible: true, order: 6 },
        { field: 'requester', header: 'Requester', sortable: true, filter: false, visible: true, order: 7 },
        { field: 'workOrderNumber', header: 'Work Order', sortable: true, filter: false, visible: false, order: 8 },
        { field: 'account', header: 'Account', sortable: true, filter: false, visible: false, order: 9 },
        { field: 'manufacturer', header: 'Manufacturer', sortable: true, filter: false, visible: false, order: 10 },
        { field: 'price', header: 'Price', sortable: 'custom', filter: false, visible: false, order: 11, type: 'number' },
        { field: 'lastApprovedBy', header: 'Last Approved By', sortable: true, filter: false, visible: false, order: 12 },
        { field: 'created', header: 'Date Placed', sortable: 'custom', filter: false, visible: false, order: 13, type: 'date' },
        { field: 'extendedPrice', header: 'Extended Price', sortable: 'custom', filter: false, visible: false, order: 14, type: 'number' },
        { field: 'carrierPriority', header: 'Carrier/Priority', sortable: true, filter: false, visible: false, order: 15 },
        { field: 'assetId', header: 'Asset ID', sortable: true, filter: false, visible: false, order: 16 },
        { field: 'costCenter', header: 'Cost Center', sortable: true, filter: false, visible: false, order: 17 },
        { field: 'shipping', header: 'Shipping', sortable: 'custom', filter: false, visible: false, order: 18, type: 'number' },
        { field: 'fees', header: 'Fees', sortable: 'custom', filter: false, visible: false, order: 19, type: 'number' },
        { field: 'subTotal', header: 'Subtotal', sortable: 'custom', filter: false, visible: false, order: 20, type: 'number' },
    ],
    approvalColumns: [
        { field: 'orderId', header: 'Order #', sortable: 'custom', filter: true, visible: true, order: 0, type: 'number' },
        { field: 'lineItemId', header: 'Ref #', sortable: 'custom', filter: false, visible: true, order: 1, type: 'number' },
        { field: 'partNumber', header: 'Item #', sortable: true, filter: true, visible: true, order: 2 },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 3 },
        { field: 'condition', header: 'Condition', sortable: true, filter: false, visible: true, order: 4 },
        { field: 'quantity', header: 'Qty', sortable: 'custom', filter: false, visible: true, order: 5, type: 'number' },
        { field: 'facility', header: 'Facility', sortable: true, filter: false, visible: true, order: 6 },
        { field: 'requester', header: 'Requester', sortable: true, filter: false, visible: true, order: 7 },
        { field: 'workOrderNumber', header: 'Work Order', sortable: true, filter: false, visible: false, order: 8 },
        { field: 'account', header: 'Account', sortable: true, filter: false, visible: false, order: 9 },
        { field: 'manufacturer', header: 'Manufacturer', sortable: true, filter: false, visible: false, order: 10 },
        { field: 'price', header: 'Price', sortable: 'custom', filter: false, visible: false, order: 11, type: 'number' },
        { field: 'lastApprovedBy', header: 'Last Approved By', sortable: true, filter: false, visible: false, order: 12 },
        { field: 'created', header: 'Date Placed', sortable: 'custom', filter: false, visible: false, order: 13, type: 'date' },
        { field: 'extendedPrice', header: 'Extended Price', sortable: 'custom', filter: false, visible: false, order: 14, type: 'number' },
        { field: 'carrierPriority', header: 'Carrier/Priority', sortable: true, filter: false, visible: false, order: 15 },
        { field: 'assetId', header: 'Asset ID', sortable: true, filter: false, visible: false, order: 16 },
        { field: 'costCenter', header: 'Cost Center', sortable: true, filter: false, visible: false, order: 17 },
        { field: 'purchaseType', header: 'Purchase Type', sortable: true, filter: false, visible: false, order: 18 },
    ],
    rgaColumns: [
        { field: 'rgaNumber', header: 'RGA #', sortable: true, filter: true, visible: true, order: 0 },
        { field: 'orderId', header: 'Order #', sortable: 'custom', filter: false, visible: true, order: 1, type: 'number' },
        { field: 'poNumber', header: 'PO #', sortable: true, filter: true, visible: true, order: 2 },
        { field: 'lineItemId', header: 'Ref #', sortable: 'custom', filter: false, visible: true, order: 3, type: 'number' },
        { field: 'requester', header: 'Requester', sortable: true, filter: false, visible: true, order: 4 },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 5 },
        { field: 'facility', header: 'Facility', sortable: true, filter: false, visible: true, order: 6 },
        { field: 'dateEntered', header: 'Entered', sortable: 'custom', filter: false, visible: true, order: 7, type: 'date' },
        { field: 'dueDate', header: 'Due Date', sortable: 'custom', filter: false, visible: true, order: 8, type: 'date' },
        { field: 'manufacturer', header: 'Manufacturer', sortable: true, filter: false, visible: true, order: 9 },
        { field: 'quantity', header: 'Qty', sortable: true, filter: false, visible: true, order: 10, type: 'number' },
        { field: 'rgaReason', header: 'RGA Reason', sortable: true, filter: false, visible: true, order: 11 },
    ],
    myOemColumns: [
        { field: 'oemName', header: 'OEM', sortable: true, filter: true, visible: true, order: 0 },
        { field: 'discount', header: 'Discount', sortable: 'custom', filter: false, visible: true, order: 1 },
        { field: 'contractNumber', header: 'Contract Number', sortable: true, filter: true, visible: true, order: 2 },
        { field: 'contractExpirationDate', header: 'Contract Expiration', sortable: 'custom', filter: false, visible: true, order: 3 },
        { field: 'acceptsReturns', header: 'Accepts Returns', sortable: true, filter: false, visible: true, order: 4 },
        { field: 'restockFee', header: 'Re-stocking Fee', sortable: true, filter: false, visible: true, order: 5 },
    ],
    myOemOrdersColumns: [
        { field: 'orderId', header: 'Order #', sortable: true, filter: true, visible: true, order: 0 },
        { field: 'poNumber', header: 'PO #', sortable: 'custom', filter: false, visible: true, order: 1 },
        { field: 'requestorFullName', header: 'Requestor', sortable: true, filter: true, visible: true, order: 2 },
        { field: 'partNumber', header: 'Item #', sortable: 'custom', filter: false, visible: true, order: 3 },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 4 },
        { field: 'facilityName', header: 'Facility', sortable: true, filter: false, visible: true, order: 5 },
        { field: 'statusName', header: 'Status', sortable: true, filter: false, visible: true, order: 6 },
        { field: 'createdDate', header: 'Date Created', sortable: true, filter: false, visible: true, order: 7, type: 'date' },
    ],
    myDashboardColumns: [
        { field: 'partNumber', header: 'Item #', sortable: 'custom', filter: false, visible: true, order: 0 },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 1 },
        { field: 'facilityName', header: 'Facility', sortable: true, filter: false, visible: true, order: 2 },
        { field: 'requester', header: 'Requester', sortable: true, filter: false, visible: true, order: 3 },
        { field: 'status', header: 'Status', sortable: true, filter: false, visible: true, order: 4 },
        { field: 'lineItemId', header: 'Ref #', sortable: 'custom', filter: false, visible: true, order: 5, type: 'number' },
        { field: 'dateCreated', header: 'Date Created', sortable: true, filter: false, visible: true, order: 6, type: 'date' },
    ],
    visualFormularyColumns: [
        { field: 'isActive', header: 'Active', sortable: true, filter: false, visible: true, order: 0, template: 'switch' },
        { field: 'name', header: 'Rule Name', sortable: true, filter: false, visible: true, order: 1 },
        { field: 'proFormaSavings', header: 'Pro forma Savings', sortable: true, filter: false, visible: true, order: 2, type: 'number', template: 'money', decimals: 0 },
        { field: 'formularyScore', header: 'Formulary Score', sortable: true, filter: false, visible: true, order: 3, type: 'number', template: 'visualFormularyScore' },
        { field: 'manufacturerDisplay', header: 'OEM', sortable: true, filter: false, visible: false, order: 4 },
        { field: 'categoryDisplay', header: 'Category', sortable: true, filter: false, visible: false, order: 5 },
        { field: 'modalityDisplay', header: 'Modality', sortable: true, filter: false, visible: false, order: 6 },
        { field: 'displayPartNumber', header: 'Item Detail', sortable: true, filter: false, visible: false, order: 7, template: 'detailUrl' },
        { field: 'preferredCondition', header: 'Preferred Condition', sortable: true, filter: false, visible: false, order: 8 },
        { field: 'facilityDisplay', header: 'Facilities', sortable: true, filter: false, visible: false, order: 9, template: 'visualFormularyFacilites' },
        { field: 'displayPreference', header: 'Display Type', sortable: true, filter: false, visible: false, order: 10 },
        { field: 'requireApproval', header: 'Purchase Requires Approval', sortable: true, filter: false, visible: false, order: 11 },
        { field: 'displayPreferredChoice', header: "Display 'Preferred Choice'", sortable: true, filter: false, visible: false, order: 12 },
        { field: 'reasonForRule', header: 'Reason For Rule', sortable: true, filter: false, visible: false, order: 13 },
        { field: 'sortOrder', header: 'Rule Ranking', sortable: true, filter: false, visible: false, order: 14, type: 'number' },
    ],
    loanerColumns: [
        { field: 'lineItemId', header: 'Ref #', sortable: 'custom', filter: true, visible: true, order: 0, type: 'number' },
        { field: 'partNumber', header: 'Item #', sortable: true, filter: false, visible: false, order: 1 },
        { field: 'description', header: 'Description', sortable: true, filter: false, visible: true, order: 2 },
        { field: 'facility', header: 'Facility', sortable: true, filter: false, visible: true, order: 3 },
        { field: 'repairLineItemId', header: 'Repair Ref #', sortable: 'custom', filter: true, visible: true, order: 4, type: 'number' },
        { field: 'hasRgaDocumentation', header: 'Return Labels & Document', sortable: true, filter: false, visible: true, order: 5 },
        { field: 'altStatus', header: 'Status', sortable: true, filter: false, visible: true, order: 6 },
        { field: 'requester', header: 'Requester', sortable: true, filter: false, visible: true, order: 7 },
        { field: 'priority', header: 'Priority', sortable: true, filter: false, visible: true, order: 9 },
        { field: 'poNumber', header: 'PO #', sortable: true, filter: true, visible: true, order: 10 },
        { field: 'equipmentSerialNumber', header: 'Serial Number', sortable: true, filter: false, visible: false, order: 12 },
        { field: 'trackingNumber', header: 'Tracking #', sortable: true, filter: false, visible: true, order: 13, template: 'trackingNumber' },
        { field: 'estimatedDelivery', header: 'Scheduled Delivery Date', sortable: true, filter: false, visible: true, order: 14, template: 'estimatedDelivery' },
    ],
    opsDashboardDefaultColumns: [
        { field: 'strippedPartNumber', header: 'Item #', sortable: true, visible: true,order: 0 },
        { field: 'description', header: 'Description', sortable: true, visible: true,order: 1 },
        { field: 'facility', header: 'Facility', sortable: true, visible: true,order: 2 },
        { field: 'requestor', header: 'Requester', sortable: true, visible: true,order: 3 },
        { field: 'status', header: 'Status', sortable: true, visible: true,order: 4 },
        { field: 'lineItemId', header: 'Ref #', sortable: true, visible: true,order: 5 },
        { field: 'dateCreated', header: 'Date Created', sortable: true, type: 'date', order: 6 },
        { field: 'orderId', header: 'Order #', sortable: 'custom', filter: true, visible: true, order: 7, type: 'number' },
        { field: 'poNumber', header: 'PO #', sortable: true, filter: true, visible: true, order: 8 },
        { field: 'priorityDesc', header: 'Priority', sortable: true, filter: false, visible: true, order: 9 },
        { field: 'partNumber', header: 'Item #', sortable: true, filter: false, visible: false, order: 10 },
        { field: 'quantity', header: 'Qty', sortable: true, filter: false, visible: false, order: 11, type: 'number' },
        { field: 'estimatedDelivery', header: 'Scheduled Delivery', sortable: true, filter: false, visible: false, order: 12, template: 'estimatedDelivery' },
        { field: 'estimatedShipDate', header: 'Estimated Ship Date', sortable: true, filter: false, visible: true, order: 13, template: 'date' },
        { field: 'carrier', header: 'Ship Method', sortable: true, filter: false, visible: false, order: 14 },
        { field: 'trackingNumber', header: 'Tracking Number', sortable: true, filter: false, visible: true, order: 15, template: 'trackingNumber' },
        { field: 'serialNumber', header: 'Equip Serial', sortable: true, filter: false, visible: false, order: 16 },
        { field: 'invoiceNumber', header: 'Invoice #', sortable: true, filter: false, visible: false, order: 17, template: 'invoiceNumber' },
        { field: 'coreSerial', header: 'Item/Core Serial', sortable: true, filter: false, visible: false, order: 18 },
        { field: 'account', header: 'Account', sortable: true, filter: false, visible: false, order: 19 },
        { field: 'costCenter', header: 'Cost Center', sortable: true, filter: false, visible: false, order: 20 },
        { field: 'model', header: 'Model', sortable: true, filter: false, visible: false, order: 21 },
        { field: 'unitPrice', header: 'Price', sortable: 'custom', filter: false, visible: false, order: 22, type: 'number', template: 'money' },
        { field: 'extendedPrice', header: 'Extended Price', sortable: 'custom', filter: false, visible: false, order: 23, type: 'number', template: 'money' },
        { field: 'workOrderNumber', header: 'Work Order', sortable: true, filter: false, visible: false, order: 24 },
        { field: 'checkedIn', header: 'Checked In', sortable: true, filter: false, visible: false, order: 25 },
        { field: 'manufacturer', header: 'Manufacturer', sortable: true, filter: false, visible: false, order: 26 },
        { field: 'assetId', header: 'Asset ID', sortable: true, filter: false, visible: false, order: 27 },
        { field: 'oemPrice', header: 'OEM Price', sortable: true, filter: false, visible: false, order: 28, type: 'number', template: 'money' },
        { field: 'conditionType', header: 'Condition Code', sortable: true, filter: false, visible: false, order: 29 },
        { field: 'PODLetter', header: 'Proof of Delivery Document', sortable: true, filter: false, visible: false, order: 30, template: 'podLetter' },
        { field: 'hasCreditMemo', header: 'Credit Memo', sortable: false, filter: false, visible: true, order: 31, template: 'creditMemoDoc' },
        { field: 'hasRgaDocumentation', header: 'Return Labels & Document', sortable: true, filter: false, visible: true, order: 32, allowedTabs: ['initiated'] },
        { field: 'hasServiceContract', header: 'Contract', sortable: true, filter: false, visible: true, order: 33 },
        { field: 'serviceType', header: 'Service Type', sortable: true, filter: true, visible: true, order: 34 },
        { field: 'fsrDocs', header: 'FSR Documentation', sortable: false, filter: false, visible: true, order: 352, template: 'fsrDoc' },
    ],
    totalOrders: 0,
    registration: null,
    loginMessage: null,
    orderView: {
        showGridLayout: false,
        pageSize: 10,
        maxPages: 15,
        activePage: 1,
        sortColumn: 'OrderNumber',
        sortDirection: 'desc',
        userOnly: true,
        description: '',
        filter: {
            startdate: moment().subtract(29, 'days'),
            enddate: moment(),
        },
        ranges: {
            'Today': [moment(), moment()],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'Last 90 Days': [moment().subtract(89, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        },
        requestQuote: {},
    },
    mobileMenuOpen: MobileMenu.None,
    contractOrdersView: {
        currentTab: 'new',
        activePage: 1,
        sortColumn: 'referenceNumber',
        sortDirection: 'asc',
        searchTerm: '',
        filter: {
            searchFacilityId: 0,
            searchFacility: null,
        },
    },
    formularyRulesView: {
        pageSize: 10,
    },
    turnstileCompanies: [],
};

const ColumnShape = PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    header: PropTypes.string,
    sortable: PropTypes.string,
    filter: PropTypes.bool,
    visible: PropTypes.bool,
    order: PropTypes.number,
    type: PropTypes.string,
}));

export const StateShape = PropTypes.shape({
    isBusy: PropTypes.bool,
    unconfirmedEmail: PropTypes.bool,
    loginUrlReferrer: PropTypes.string,
    expiredUserName: PropTypes.string,
    identity: PropTypes.object,
    activeRoles: PropTypes.array,
    contract: PropTypes.object,
    quotes: PropTypes.array,
    orders: PropTypes.array,
    ordersFilters: PropTypes.array,
    ordersPredefinedFilters: PropTypes.array,
    dashboardViewSettings: PropTypes.array,
    facility: PropTypes.object,
    facilities: PropTypes.array,
    companyFields: PropTypes.array,
    settings: PropTypes.object,
    lists: PropTypes.array,
    listItems: PropTypes.array,
    widgets: PropTypes.array,
    portals: PropTypes.array,
    widgetsToPrint: PropTypes.array,
    info: {
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        passwordExpired: PropTypes.bool,
        eulaRequired: PropTypes.bool,
        privacyPolicyAccepted: PropTypes.bool,
        privacyPolicyAcceptedTimeStamp: PropTypes.object,
        termsOfUsePolicyAccepted: PropTypes.bool,
        termsOfUsePolicyAcceptedTimeStamp: PropTypes.object,
        termsAndConditionsPolicyAccepted: PropTypes.bool,
        termsAndConditionsPolicyAcceptedTimeStamp: PropTypes.object,
        accessibilityPolicyAccepted: PropTypes.bool,
        accessibilityPolicyAcceptedTimeStamp: PropTypes.object,
    },
    psPlus: {
        program: PropTypes.shape({
            programId: PropTypes.string,
            companyId: PropTypes.number,
        }),
    },
    acceptedProp65Warning: PropTypes.bool,
    companyLogo: PropTypes.object,
    quotesColumns: ColumnShape,
    repairsColumns: ColumnShape,
    ordersColumns: ColumnShape,
    myOrdersColumns: ColumnShape,
    awaitingPoColumns: ColumnShape,
    approvalColumns: ColumnShape,
    rgaColumns: ColumnShape,
    myOemColumns: ColumnShape,
    myOemOrdersColumns: ColumnShape,
    myDashboardColumns: ColumnShape,
    loanerColumns: ColumnShape,
    totalOrders: PropTypes.number,
    registration: PropTypes.object,
    loginMessage: PropTypes.object,
    orderView: PropTypes.shape({
        showGridLayout: PropTypes.bool,
        pageSize: PropTypes.number,
        maxPages: PropTypes.number,
        activePage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortDirection: PropTypes.string,
        userOnly: PropTypes.bool,
        description: PropTypes.string,
        filter: PropTypes.shape({
            startdate: PropTypes.instanceOf(moment),
            enddate: PropTypes.instanceOf(moment),
        }),
        ranges: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
        requestQuote: PropTypes.object,
    }),
    mobileMenuOpen: PropTypes.oneOf(Object.values(MobileMenu)),
    contractOrdersView: PropTypes.shape({
        currentTab: PropTypes.string,
        activePage: PropTypes.number,
        sortColumn: PropTypes.string,
        sortDirection: PropTypes.string,
        searchTerm: PropTypes.string,
        filter: PropTypes.shape({
            searchFacilityId: PropTypes.number,
            searchFacility: PropTypes.object,
        }),
    }),
    formularyRulesView: PropTypes.shape({
        pageSize: PropTypes.number,
    }),
    turnstileCompanies: PropTypes.arrayOf(PropTypes.number),
});

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    initializeUser: () => initializeUser(),
    checkProp65: () => checkProp65(),
    // setUserLists: (lists) => setUserLists(lists),
    getLists: () => getLists(),
    saveList: (payload) => saveList(payload),
    saveAdminList: (payload) => saveAdminList(payload),
    deleteList: (payload) => deleteList(payload),
    sortLists: (payload) => sortLists(payload),
    shareList: (payload, numberOfShares) => shareList(payload, numberOfShares),
    deleteListItem: (id, partNumber) => deleteListItem(id, partNumber),
    getSettings: () => getSettings(),
    getCachedSettings: () => getCachedSettings(),
    addLegacyQuoteItemToCart: (quoteOption) => addLegacyQuoteItemToCart(quoteOption),
    updatePartReceived: (lineItem) => updatePartReceived(lineItem),
    getOrderLines: (lineItemId) => getOrderLines(lineItemId),
    getContractProOrders: () => getContractProOrders(),
    getContractProFacilityOrders: (facilityId) => getContractProFacilityOrders(facilityId),
    getContractProOrderDetails: (orderId) => getContractProOrderDetails(orderId),
    setContractProOrdersFilter: (filter) => setContractProOrdersFilter(filter),
    setFormularyRulesFilter: (pageSize) => setFormularyRulesFilter(pageSize),
    setOrdersPredefinedFilters: (filters) => setOrdersPredefinedFilters(filters),
    setDashboardViewSettings: (filters) => setDashboardViewSettings(filters),
    getActiveListItems: (listName, facilityId, start, pageSize) => getActiveListItems(listName, facilityId, start, pageSize),
    setExpiredUser: (userName) => setExpiredUser(userName),
    getFacilities: () => getFacilities(),
    confirmAcknowledgement: () => confirmAcknowledgement(),
    getCompanyFields: (companyId) => getCompanyFields(companyId),
    saveUserReduxState: (user) => saveUserReduxState(user),
    getLineItemEvents: (lineItemId) => getLineItemEvents(lineItemId),
    saveContract: (inContract) => saveContract(inContract),
    loadQuotes: (lineItemIds, showAllQuotes) => loadQuotes(lineItemIds, showAllQuotes),
    setAcceptProp65: (accepted) => setAcceptProp65(accepted),
    setOemOrder: (oemOrder) => setOemOrder(oemOrder),
    openMobileMenu: (menu) => openMobileMenu(menu),
    closeMobileMenu: () => closeMobileMenu(),
    loadServiceOrders: (lineItemIds, showAllOrders) => loadServiceOrders(lineItemIds, showAllOrders),
    setAcceptPolicies: (policies) => setAcceptPolicies(policies),
    markEulaAccepted: () => markEulaAccepted(),
    saveWidget: (payload) => saveWidget(payload),
    editWidget: (payload) => editWidget(payload),
    deleteWidget: (id) => deleteWidget(id),
    getWidgets: () => getWidgets(),
    savePortal: (payload) => savePortal(payload),
    editPortal: (payload) => editPortal(payload),
    deletePortal: (payload) => deletePortal(payload),
    updatePortal: (portal) => updatePortal(portal),
    setWidgetsToPrint: (widgets) => setWidgetsToPrint(widgets),
    getPsPlusProgram: (programId) => getPsPlusProgram(programId),
    getPsPlusPrograms: (userId) => getPsPlusPrograms(userId),
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

function setOemOrder(oemOrder) {
    return (dispatch) => dispatch({ type: 'SET_OEM_ORDER', oemOrder: oemOrder });
}

function setAcceptProp65(accepted) {
    return (dispatch) => dispatch({ type: 'SET_PROP65_ACCEPT', accepted: accepted });
}

export function setAcceptPolicies(policies) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'POLICIES_ACCEPT_REQ' }),
            response: (response) => dispatch({ type: 'POLICIES_ACCEPT_RESP', response: response }),
            error: (error) => dispatch({ type: 'POLICIES_ACCEPT_ERR', response: error }),
        };

        bound.request();
        return axios.post('/ShoppingService/api/v1/account/policies', policies)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function markEulaAccepted() {
    return (dispatch) => {
        dispatch({ type: 'MARK_EULA_ACCEPTED' });
    };
}

export function initializeUser() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'INIT_USER_REQ' }),
            response: (response) => dispatch({ type: 'INIT_USER_RESP', response: response }),
            error: (error) => dispatch({ type: 'INIT_USER_ERR', response: error }),
        };

        bound.request();
        return axios.get('/ShoppingService/api/v1/account')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function saveList(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'CREATE_NEW_LIST' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'CREATE_NEW_LIST_FAILED', response: err }),
        };
        bound.request();

        return axios.post('/ShoppingService/api/v1/lists', payload).then(bound.response)
            .catch(bound.error);

    }
}
export function saveAdminList(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'CREATE_NEW_LIST' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'CREATE_NEW_LIST_FAILED', response: err }),
        };
        bound.request();

        return axios.post('/ShoppingService/api/v1/lists/admin', payload)
            .then(bound.response)
            .catch(bound.error);
    }
}

export function deleteList(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'DELETE_LIST' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'DELETE_LIST_FAILED', response: err }),
        };
        bound.request();

        return axios.delete('/ShoppingService/api/v1/lists/' + payload)
            .then(bound.response)
            .catch(bound.error);
    }
}

export function sortLists(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'SORT_LISTS' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'SORT_LIST_FAILED', response: err }),
        };
        bound.request();

        return axios.post('/ShoppingService/api/v1/lists/sort', payload)
            .then(bound.response)
            .catch(bound.error);
    }
}

export function deleteListItem(id, partNumber) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'DELETE_LIST_ITEM' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'DELETE_LIST_ITEM_FAILED', response: err }),
        };
        bound.request();

        return axios.delete(`/ShoppingService/api/v1/lists/list/${id}/item/${partNumber}`)
            .then(bound.response)
            .catch(bound.error);

    }
}

export function getLists() {
    return (dispatch) => {

        const bound = {
            request: () => dispatch({ type: 'GET_LISTS' }),
            response: (response) => dispatch({ type: 'GET_LISTS_RESP', response: response }),
            error: (err) => dispatch({ type: 'GET_LISTS_FAILED', response: err }),
        };
        bound.request();
        return axios.get('/ShoppingService/api/v1/lists').then(bound.response)
            .catch(bound.error);
    }
}

export function shareList(payload, numberOfShares) {
    return (dispatch) => {

        const bound = {
            request: () => dispatch({ type: 'SHARE_LIST' }),
            response: (response) => dispatch({ type: 'SHARE_LIST_RESP', response: { response, numberOfShares, ravenId: payload.ravenId } }),
            error: (err) => dispatch({ type: 'SHARE_LIST_FAILED', response: err }),
        };
        bound.request();

        return axios.post(`ShoppingService/api/v1/lists/share`, payload).then(bound.response).catch(bound.error)
    }

}

function checkProp65() {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'GET_HAS_ACCEPTED_PROP65_REQ' }),
            response: (response) => dispatch({ type: 'GET_HAS_ACCEPTED_PROP65_RESP', response: response }),
            error: (error) => dispatch({ type: 'GET_HAS_ACCEPTED_PROP65_ERR', response: error }),
        };

        bound.request();

        return axios.get(`/SettingsService/api/v1/acknowledgement`)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function confirmAcknowledgement() {
    return (dispatch) => {
        const bound = {
            getConfirmAcknowledgementReq: () => dispatch({
                type: 'GET_CONFIRM_ACKNOWLEDGEMENT_REQ',
            }),
            getConfirmAcknowledgementResp: response => dispatch({
                type: 'GET_CONFIRM_ACKNOWLEDGEMENT_RESP',
                response: response,
            }),
            getConfirmAcknowledgementErr: error => dispatch({
                type: 'GET_CONFIRM_ACKNOWLEDGEMENT_ERR',
                response: error,
            }),
        };

        bound.getConfirmAcknowledgementReq();

        return axios
            .get(`/SettingsService/api/v1/confirmAcknowledgement`)
            .then(bound.getConfirmAcknowledgementResp)
            .catch(bound.getConfirmAcknowledgementErr);
    }
}

export function getActiveListItems(listName, facilityId, start, pageSize) {
    return (dispatch) => {
        const bound = {
            getActiveListItemsRequest: () => dispatch({
                type: GET_ACTIVE_LIST_ITEMS_REQUEST,
            }),
            getActiveListItemsResponse: response => dispatch({
                type: GET_ACTIVE_LIST_ITEMS_RESPONSE,
                response: response,
            }),
            getActiveListItemsError: error => dispatch({
                type: GET_ACTIVE_LIST_ITEMS_ERROR,
                response: error,
            }),
        };

        bound.getActiveListItemsRequest();

        return axios
            .get(`/CatalogService/api/v1/list/${listName}?customerId=${facilityId}&limit=${pageSize}&start=${start}`)
            .then(bound.getActiveListItemsResponse)
            .catch(bound.getActiveListItemsError);
    };
}

function saveContract(inContract) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'SAVE_CONTRACT_REQ' }),
            response: (response) => dispatch({ type: 'SAVE_CONTRACT_RESP', response: response }),
            error: (error) => dispatch({ type: 'SAVE_CONTRACT_ERR', response: error }),
        };

        bound.request();

        if (inContract.contractId === 0) {
            return axios.post('/ShoppingService/api/v1/myoem/createContract', inContract)
                .then(bound.response)
                .catch(bound.error);
        } else {
            return axios.post('/ShoppingService/api/v1/myoem/saveContract', inContract)
                .then(bound.response)
                .catch(bound.error);
        }
    };
}

function loadQuotes(lineItemIds = [], showAllQuotes = undefined) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_QUOTES' }),
            response: (response) => dispatch({
                type: 'GET_QUOTES_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'GET_QUOTES_ERR',
                response: error,
            }),
        };

        bound.request();

        let request = { lineItemIds: lineItemIds || [], showAllOrders: showAllQuotes };
        return axios.post('/ShoppingService/api/v1/quotes/myOrders', request).then(bound.response).catch(bound.error);
    };
}

function loadServiceOrders(lineItemIds, showAllOrders) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_SERVICE_ORDERS' }),
            response: (response) => dispatch({
                type: 'GET_SERVICE_ORDERS_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'GET_SERVICE_ORDERS_ERR',
                response: error,
            }),
        };

        bound.request();

        let request = { lineItemIds: lineItemIds || [], showAllOrders };
        return axios.post('/ShoppingService/api/v1/service/orders', request).then(bound.response).catch(bound.error);
    };
}

export function getSettings() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_CONTACT_SETTINGS_REQ' }),
            response: (response) => dispatch({ type: 'GET_CONTACT_SETTINGS_RESP', response: response }),
            error: (error) => dispatch({ type: 'GET_CONTACT_SETTINGS_ERR', response: error }),
        };

        bound.request();
        return axios.get('/ShoppingService/api/v1/company/contact/settings').then(bound.response).catch(bound.error);
    };
}

export function getCachedSettings() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_CONTACT_SETTINGS_REQ' }),
            response: (response) => dispatch({ type: 'GET_CONTACT_SETTINGS_RESP', response: response }),
            error: (error) => dispatch({ type: 'GET_CONTACT_SETTINGS_ERR', response: error }),
        };

        bound.request();
        return axios.get('/ShoppingService/api/v1/company/contact/cachedSettings').then(bound.response).catch(bound.error);
    };
}

function getOrderLines(lineItemId) {

    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_ORDER_LINES' }),
            response: (response) => dispatch({
                type: 'GET_ORDER_LINES_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'GET_ORDER_LINES_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.get(`/ShoppingService/api/v1/orders/${lineItemId}`).then(bound.response).catch(bound.error);
    };
}

function getContractProOrders() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_CONTRACT_PRO_ORDERS' }),
            response: (response) => dispatch({
                type: 'GET_CONTRACT_PRO_ORDERS_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'GET_CONTRACT_PRO_ORDERS_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.get(`/FormularyService/api/v1/contracts/orders/`).then(bound.response).catch(bound.error);
    };
}

function getContractProFacilityOrders(facilityId) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_CONTRACT_PRO_FACILITY_ORDERS' }),
            response: (response) => dispatch({
                type: 'GET_CONTRACT_PRO_FACILITY_ORDERS_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'GET_CONTRACT_PRO_FACILITY_ORDERS_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.get(`/FormularyService/api/v1/contracts/orders/${facilityId}`).then(bound.response).catch(bound.error);
    };
}

function getContractProOrderDetails(orderId) {

    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_CONTRACT_PRO_ORDER_DETAILS' }),
            response: (response) => dispatch({
                type: 'GET_CONTRACT_PRO_ORDER_DETAILS_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'GET_CONTRACT_PRO_ORDER_DETAILS_ERR',
                response: error,
            }),
        };

        bound.request();
        return axios.get(`/FormularyService/api/v1/contracts/order/${orderId}`).then(bound.response).catch(bound.error);
    };
}

function getLineItemEvents(lineItemId) {

    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_LINE_ITEM_EVENTS' }),
            response: (response) => dispatch({
                type: 'GET_LINE_ITEM_EVENTS_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'GET_LINE_ITEM_EVENTS_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.get(`/ShoppingService/api/v1/lineitems/${lineItemId}/events`).then(bound.response).catch(bound.error);
    };
}

function getPsPlusProgram(programId) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_PS_PLUS_PROGRAM' }),
            response: (response) => dispatch({
                type: 'GET_PS_PLUS_PROGRAM_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'GET_PS_PLUS_PROGRAM_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.post(`/ShoppingService/api/v1/company/program`, { programId }).then(bound.response).catch(bound.error);
    };
}

function getPsPlusPrograms(userId) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_PS_PLUS_PROGRAMS' }),
            response: (response) => dispatch({
                type: 'GET_PS_PLUS_PROGRAMS_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'GET_PS_PLUS_PROGRAMS_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.get(`/ShoppingService/api/v1/company/programs?userId=${userId}`).then(bound.response).catch(bound.error);
    };
}

function setExpiredUser(userName) {

    return (dispatch) => {

        dispatch({ type: 'SET_EXPIRED_USER', expiredUserName: userName });
    };
}

function setOrdersPredefinedFilters(filters) {

    return (dispatch) => {

        dispatch({ type: 'SET_ORDERS_PREDEFINED_FILTERS', ordersPredefinedFilters: filters });
    };
}

export function setDashboardViewSettings(dashboardViewSettings) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'SET_DASHBOARD_VIEW_SETTINGS_REQ', dashboardViewSettings }),
            response: (response) => dispatch({
                type: 'SET_DASHBOARD_VIEW_SETTINGS_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'SET_DASHBOARD_VIEW_SETTINGS_ERR',
                response: error,
            }),
        };

        bound.request();

        const request = {
            DefaultCategories: dashboardViewSettings.selectedCategories,
            DefaultFacilities: dashboardViewSettings.selectedFacilities,
            DefaultModalities: dashboardViewSettings.selectedModalities,
            DefaultRequesters: dashboardViewSettings.selectedRequesters,
        }
        return axios.post('/ShoppingService/api/v1/account/save/defaultFilters', request)
            .then(bound.response)
            .catch(bound.error);
    };
}

function setContractProOrdersFilter(filters) {
    return (dispatch) => {

        dispatch({ type: 'SET_CONTRACT_PRO_ORDERS_FILTERS', filters });
    };
}
function setFormularyRulesFilter(pageSize) {
    return (dispatch) => {
        dispatch({ type: 'SET_FORMULARY_RULES_FILTERS', pageSize });
    };
}
function addLegacyQuoteItemToCart(quoteOption) {

    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'ADD_QUOTE_TO_CART_REQ' }),
            response: (response) => dispatch({
                type: 'ADD_QUOTE_TO_CART_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'ADD_QUOTE_TO_CART_ERR',
                response: error,
            }),
        };

        bound.request();
        return axios.post('/ShoppingService/api/v1/cart/addQuoteToCart', quoteOption)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function updatePartReceived(lineItem) {

    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'PART_RECEIVED_REQ' }),
            response: (response) => dispatch({
                type: 'PART_RECEIVED_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'PART_RECEIVED_ERR',
                response: error,
            }),
        };

        bound.request();

        let partReceived = {};
        partReceived.lineItemId = lineItem.lineItemId;
        partReceived.orderId = lineItem.orderId;
        partReceived.orderLineItemId = lineItem.statusId;

        return axios.post('/ShoppingService/api/v1/lineItems/received', partReceived).then(bound.response).catch(bound.error);
    };
}

export function getFacilities() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_FACILITIES_REQ' }),
            response: (response) => dispatch({ type: 'GET_FACILITIES_RESP', response: response }),
            error: (error) => dispatch({ type: 'GET_FACILITIES_ERR', response: error }),
        };

        bound.request();
        return axios.post('/ShoppingService/api/v1/cart/facilities').then(bound.response).catch(bound.error);
    };
}

export function getCompanyFields(companyId) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_COMPANY_FIELDS_REQ' }),
            response: (response) => dispatch({ type: 'GET_COMPANY_FIELDS_RESP', response: response }),
            error: (error) => dispatch({ type: 'GET_COMPANY_FIELDS_ERR', response: error }),
        };

        return axios.get(`/ShoppingService/api/v1/companies/${companyId}/fields`).then(bound.response).catch(bound.error);
    };
}

export function saveUserReduxState(changes) {
    return (dispatch, getState) => {
        const { user } = getState();
        let updatedUser = _.merge(_.cloneDeep(user), changes);
        const isNotLocalhost = window.location.hostname !== 'localhost';
        Cookies.set('facilityId', updatedUser.facility.facilityId, { expires: 30, 'secure': isNotLocalhost, sameSite: isNotLocalhost ? 'None' : undefined });
        dispatch({ type: 'SAVE_USER_REDUX', user: updatedUser });
    };
}

export function openMobileMenu(menu) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_MOBILE_MENU', menu });
    };
}

export function closeMobileMenu() {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_MOBILE_MENU', menu: MobileMenu.None });
    };
}

export function saveWidget(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'CREATE_NEW_WIDGET' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'CREATE_NEW_WIDGET_FAILED', response: err }),
        };
        bound.request();

        return axios.post('/SettingsService/api/v1/rp/widget/save', payload).then(bound.response)
            .catch(bound.error);

    }
}

export function getWidgets() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'GET_WIDGETS' }),
            response: (response) => dispatch({ type: 'GET_WIDGETS_RESP', response: response }),
            error: (err) => dispatch({ type: 'GET_WIDGETS_FAILED', response: err }),
        };
        bound.request();

        return axios.get('SettingsService/api/v1/rp/widgets').then(bound.response)
            .catch(bound.error);

    }
}

export function editWidget(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'EDIT_WIDGET' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'EDIT_WIDGET_FAILED', response: err }),
        };
        bound.request();

        return axios.put(`/SettingsService/api/v1/rp/widget/edit/${payload.ravenId}`, payload).then(bound.response)
            .catch(bound.error);

    }
}

export function deleteWidget(id) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'DELETE_WIDGET' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'DELETE_WIDGET_FAILED', response: err }),
        };
        bound.request();

        return axios.delete(`SettingsService/api/v1/rp/widget/${id}`).then(bound.response)
            .catch(bound.error);

    }
}

export function savePortal(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'CREATE_NEW_PORTAL' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'CREATE_NEW_PORTAL_FAILED', response: err }),
        };
        bound.request();

        return axios.post('/SettingsService/api/v1/rp/portal/save', payload).then(bound.response)
            .catch(bound.error);

    }
}

export function editPortal(payload) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'EDIT_PORTAL' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'EDIT_PORTAL_FAILED', response: err }),
        };
        bound.request();

        return axios.put(`/SettingsService/api/v1/rp/portal/edit/${payload.ravenId}`, payload).then(bound.response)
            .catch(bound.error);

    }
}

export function deletePortal(id) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'DELETE_PORTAL' }),
            response: (response) => dispatch({ type: 'GENERAL_RESPONSE', response: response }),
            error: (err) => dispatch({ type: 'DELETE_PORTAL_FAILED', response: err }),
        };
        bound.request();

        return axios.delete(`/SettingsService/api/v1/rp/portal/${id}`).then(bound.response)
            .catch(bound.error);

    }
}

export function updatePortal(portal) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_PORTAL_WIDGETS', portal });
    };
}

export function setWidgetsToPrint(widgets) {
    return (dispatch, getState) => {
        dispatch({ type: 'SET_WIDGETS_TO_PRINT', widgets });
    };
}

export function setPsProgram(payload) {
    return (dispatch) => {
        dispatch({ type: 'SET_PS_PROGRAM', payload });
    }
}

export const reducer = (state = _.cloneDeep(defaultUserState), action = null) => {
    switch (action.type) {
        case 'POLICIES_ACCEPT_RESP':
            return {
                ...state,
                info: {
                    ...state.info,
                    privacyPolicyAccepted: true,
                    privacyPolicyAcceptedTimeStamp: moment().toDate(),
                    termsOfUsePolicyAccepted: true,
                    termsOfUsePolicyAcceptedTimeStamp: moment().toDate(),
                    termsAndConditionsPolicyAccepted: true,
                    termsAndConditionsPolicyAcceptedTimeStamp: moment().toDate(),
                    accessibilityPolicyAccepted: true,
                    accessibilityPolicyAcceptedTimeStamp: moment().toDate(),
                },
            };
        case 'MARK_EULA_ACCEPTED': {
            const acceptedState = {
                eulaRequired: false,
                privacyPolicyAccepted: true,
                privacyPolicyAcceptedTimeStamp: moment().toDate(),
            };
            const updatedInfo = _.merge({}, state.info, acceptedState);
            return { ...state, info: updatedInfo };
        }
        case 'SET_DASHBOARD_VIEW_SETTINGS_REQ': {
            const { dashboardViewSettings } = action;
            let dashboardSettings = { ...state.dashboardViewSettings };

            if (dashboardViewSettings.selectedRequesters && dashboardViewSettings.selectedRequesters.length) {
                dashboardSettings.selectedRequesters = dashboardViewSettings.selectedRequesters.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            if (dashboardViewSettings.selectedCategories && dashboardViewSettings.selectedCategories.length) {
                dashboardSettings.selectedCategories = dashboardViewSettings.selectedCategories.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            if (dashboardViewSettings.selectedModalities && dashboardViewSettings.selectedModalities.length) {
                dashboardSettings.selectedModalities = dashboardViewSettings.selectedModalities.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            if (dashboardViewSettings.selectedFacilities && dashboardViewSettings.selectedFacilities.length) {
                dashboardSettings.selectedFacilities = dashboardViewSettings.selectedFacilities.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            return { ...state, isBusy: false, dashboardViewSettings: dashboardSettings };
        }
        case 'SET_DASHBOARD_VIEW_SETTINGS_REQ': {
            const { dashboardViewSettings } = action;
            let dashboardSettings = { ...state.dashboardViewSettings };

            if (dashboardViewSettings.selectedRequesters && dashboardViewSettings.selectedRequesters.length) {
                dashboardSettings.selectedRequesters = dashboardViewSettings.selectedRequesters.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            if (dashboardViewSettings.selectedCategories && dashboardViewSettings.selectedCategories.length) {
                dashboardSettings.selectedCategories = dashboardViewSettings.selectedCategories.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            if (dashboardViewSettings.selectedModalities && dashboardViewSettings.selectedModalities.length) {
                dashboardSettings.selectedModalities = dashboardViewSettings.selectedModalities.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            if (dashboardViewSettings.selectedFacilities && dashboardViewSettings.selectedFacilities.length) {
                dashboardSettings.selectedFacilities = dashboardViewSettings.selectedFacilities.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            return { ...state, isBusy: false, dashboardViewSettings: dashboardSettings };
        }
        case 'INIT_USER_REQ':
        case 'SAVE_USER_REQ':
        case 'GET_HAS_ACCEPTED_PROP65_REQ':
        case GET_ACTIVE_LIST_ITEMS_REQUEST:
        case 'POLICIES_ACCEPT_REQ':
            return { ...state, isBusy: true };

        case 'INIT_USER_RESP':
        case 'SAVE_USER_RESP': {
            const { response: { data: { lists, dashboardViewSettings: savedSettings } } } = action;
            let dashboardSettings = { ...state.dashboardViewSettings };

            if (savedSettings.defaultRequesters && savedSettings.defaultRequesters.length) {
                dashboardSettings.selectedRequesters = savedSettings.defaultRequesters.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            if (savedSettings.defaultCategories && savedSettings.defaultCategories.length) {
                dashboardSettings.selectedCategories = savedSettings.defaultCategories.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            if (savedSettings.defaultModalities && savedSettings.defaultModalities.length) {
                dashboardSettings.selectedModalities = savedSettings.defaultModalities.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            }

            if (savedSettings.defaultFacilities && savedSettings.defaultFacilities.length) {
                dashboardSettings.selectedFacilities = savedSettings.defaultFacilities.reduce((obj, item) => Object.assign(obj, { [item.id]: item.name }), {});
            } else {
                const { facilities, settings: { defaultFacilityId } } = state;
                const defaultFacility = facilities.find(f => f.facilityId === defaultFacilityId);
                dashboardSettings.selectedFacilities = defaultFacility ? { [defaultFacilityId]: defaultFacility.facilityName } : { '-1': 'All Facilities' };
            }

            return { ...state, isBusy: false, dashboardViewSettings: dashboardSettings };
        }

        case 'GET_HAS_ACCEPTED_PROP65_RESP':
            return { ...state, acceptedProp65Warning: action.response.data.accepted, isBusy: false }

        case 'GET_CONFIRM_ACKNOWLEDGEMENT_REQ':
            return { ...state, isBusy: true };

        case 'GET_CONFIRM_ACKNOWLEDGEMENT_RESP':
            let { settings } = state;
            settings.hasAcceptedProp65 = action.response.data.success;
            return { ...state, settings, isBusy: false };

        case 'SAVE_CONTRACT_RESP':
            return { ...state, contract: action.contract };

        case 'INIT_USER_ERR':
        case 'SAVE_USER_ERR':
        case 'SET_DASHBOARD_VIEW_SETTINGS_ERR':
        case 'GET_CONTACT_SETTINGS_ERR':
        case GET_ACTIVE_LIST_ITEMS_ERROR:
        case 'POLICIES_ACCEPT_ERR':
            console.log('User store error');
            return { ...state, isBusy: false };

        case 'GET_HAS_ACCEPTED_PROP65_ERR':
        case 'GET_CONFIRM_ACKNOWLEDGEMENT_ERR':
            return { ...state, isBusy: false };

        case 'GET_CONTACT_SETTINGS_RESP':
            let { response: { data } } = action;
            sessionStorage.userFields = JSON.stringify(data.settings.requiredFields);
            return {
                ...state,
                settings: data.settings,
                info: data.userInfo,
                acceptedProp65Warning: data.prop65accepted,
                companyLogo: data.companyLogo,
                turnstileCompanies: process.env.REACT_APP_TURNSTILE_COMPANIES?.split(',').map(Number),
            };
        case 'GET_ORDERS_RESP':
            return { ...state, isBusy: true, orders: action.orders, totalOrders: action.totalOrders };
        case 'GET_FACILITIES_RESP':
            {
                const { response: {
                    data,
                } } = action;

                return { ...state, facilities: data };
            }

        case GET_ACTIVE_LIST_ITEMS_RESPONSE:
            return { ...state, isBusy: false, listItems: action.response.data };

        case 'GET_COMPANY_FIELDS_RESP':
            {
                let { response: { data } } = action;

                if (sessionStorage.urlFields) {
                    const fields = JSON.parse(sessionStorage.urlFields);

                    _.forEach(fields, x => {
                        let f = data.filter(d => d.fieldUid === x.fieldUid);
                        if (f.length > 0) {
                            f[0].value = x.value;
                        }
                    });
                }

                sessionStorage.fields = JSON.stringify(data);
                sessionStorage.removeItem('urlFields');
                return { ...state, companyFields: data };
            }

        case 'GET_QUOTES_RESP':
            {
                const { response: { data } } = action;
                return { ...state, quotes: data.records };
            }

        case 'GET_SERVICE_ORDERS_RESP':
            {
                const { response: { data } } = action;
                return { ...state, serviceOrders: data };
            }
        case 'GET_PS_PLUS_PROGRAM_RESP':
            return {
                ...state, psPlus: {
                    ...state.psPlus,
                    program: action.response.data,
                }
            }
        case 'GET_PS_PLUS_PROGRAMS_RESP':
            const facilities = action.response.data.map((f, i) => {
                return { title: f.companyName, value: f.programId, checked: i === 0 }
            });

            return {
                ...state, psPlus: {
                    ...state.psPlus,
                    facilities,
                }
            }
        case 'SET_OEM_ORDER':
            return { ...state, oemOrder: action.oemOrder };

        case 'SET_PROP65_ACCEPT':
            return { ...state, acceptedProp65Warning: action.accepted };

        case 'SAVE_USER_REDUX':
            return { ...action.user };

        case 'SET_EXPIRED_USER':
            return { ...state, expiredUserName: action.expiredUserName };

        case 'SET_ORDERS_COLUMNS':
            return { ...state, ordersColumns: action.ordersColumns };

        case 'SET_ORDERS_FILTERS':
            return { ...state, ordersFilters: action.ordersFilters };

        case 'SET_ORDERS_PREDEFINED_FILTERS':
            return { ...state, ordersPredefinedFilters: action.ordersPredefinedFilters };

        case 'SET_DASHBOARD_VIEW_SETTINGS_RESP':
            return { ...state };

        case 'SET_DASHBOARD_VIEW_SETTINGS_RESP':
            return { ...state };

        case 'SET_CONTRACT_PRO_ORDERS_FILTERS':
            return { ...state, contractOrdersView: action.filters };

        case 'SET_MOBILE_MENU':
            return { ...state, mobileMenuOpen: action.menu };

        case 'SET_PORTAL_WIDGETS':
            return { ...state, setting: { ...settings, portal: action.portal } }

        case 'SET_WIDGETS_TO_PRINT':
            return { ...state, widgetsToPrint: action.widgets }

        case 'SET_FORMULARY_RULES_FILTERS':
            const { pageSize } = action;
            if (pageSize)
                localStorage.setItem('formularyRulesPageSize', JSON.stringify(pageSize));
            return { ...state, formularyRulesView: pageSize };

        case 'GET_LISTS_RESP':
            {
                const { response: { data } } = action;
                return {
                    ...state,
                    lists: data,
                };
            }

        case 'GET_WIDGETS_RESP':
            {
                const { response: { data } } = action;
                return {
                    ...state,
                    widgets: data,
                };
            }

        case 'SHARE_LIST_RESP':
            return { ...state, isBusy: false };

        case 'GENERAL_RESPONSE':
            return { ...state, isBusy: false };

    }
    return { ...state };
};
