import React, {useEffect, useState, useRef} from 'react';
import styled, {css} from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-regular-svg-icons/faChevronDown';
import {faChevronUp} from '@fortawesome/pro-regular-svg-icons/faChevronUp';

const Container = styled.div`
    display: block;
    margin-top: 7px;
`;

const Content = styled.p`
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    display: block;
    max-height: 46px;
    height: auto;
    overflow: hidden;

    ${props => props.$isExpanded && css`
        overflow: visible;
        height: auto;
        max-height: initial;
    `}
`;

const Chevron = styled(FontAwesomeIcon)`
    font-weight: normal;
    font-style: normal;
    text-decoration: none;
    font-size: 14px;
`;

const Description = ({children}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const contentRef = useRef();
    const [isExpandable, setIsExpandable] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (contentRef.current) {
                setIsExpandable(parseInt(contentRef.current.scrollHeight) > 46);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (<Container onClick={() => setIsExpanded(!isExpanded)}>
        <Content ref={contentRef} $isExpanded={isExpanded}>{children}</Content>
        {isExpandable && <div>
            <Chevron icon={isExpanded ? faChevronUp : faChevronDown} />
        </div>}
    </Container>);
};

export default Description;
