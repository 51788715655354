import axios from 'axios';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {objectToPropTypes} from 'utility';

const GET_SITE_SETTINGS_REQ = 'GET_SITE_SETTINGS_REQ';
const GET_SITE_SETTINGS_RESP = 'GET_SITE_SETTINGS_RESP';
const GET_SITE_SETTINGS_ERR = 'GET_SITE_SETTINGS_ERR';
const SAVE_SITE_SETTINGS_REQ = 'SAVE_SITE_SETTINGS_REQ';
const SAVE_SITE_SETTINGS_RESP = 'SAVE_SITE_SETTINGS_RESP';
const SAVE_SITE_SETTINGS_ERR = 'SAVE_SITE_SETTINGS_ERR';
const GET_MODALITIES_REQ = 'GET_MODALITIES_REQ';
const GET_MODALITIES_RESP = 'GET_MODALITIES_RESP';
const GET_MODALITIES_ERR = 'GET_MODALITIES_ERR';
const GET_OPSDASH_CATEGORIES_REQ = 'GET_OPSDASH_CATEGORIES_REQ';
const GET_OPSDASH_CATEGORIES_RESP = 'GET_OPSDASH_CATEGORIES_RESP';
const GET_OPSDASH_CATEGORIES_ERR = 'GET_OPSDASH_CATEGORIES_ERR';
const GET_L1_CATEGORIES_REQ = 'GET_L1_CATEGORIES_REQ';
const GET_L1_CATEGORIES_RESP = 'GET_L1_CATEGORIES_RES';
const GET_L1_CATEGORIES_ERR = 'GET_L1_CATEGORIES_ERR';
const GET_COMPANY_TYPES_REQ = 'GET_COMPANY_TYPES_REQ';
const GET_COMPANY_TYPES_RESP = 'GET_COMPANY_TYPES_RESP';
const GET_COMPANY_TYPES_ERR = 'GET_COMPANY_TYPES_ERR';
const GET_CUSTOMER_TIERS_REQ = 'GET_CUSTOMER_TIERS_REQ';
const GET_CUSTOMER_TIERS_RESP = 'GET_CUSTOMER_TIERS_RESP';
const GET_CUSTOMER_TIERS_ERR = 'GET_CUSTOMER_TIERS_ERR';
const GET_PRICINGS_REQ = 'GET_PRICINGS_REQ';
const GET_PRICINGS_RESP = 'GET_PRICINGS_RESP';
const GET_PRICINGS_ERR = 'GET_PRICINGS_ERR';

const defaultState = {
    isBusy: false,
    configuration: {
        features: {
            oemConnectOrder: true,
            notifications: true,
            dashboard: true,
            qualtricsEnableCollectPriceFeedback: false,
            qualtricsEnableCollectListingFeedback: false,
            psPlusContent: false,
        },
    },
    siteSettings: {
        qualtricsEnableCollectPriceFeedback: false,
        qualtricsEnableCollectListingFeedback: false,
        apolloSisenseDashboardId: '',
        apolloSisenseIe11DashboardId: '',
        apolloSisenseTestDashboardId: '',
        hideNoEsdMessaging: true,
        oktaAuthentication: true,
        disableUpsCostRule: false
    },
    modalities: [],
    opsDashboardCategories: [],
    l1categories: [],
    siteSettingsLoaded: false,
    companyTypes: [],
    customerTiers: [],
    pricings: []
};

export const StateShape = objectToPropTypes(defaultState);

export const actionCreators = {
    loadConfiguration,
    saveConfiguration,
    loadSiteSettings,
    saveSiteSettings,
    loadModalities,
    loadCompanyTypes,
    loadCustomerTiers,
    loadPricings
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

export function loadConfiguration() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: 'LOAD_CFG_REQUEST',
            }),
            response: response => dispatch({
                type: 'LOAD_CFG_RESPONSE',
                response: response,
            }),
            error: error => dispatch({
                type: 'LOAD_CFG_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.get(`/ShoppingService/api/v1.0/configuration`)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function saveConfiguration (features) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: 'SAVE_CFG_REQUEST',
            }),
            response: response => dispatch({
                type: 'SAVE_CFG_RESPONSE',
                response: response,
            }),
            error: error => dispatch({
                type: 'SAVE_CFG_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.post(`/ShoppingService/api/v1.0/configuration`, features)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function loadSiteSettings() {
    return (dispatch) => {
        const actions = {
            request: () => dispatch({
                type: GET_SITE_SETTINGS_REQ,
            }),
            response: response => dispatch({
                type: GET_SITE_SETTINGS_RESP,
                response,
            }),
            error: error => dispatch({
                type: GET_SITE_SETTINGS_ERR,
                response: error,
            }),
        };

        actions.request();

        return axios
            .get(`/SettingsService/api/v1/siteSettings`)
            .then(actions.response)
            .catch(actions.error);
    };
}

export function saveSiteSettings(settings) {
    return (dispatch) => {
        const actions = {
            request: () => dispatch({
                type: SAVE_SITE_SETTINGS_REQ,
            }),
            response: response => dispatch({
                type: SAVE_SITE_SETTINGS_RESP,
                response,
            }),
            error: error => dispatch({
                type: SAVE_SITE_SETTINGS_ERR,
                response: error,
            }),
        };

        actions.request();

        return axios
            .post(`/SettingsService/api/v1/siteSettings`, settings)
            .then(actions.response)
            .catch(actions.error);
    };
}

export function loadModalities() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: GET_MODALITIES_REQ,
            }),
            response: response => dispatch({
                type: GET_MODALITIES_RESP,
                response: response,
            }),
            error: error => dispatch({
                type: GET_MODALITIES_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.get('ShoppingService/api/v1/reportingPortal/modalities')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function loadOpsDashboardCategories() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: GET_OPSDASH_CATEGORIES_REQ,
            }),
            response: response => dispatch({
                type: GET_OPSDASH_CATEGORIES_RESP,
                response: response,
            }),
            error: error => dispatch({
                type: GET_OPSDASH_CATEGORIES_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.get('FormularyService/api/v1/opsdashboardcategories')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function loadL1Categories() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: GET_L1_CATEGORIES_REQ,
            }),
            response: response => dispatch({
                type: GET_L1_CATEGORIES_RESP,
                response: response,
            }),
            error: error => dispatch({
                type: GET_L1_CATEGORIES_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.get('FormularyService/api/v1/categories/l1')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function loadCompanyTypes() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: GET_COMPANY_TYPES_REQ,
            }),
            response: response => dispatch({
                type: GET_COMPANY_TYPES_RESP,
                response: response,
            }),
            error: error => dispatch({
                type: GET_COMPANY_TYPES_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.get('/ShipIntegrationService/api/v1/Shipping/CompanyTypes')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function loadCustomerTiers() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: GET_CUSTOMER_TIERS_REQ,
            }),
            response: response => dispatch({
                type: GET_CUSTOMER_TIERS_RESP,
                response: response,
            }),
            error: error => dispatch({
                type: GET_CUSTOMER_TIERS_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.get('/ShipIntegrationService/api/v1/Shipping/CustomerTiers')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function loadPricings() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: GET_PRICINGS_REQ,
            }),
            response: response => dispatch({
                type: GET_PRICINGS_RESP,
                response: response,
            }),
            error: error => dispatch({
                type: GET_PRICINGS_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.get('/ShipIntegrationService/api/v1/Shipping/GetPricings')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function reducer(state = _.cloneDeep(defaultState), action = null) {
    switch (action.type) {
        case 'LOAD_CFG_REQUEST':
        case 'SAVE_CFG_REQUEST':
        case GET_SITE_SETTINGS_REQ:
        case SAVE_SITE_SETTINGS_REQ:
        case GET_MODALITIES_REQ:
            return {...state, isBusy: true};

        case 'LOAD_CFG_RESPONSE':
            return {...state, isBusy: false, configuration: action.response.data};

        case GET_SITE_SETTINGS_RESP: {
            const siteSettings = {
                settings: action.response.data.settings,
                ...action.response.data.settings.reduce((result, {settingType, settingCode, value}) => ({
                    ...result,
                    [_.camelCase(settingCode)]: settingType === 'F' ? value === 'Y' : value,
                }), {}),
            }
            return {
                ...state,
                isBusy: false,
                siteSettings,
                siteSettingsLoaded: true,
            };
        }

        case GET_MODALITIES_RESP:
            return {...state, isBusy: false, modalities: action.response.data};
        case GET_OPSDASH_CATEGORIES_RESP:
            return {...state, isBusy: false, opsDashboardCategories: action.response.data.result};
        case GET_L1_CATEGORIES_RESP:
            return {...state, isBusy: false, l1categories: action.response.data};
        case GET_COMPANY_TYPES_RESP:
            return {...state, isBusy: false, companyTypes: action.response.data.map(c => { return { value: c.groupItemId, text: c.description } })};
        case GET_CUSTOMER_TIERS_RESP:
            return {...state, isBusy: false, customerTiers: action.response.data.map(c => { return { value: c.code, text: c.description } })};
        case GET_PRICINGS_RESP:
            return {...state, isBusy: false, pricings: action.response.data};

        case 'LOAD_CFG_ERR':
        case 'SAVE_CFG_RESPONSE':
        case 'SAVE_CFG_ERR':
        case SAVE_SITE_SETTINGS_RESP:
        case SAVE_SITE_SETTINGS_ERR:
        case GET_SITE_SETTINGS_ERR:
        case GET_MODALITIES_ERR:
            return {...state, isBusy: false};

    }
    return state;
}
