import axios from 'axios';
import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import moment from 'moment';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import _ from 'lodash';
import Acknowledgement from 'components/Acknowledgement';
import AddToCartConfirmation from 'components/AddToCartConfirmation';
import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm'
import FacilityDialog from 'components/FacilitySearchDialog';
import {Popup, Button} from '@partssourceinc/react-ui-core';
import OnCreditHoldModal from 'components/OnCreditHoldModal';
import {QuantitySelector} from '@partssourceinc/react-ui-core';
import RepairQuantitySelector from 'components/RepairQuantitySelector'
import WarrantyPopUpForm from 'components/WarrantyPopUpForm';
import {getCompanyAndVendorFields, getPartCondition, getPurchaseChoice, saveAssetInformationToStorage, getFormularyDisplayType, getOptionBasePrice} from 'productUtility';
import * as CartStore from 'stores/Cart';
import * as UserStore from 'stores/User';
import * as NetworkStore from 'stores/Network';
import * as ParamsStore from 'stores/Params';
import {getCookie, logEvent, isQuotedRepair, formatMoney} from 'utility';
import 'less/addToCartButton.less';
import 'less/addtocartconfirmation.less';
import 'less/carousel/carousel.less';
import 'less/requestquote.less';

export const DisplayType = {
    BuyItAgain: 'BuyItAgain',
    CartPanel: 'CartPanel',
    Lists: 'Lists',
    RequestQuote: 'RequestQuote',
};

@withRouter
@connect((state) => ({user: state.user, params: state.params, sessionStorageAvailable: state.network.sessionStorageAvailable}), _.merge({}, CartStore.actionCreators, UserStore.actionCreators, NetworkStore.actionCreators))
export class AddToCartButton extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        option: PropTypes.object.isRequired,
        quantity: PropTypes.number.isRequired,
        disabled: PropTypes.bool,
        checkForWarranty: PropTypes.bool,
        checkForAcknowldegement: PropTypes.bool,
        isQuote: PropTypes.bool,
        buttonText: PropTypes.string,
        hadContractOptions: PropTypes.bool,
        // displayType: PropTypes.oneOf(Object.keys.DisplayType),
        ...ReactRouterPropTypes,
        ...CartStore.ActionShape,
        ...UserStore.ActionShape,
        user: UserStore.StateShape,
        checkout: CartStore.StateShape.checkout,
        params: ParamsStore.StateShape,
        quoteSource: PropTypes.string,
    };

    static defaultProps = {
        checkForWarranty: false,
        checkForAcknowldegement: false,
        isQuote: false,
        buttonText: null,
        displayType: DisplayType.BuyItAgain,
        showCreditHoldModal: false,
    };

    constructor(props) {
        super(props);
        const {product: {priority}, user: {settings: {requiredFields}}} = this.props;

        this.state = {
            customFields: [],
            quantity: props.quantity || 1,
            showAssetInformationPopup: false,
            showAddToCartConfirmation: false,
            showAcknowledgement: false,
            showFacilityDialog: false,
            showWarrantyPopUp: false,
            warrantyData: null,
            isUrgency: priority || false,
            formularyFields: [],
            loanerTermsAcknowledged: false,
            onSiteService: {},
            showShippingFields: (props.showShippingFields && props.IsQuote) || false,
            shippingAddresses: props.shippingAddresses || [],
            qtyFocused: false,
            isExchangeTermsChecked: false,
        };
        this.checkRequiredFields = this.checkRequiredFields.bind(this);
    }

    componentWillReceiveProps = (newProps) => {
        const {quantity} = this.props;

        if (newProps.quantity !== quantity) {
            this.setState({quantity: parseInt(newProps.quantity || '')});
        }
    }

    checkForWarranty = () => {
        const ASSET_KEY = '11111111-1111-1111-1111-111111111111';
        const {user: {facility}, product, option, sessionStorageAvailable} = this.props;
        const {customFields} = this.state;

        const storageData = (sessionStorageAvailable && sessionStorage.fields) ? JSON.parse(sessionStorage.fields) : [];
        const fieldsFromStorage = sessionStorageAvailable ? storageData.filter(f => customFields.find(f2 => f2.fieldUid === f.fieldUid)) : [];
        const assetWithKey = storageData.find(f => f.fieldUid === ASSET_KEY);
        const asset = assetWithKey ? assetWithKey.value : null;

        if (!facility || !facility.facilityId || !(facility.facilityId > 0)) {
            this.setState({showWarrantyPopUp: false});
            return;
        }
        if (!asset) {
            this.addToCart(fieldsFromStorage);
        } else {
            const data = {
                partNumber: product.displayPartNumber,
                oemId: product.manufacturerId,
                conditionId: (option && option.lineItemCondition) || 0,
                assetId: asset,
                facilityId: facility.facilityId,
            };

            axios.post('/ShoppingService/api/v1/lineItems/warrantycheck', data).then(response => {
                if (response.data.length > 0) {
                    let warrantydata = response.data[0];
                    let logDetails = [];
                    let currDate = new Date();
                    const id_ins = getCookie('id_ins');

                    logEvent('Warranty Popup', {
                        id_ins: getCookie('id_ins'),
                        'Part O-String': product.id ? product.id : '',
                        'Part V-String': option.vendorItemNumber ? option.vendorItemNumber : '',
                        'Warranty length': `${warrantydata.daysCovered}days`,
                        'Remaining warranty': `${parseInt((moment(warrantydata.warrantyExpirationDate).toDate() - currDate) / (1000 * 60 * 60 * 24))}days`,
                    });

                    this.setState({showWarrantyPopUp: true, showAssetInformationPopup: false, warrantydata});
                } else {
                    this.setState({showWarrantyPopUp: false, showAssetInformationPopup: false});
                    this.addToCart(fieldsFromStorage);
                }
            });
        }
    }

    checkRequiredFields = () => {
        const {user: {facility}, product, option, history, isQuote, displayType, checkForWarranty, hasLoanerAdded} = this.props;
        const {quantity} = this.state;
        const {lineItemCondition, isRepairOption} = option;
        const {productTypeId} = product;

        if (!facility || !facility.facilityId || !(facility.facilityId > 0)) {
            this.setState({showFacilityDialog: true});
            return;
        }

        const isQuotedRepair = lineItemCondition === 23 || lineItemCondition === 25;

        if (!isQuotedRepair && (option.isRepairOption || product.productTypeId == 6) && quantity > 1) {
            history.push({
                pathname: '/repairwizard',
                state: {
                    product,
                    option,
                    quantity,
                    isQuote,
                    hasLoanerAdded,
                },
            });
            return;
        } else if(isQuotedRepair && (option.isRepairOption || product.productTypeId == 6)){
            history.push({
                pathname: '/request-quoted-repair',
                state: {
                    product,
                    option,
                    quantity,
                    isQuote,
                    hasLoanerAdded,
                    shouldAutoSource: displayType === DisplayType.CartPanel
                },
            });
            return;

        }

        this.handleAcknowledgement();
    }

    handleAcknowledgement = () => {
        const {
            user: {settings, facility},
            option,
            checkForAcknowldegement,
            hasOnSiteService,
            showShippingFields} = this.props;

        const containsChemicals = option.attributes && option.attributes.filter(a => a.name.toLowerCase() === 'prop65r' || a.name.toLowerCase() === 'prop65c');
        
        let hasUnvalidatedContract = option && option.formularySetting && option.formularySetting.hasUnvalidatedContract;
        let omitContractFields = option.isContractProOption && hasUnvalidatedContract;
        let selectedFacility = (facility && facility.facilityId) ? facility.facilityId : 0;  
        const customFields = getCompanyAndVendorFields(false, omitContractFields, option, selectedFacility);

        const hasRequiredFields = customFields.filter(x => x.isRequired).length > 0 || customFields.filter(x => x.fieldType !== 2 && x.value && x.value !== null && x.value !== '').length > 0;
        const showAcknowledgement = checkForAcknowldegement && containsChemicals && containsChemicals.length > 0 && !settings.hasAcceptedProp65;
        const showFormulary = !option.isFormularyOption && option.formularySetting && option.formularySetting.approvalRequired;

        if (showAcknowledgement) {
            this.setState({showAcknowledgement});
        } else if (hasRequiredFields || hasOnSiteService || showShippingFields) {
            this.setState({showAssetInformationPopup: true, customFields: customFields}, () => console.log('showAssetInformationPopup set'));
        } else if (settings.statOrder || showFormulary || option.purchaseChoice === 2) {
            this.setState({showAssetInformationPopup: true, customFields: customFields});
        } else {
            this.addToCart(customFields);
        }
    }

    acknowledgeRisk = () => {
        const {option, confirmAcknowledgement, user: {facility}} = this.props;
        if (!confirmAcknowledgement()) {
            console.log('Error in confirmAcknowledgement');
            return;
        }
        let selectedFacility = (facility && facility.facilityId) ? facility.facilityId : 0;  
        let customFields = getCompanyAndVendorFields(false, false, option, selectedFacility);
        const hasRequiredFields = customFields.filter(x => x.isRequired).length > 0;

        if (hasRequiredFields) {
            this.setState({showAssetInformationPopup: true, showAcknowledgement: false, customFields: customFields});
        } else {
            this.setState({showAcknowledgement: false});
            this.addToCart(customFields);
        }
    }

    addToCart(customFields) {
        const {
            loadUserCart,
            user: {facility, info: {contactId}},
            product,
            option,
            isQuote,
            params,
            hasOnSiteService,
            location: {pathname},
            quoteSource,
            showShippingFields,
            sessionStorageAvailable,
            hasLoanerAdded,
            assetSiteId,
            assetFacilityId,
            outrightListPrice} = this.props;
        const {quantity, isUrgency, formularyFields, onSiteService, shippingFields, isExchangeTermsChecked} = this.state;

        this.setState({addingToCart: true});

        const selectedOption = option;
        const partImage = selectedOption.images && selectedOption.images[0].image177;
        const NOTE_KEY = '99999999-9999-9999-9999-999999999999';

        const storageData = (sessionStorageAvailable && sessionStorage.fields) ? JSON.parse(sessionStorage.fields) : [];
        let note = storageData.find(f => f.fieldUid === NOTE_KEY);
        if (customFields.length === 0 && note !== null) {
            customFields.push(note);
        }       

        const fieldValues = customFields || [];
        const id_ins = getCookie('id_ins');
        let cartItem = {
            UserId: contactId,
            CompanyId: facility.facilityId,
            GroupId: facility.groupId,
            RequesterId: contactId,
            PhoneNumber: facility.phoneNumber,
            VendorId: (selectedOption && selectedOption.vendorId) || 0,
            ConditionId: (selectedOption && selectedOption.lineItemCondition) || 0,
            purchaseChoiceId: (selectedOption && selectedOption.purchaseChoice) || 0,
            ResearchRedisId: product.researchRedisId,
            Quantity: quantity,
            ProductId: product.id,
            FakeRefurb: selectedOption.fakeRefurb,
            OemId: product.manufacturerId,
            partNumber: product.displayPartNumber,
            PartNumberStripped: product.partNumber,
            description: selectedOption.description,
            ImagePath: partImage,
            WarrantyId: (selectedOption && selectedOption.lineItemWarranty) || 0,
            Fields: fieldValues && fieldValues.filter(x => !x.formularyField) || [],
            IsQuote: isQuote,
            IsProStockItem: (selectedOption && selectedOption.isProStockItem) || false,
            UrgencyId: isUrgency,
            CustomerOrderKey: (sessionStorageAvailable && sessionStorage.wo_id) ? sessionStorage.wo_id : '',
            FormularyFields: formularyFields,
            Id_ins: id_ins,
            ContractProId: selectedOption && selectedOption.isContractProOption ? selectedOption.formularySetting.contractId : '',
            OemItemNumber: selectedOption.oemItemNumber,
            AddLoaner: hasLoanerAdded,
            ContractValidated: selectedOption && selectedOption.formularySetting ? !selectedOption.formularySetting.hasUnvalidatedContract : false,
            VariantId: selectedOption.variantId,
            FormularyId: selectedOption.formularySetting?.formularyId || '',
            FormularyRuleName: selectedOption.formularySetting?.formularyRuleName || '',
            FormularySuppressionBehavior: selectedOption.formularySetting?.suppressionBehavior || 0,
            IsExchangeTermsChecked: isExchangeTermsChecked,
        };

        if (hasOnSiteService) {
            cartItem.AddOnSiteService = true;
        }
        if (showShippingFields) {
            cartItem.ShippingSpeedId = shippingFields.selectedShipSpeedId;
            cartItem.ShippingAddressId = shippingFields.selectedShipAddress.addressId
        }
        if (cartItem.ImagePath === 'https://assets.partsfinder.com/image/') cartItem.ImagePath = '';

        if (assetSiteId != '') {
            cartItem.AssetSiteId = assetSiteId;
        }
        if (assetFacilityId != '') {
            cartItem.AssetFacilityId = assetFacilityId;
        }
        
        dataLayer.push({
            event: 'brAddToCart',
            brProductId: product.id,
            brProductSku: product.id,
        }, {
            event: 'addToCart',
            ecommerce: {
                add: {
                    products: [{
                        name: product.partNumber,
                        id: product.id,
                        price: selectedOption.price,
                        brand: product.manufacturer,
                        category: product.modalityId,
                        variant: `${getPartCondition(selectedOption.lineItemCondition)} -  ${getPurchaseChoice(selectedOption.purchaseChoice)}`,
                        quantity: quantity,
                    }],
                    id_ins: id_ins,
                },
            },
        });

        axios.post('/ShoppingService/api/v1/cart/add', cartItem).then((x) => {
            loadUserCart();
            this.setState({showAssetInformationPopup: false, showWarrantyPopUp: false, showAddToCartConfirmation: true, addingToCart: false});

            if (selectedOption && selectedOption.formularySetting && selectedOption.formularySetting.formularyId) {
                logEvent('ADD TO CART', {
                    'FormularyRuleName': selectedOption.formularySetting.formularyRuleName, // The name of the formulary rule
                    'FormularyRuleId': selectedOption.formularySetting.formularyId, // The formulary rule id 
                    'FormularyRuleSavingsAmt': outrightListPrice - selectedOption.price, // The difference between the OEM Original Price and the selected condition price
                });
            }

            if (isQuote) {
                logEvent('Request Quote', {
                    id_ins: id_ins,
                    'Item Number': product.partNumber,
                    'Qty': quantity,
                    'OEM': product.manufacturer,
                    'Ref #': x.data.lineItemId,
                    'Referrer': pathname,
                    'Source': quoteSource,
                });
            }

            this.submitAdobeAddToCart(product, selectedOption, quantity, x.data.lineItemId, isUrgency);
        });       
    }

    submitAdobeAddToCart(product, selectedOption, quantity, lineItemId, criticalHardDown) {
        const {user: {facility}} = this.props;
        const productDifferentiatingAttributes = selectedOption.attributes
            .filter((attribute => attribute.purpose.toLowerCase() === 'differentiating attribute'))
            .map(attribute => `${attribute.name}~${attribute.value}`).join('|');
        
        window.appEventData = window.appEventData || [];
        window.appEventData.push({
            'event': 'Product Added to Cart',
            'product': [
                {
                    'price': {
                        'basePrice': getOptionBasePrice(selectedOption),
                        'priceTier': '',
                        'priceType': selectedOption.oemListPrice ? getPartCondition(selectedOption.lineItemCondition) : '',
                        'sellingPrice': selectedOption.price.toFixed(2).toString(),
                    },
                    'productInfo': {
                        'brand': product.brand || product.manufacturer || '',
                        'businessUnit': product.modality ? product.modality : '',
                        'criticalHardDown': criticalHardDown.toString(),
                        'facilityID': facility.facilityId.toString(),
                        'formularyName': selectedOption.formularySetting.formularyRuleName || '',
                        'formularyDisplayType': getFormularyDisplayType(selectedOption.formularySetting.suppressionBehavior, selectedOption.formularySetting.formularyId),
                        'name': product.id,
                        'productCategory': product.productCategory ? product.productCategory : product.categories?.map(category => category.split('|').pop()).join(', ') || '',
                        'productCondition': getPartCondition(selectedOption.lineItemCondition),
                        'productID': product.id,
                        'productLine': '',
                        'productPurchaseType': getPurchaseChoice(selectedOption.purchaseChoice),
                        'productVariant': product.isVariantProduct ? productDifferentiatingAttributes : '',
                        'refId': lineItemId.toString(),
                        'sku': selectedOption.vendorItemNumber ? selectedOption.vendorItemNumber : '',
                        'vString': selectedOption.vendorItemNumber ? selectedOption.vendorItemNumber : '',
                    },
                    'quantity': quantity,
                },
            ],
        });
    }

    renderWarrantyPopup = () => {
        const {warrantydata} = this.state;

        return (
            <WarrantyPopUpForm
                warrantydata={warrantydata}
                onConfirm={() => this.setState({showWarrantyPopUp: false})}
                onCancel={this.onWarrantyContinuePurchase}
                showConfirmation={() => this.setState({showConfirmationPopup: true})} />
        );
    }

    renderWarrantyConfirmationPopup = () => {
        return (
            <Popup confirmText="Ok" cancelText="" show={true} hideButtons={false} className="note--warning cart-confirmation-popup"
                onConfirm={() => this.setState({showConfirmationPopup: false})}
                onCancel={() => this.setState({showConfirmationPopup: false})}
                style={{padding: '25px'}}>
                <div className="duplicate-group-name">
                    Thank you. Your warranty replacement has
                    been submitted and a customer care associate
                    will contact you soon with return
                    instructions.
                </div>
            </Popup>
        );
    }

    onWarrantyContinuePurchase = () => {
        const {option, user: {facility}} = this.props;
        let selectedFacility = (facility && facility.facilityId) ? facility.facilityId : 0; 
        const customFields = getCompanyAndVendorFields(false, false, option, selectedFacility);
        this.addToCart(customFields);
        this.setState({showWarrantyPopUp: false});
    }

    renderAssetInformationPopup =() => {
        const {
            option,
            product,
            hadContractOptions,
            hasOnSiteService,
            user: {facility},
            showShippingFields,
            shippingAddresses,
            isQuote,
            hasLoanerAdded} = this.props;
        const {isUrgency, loanerTermsAcknowledged} = this.state;

        let hasUnvalidatedContract = option && option.formularySetting && option.formularySetting.hasUnvalidatedContract;
        let omitContractFields = option.isContractProOption && hasUnvalidatedContract;
        let selectedFacility = (facility && facility.facilityId) ? facility.facilityId : 0;
        let customFields = getCompanyAndVendorFields(false, omitContractFields, option, selectedFacility);

        let showShipping = showShippingFields && isQuote;
        let condition = getPartCondition(option.lineItemCondition);
        let showFormulary = !option.isFormularyOption && !option.isContractProOption && option.formularySetting && option.formularySetting.approvalRequired && facility.purchaseLimit !== 999999;
        return (<AssetInformationForm
            fields={customFields}
            onSubmit={this.getFieldValues}
            formType={AssetFormType.Both}
            hasOnSiteService={hasOnSiteService}
            readOnly={false}
            onCancel={() => this.setState({showAssetInformationPopup: false})}
            showRequester={false}
            hideSaveButton={true}
            allowMissingFields={false}
            onCheckBoxUpdate={(i) => this.setState({isUrgency: i.checked})}
            onExchangeTermsAccepted={(checked) => this.setState({isExchangeTermsChecked: checked})}
            priority={isUrgency}
            loanerTermsAcknowledged={loanerTermsAcknowledged}
            hasLoanerAdded= {hasLoanerAdded}
            condition={condition}
            showFormulary={showFormulary}
            isRepairOption={option?.isRepairOption}
            onFieldUpdate={(fields) => this.onFieldUpdate(fields)}
            hadContractOptions={hadContractOptions}
            showShippingFields={showShipping}
            shippingAddresses={shippingAddresses}
            oem={{id: product.manufacturerId, name: product.manufacturer}} 
            isExchangeTermsRequired={option.purchaseChoice === 2 && !isQuote} />
        );
    }

    onFieldUpdate = (fields) => {

        const {sessionStorageAvailable} = this.props;

        let storageData = (sessionStorageAvailable && sessionStorage.fields) ? JSON.parse(sessionStorage.fields) : [];
        fields.forEach(d => {
            let field = storageData.find(x => x.fieldUid && x.fieldUid === d.fieldUid);
            if (field) {
                field.value = d.value;
            } else {
                storageData.push(d);
            }
        });
        if (sessionStorageAvailable)
            sessionStorage.fields = JSON.stringify(storageData);
    }

    getFieldValues = (values, formType, requester, isUrgencey, formularyFields, onSiteService, shippingFields, loanerTermsAcknowledged) => {
        const {checkForWarranty} = this.props;
        saveAssetInformationToStorage(values);

        this.setState({formularyFields: formularyFields || [], onSiteService: onSiteService || {}, shippingFields: shippingFields || {}}, () => {

            if (checkForWarranty) {
                this.checkForWarranty();
            } else {

                this.addToCart(values);
            }
        });
    }

    handleAddToCartCancel = () => {
        this.setState({showAddToCartConfirmation: false, showAssetInformationPopup: false, showWarrantyPopUp: false});
    }

    handleAddToCartConfirm = () => {
        const {isQuote, history, option} = this.props;

        history.push(isQuote || isQuotedRepair(option.lineItemCondition) ? '/quotes' : '/cart');
    }

    onSelectFacility = async (facility) => {
        const {saveUserReduxState} = this.props;
        await saveUserReduxState({facility: facility});
        this.setState({showFacilityDialog: false});
        this.checkRequiredFields();
    }

    onQuantityChange = (e) => {
        const {onQtyChange} = this.props;
        this.setState({quantity: parseInt(e.target.value)});

        if (onQtyChange)
            onQtyChange(parseInt(e.target.value));
    }
    renderCreditHoldModal = () => {
        this.setState({showCreditHoldModal: true});
    }
    closeCHModal = () => {
        this.setState({showCreditHoldModal: false});
    }
    render() {
        const {user: {settings, facility}, product, option, isQuote, displayType, buttonText, hasOnSiteService, hasLoanerAdded, allowQtyEdit, disabled} = this.props;
        const {showAssetInformationPopup, showAddToCartConfirmation, quantity = 1, addingToCart, showFacilityDialog, showWarrantyPopUp, 
            showAcknowledgement, showConfirmationPopup, onSiteService, qtyFocused, showCreditHoldModal} = this.state;
        const confirmButtonText = (isQuote ? 'VIEW QUOTE' : 'VIEW CART') || (isQuotedRepair(option.lineItemCondition) ? 'VIEW QUOTE' : 'VIEW CART');
        const itemNumbers = [];
        itemNumbers.push(product.oemItemNumber);
        const showAddToCartSpinner = addingToCart && !(showWarrantyPopUp || showConfirmationPopup || showAssetInformationPopup);
        const restrictRequestQuote = (facility && parseInt(facility.facilityId) > 0) ? facility.creditHold : settings.creditHold;
        const minQty = option && option.moq ? option.moq : 1;
        const minQtyMessage = '(Min. Amount Required)';
        const showAddBill = option && option.purchaseChoice === 2;
        const cartPanelRender = () => {
            return (<div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                {showAddBill && 
                    <div style={{flex: '1 0 100%'}}>
                        {option.addBillAmount > 0 ? 
                            <div className="addBillMessage">
                                <span>{formatMoney(option.addBillAmount)} additional cost</span> if the core part is not returned within <span>15 days.</span>
                            </div> : 
                            <div className="addBillMessage">
                                An<span> additional cost</span> will be charged if the core part is not returned within <span>15 days.</span>
                            </div>
                        }
                    </div>
                }
                <div className="qtySelector">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <label style={{marginRight: '10px', fontSize: '16px', fontWeight: '400'}}>Qty:</label>
                        {allowQtyEdit && !option.showCustomCatalogMsg ? (
                            option.purchaseChoice !== 3 ? (
                                <QuantitySelector id="qty" name="qty" selectedValue={quantity} 
                                    onFocus={() => this.setState({qtyFocused: true})} 
                                    onBlur={() => this.setState({qtyFocused: false})}
                                    onChange={this.onQuantityChange} 
                                    minQty={minQty} minQtyOptionLabel={minQty > 1 ? minQtyMessage : ''} />
                            ) : (
                                <RepairQuantitySelector id="qty" name="qty" selectedValue={quantity} onChange={this.onQuantityChange} />
                            )
                        ) : null}
                    </div>
                    {!qtyFocused && option.moq && option.moq > quantity && <span className="min-order-qty-msg">{`This item has a minimum order quantity of ${option.moq}.`}</span>}
                </div>
                <div className="btnAdd" style={{width: '54%'}} data-ea-cta-link={getPartCondition(option.lineItemCondition)}>
                    <Button 
                        disabled={disabled}
                        secondary={true} 
                        style={{marginTop: '0px'}}
                        title="Add to Cart" 
                        onClick={(restrictRequestQuote && buttonText.trim().toUpperCase() !== 'ADD TO CART') ? this.renderCreditHoldModal : this.checkRequiredFields}
                        loading={showAddToCartSpinner}>
                        {`${buttonText || 'ADD TO CART'}`}
                    </Button>
                </div>
                {(showCreditHoldModal) && <OnCreditHoldModal onSubmit={this.closeCHModal}/>}
            </div>);
        };

        return (<div className="add-to-cart-component-inline" style={{marginTop: displayType === DisplayType.CartPanel ? '20px' : '0px'}}>
            {(displayType === DisplayType.BuyItAgain || displayType === DisplayType.Lists) && <div className="buttons">
                <div className="lables">Qty:</div>
                <div className="qtySelector"><QuantitySelector id="qty" name="qty" selectedValue={quantity} onChange={this.onQuantityChange} /></div>
                <div className="btnAdd"><Button secondary={true} title="Add to Cart" onClick={this.checkRequiredFields} loading={showAddToCartSpinner}>{`${buttonText || 'ADD TO CART'}`}</Button></div>
            </div>}
            {displayType === DisplayType.CartPanel && cartPanelRender()}
            {displayType === DisplayType.RequestQuote && 
                <span onClick={() => restrictRequestQuote ? this.renderCreditHoldModal() : this.checkRequiredFields()} 
                      style={settings.prominentQuoteRequests ? {textDecoration: 'underline', cursor: 'pointer', color: '#005DA6', fontSize: '20px'} : {textDecoration: 'underline', cursor: 'pointer', color: '#949494'}}> 
                      Request a quote 
                </span>}
            {showWarrantyPopUp && this.renderWarrantyPopup()}
            {showConfirmationPopup && this.renderWarrantyConfirmationPopup()}
            {showAssetInformationPopup && this.renderAssetInformationPopup()}
            {showAddToCartConfirmation && 
                <AddToCartConfirmation 
                    show={true} 
                    product={product} 
                    hasOnSiteService={hasOnSiteService} 
                    onSiteService={onSiteService} 
                    hasLoanerAdded={hasLoanerAdded} 
                    isQuote={isQuote} 
                    quantity={quantity} 
                    option={option}
                    confirmButtonText={confirmButtonText} 
                    onConfirm={::this.handleAddToCartConfirm} 
                    onCancel={::this.handleAddToCartCancel} />}
            {showFacilityDialog && <FacilityDialog onSubmit={this.onSelectFacility} onCancel={() => this.setState({showFacilityDialog: false})} disableClose={false} />}
            {showAcknowledgement && <Acknowledgement acknowledgeRisk={this.acknowledgeRisk} itemNumbers={itemNumbers} onCancel={() => this.setState({showAcknowledgement: false})} />}
            {(showCreditHoldModal) && <OnCreditHoldModal onSubmit={this.closeCHModal}/>}
        </div>);
    }
}
