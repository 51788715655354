import * as PropTypes from 'prop-types';
import * as React from 'react';
import MediaQuery from 'react-responsive';

export class Responsive extends React.Component {
    static propTypes = {
        children: PropTypes.node,
        breakpoint: PropTypes.string,
    };
    
    render() {
        const {breakpoint, children, ...rest} = this.props;
    
        switch (breakpoint) {
            case 'xs':
                return (<MediaQuery {...rest} maxWidth={549}>
                    {children}
                </MediaQuery>);
            case 'sm':
                return (<MediaQuery {...rest} minWidth={550} maxWidth={819}>
                    {children}
                </MediaQuery>);
            case 'md':
                return (<MediaQuery {...rest} minWidth={820} maxWidth={1149}>
                    {children}
                </MediaQuery>);
            case 'lg':
                return (<MediaQuery {...rest} minWidth={1150}>
                    {children}
                </MediaQuery>);
            case 'portrait':
                return (<MediaQuery {...rest} maxWidth={819}>
                    {children}
                </MediaQuery>);
            case 'landscape':
                return (<MediaQuery {...rest} maxWidth={1149}>
                    {children}
                </MediaQuery>);
            case 'default':
                return (<MediaQuery {...rest} minWidth={820}>
                    {children}
                </MediaQuery>);
        }
    }
}

export const Xs = props => <Responsive {...props} breakpoint={'xs'} />;
export const Sm = props => <Responsive {...props} breakpoint={'sm'} />;
export const Md = props => <Responsive {...props} breakpoint={'md'} />;
export const Lg = props => <Responsive {...props} breakpoint={'lg'} />;

export const Phone = props => <Responsive {...props} breakpoint={'xs'} />;
export const PhoneBig = props => <Responsive {...props} breakpoint={'sm'} />;
export const PhoneLandscape = props => <Responsive {...props} breakpoint={'landscape'} />;
export const PhonePortrait = props => <Responsive {...props} breakpoint={'portrait'} />;
export const Tablet = props => <Responsive {...props} breakpoint={'md'} />;
export const Desktop = props => <Responsive {...props} breakpoint={'lg'} />;
export const Default = props => <Responsive {...props} breakpoint={'default'} />;
