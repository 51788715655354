import PropTypes from 'prop-types'
import * as React from 'react';
import {connect} from 'react-redux';
import TextFieldApiSelect, {ApiSelectType} from 'components/TextFieldApiSelect';
import {TextFieldSelect, Popup} from '@partssourceinc/react-ui-core';
import * as UserStore from 'stores/User';
import 'less/header.less';

@connect((state) => ({user: state.user}))
export default class FacilitySearchDialog extends React.Component {
    static propTypes = {
        onCancel: PropTypes.func,
        onSubmit: PropTypes.func,
        adminSearch: PropTypes.bool,
        label: PropTypes.string,
        user: UserStore.StateShape,
        disableClose: PropTypes.bool,
    };

    constructor(props) {
        super(props);
    }

    closePopup() {
        const {disableClose, onCancel} = this.props;
        if (disableClose)
            return;
        
        onCancel();
    }

    onSelectFacility(facility) {
        const {onSubmit} = this.props;
        onSubmit(facility);
    }

    render() {
        const {user: {facilities}, disableClose, adminSearch, label} = this.props;
        const renderData = facilities.map(f => {
            return Object.assign({},
                f, 
                {flag: f.creditHold ? '(Credit Hold)' : ''}, 
                {background: f.creditHold ? '#EFC3CA' : null}
                )
        })

        let title = 'Select Facility';
        if (adminSearch) title = 'Select a Facility';
        if (label) title = label;

        return (
            <Popup confirmText="Save"
                cancelText="Cancel"
                show={true}
                hideButtons={true}
                onCancel={::this.closePopup}
                className="facility-dialog"
                disableClose={disableClose}
            >
                <div>
                    <h1>{title}</h1>
                    {!adminSearch 
                        ? <TextFieldSelect
                            className="select-facility-input"
                            keyField="facilityId"
                            valueField="facilityName"
                            onSelect={::this.onSelectFacility}
                            listItems={renderData}
                            label="Select Facility"
                            autoFocus={true}
                            placeHolder="Your Facility"
                            autoExpand={true} />
                        : <TextFieldApiSelect
                            className="select-facility-input"
                            keyField="facilityId"
                            valueField="facilityName"
                            onSelect={::this.onSelectFacility}
                            listItems={facilities}
                            autoFocus={true} 
                            label="Facility"
                            placeHolder="Facility"
                            autoExpand={true}
                            selectType={ApiSelectType.Facility} />}
                </div>
            </Popup>
        );
    }
}
