import axios from 'axios';
import _ from 'lodash'
import moment from 'moment';
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import {connect} from 'react-redux';
import {Popup, Dropdown, TextField, RadioButton, Button} from '@partssourceinc/react-ui-core';
import * as UserStore from 'stores/User';
import $ from 'jquery';
import 'less/addressDialog.less';

export const AddressFormType = {
    AddEdit: 'AddEdit',
    Validate: 'Validate',
    DuplicateFound: 'DuplicateFound',
};

@connect((state) => ({user: state.user}))
export default class AddressDialog extends Component {
    static propTypes = {
        address: PropTypes.object,
        onSave: PropTypes.func,
        onCancel: PropTypes.bool,
        user: UserStore.StateShape,
        addressFormType: PropTypes.string,
        facilityId: PropTypes.number,
        controlInline: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            saving: false,
            address: _.cloneDeep(props.address),
            suggestedAddress: null,
            showErrors: false,
            stateList: [],
            countryList: [],
            addressFormType: props.addressFormType || AddressFormType.AddEdit,
            selectedAddressOption: '2',
            duplicateAddress: null,
            controlInline: props.controlInline || false,
        }
        this.renderContent = this.renderContent.bind(this);
    }

    componentWillMount() {
        const {addressFormType} = this.props;
        
        axios.get('ShoppingService/api/v1/company/addressoptions').then(x => {
            this.setState({stateList: x.data.stateList, countryList: x.data.countryList});
        });

        if (addressFormType && addressFormType === AddressFormType.Validate) {
            this.validateAddress();
        }
    }

    onSave() {
        const {addressFormType} = this.state;
        const {user: {info}} = this.props;
       
        if (addressFormType === AddressFormType.AddEdit) {
            if (this.hasMissingFields()) {
                this.setState({showErrors: true});
                return;
            }

            this.validateAddress();
        } else if (addressFormType === AddressFormType.Validate) {
            const {selectedAddressOption, address, suggestedAddress} = this.state;
            this.saveAddress(selectedAddressOption === '1'
                ? _.merge(address, {validated: moment().format(), validatedOverrideUserId: info.contactId}) 
                : _.merge(suggestedAddress, {validated: moment().format()}));
        }
    }

    onCancel() {
        const {addressFormType, duplicateAddress} = this.state;
        const {onSave, onCancel} = this.props;

        if (addressFormType === AddressFormType.DuplicateFound)
            onSave(duplicateAddress, false);
        else
            onCancel();
    }

    hasMissingFields() {
        const {address} = this.state;

        if (!address.description || !address.street1 || !address.city || !address.postalCode)
            return true;

        let reqFields = $('.address-dialog-form [required]');

        for (let i = 0; i < reqFields.length; i++) {
            let x = reqFields[i];
            if (!address[x.id])
                return true;
        }

        return false;
    }

    onAddressFieldChange(e, data) {
        let {address} = this.state;
        address[data.id] = data.value;
        if (data.id === 'country') address.state = '';
        this.setState({address});
    }

    onEditAddress() {
        this.setState({addressFormType: AddressFormType.AddEdit});
    }

    validateAddress() {
        let {address, suggestedAddress} = this.state;
        this.setState({validating: true});

        axios.post('ShoppingService/api/v1/company/address/validate', address).then(x => {
            let a = x.data;

            if (a.isValid && a.isOriginalAddressValid) {
                address = _.merge(a.alternateAddress, {validated: moment().format()});
                this.saveAddress(address);
            } else if (!a.isOriginalAddressValid && a.alternateAddress) {
                suggestedAddress = a.alternateAddress;
                this.setState({
                    addressFormType: AddressFormType.Validate, 
                    address,
                    suggestedAddress,
                    selectedAddressOption: suggestedAddress ? '2' : '1',
                    validating: false,
                });
            } else {
                suggestedAddress = null;
                this.setState({
                    addressFormType: AddressFormType.Validate, 
                    address,
                    suggestedAddress,
                    selectedAddressOption: suggestedAddress ? '2' : '1',
                    validating: false,
                });
            }
        })
    }

    saveAddress(address) {
        this.setState({saving: true});
        const {user: {facility, info}, onSave, facilityId} = this.props;
        address.companyId = facilityId || facility.facilityId;
        address.userId = info.contactId;
        axios.post('/ShoppingService/api/v1/company/saveAddress', address).then(x => {
            if (x.data.exists) {
                this.setState({
                    duplicateAddress: x.data.duplicateAddress, 
                    addressFormType: AddressFormType.DuplicateFound,
                    saving: false,
                    validating: false,
                });
            } else {
                onSave(x.data.address, !address.addressId);
            }
        });
    }

    onAddressCheck(e) {
        this.setState({selectedAddressOption: e.target.value});
    }

    render () {
        const {
            saving,
            address,addressFormType,
            validating,
            controlInline,
        } = this.state;
        
        let buttonText = 'Add Address';
        if (addressFormType === AddressFormType.Validate) buttonText = 'Verify';
        else if (address.addressId > 0) buttonText = 'Edit Address';
        if (controlInline) {
            return (           
                <div className="address-dialog" style={{width: '320px'}}>
                    {this.renderContent()}
                    {addressFormType === AddressFormType.DuplicateFound ? 
                        <Button style={{'marginTop': '15px'}} secondary={true} onClick={::this.onCancel} loading={saving || validating} tabIndex={0}>
                    Cancel                    
                        </Button> : 
                        <Button style={{'marginTop': '15px'}} secondary={true} onClick={::this.onSave} loading={saving || validating} tabIndex={0}>
                            {buttonText}                    
                        </Button>}
                </div>)
        } else {
            return (           
                <Popup confirmText={buttonText}
                    cancelText="Cancel"
                    show={true}
                    onConfirm={::this.onSave}
                    onCancel={::this.onCancel}
                    className="address-dialog"
                    hideButtons={addressFormType === AddressFormType.DuplicateFound}
                    loading={saving || validating}>
                    {this.renderContent()}
                </Popup>)
        }           
    }
    renderContent () {
        const {
            saving,
            address,
            showErrors,
            countryList,
            stateList,
            addressFormType,
            selectedAddressOption,
            suggestedAddress,
            validating,
            duplicateAddress,
            controlInline,
        } = this.state;
        let filteredStates = stateList.filter(x => x.countryCode === address.country);

        let originalAddressLabel = `<div>${address.street1}</div>`;
        if (address.street2) originalAddressLabel += `<div>${address.street2}</div>`;
        originalAddressLabel += `<div>${address.city}, ${address.state} ${address.postalCode}</div>`;
        originalAddressLabel += `<div>${address.country}</div>`;

        let suggestedAddressLabel;

        if (suggestedAddress) {
            suggestedAddressLabel = `<div>${suggestedAddress.street1}</div>`;
            if (suggestedAddress.street2) originalAddressLabel += `<div>${suggestedAddress.street2}</div>`;
            suggestedAddressLabel += `<div>${suggestedAddress.city}, ${suggestedAddress.state} ${suggestedAddress.postalCode}</div>`;
            suggestedAddressLabel += `<div>${suggestedAddress.country}</div>`
        }

        let buttonText = 'Add Address';
        if (addressFormType === AddressFormType.Validate) buttonText = 'Verify';
        else if (address.addressId > 0) buttonText = 'Edit Address';

        const originalChecked = selectedAddressOption === '1';
        return (<div>            
            {addressFormType === AddressFormType.AddEdit && <div className="address-dialog-form">
                {!controlInline ? <h1>{address.addressId > 0 ? 'Edit Shipping Address' : 'Add Shipping Address'}</h1> : null}
                <TextField id="description" label="Description" showErrorMessage={showErrors && !address.description} tabIndex={0} required={true}
                    placeholder="Description" text={address.description} onChange={::this.onAddressFieldChange} />
                <TextField id="street1" label="Address Line 1" showErrorMessage={showErrors && !address.street1} tabIndex={0} required={true}
                    placeholder="Address Line 1" text={address.street1} onChange={::this.onAddressFieldChange} />
                <TextField id="street2" label="Address Line 2" showErrorMessage={false} tabIndex={0}
                    placeholder="Address Line 2" text={address.street2} onChange={::this.onAddressFieldChange} />
                <TextField id="city" label="City " showErrorMessage={showErrors && !address.city} tabIndex={0} required={true}
                    placeholder="City" text={address.city} onChange={::this.onAddressFieldChange} />
                <div className="split">
                    {filteredStates.length > 0 ?
                        <Dropdown id="state" name="state" label={'State'} valueField="state" textField="description" showErrorMessage={showErrors && !address.state}
                            options={filteredStates} selectedValue={address.state} onChange={::this.onAddressFieldChange} required={true} tabIndex={0} /> :
                        <TextField id="state" label="State " showErrorMessage={showErrors && !address.state} tabIndex={0} required={true}
                            placeholder="State" text={address.state} onChange={::this.onAddressFieldChange} />}
                    <TextField id="postalCode" label="Postal Code" showErrorMessage={showErrors && !address.postalCode} tabIndex={0} required={true}
                        placeholder="Postal Code" text={address.postalCode} onChange={::this.onAddressFieldChange} />
                </div>
                <Dropdown id="country" name="country" label={'Country'} valueField="countryCode" textField="description" showErrorMessage={showErrors && !address.country}
                    options={countryList} selectedValue={address.country} onChange={::this.onAddressFieldChange} required={true} tabIndex={0} />
            </div>}
            {addressFormType === AddressFormType.Validate && 
                    <div className="address-validation-form">
                        {!controlInline ? <h1>Verify Your Address</h1> : null}
                        {!validating && <div>
                            {!suggestedAddress && <div className="not-verified">This address could not be verified.</div>}
                            <div className="section-title">Your Entry <span onClick={::this.onEditAddress}>Edit</span></div>
                            <RadioButton  
                                id="original-address"
                                checked={originalChecked} 
                                value="1" 
                                onChange={::this.onAddressCheck} 
                                name="original-address"
                                label={originalAddressLabel} />
                            {suggestedAddressLabel && <div style={{width: '100%'}}>
                                <div className="section-title">Suggested Address</div>
                                <RadioButton  
                                    id="suggested-address"
                                    checked={!originalChecked} 
                                    value="2" 
                                    onChange={::this.onAddressCheck} 
                                    name="suggested-address"
                                    label={suggestedAddressLabel} />
                            </div>}
                        </div>}
                    </div>}
            {addressFormType === AddressFormType.DuplicateFound && <div>This address already exists as - {duplicateAddress.description}</div>}
        </div>
        )
    }
}

