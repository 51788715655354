import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import {connect} from 'react-redux';
import {matchPath, withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import {ImageGallery, ProductCarousel, NavMenu, Popup, ProductImage} from '@partssourceinc/react-ui-core';
import {Link} from 'react-router-dom';
import queryString from 'query-string';

import {getBreadCrumb, getPartCondition, getPurchaseChoice, getPartConditionDescription, getFormularyDisplayType, getOptionBasePrice} from 'productUtility';
import CartPanel from 'pages/CartPanel';
import ListMenu from 'pages/ListMenu';
import ProductOption from 'pages/ProductOption';
import ProductTabs from 'pages/ProductTabs';
import {AddToCartButton, DisplayType} from 'components/AddToCartButton';
import AddToCartConfirmation from 'components/AddToCartConfirmation';
import ProductDetail from 'components/cms/ProductDetail';
import FacilityDialog from 'components/FacilitySearchDialog';
import PageMetaData from 'components/PageMetaData';
import * as CartStore from 'stores/Cart';
import * as NetworkStore from 'stores/Network';
import * as ParamsStore from 'stores/Params';
import * as ProductStore from 'stores/Product';
import * as SettingsStore from 'stores/Settings'
import * as UserStore from 'stores/User';
import {getCookie, slugify, logEvent, addToCookieIfExclusiveSupplier, isQuotedRepair} from 'utility';
import {initQualtrics} from '../scripts';
import * as BloomReachStore from 'stores/BloomReach';
import * as CompaniesStore from 'stores/Companies';
import * as SystemStore from 'stores/System';
import qs from 'qs';

import 'less/product.less';
import {BrComponent} from '@bloomreach/react-sdk';
import StickyHeader from './Product/StickyHeader';

@withRouter
@connect(
    state => ({
        network: state.network,
        user: state.user,
        cart: state.cart,
        params: state.params,
        currentProduct: state.currentProduct,
        options: state.currentProduct.displayOptions,
        bloomReach: state.bloomReach,
        configuration: state.system.configuration,
        oneSourceMappings: state.companies.oneSourceMappings,
        settings: state.settings,
        system: state.system,
        sessionStorageAvailable: state.network.sessionStorageAvailable,
    }),
    _.merge({},
        CartStore.actionCreators,
        UserStore.actionCreators,
        ParamsStore.actionCreators,
        ProductStore.actionCreators,
        BloomReachStore.actionCreators,
        CompaniesStore.actionCreators
    )
)
export default class Product extends React.Component {
    static displayName = 'Product';

    static propTypes = {
        ...ReactRouterPropTypes,
        ...CartStore.ActionShape,
        ...UserStore.ActionShape,
        ...ParamsStore.ActionShape,
        ...ProductStore.ActionShape,
        ...SettingsStore.ActionShape,
        network: NetworkStore.StateShape,
        user: UserStore.StateShape,
        cart: CartStore.StateShape,
        params: ParamsStore.StateShape,
        currentProduct: ProductStore.StateShape,
        system: SystemStore.StateShape,
    };

    constructor(props) {
        super(props);
        const moq = props.currentProduct && props.currentProduct.selectedOption && props.currentProduct.selectedOption.moq;
        
        this.state = {
            quantity: moq || 1,
            selectedQuantity: moq || 1,
            showAddToCartConfirmation: false,
            showFacilityDialog: false,   
            facilityId: props.user.facility.facilityId,    
            delayLoadingIcon: true,
            loadingProduct: true,
            showLists: false,
            listName: '',
            existsInList: false,
            additionalOptionsClicked: false,
            showAlternativeOptions: props.currentProduct.showAlternativeOptions,
            needsFacilityForRFQ: false,
            showConditionDescription: false,
            currentImageIndex: 0,
            showListItemConfirmation: false,
            showListItemConfirmationMessage: '',
            selectedListName: '',
            selectedListId: 0,
            showShippingFields: false,
            shippingAddresses: [],
            hasVendorCertMix: false,
        };

        setTimeout(() => this.setState({delayLoadingIcon: false}), 250);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            network: {isLoggedIn},
            user: {info: {rootCompanyId}},
            currentProduct: {
                product,
                selectedOption,
            },
            system: {siteSettings: {qxEnableCollectPriceFeedback, qxEnableCollectListingFeedback}},
        } = this.props;
        const {currentImageIndex} = this.state;

        if (prevProps.system.siteSettings.qxEnableCollectPriceFeedback !== qxEnableCollectPriceFeedback ||
            prevProps.system.siteSettings.qxEnableCollectListingFeedback !== qxEnableCollectListingFeedback ||
            prevProps.network.isLoggedIn !== isLoggedIn ||
            prevProps.user.info.rootCompanyId !== rootCompanyId
        ) {

            // Qualtrics manual load
            if (qxEnableCollectPriceFeedback || qxEnableCollectListingFeedback) {
                if (typeof(initQualtrics) !== 'undefined') {
                    initQualtrics();
                }
            }
            this.setQualtricsData(product, selectedOption, currentImageIndex);
        }
    }
    getFacilitySettings(facility) {
        if (!facility || !facility.facilityId) return;

        const {loadFacilityAddresses} = this.props;
        Promise.all([axios.get(`/SettingsService/api/v1/companySettings/${facility.facilityId}`),
            loadFacilityAddresses(facility.facilityId)]).then(x => {
            let addss = x[1].response.data.shippingAddresses;
            this.setState({showShippingFields: x[0].data.settings.fullyBurdenedQuoting, shippingAddresses: addss});
        });
    }
    componentWillMount() {
        const {currentProduct: {selectedOption}} = this.props;
        
        if (selectedOption.moq) {
            this.setState({quantity: selectedOption.moq, selectedQuantity: selectedOption.moq});
        }

    }
    componentWillReceiveProps(newProps) {
        const {location: {pathname}, resetProduct, currentProduct: {selectedOption}} = this.props;
        const {facilityId, needsFacilityForRFQ, hasVendorCertMix} = this.state;

        if (newProps.location.pathname !== pathname) {
            resetProduct();
            this.refreshState(newProps, false);
        } else if (newProps.user.facility.facilityId && newProps.user.facility.facilityId !== facilityId && (newProps.user.settings.refreshPricingOnFacilityChange || needsFacilityForRFQ || hasVendorCertMix)) {
            this.setState({facilityId: newProps.user.facility.facilityId, loadingProduct: true});
            if (newProps.user.facility.facilityId === -1) {
                this.setState({showFacilityDialog: true, loadingProduct: true});
            } else {
                this.refreshState(newProps, true, true);
            }
        }
    }

    setQualtricsData = (product, selectedOption, currentImageIndex) => {
        const {
            network: {isLoggedIn},
            system: {siteSettings: {qxEnableCollectPriceFeedback, qxEnableCollectListingFeedback}},
            user: {info: {contactId, fullName, rootCompanyId, rootCompanyName, email}},
            settings: {hidePricing},
            match: {params: {partNumber}},
        } = this.props;

        if (isLoggedIn && (qxEnableCollectPriceFeedback || qxEnableCollectListingFeedback)) {
            let images = (product.images || []).map(function(item) {
                return {
                    img: item.image,
                };
            });

            let showPricing = (!hidePricing && selectedOption.price != null) ? true : false;
            // set element in window for qualtrics script
            window.qualtricsData = {
                userId: contactId,
                userName: fullName,
                parentId: rootCompanyId,
                parentName: rootCompanyName,
                oemPartNumber: partNumber,
                oString: product.oemItemNumber, // selectedOption.psPartNumber,
                vString: selectedOption.vendorItemNumber,
                photoFileName: images && images[currentImageIndex] && images[currentImageIndex].img ? images[currentImageIndex].img : '',
                showPricing,
                userEmail: email,
            };
        }
    }

    handleClick = (e) => {
        if (e.target.id === 'ttConditionDescription') {
            return;
        }

        this.setState({showConditionDescription: false});
    };

    componentDidMount() {
        const {
            user: {settings, facility, facilities},
            network: {isLoggedIn, tokenInfo: {userId}},
            getCompanyFields,
            oneSourceMappings,
            sessionStorageAvailable,
            getOneSourceOemMappings,
            currentProduct: {
                product,
                selectedOption,
            },
            system: {siteSettings: {qxEnableCollectPriceFeedback, qxEnableCollectListingFeedback}},
        } = this.props;
        const {currentImageIndex} = this.state;

        this.setQualtricsData(product, selectedOption, currentImageIndex);

        if (sessionStorageAvailable && !sessionStorage.fields) {
            getCompanyFields(facilities.length ? facilities[0].facilityId : 0, userId ? userId : 0);
        }

        // check to see if RFQ Management Exists
        let allFacilitiesDisabled = !facilities.some(f => f.rfqManagement === 1 || f.rfqManagement === 3 || f.rfqManagement === 4);
        let allFacilitiesEnabled = !facilities.some(f => f.rfqManagement === 0 || f.rfqManagement === 2 || f.rfqManagement === 4);
        let needsFacilityForRFQ = !(allFacilitiesDisabled || allFacilitiesEnabled);

        let hasVendorCertMix = facilities.length > 0 && !facilities.every(x => x.showVendorCertifications === facilities[0].showVendorCertifications);

        if (isLoggedIn && (settings.hasFacilityLevelPricing || needsFacilityForRFQ || hasVendorCertMix) && (!facility.facilityId || !(facility.facilityId > 0))) {
            this.setState({showFacilityDialog: true, needsFacilityForRFQ: needsFacilityForRFQ, hasVendorCertMix: hasVendorCertMix});
        } else {
            this.setState({needsFacilityForRFQ: needsFacilityForRFQ});
            this.refreshState(this.props);
        }

        if (isLoggedIn)
            this.getFacilitySettings(facility);

        $('body').css({overflowX: 'hidden'});
        document.addEventListener('mousedown', this.handleClick, false);

        if (settings.oneSource && oneSourceMappings.length === 0) {
            getOneSourceOemMappings();
        }

        // Qualtrics manual load
        if (qxEnableCollectPriceFeedback || qxEnableCollectListingFeedback) {
            if (typeof(initQualtrics) !== 'undefined') {
                initQualtrics();
            }
        }
    }

    componentWillUnmount() {
        const {removeProductFromCache, resetProduct} = this.props;
        removeProductFromCache();
        resetProduct();
        $('body').css({overflowX: 'visible'});
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    refreshState(props, skipBrTracking, forceRefresh) {
        const {pspn: psPartNumber, vrnt} = queryString.parse(window.location.search);
        const {cart, saveCart, getBrowsingHistory, loadProduct, logProduct, user: {facility}, bloomReach, resetPath} = this.props;
        const {location: {pathname, search}, match: {params: {oemName, partNumber}}, network: {isLoggedIn}, setReduxStateFromCookie} = props;

        cart.loadingPricing = true;

        if (`${pathname}${search}` === bloomReach.request.path && !forceRefresh) {
            this.continueStateRefresh(props, skipBrTracking);
            resetPath();
        } else {
            loadProduct(oemName, partNumber, psPartNumber, vrnt, false).then(x => {
                const {response: {data: {product}}} = x;
                this.continueStateRefresh(props, skipBrTracking, product.redirectUrl);             
            });
        }
    }

    continueStateRefresh(props, skipBrTracking, redirectUrl) {
        const {pspn: psPartNumber, vrnt} = queryString.parse(window.location.search);
        const {cart, saveCart, getBrowsingHistory, getBrowsingHistoryFromCookieProductsData, logProduct, user: {facility, facilities}, loadAlsoBoughts} = this.props;
        const {
            location: {pathname},
            match: {params: {partNumber}},
            network: {isLoggedIn},
            setReduxStateFromCookie,
            system: {configuration: {features: {oemConnectOrder}}},
        } = props;
        const {currentImageIndex, quantity} = this.state;

        logProduct(props.params.referer, facility);
        loadAlsoBoughts();
        cart.loadingPricing = false;
        let newPathName = redirectUrl || pathname.substring(0, pathname.indexOf(partNumber) + partNumber.length);
        const {currentProduct: {selectedOption, product}} = this.props;
        const variantParam = vrnt ? `&vrnt=${vrnt}` : '';
  
        if (typeof window !== 'undefined') {
            if (psPartNumber) {
                window.history.replaceState(null, null, `${newPathName}?pspn=${psPartNumber.toLowerCase()}${variantParam}`);
            } else if (selectedOption && selectedOption.psPartNumber) {
                window.history.replaceState(null, null, `${newPathName}?pspn=${selectedOption.psPartNumber.toLowerCase()}${variantParam}`);
            } else if (selectedOption && !selectedOption.psPartNumber) {
                window.history.replaceState(null, null, `${newPathName}${variantParam.replace('&', '?')}`);
            }
        }

        if (selectedOption && selectedOption.moq && quantity === 1) {
            this.setState({quantity: selectedOption.moq, selectedQuantity: selectedOption.moq});
        }

        dataLayer.push({
            'event': 'PDP Load',
            'ecommerce': {
                'detail': {

                    'products': [{
                        'name': product.partNumber,
                        'id': product.id,
                        'price': selectedOption.price,
                        'brand': product.manufacturer,
                        'category': '',
                        'variant': `${getPartCondition(selectedOption.lineItemCondition)} -  ${getPurchaseChoice(selectedOption.purchaseChoice)}`,
                    }],
                },
            },
        });

        this.submitAdobeProductViewed(product, selectedOption, skipBrTracking);

        this.setState({loadingProduct: false});
        saveCart(cart);

        if (!skipBrTracking) {
            dataLayer.push({
                event: 'brPageView',
                brPageType: 'product',
                brProductId: product.id,
                brProductSku: product.id,
                brProductName: product.title,
                brIsConversion: 0,
            });
        }

        if (!isLoggedIn) {
            let browsingCookie = [];
            let browsingHistoryStr = getCookie('browsingHistory');

            if (browsingHistoryStr !== '') {
                try {
                    browsingCookie = JSON.parse(browsingHistoryStr);
                    if (browsingCookie)
                        browsingCookie = browsingCookie.filter(x => x.dateViewed);
                } catch (err) {
                    console.log(err);
                }
            }

            _.remove(browsingCookie, b => b.productOString === product.id);

            // here we add the data to the cookie
            browsingCookie.push({productOString: product.id, dateViewed: moment().format()});

            setReduxStateFromCookie(browsingCookie);
            getBrowsingHistoryFromCookieProductsData();
        } else if (!cart.itemHistory || !cart.itemHistory.views || cart.itemHistory.views.length === 0 || cart.itemHistory.views[0].product.id !== product.id) {
            // here we'd be logged in
            getBrowsingHistory();
        }

        this.setQualtricsData(product, selectedOption, currentImageIndex);
    }

    submitAdobeProductViewed(product, selectedOption) {                
        const {currentProduct} = this.props;
        const productDifferentiatingAttributes = currentProduct.options.flatMap(option => option.attributes || [])
            .filter((attribute => attribute.purpose.toLowerCase() === 'differentiating attribute'))
            .map(attribute => `${attribute.name}~${attribute.value}`).join('|');
        
        window.appEventData = window.appEventData || [];
        window.appEventData.push({
            'event': 'Product Viewed',
            'product': [
                {
                    'price': {
                        'basePrice': getOptionBasePrice(selectedOption),
                        'priceTier': '',
                        'priceType': selectedOption.oemListPrice ? getPartCondition(selectedOption.lineItemCondition) : '',
                        'sellingPrice': selectedOption.price ? selectedOption.price.toFixed(2).toString() : '',
                    },
                    'productInfo': {
                        'brand': product.manufacturer,
                        'businessUnit': product.modality ? product.modality : '',
                        'formularyName': selectedOption.formularySetting?.formularyRuleName ? selectedOption.formularySetting.formularyRuleName : '',
                        'formularyDisplayType': getFormularyDisplayType(selectedOption.formularySetting?.suppressionBehavior, selectedOption.formularySetting?.formularyId),
                        'productCategory': product.productCategory ? product.productCategory : product.categories?.map(category => category.split('|').pop()).join(', ') || '',
                        'productCondition': getPartCondition(selectedOption.lineItemCondition),
                        'productID': product.id,
                        'productLine': '',
                        'productPurchaseType': getPurchaseChoice(selectedOption.purchaseChoice),
                        'productVariant': product.isVariantProduct ? productDifferentiatingAttributes : '',
                        'quantityAvailable': selectedOption.inventory ? selectedOption.inventory : '',
                        'sku': selectedOption.vendorItemNumber ? selectedOption.vendorItemNumber : '',
                        'vString': selectedOption.vendorItemNumber ? selectedOption.vendorItemNumber : '',
                    },
                },
            ],
        });
    }

    renderOptions(hideRequestQuote) {
        const {user: {settings, facility, companyLogo}, currentProduct: {product, selectedOption}, selectOption, options} = this.props;

        const {quantity, showAlternativeOptions, additionalOptionsClicked, showShippingFields, shippingAddresses, selectedQuantity} = this.state;

        const option = selectedOption || {};

        let vendorIdsFromCookie = getCookie('eoem');

        let eomQs = qs.parse(this.props.location.search, {ignoreQueryPrefix: true}).eom

        if (!vendorIdsFromCookie && eomQs === '1') {
            let oemName = this.props.match.params.oemName;

            if (!_.isNil(oemName)) {
                addToCookieIfExclusiveSupplier(oemName);
                vendorIdsFromCookie = getCookie('eom');
            }
        }

        let showAdditionalOptionsLink = !vendorIdsFromCookie && !showAlternativeOptions && (options.some(o => o.displayToCustomer === 2));
        let nonFormularyOptionsCount = options.filter(x => !x.isFormularyOption).length;
        let hasNonFormularyOptions = nonFormularyOptionsCount > 0;
        let hideNonFormularyOptions = hasNonFormularyOptions && options.some(x => x.formularySetting && x.formularySetting.showAdditionalOptionsLink);
        let nonFormularyOptionsNeedApproval = !product.isVariantProduct && hasNonFormularyOptions && options.some(x => x.formularySetting && x.formularySetting.approvalRequired && ((x.price * selectedQuantity) > x.formularySetting.approvalLimit)) && facility.purchaseLimit !== 999999;

        // Filter out only the options with Exclusive Vendor (if any) using the id stored in the cookie
        let displayOptions = [];

        if (vendorIdsFromCookie != '') {
            _.each(options, function(option) {
                if (!option.fakeRefurb && vendorIdsFromCookie.includes(option.vendorId)) {
                    displayOptions.push(option);
                }
            });
        }
        if (displayOptions.length < 1) {
            displayOptions = hideRequestQuote ? options.filter(x => !x.fakeRefurb) : options;
        }

        let hadContractOptions = displayOptions.some(o => o.isContractProOption);
        let hadFormularyOptions = displayOptions.some(o => o.isFormularyOption);

        return (<div id="product_options" className="product_options">
            <div className="title ps-hidden-sm ps-hidden-xs">
                <b>BUYING OPTIONS:</b>
            </div>

            <div className="product_options_wrapper">
                {displayOptions.map((o, i) =>
                    (!showAlternativeOptions && o.displayToCustomer === 2) || (!additionalOptionsClicked && hideNonFormularyOptions && !o.isFormularyOption) ? null
                        : <ProductOption product={product} option={o} key={`opt_${i}`} quantity={selectedQuantity} facility={facility} selected={o.isSelected} hidePricing={settings.hidePricing} onOptionSelected={() => this.handleOptionSelected(i)} companyLogo={companyLogo} />)}

                {showAdditionalOptionsLink && <div style={{marginTop: '15px'}}>
                    <span onClick={() => this.setState({showAlternativeOptions: true}) } style={{textDecoration: 'underline', cursor: 'pointer', color: '#949494', display: 'inline-block'}}>
                        {'Show Alternative Options'}
                    </span>
                </div>}

                {hideNonFormularyOptions && !additionalOptionsClicked &&
                <div style={{marginTop: '15px'}}>
                    <span onClick={() => this.setState({additionalOptionsClicked: true}) } style={{textDecoration: 'underline', cursor: 'pointer', color: '#949494', display: 'inline-block'}}>
                        {`See Additional Options (${nonFormularyOptionsCount})`}
                    </span>
                    {nonFormularyOptionsNeedApproval && <span className="approval-required">Approval Required</span>}
                </div>}

                {!hideRequestQuote ? <div className="ps-hidden-md ps-hidden-sm ps-hidden-xs" style={{marginTop: '15px', marginRight: '10px', height: '100px'}}>
                    <span id="additional-option" style={settings.prominentQuoteRequests ? {fontSize: '22px', fontWeight: '400', lineHeight: '22px'} : {fontSize: '16px', fontWeight: '400', lineHeight: '22px'}}> Need an additional option? </span>

                    <AddToCartButton
                        product={product} 
                        option={option} 
                        quantity={quantity} 
                        checkForWarranty={true} 
                        checkForAcknowldegement={true} 
                        onQtyChange={(qty) => this.handleQuantityChange(qty)}
                        isQuote={true}
                        quoteSource="Additional Options" 
                        displayType={DisplayType.RequestQuote} 
                        hadContractOptions={hadContractOptions}
                        hadFormularyOptions={hadFormularyOptions}
                        showShippingFields={false}
                        shippingAddresses={shippingAddresses} />
                </div> : null}
                <div className="kevel-ad">
                    <BrComponent path={'main/left'} />
                </div>
            </div>

        </div>);
    }

    renderLoading(renderLoading) {
        const {delayLoadingIcon} = this.state;
        return (<div>
            <div className="product loading-prices" style={renderLoading ? {marginTop: '65px'} : null}>
                {!delayLoadingIcon &&
                  <React.Fragment>
                      <span className="message">
                    Evaluating highest quality and best pricing
                    options...
                      </span>
                      <div className="loader" />
                  </React.Fragment>}
            </div>
        </div>);
    }

    render() {
        const {
            user: {facility, settings},
            network: {isLoggedIn},
            options,
            currentProduct: {selectedOption, product},
            match: {params: {oemName, partNumber}},
            location: {pathname,breadCrumbs, showBackButton},
        } = this.props;
        const {
            quantity,
            showFacilityDialog,
            showAddToCartConfirmation,
            listName,
            existsInList,
            loadingProduct,
            selectedQuantity,
        } = this.state;

        const option = selectedOption || {};
        const seoTitle = `${product.displayPartNumber} ${product.manufacturer} ${product.description} : PartsSource`;
        const seoDescription = `Buy ${product.manufacturer} ${product.displayPartNumber} ${product.description} at PartsSource.
            Largest Online marketplace for Medical Equipment Replacement Parts featuring OEM Biomedical parts.
            Medical parts online catalog – easy part ID and SmartPrice.`;
        let newPathName = pathname.substring(0, pathname.indexOf(partNumber) + partNumber.length);
        const canonical = ((window.location.origin + newPathName) || '').toLowerCase();

        let hideRequestQuote = facility.facilityId > 0 && (!facility.showTfyp || (facility.rfqManagement === 4 && options.filter(x => x.isBelowRfqLimit).length > 0));
        
        const isContractPro = selectedOption.isContractProOption;
        const sendForStockCheck = ((selectedOption.formularySetting && selectedOption.formularySetting.sendForStockCheck) && (selectedOption.price * selectedQuantity) > selectedOption.formularySetting.submitToSourcingLimit) || selectedOption.sendForStockCheck;
        const isQuote = isContractPro ? false : (settings.isEQuoteEnabled && (options.length === 0 || !(selectedOption.price != null))) || selectedOption.fakeRefurb || settings.noBuy || isQuotedRepair(selectedOption.lineItemCondition) || sendForStockCheck;
        const showHeader = (!hideRequestQuote || selectedOption.price != null || selectedOption.showCustomCatalogMsg ||
            (selectedOption.isRepairOption && isQuote)) && !product.notAvailable;

        if (options.filter(x => x.isSmartPriceOnly).length) hideRequestQuote = true;

        if (product.isDiscontinued || !isLoggedIn) hideRequestQuote = true;
        
        // If the page is being server side rendered, we don't want the loading div to render.
        // this is because when Google indexes the page, it doesn't use JS, so we want a fully rendered,
        // meaningfully painted page to be the response of the HTTP request.
        //
        // Without JS, "loadingProduct" never gets set to false, and the page appears blank. However,
        // everything except Features, Equivalents, and Fitments is actually rendered without client
        // side JS, hiding behind the div rendered by `renderLoading`, so all we need to do to satisfy
        // Google is not render the aforementioned div.

        const isSsr = typeof window === 'undefined' || typeof document === 'undefined';

        return (<div>
            {showHeader && <StickyHeader product={product} option={option} quantity={quantity} variants={options} />}
            {loadingProduct && !isSsr ? this.renderLoading(showBackButton) : null }
            <div className={options.length > 1 ? 'product' : 'product no-option'}>
                <PageMetaData
                    canonical={canonical}
                    title={seoTitle}
                    description={seoDescription}
                    pageType="product"
                    trackAnalytics={false} />
                <div className="product_header ps-hidden-sm ps-hidden-xs">
                    <NavMenu
                        options={getBreadCrumb(breadCrumbs, partNumber, oemName, product.manufacturer)}
                        isPDP={true}
                        className={`${showBackButton ? 'header-nav-menu product_header-nav-menu' : 'header-nav-menu'}`}
                        showBackButton={showBackButton} />
                </div>

                {product.isDiscontinued && 
                        <div className="item-unavailable">
                            <span className="item-unavailable-exclamation">!</span>
                            {hideRequestQuote ? 'This item is no longer available.' : 'This item has been discontinued.'}
                        </div>
                }

                <div className="product_body">
                    <div id="listing-feedback" style={{display: 'none'}} ref={input => this.pricingFeedback = input} />
                    {options.length > 1 ? <div id="product_left-column" className="product_left-column">{this.renderOptions(hideRequestQuote)}</div> : null}
                    <div className={`product_right-column${ options.length > 1 ? '' : ' product_right-column--no-options' }`}>
                        {this.renderDetail(hideRequestQuote)}
                    </div>
                </div>

                {showAddToCartConfirmation &&
                    <AddToCartConfirmation
                        show={true}
                        product={product}
                        isList={true}
                        listName={listName}
                        existsInList={existsInList}
                        quantity={quantity}
                        option={option}
                        onCancel={::this.handleAddToCartCancel}
                        onConfirm={::this.handleAddToCartConfirm} />}
            </div>

            {showFacilityDialog &&
                <FacilityDialog
                    onSubmit={::this.onSelectFacility}
                    onCancel={() => this.setState({showFacilityDialog: false}) }
                    disableClose={ !facility.facilityId || !(facility.facilityId > 0) } />}
        </div>);
    }

    handleAddToCartCancel() {
        this.setState({showAddToCartConfirmation: false});
    }

    handleAddToCartConfirm(isQuote) {
        const {listName} = this.state;
        const {history} = this.props;

        history.push(`/lists/${listName}`);
    }

    handleAddToList(listName, existsInList) {
        const {currentProduct: {selectedOption: {psPartNumber}}} = this.props;
        this.setState({showLists: false, showAddToCartConfirmation: true, existsInList, listName});
        logEvent('ADD TO LIST', {
            'PS Part Number': psPartNumber,
            'List Name': listName,
        });
    }

    handleQuantityChange(qty) {
        this.setState({selectedQuantity: qty});
    }

    validateContract(contractId, assetDataFacilityId) {
        const {validateContract, setContractOptionVendorFieldValue, match: {params: {oemName, partNumber}}} = this.props;
        const {pspn: psPartNumber} = queryString.parse(window.location.search);

        this.setState({loadingProduct: true});

        validateContract(oemName, partNumber, psPartNumber, contractId).then(() => {
            if (assetDataFacilityId) {
                const functionalLocationUid = 'C33AE36B-68F2-47A7-9442-68E92B80594F';
                setContractOptionVendorFieldValue(functionalLocationUid, assetDataFacilityId);
            }

            this.setState({loadingProduct: false});
        });
    }

    setContractOptionVendorFieldValue(functionalLocationUid, assetDataFacilityId) {
        const {setContractOptionVendorFieldValue} = this.props;
        
        setContractOptionVendorFieldValue(functionalLocationUid, assetDataFacilityId);
    }

    openListMenu() {
        let {showLists} = this.state;
        if (!showLists) {
            this.setState({showLists: !showLists});
        }
    }

    handleAddItemToList(itemAdded, list) {

        const {currentProduct: {selectedOption: {psPartNumber}}} = this.props;
        let message = '';
        if (itemAdded) {
            message = 'This item was successfully added to ';
        } else {
            message = 'This item was already in  ';
        }

        this.submitAdobeListItemAdded();

        this.setState({showLists: false,
            showListItemConfirmationMessage: message,
            showListItemConfirmation: true,
            selectedListName: list.name,
            selectedListId: list.ravenId,
        });
    }

    submitAdobeListItemAdded() {
        const {currentProduct: {selectedOption, product}, currentProduct} = this.props;
        const {selectedQuantity} = this.state;
        const productDifferentiatingAttributes = currentProduct.options.flatMap(option => option.attributes || [])
            .filter((attribute => attribute.purpose.toLowerCase() === 'differentiating attribute'))
            .map(attribute => `${attribute.name}~${attribute.value}`).join('|');

        window.appEventData = window.appEventData || [];
        window.appEventData.push({
            'event': 'Product Added to Save List',
            'product': [
                {
                    'price': {
                        'basePrice': getOptionBasePrice(selectedOption),
                        'priceTier': '',
                        'priceType': selectedOption.oemListPrice ? getPartCondition(selectedOption.lineItemCondition) : '',
                        'sellingPrice': selectedOption.price.toFixed(2).toString(),
                    },
                    'productInfo': {
                        'brand': product.manufacturer,
                        'businessUnit': product.modality ? product.modality : '',
                        'formularyName': selectedOption.formularySetting.formularyRuleName ? selectedOption.formularySetting.formularyRuleName : '',
                        'name': product.id,
                        'formularyDisplayType': getFormularyDisplayType(selectedOption.formularySetting.suppressionBehavior, selectedOption.formularySetting.formularyId),
                        'productCategory': product.productCategory ? product.productCategory : product.categories?.map(category => category.split('|').pop()).join(', ') || '',
                        'productCondition': getPartCondition(selectedOption.lineItemCondition),
                        'productID': product.id,
                        'productLine': '',
                        'productPurchaseType': getPurchaseChoice(selectedOption.purchaseChoice),
                        'productVariant': product.isVariantProduct ? productDifferentiatingAttributes : '',
                        'sku': selectedOption.vendorItemNumber ? selectedOption.vendorItemNumber : '',
                        'vString': selectedOption.vendorItemNumber ? selectedOption.vendorItemNumber : '',
                    },
                    'quantity': selectedQuantity,
                },
            ],
        });
    }

    viewListRedirect () {
        const {history} = this.props;
        const {selectedListId} = this.state;

        history.push(`/list/${selectedListId}`);
    }

    closeListItemConfirmationPopup () {
        this.setState({showListItemConfirmation: false})
    }

    handleOptionSelected = (index) => {
        const {location: {pathname}, match: {params: {partNumber}}, params: {referer}, currentProduct: {product, originalProduct, crossSellProducts}, options, selectOption, logProduct, user: {facility}, selectCrossSellItem, selectOriginalItem} = this.props;
        const {currentImageIndex} = this.state;
        const newPathName = pathname.substring(0, pathname.indexOf(partNumber) + partNumber.length).toLowerCase();

        selectOption(index);

        const selectedOption = options[index];

        this.submitAdobeProductInteraction('Option Changed');

        dataLayer.push({
            'event': 'PDP Condition change',
            'ecommerce': {
                'detail': {

                    'products': [{
                        'name': product.partNumber,
                        'id': product.id,
                        'price': selectedOption.price,
                        'brand': product.manufacturer,
                        'category': '',
                        'variant': `${getPartCondition(selectedOption.lineItemCondition)} -  ${getPurchaseChoice(selectedOption.purchaseChoice)}`,
                    }],
                },
            },
        });
        if (selectedOption.psPartNumber) {
            const params = new URLSearchParams(window.location.search);
            params.delete('pspn');
            params.append('pspn', selectedOption.psPartNumber)
            window.history.replaceState(null, null, `${newPathName}?${params.toString()}`);
        } else {
            window.history.replaceState(null, null, `${newPathName}`);
        }

        logProduct(referer, facility);

        if (selectedOption.isRepairCrossSell) {
            const crossSellProd = crossSellProducts.find(prod => prod.id === selectedOption.oemItemNumber)
            selectCrossSellItem(crossSellProd);
        } else if (originalProduct && Object.keys(originalProduct).length > 0) {
            selectOriginalItem();
        }

        this.setQualtricsData(product, selectedOption, currentImageIndex);
    }

    submitAdobeProductInteraction(interactionDetail) {
        const {currentProduct: {selectedOption, product}, currentProduct} = this.props;
        const productDifferentiatingAttributes = currentProduct.options.flatMap(option => option.attributes || [])
            .filter((attribute => attribute.purpose.toLowerCase() === 'differentiating attribute'))
            .map(attribute => `${attribute.name}~${attribute.value}`).join('|');

        window.appEventData = window.appEventData || [];
        window.appEventData.push({
            'event': 'Product Interaction Occurred',
            'product': [
                {
                    'price': {
                        'basePrice': getOptionBasePrice(selectedOption),
                        'priceTier': '',
                        'priceType': selectedOption.oemListPrice ? getPartCondition(selectedOption.lineItemCondition) : '',
                        'sellingPrice': selectedOption.price.toFixed(2).toString(),
                    },
                    'productInfo': {
                        'brand': product.manufacturer,
                        'businessUnit': product.modality ? product.modality : '',
                        'formularyName': selectedOption.formularySetting.formularyRuleName ? selectedOption.formularySetting.formularyRuleName : '',
                        'formularyDisplayType': getFormularyDisplayType(selectedOption.formularySetting.suppressionBehavior, selectedOption.formularySetting.formularyId),
                        'name': product.id,
                        'productCategory': product.productCategory ? product.productCategory : product.categories?.map(category => category.split('|').pop()).join(', ') || '',
                        'productCondition': getPartCondition(selectedOption.lineItemCondition),
                        'productID': product.id,
                        'productPurchaseType': getPurchaseChoice(selectedOption.purchaseChoice),
                        'productVariant': product.isVariantProduct ? productDifferentiatingAttributes : '',
                        'sku': selectedOption.vendorItemNumber ? selectedOption.vendorItemNumber : '',
                        'vString': selectedOption.vendorItemNumber ? selectedOption.vendorItemNumber : '',
                    },
                    'productInteraction': {
                        'interactionDetail': interactionDetail,
                    },
                },
            ],
        });
    }

    onSelectFacility(facility) {
        const {saveUserReduxState} = this.props;
        saveUserReduxState({facility: facility});
        this.setState({showFacilityDialog: false});
    }

    logOneSourceClick = () => {
        logEvent('oneSource Click', {
            'Source': 'PDP',
            'Source Descriptor': window.location.href,
        });
    };

    onSelectedImageChange = ({index} = event) => {
        const {currentProduct: {product, selectedOption}} = this.props;
        this.setState({currentImageIndex: index});
        this.setQualtricsData(product, selectedOption, index);

        if (event.type === 'click') {
            this.submitAdobeProductInteraction(`Image ${index} Clicked`);
        }
    }

    renderDetail(hideRequestQuote) {
        const {
            user: {settings, companyLogo, preferredLogo},
            options,
            currentProduct: {
                product,
                selectedOption,
                outrightListPrice,
                oemPrice,
                oemPriceDate,
                hasOemContract,
                alsoBoughts,
            },
            system: {
                configuration: {features: {oemConnectOrder}},
                siteSettings: {qxEnableCollectPriceFeedback},
            },
            oneSourceMappings,
            network: {isLoggedIn},
        } = this.props;

        const {currentProduct: {selectedOption: {psPartNumber, description, images: imgs}}} = this.props;

        const match = matchPath(window.location.pathname, {
            path: '(/site/_cmsinternal)?/parts/:oemName/:partNumber/:psPartNumber',
            exact: true,
        });
        const {showLists, showConditionDescription, showListItemConfirmation, showListItemConfirmationMessage,
            selectedListName, selectedQuantity, showShippingFields, shippingAddresses} = this.state;
        const oemName = selectedOption.lineItemCondition === 1 && product.manufacturer ? `by ${product.manufacturer}` : '';

        const productDescription = description || product.description;

        let images = (imgs || []).map(function(item) {
            return {
                img: item.image,
                largeImage: item.largeImage,
                altText: item.altText || `${productDescription} ${oemName}`,
                thumbnail: item.thumbnail,
            };
        });

        const isContractPro = selectedOption.isContractProOption;
        const sendForStockCheck = ((selectedOption.formularySetting && selectedOption.formularySetting.sendForStockCheck) && (selectedOption.price * selectedQuantity) > selectedOption.formularySetting.submitToSourcingLimit) || selectedOption.sendForStockCheck;
        const isQuote = isContractPro ? false : (settings.isEQuoteEnabled && (options.length === 0 || (selectedOption.price == null || selectedOption.price == 0))) || selectedOption.fakeRefurb || settings.noBuy || isQuotedRepair(selectedOption.lineItemCondition) || sendForStockCheck;
        const quoteSource = isQuote ? selectedOption.fakeRefurb ? 'Fake Refurb Option' : 'Main CTA Button' : '';
        const displayPartNumberInHeader = [1].indexOf(selectedOption.lineItemCondition) > -1 && product.displayPartNumber;
        const displayAltPartNumberFormat = [6, 2, 4].indexOf(selectedOption.lineItemCondition) > -1;
        const hadContractOptions = options.some(o => o.isContractProOption);
        const oemHasMapping = oneSourceMappings
            .filter(d => d.partsSourceRefs.length > 0)
            .find(d => _.some(d.partsSourceRefs, ['id', (product.manufacturerId || '').toString()]));
        const {pspn} = queryString.parse(window.location.search);
        const showFormularyBadge = selectedOption && selectedOption.isFormularyOption && selectedOption.formularySetting.showBadge && !selectedOption.isContractProOption;
        const showCompanyLogo = selectedOption.formularySetting && selectedOption.formularySetting.showCompanyLogo || selectedOption.formularySetting && selectedOption.formularySetting.showCompanyLogo == undefined;
        const showPplBadge = selectedOption && selectedOption.lineItemCondition === 40;
        const listMenuCSS = !product.isVariantProduct || !selectedOption.minPrice ? '' : 'disabled';
        const productKey = `${product.id}${selectedOption.psPartNumber}${selectedOption.selectedVariantId}${selectedOption.moq}`;

        return (
            <div style={{paddingTop: '35px', paddingBottom: '60px'}}>                
                <div className="product_main">
                    <div className="product-info-wrapper">
                        <div className="product_images">
                            <ImageGallery images={images} className="product_popup"
                                onSelectedImageChange={(event) => {
                                    this.onSelectedImageChange(event);
                                }} />
                        </div>
                    </div>
                    {product.isDiscontinued && hideRequestQuote && 
                        <div className="product_cart product_cart--no-options">
                            <div className="product_header">
                                <h1 className="">
                                    {productDescription} {oemName}
                                </h1>
                                <div style={{color: 'rgb(118, 118, 118)', fontWeight: '600', paddingTop: '30px'}}>Unavailable</div>
                                <div className="item-no-longer-available">
                                    <span className="item-unavailable-exclamation">!</span>
                                    This item is no longer available from PartsSource.
                                </div>
                            </div>
                        </div>

                    }
                    {!product.isDiscontinued && hideRequestQuote && selectedOption.price == null && 
                        <div className="product_cart product_cart--no-options">
                            <div className="product_header">
                                <h1 className={`${options.length > 1 ? '--no-options' : ''}`}>
                                    <div>
                                        {displayPartNumberInHeader ? <span className="product_option_row">{`OEM#: ${product.displayPartNumber}`}</span> : null}
                                        {displayAltPartNumberFormat ? <span className="product_option_row">{`Replaces ${product.manufacturer} ${product.displayPartNumber}`}</span> : null}
                                    </div>
                                    {productDescription} {oemName}
                                </h1>
                            </div>
                        </div>
                    }
                    {(
                        !hideRequestQuote || 
                        selectedOption.price != null || 
                        selectedOption.showCustomCatalogMsg ||
                        (selectedOption.isRepairOption && isQuote)) && 
                        !product.notAvailable && <div key="cart_panel" className={`product_cart${ options.length < 2 ? ' product_cart--no-options' : '' }`}>
                        <div>
                           {showPplBadge ? (
                                <div className="ppl_badge_top">                                  
                                    <img src="/images/ppl_banner_top.png" style={{width: '250px'}} alt="Performance Line" /> 
                                </div>
                            ) : null}
                            {showFormularyBadge ? (
                                <div className="fomulary-logo">
                                    {showCompanyLogo ? (
                                        companyLogo && <img src={`data:image/jpeg;base64,${companyLogo}`} />
                                    ) : null }
                                    <img src={`${preferredLogo}`} style={{width: '125px'}} alt="Preferred" /> 
                                </div>
                            ) : null}
                            <div className="product_header">
                                <h1 className={`${options.length > 1 ? '--no-options' : ''}`}>
                                    <div>
                                        {displayPartNumberInHeader ? <span className="product_option_row">{`OEM#: ${product.displayPartNumber}`}</span> : null}
                                        {displayAltPartNumberFormat ? <span className="product_option_row">{`Replaces ${product.manufacturer} ${product.displayPartNumber}`}</span> : null}
                                    </div>
                                    {productDescription} {oemName}
                                </h1>
                            </div>
                            <div className={options.length > 1 ? 'product_option' : 'product_option no-option'}>
                                <div className="product_option_title">
                                    {selectedOption && selectedOption.lineItemCondition ? getPartCondition(selectedOption.lineItemCondition) : null}
                                    {selectedOption && selectedOption.purchaseChoice === 2 && <img src="/images/exchange.svg" alt="Exchange Item" style={{height: '48px', width: '62px', padding: '0 7px'}} />}
                                    {selectedOption && !selectedOption.isRepairOption && selectedOption.purchaseChoice ? (<span>{' '}{getPurchaseChoice(selectedOption.purchaseChoice)}</span>) : null}
                                    {selectedOption && selectedOption.lineItemCondition ? <i id="ttConditionDescription" style={{cursor: 'pointer'}} onClick={() => this.setState({showConditionDescription: true})} className="fa fa-question-circle product_tooltip">
                                        {showConditionDescription ? <span>{getPartConditionDescription(selectedOption.lineItemCondition)}</span> : null}
                                    </i> : null}
                                </div>
                                {selectedOption.badges && <div className={`product_badge product_badge--${slugify(selectedOption.badges)}`}>{selectedOption.badges}</div>}
                                {selectedOption.isProStockItem && <div className="product_badge product_badge--pro-stock"><b>PRO</b> Dedicated Stock</div>}
                            </div>
                        
                            <CartPanel
                                key={productKey}
                                product={product}
                                option={selectedOption}
                                isQuote={isQuote}
                                quoteSource={quoteSource}
                                outrightListPrice={outrightListPrice}
                                oemPrice={oemPrice}
                                oemPriceDate={oemPriceDate}
                                showOemConnect={oemConnectOrder && hasOemContract}
                                hadContractOptions={hadContractOptions}
                                onQuantityChanged={::this.handleQuantityChange}
                                onContractValidated={::this.validateContract}
                                showShippingFields={showShippingFields}
                                shippingAddresses={shippingAddresses}
                                quantity={selectedQuantity}
                            />
                            {!settings.noBuy && !isQuote ?
                                <div className={!showLists ? `addToListContainer chevron-link pad ${listMenuCSS}` : 'addToListContainer chevron-link pad addToListContainer-selected'} onClick={() => this.openListMenu()}>
                                    <span className="addToListDropdown" style={{cursor: 'pointer'}} >ADD TO LIST</span>
                                    <span className="dropdown-toggle dropdown-toggle-split" />
                                    {showLists && 
                                        <ListMenu
                                            quantity={selectedQuantity} 
                                            product={product}
                                            psPartNumber={psPartNumber}
                                            option={selectedOption}
                                            onClose={() => this.setState({showLists: false})}
                                            onAddItemToList={::this.handleAddItemToList}
                                            onAddToList={::this.handleAddToList} />}
                                </div> : null}
                            {isLoggedIn && qxEnableCollectPriceFeedback && <div id="priceFeedback" className="price-feedback">Tell us about a lower price</div>}
                        </div></div>}
                </div>
                {showPplBadge && <div><img src="/images/ppl_banner_full.png" style={{width: '100%'}}  alt="Performance Line" /></div>}
                {showListItemConfirmation && <Popup
                    confirmText="OK"
                    className="list-item-dialog"
                    show={showListItemConfirmation}
                    onConfirm={() => this.viewListRedirect()}
                    onCancel={() => this.closeListItemConfirmationPopup()}
                    cancelText="Continue Shopping"
                    confirmText="View Your List"
                >
                    <div>
                        <div className="list-item-paragraph no-right-gutter">
                            <div className="selected-list-popup-message">{showListItemConfirmationMessage}</div><div className="selected-list-name">&nbsp;{selectedListName}</div>
                        </div>

                        <div className="list-item-paragraph no-right-gutter">
                            <div className="image-wrapper">
                                <ProductImage url={`${product.images[0].image177}`} style={{width: '120px'}} />
                            </div>
                            <div className="list-item-paragraph-details">
                                <div className="list-item-paragraph-details-desc">{product.description}</div>
                                <div className="list-item-pragraph-info">
                                    <div className="list-item-pragraph-info-oem">
                                OEM: <span className="list-item-pragraph-info-oem-bold">{product.displayPartNumber}</span>
                                    </div>
                                    <div className="list-item-pragraph-info-oem">PS #: {product.id}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>}

                <div className="ps-hidden-sm ps-hidden-md ps-hidden-lg ps-hidden-xl">
                    <div key="tabs" className={`product-tabs ${options.length === 1 ? 'product-tabs--no-options' : '' }`}>
                        <ProductTabs product={product} option={selectedOption} key={productKey} />
                    </div>
                </div>

                <div className="ps-hidden-xs ps-hidden-md ps-hidden-lg ps-hidden-xl">
                    <div key="tabs" className={`product-tabs ${options.length === 1 ? 'product-tabs--no-options' : '' }`}>
                        <ProductTabs product={product} option={selectedOption} key={productKey} />
                    </div>
                </div>

                <div className={options.length > 1 ? 'product-info' : 'product-info product-info--no-options'}>
                    {settings.oneSource && oemHasMapping && <div className="onesource-container">
                        <img alt="One Source" src="/images/logo_one-source.svg" />
                        <Link
                            to={`/onesource-document-search?oemId=${product.manufacturerId}`}
                            onClick={this.logOneSourceClick}
                            target="_blank"
                        >
                            Access your oneSOURCE Library
                        </Link>
                    </div>}

                    <div className="ps-hidden-xs ps-hidden-sm">
                        <div key="tabs" className={`product-tabs ${options.length === 1 ? 'product-tabs--no-options' : '' }`}>
                            <ProductTabs product={product} option={selectedOption} key={productKey} onPricingFeedback={() => this.pricingFeedback.click()} />
                        </div>
                    </div>

                    {pspn && <BrComponent path={'main'}>
                        <ProductDetail />
                    </BrComponent>}
                </div>

                <div className="product_bottom-carousel">
                    <ProductCarousel products={alsoBoughts} title={'Customers Also Bought'} />
                </div>

                <BrComponent path={'main/container'} />
            </div>
        );
    }
}
