import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import {reducer as userReducer} from 'stores/User';
import {reducer as networkReducer} from 'stores/Network';
import {reducer as cartReducer} from 'stores/Cart';
import {reducer as settingsReducer} from 'stores/Settings';
import {reducer as companiesReducer} from 'stores/Companies';
import {reducer as paramsReducer} from 'stores/Params';
import {reducer as productReducer} from 'stores/Product';
import {reducer as catalogReducer} from 'stores/Catalog';
import {reducer as bloomReachReducer} from 'stores/BloomReach';
import {reducer as dashboardReducer} from 'stores/Dashboard';
import {reducer as systemReducer} from 'stores/System';
import {reducer as kevelReducer} from 'stores/Kevel';
import {reducer as reportingReducer} from 'stores/Reporting';
import {reducer as dataConfigurationReducer} from 'stores/DataConfiguration';
import websockets from 'stores/socket';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
const appReducer = combineReducers({
    websockets,
    network: networkReducer,
    user: userReducer,
    cart: cartReducer,
    companies: companiesReducer,
    settings: settingsReducer,
    routing: routerReducer,
    params: paramsReducer,
    currentProduct: productReducer,
    dashboard: dashboardReducer,
    bloomReach: bloomReachReducer,
    catalog: catalogReducer,
    system: systemReducer,
    kevel: kevelReducer,
    reporting: reportingReducer,
    dataConfig: dataConfigurationReducer
});

export * from 'stores/socket';

export default appReducer;
