export function initQualtrics() {
    // Qualtrics manual load
    let stateCheck = setInterval(function () {
        if (document.readyState === 'complete') {
            clearInterval(stateCheck);
            // document ready
            // Check for up to 10 seconds to load Qualtrics script - consider it might not exist
            let qsiCheckCounts = 0;
            const qsiCheck = setInterval(function () {
                if (typeof(QSI) !== 'undefined') {
                    clearInterval(qsiCheck);
                    // Load script
                    window.QSI.API.unload();
                    window.QSI.API.load().then(window.QSI.API.run());
                } else {
                    if (qsiCheckCounts <= 10000) {
                        qsiCheckCounts += 100;
                    } else {
                    // timeout, do not load QSI
                        clearInterval(qsiCheck);
                    }
                }
            }, 100);
        }
    }, 100);
}

export function OptanonWrapper() {
    // Get initial OnetrustActiveGroups ids
    if (typeof window.OptanonWrapperCount === 'undefined') {
        otGetInitialGrps();
    }
 
    if (window.Optanon) {
        // Set google preferences from Onetrust
        let otActiveGrp = [];
        if (window.OnetrustActiveGroups) {
            otActiveGrp = window.OnetrustActiveGroups.split(',');
        } else {
            otActiveGrp = ['C0001']; // only required to work policy
        }
        
        otActiveGrp = otActiveGrp.filter(Boolean);
        if (otActiveGrp.indexOf('C0002') != -1) { 
            window.gtag('consent', 'update', {'analytics_storage': 'granted'});
            if (window.appInsights) {
                window.appInsights.core.getCookieMgr().setEnabled(true);
            }
        } else {
            window.gtag('consent', 'update', {'analytics_storage': 'denied'});
            if (window.appInsights) 
                window.appInsights.core.getCookieMgr().setEnabled(false);
            eraseCookie('_gat_UA-3410320-14');
            eraseCookie('_ga_NSD3MLGNKD');

            eraseCookie('_uetsid');
            eraseCookie('_gcl_au');
            eraseCookie('_uetVid');

            eraseCookie('ai_session');
            eraseCookie('ai_user');
            eraseCookie('ai_authUser');
        }

        if (otActiveGrp.indexOf('C0004') != -1) { 
            window.gtag('consent', 'update', {'ads_storage': 'granted'})
        } else {
            window.gtag('consent', 'update', {'ads_storage': 'denied'})
        }

        // Delete cookies
        otDeleteCookie(window.otIniGrps);
        // Not handled by autoblocker
        if (otActiveGrp.indexOf('C0003') === -1) { 
            eraseCookie('searchHistory');
            eraseCookie('browsingHistory');
            eraseCookie('requesterViewType-onSiteServiceColumns');
            eraseCookie('requesterViewType-myOrdersColumns');
            eraseCookie('requesterViewType-repairsColumns');
            eraseCookie('requesterViewType-rgaColumns');
            eraseCookie('requesterViewType-quotesColumns');
        }

        clearDup();
    }

    // Assign OnetrustActiveGroups to custom variable
    function otGetInitialGrps() {
        window.OptanonWrapperCount = '';
        window.otIniGrps = window.OnetrustActiveGroups;
    }
 
    function otDeleteCookie(iniOptGrpId) {
        let otDomainGrps = JSON.parse(JSON.stringify(window.Optanon.GetDomainData().Groups));
        let otDeletedGrpIds = otGetInactiveId(iniOptGrpId, window.OnetrustActiveGroups);
        if (otDeletedGrpIds.length !== 0 && otDomainGrps.length !== 0) {
            for (let i = 0; i < otDomainGrps.length; i++) {
                // Check if CustomGroupId matches
                if (otDomainGrps[i].CustomGroupId !== '' && otDeletedGrpIds.includes(otDomainGrps[i].CustomGroupId)) {
                    for (let j = 0; j < otDomainGrps[i].Cookies.length; j++) {
                        // console.log("otDeleteCookie",otDomainGrps[i]['Cookies'][j]['Name'])
                        // Delete cookie
                        eraseCookie(otDomainGrps[i].Cookies[j].Name);
                    }
                }
 
                // Check if Hostid matches
                if (otDomainGrps[i].Hosts.length !== 0) {
                    for (let j = 0; j < otDomainGrps[i].Hosts.length; j++) {
                        // Check if HostId presents in the deleted list and cookie array is not blank
                        if (otDeletedGrpIds.includes(otDomainGrps[i].Hosts[j].HostId) && otDomainGrps[i].Hosts[j].Cookies.length != 0) {
                            for (let k = 0; k < otDomainGrps[i].Hosts[j].Cookies.length; k++) {
                                // Delete cookie
                                eraseCookie(otDomainGrps[i].Hosts[j].Cookies[k].Name);
                            }
                        }
                    }
                }
 
            }
        }
        otGetInitialGrps(); // Reassign new group ids
    }
 
    // Get inactive ids
    function otGetInactiveId(customIniId, otActGrp) {
        // Initial OnetrustActiveGroups
        // console.log("otGetInactiveId",customIniId)
        customIniId = customIniId.split(',');
        customIniId = customIniId.filter(Boolean);
 
        // After action OnetrustActiveGroups
        otActGrp = otActGrp.split(',');
        otActGrp = otActGrp.filter(Boolean);
 
        let result = [];
        for (let i = 0; i < customIniId.length; i++) {
            if (otActGrp.indexOf(customIniId[i]) <= -1) {
                result.push(customIniId[i]);
            }
        }
        return result;
    }

    // Delete cookie
    function eraseCookie(name) {
        // Delete root path cookies
        let domainName = window.location.hostname;
        let dotPos = domainName.indexOf('.');
        let allSubDomains = domainName.substring(dotPos);
        document.cookie = name + '=; Max-Age=-99999999; Path=/;Domain=' + domainName;
        document.cookie = name + '=; Max-Age=-99999999; Path=/;Domain=' + allSubDomains;
        document.cookie = name + '=; Max-Age=-99999999; Path=/;';
 
        // Delete LSO incase LSO being used, cna be commented out.
        localStorage.removeItem(name);
 
        // Check for the current path of the page
        let pathArray = window.location.pathname.split('/');
        // Loop through path hierarchy and delete potential cookies at each path.
        for (let i = 0; i < pathArray.length; i++) {
            if (pathArray[i]) {
                // Build the path string from the Path Array e.g /site/login
                let currentPath = pathArray.slice(0,i + 1).join('/');
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + ';Domain=' + domainName;
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + ';Domain=' + allSubDomains;
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + ';';
                // Maybe path has a trailing slash!
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + '/;Domain=' + domainName;
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + '/;Domain=' + allSubDomains;
                document.cookie = name + '=; Max-Age=-99999999; Path=' + currentPath + '/;';
                
            }
        }
    }

    // Should only be used ONLY on Cookie Policy.  
    // Trigger function below to remove duplicate categories.
    
    function clearDup() {
        let sec = document.getElementById('ot-sdk-cookie-policy');
        let tally = [];
        if (sec) { 
            for (let i = sec.length - 1; i >= 0; i--) {
                if (tally[sec[i].firstChild.innerText] === undefined) {
                    tally[sec[i].firstChild.innerText] = 1;
                } else {
                    // console.log(i,sec[i].firstChild.innerText);
                    sec[i].remove();
                    // return true;
                }
            }
        }        
        // return false;
    }
}

function loadJS(FILE_URL, async = true) {
    let scriptEle = document.createElement('script');

    scriptEle.setAttribute('src', FILE_URL);
    scriptEle.setAttribute('type', 'text/javascript');
    scriptEle.setAttribute('async', async);

    document.body.appendChild(scriptEle);

    // success event 
    scriptEle.addEventListener('load', () => {
        console.log('File loaded')
    });
    // error event
    scriptEle.addEventListener('error', (ev) => {
        console.log('Error on loading file', ev);
    });
}

const window = global.window;
if (window) {
    loadJS(process.env.REACT_APP_SISENSE_URL + '/js/frame.js');
    window.OptanonWrapper = OptanonWrapper;
}
