import axios from 'axios';
import Cookies from 'js-cookie';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {setToken, clearTokenInfo} from 'utility';
import {isSessionStorageAvailable} from '../utility';

export const AdminRole = {
    FullAdmin: 'Full Admin',
    ReadOnly: 'Read Only',
    Impersonation: 'Impersonation',
    RequestForQuoteManger: 'Request For Quote Manger',
    ContractOrderManager: 'ContractOrderManager',
    ContractManager: 'ContractManager',
    ReportingAdmin: 'ReportingAdmin',
    VendorReqAdmin_Dev: 'VendorReqAdmin_Dev',
    CustomerReqAdmin_Dev: 'CustomerReqAdmin_Dev',
    VendorReqAdmin_Prod: 'VendorReqAdmin_Prod',
    CustomerReqAdmin_Prod: 'CustomerReqAdmin_Prod',
    CustomerTeamContactCenterAdmin: 'CustomerTeamContactCenterAdmin',
    VendorCertAdmin: 'VendorCertAdmin',
    VariableShipMargin_Dev: 'VariableShipMargin_Dev',
    VariableShipMargin_Prod: 'VariableShipMargin_Prod',
    alertManagementAdmin_Dev: 'alertManagementAdmin_Dev',
    alertManagementAdmin_Prod: 'alertManagementAdmin_Prod',
};

export const AccountStatus = {
    Valid: 1,
    UnconfirmedEmail: 2,
    LockedOut: 4,
    Invalid: 8,
    Deactivated: 16,
    PasswordExpired: 32,
    EulaRequired: 64,
};

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
const defaultNetworkState = {
    isBusy: false,
    hasError: false,
    isLoggedIn: false,
    hideInitialSpinner: false, //Needed for SSR
    initialPage: '',
    sessionStorageAvailable: false,
    tokenInfo: {
        loginId: 0,
        userId: 0,
        firstName: null,
        lastName: null,
        subscriptionId: false,
        passwordExpired: false,
        eulaRequired: false,
        isImpersonation: false,
    },
    admin: {
        role: AdminRole.FullAdmin,
        isLoggedIn: false,
        permissions: {
            fullAdmin: true,
            readOnly: false,
            requestForQuoteManager: false,
            contractOrderManager: false,
            contractManager: false,
            vendorReqAdmin_Dev: false,
            customerReqAdmin_Dev: false,
            vendorReqAdmin_Prod: false,
            customerReqAdmin_Prod: false,
            customerTeamContactCenterAdmin: false,
            vendorCertAdmin: false,
            variableShipMargin_Dev: false,
            variableShipMargin_Prod: false,
            alertManagementAdmin_Dev: false,
            alertManagementAdmin_Prod: false,
        },
    },
    useOktaAuthentication: true
};

export const StateShape = PropTypes.shape({
    isLoggingIn: PropTypes.bool,
    isLoggingOut: PropTypes.bool,
    isBusy: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    initialPage: PropTypes.string,
    sessionStorageAvailable: PropTypes.bool,
    tokenInfo: PropTypes.shape({
        loginId: PropTypes.number,
        userId: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        subscriptionId: PropTypes.bool,
        passwordExpired: PropTypes.bool,
        eulaRequired: PropTypes.bool,
        isImpersonation: PropTypes.bool,
        isCADisabled: PropTypes.bool,
    }),
    admin: PropTypes.shape({
        isLoggedIn: PropTypes.bool,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        userId: PropTypes.number,
        username: PropTypes.string,
        permissions: PropTypes.shape({
            fullAdmin: PropTypes.bool,
            readOnly: PropTypes.bool,
            requestForQuoteManager: PropTypes.bool,
            contractOrderManager: PropTypes.bool,
            contractManager: PropTypes.bool,
            vendorReqAdmin_Dev: PropTypes.bool,
            customerReqAdmin_Dev: PropTypes.bool,
            vendorReqAdmin_Prod: PropTypes.bool,
            customerReqAdmin_Prod: PropTypes.bool,
            customerTeamContactCenterAdmin: PropTypes.bool,
            vendorCertAdmin: PropTypes.bool,
            variableShipMargin_Dev: PropTypes.bool,
            variableShipMargin_Prod: PropTypes.bool,
            alertManagementAdmin_Dev: PropTypes.bool,
            alertManagementAdmin_Prod: PropTypes.bool,
        }),
    }),
    authState: null,
    oktaAuth: null,
    isLoggingIn: false,
    isLoggingOut: false
});

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    login: (loginInfo) => login(loginInfo),
    setOktaAuthState: (authState) => setOktaAuthState(authState),
    setOktaAuth: (oktaAuth) => setOktaAuth(oktaAuth),
    setUseOktaAuthentication: (useOktaAuthentication) => setUseOktaAuthentication(useOktaAuthentication),
    setIsLoggingIn: (isLoggingIn) => setIsLoggingIn(isLoggingIn),
    setIsLoggingOut: (isLoggingOut) => setIsLoggingOut(isLoggingOut),
    ssoLogin: (ak1, ak2) => ssoLogin(ak1, ak2),
    impersonateUser: (userId) => impersonateUser(userId),
    stopImpersonation: () => stopImpersonation(),
    registerWithZipCode: (loginInfo) => registerWithZipCode(loginInfo),
    register: ({loginInfo, token}) => register({loginInfo, token}),
    forgotPassword: (loginInfo) => forgotPassword(loginInfo),
    changePassword: (loginInfo) => changePassword(loginInfo),
    hashPassword: (loginInfo) => hashPassword(loginInfo),
    forgotUsername: (loginInfo) => forgotUsername(loginInfo),
    setUserId: (userId, firstName, lastName, subscriptionId, passwordExpired, eulaRequired) => setUserId(userId, firstName, lastName, subscriptionId, passwordExpired, eulaRequired),
    setAdminUser: (tokenInfo) => setAdminUser(tokenInfo),
    setUserTokenInfo: (tokenInfo) => setUserTokenInfo(tokenInfo),
    setHasError: (hasError) => setHasError(hasError),
    setInitialPage: (initialPage) => setInitialPage(initialPage),
    logout: () => logout(),
    setMfaOptIn: (mfaOptIn) => setMfaOptIn(mfaOptIn),
    setHideInitialSpinner: (hide) => setHideInitialSpinner(hide),
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

export function ssoLogin(ak1, ak2, useOkta) {
    // let ak1 = encodeURIComponent(Cookies.get('ak1'));
    // let ak2 = encodeURIComponent(Cookies.get('ak2'));
    return (dispatch) => {
        const bound = {
            response: (response) => dispatch({
                type: 'SSO_RESP',
                response: response,
                userId: response.userName,
            }),
            error: (error) => dispatch({
                type: 'SSO_ERR',
                response: error,
            }),
        };

        if(useOkta) {
            return axios.get(`AuthenticationService/api/v1/okta/login?ak1=${encodeURIComponent(ak1)}&ak2=${encodeURIComponent(ak2)}`)
            .then(bound.response)
            .catch(bound.error);
        } else {
            const tokenRequest = `client_id=owner&client_secret=secret&grant_type=password&username=${encodeURIComponent(ak1)}&password=${encodeURIComponent(ak2)}&SSO=true`;
            return axios.post(`/AuthenticationService/connect/token`, tokenRequest, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
            .then(bound.response)
            .catch(bound.error);
        }
    };
}

function login(loginInfo) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'LOGIN_REQ'}),
            response: (response) => dispatch({
                type: 'LOGIN_RESP',
                response: response,
                userId: loginInfo.username,
            }),
            error: (error) => dispatch({
                type: 'LOGIN_ERR',
                response: error,
            }),
        };

        bound.request();
        const tokenRequest = `client_id=owner&client_secret=secret&scopes=api3%20openid&grant_type=password&username=${loginInfo.username.trim()}&password=${loginInfo.password.trim()}`;
        return axios.post(`/AuthenticationService/connect/token`, tokenRequest, {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
            .then(bound.response)
            .catch(bound.error);
    };
}

function impersonateUser(userId) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'LOGIN_REQ'}),
            response: (response) => dispatch({
                type: 'IMPERSONATE_RESP',
                response,
                userId,
            }),
            error: (error) => dispatch({
                type: 'LOGIN_ERR',
                response: error,
            }),
        };

        bound.request();
        return axios.post(`/SettingsService/api/v1/impersonate/${userId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function stopImpersonation() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'LOGIN_REQ'}),
            response: (response) => dispatch({
                type: 'LOGIN_RESP',
                response,
            }),
            error: (error) => dispatch({
                type: 'LOGIN_ERR',
                response: error,
            }),
        };

        return axios.post('/SettingsService/api/v1/stopImpersonation')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function setUserId(userId, firstName, lastName, subscriptionId, passwordExpired, eulaRequired) {
    let tokenInfo = {
        userId,
        firstName,
        lastName,
        loginId: userId,
        passwordExpired,
        eulaRequired,
    };

    if (subscriptionId)
        tokenInfo.subscriptionId = subscriptionId;

    if (userId) {
        return (dispatch) => {
            dispatch({type: 'SET_USER_ID', tokenInfo});
        };
    } else {
        return (dispatch, getState) => {
            return getState();
        }
    }
}

export function setAdminUser(tokenInfo) {
    return (dispatch) => {
        dispatch({type: 'SET_ADMIN_USER', tokenInfo})
    };
}

export function setUserTokenInfo(tokenInfo) {
    return (dispatch) => {
        dispatch({type: 'SET_TOKEN_INFO', tokenInfo})
    };
}

export function setInitialPage(initialPage) {
    return (dispatch) => {
        dispatch({type: 'SET_INITIAL_PAGE', initialPage})
    };
}

export function setHasError(hasError) {
    return (dispatch) => {
        dispatch({type: 'SET_HAS_ERROR', hasError})
    };
}

function registerWithZipCode(loginInfo) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'REGISTER_ZIP_REQ'}),
            response: (response) => dispatch({
                type: 'REGISTER_ZIP_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'REGISTER_ZIP_ERR',
                response: error,
            }),
        };

        bound.request();
        loginInfo.zipCode = loginInfo.facilityZipCode;

        return axios.post(`/ShoppingService/api/v1/account/GetRecordsAssociatedWithEmailAddress`, loginInfo)
            .then(bound.response)
            .catch(bound.error);
    };
}

function register({ loginInfo, token }) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'REGISTER_REQ'}),
            response: (response) => dispatch({
                type: 'REGISTER_RESP',
                response: response,
            }),
            error: (error) => dispatch({
                type: 'REGISTER_ERR',
                response: error,
            }),
        };

        bound.request();
        loginInfo.email = loginInfo.emailAddress;

        return axios.post(`/ShoppingService/api/v1/account/Register`, { LoginInfo: loginInfo, Token: token })
            .then(bound.response)
            .catch(bound.error);
    };
}

function forgotPassword(loginInfo) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'FORGOT_PASS_REQ'}),
            response: (response) => dispatch({
                type: 'FORGOT_PASS_RESP',
                response,
            }),
            error: (error) => dispatch({
                type: 'FORGOT_PASS_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.post(`/ShoppingService/api/v1/account/ForgotPassword`, loginInfo)
            .then(bound.response)
            .catch(bound.error);
    };
}

function forgotUsername(loginInfo) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'FORGOT_USER_REQ'}),
            response: (response) => dispatch({
                type: 'FORGOT_USER_RESP',
                response,
            }),
            error: (error) => dispatch({
                type: 'FORGOT_USER_ERR',
                response: error,
            }),
        };

        bound.request();

        return axios.post(`/ShoppingService/api/v1/account/ForgotUsername`, loginInfo)
            .then(bound.response)
            .catch(bound.error);
    };
}

function changePassword(loginInfo) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'CHANGE_PASS_REQ'}),
            response: (response) => dispatch({
                type: 'CHANGE_PASS_RESP',
                response,
            }),
            error: (error) => dispatch({
                type: 'CHANGE_PASS_ERR',
                response: error,
            }),
        };

        return axios.post(`/ShoppingService/api/v1/account/ChangePassword`, loginInfo)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function hashPassword(loginInfo) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({type: 'CHANGE_PASS_REQ'}),
            response: (response) => dispatch({
                type: 'RESET_PASS_RESP',
                response,
            }),
            error: (error) => dispatch({
                type: 'RESET_PASS_ERR',
                response: error,
            }),
        };

        return axios.post(`/ShoppingService/api/v1/account/DecryptPassword`, loginInfo)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function logout() {
    return (dispatch) => dispatch({type: 'LOGOUT_REQ'});
}

export function setOktaAuthState(authState) {
    return (dispatch) => dispatch({type: 'SET_OKTA_AUTH_STATE', authState}); 
}

export function setOktaAuth(oktaAuth) {
    return (dispatch) => dispatch({type: 'SET_OKTA_AUTH', oktaAuth}); 
}

export function setUseOktaAuthentication(useOktaAuthentication) {
    return (dispatch) => dispatch({type: 'SET_USE_OKTA_AUTHENTICATION', useOktaAuthentication});
}

export function oktaLoginUser(userInfo, access_token, refresh_token) {
    return async (dispatch, getState) => {
        // const tokenRequest = `client_id=gondola&client_secret=GondolaSecret&grant_type=password&username=${username}&password=${password}`;
        // const response = await axios.post(`${process.env.REACT_APP_ORION_API_ROUTE_URL}/AuthenticationService/connect/token`, tokenRequest)
        dispatch({type: 'OKTA_LOGIN_USER', userInfo, access_token, refresh_token});
        return userInfo;
    }
}

export function setIsLoggingIn(isLoggingIn) {
    return (dispatch) => {
        dispatch({type: 'SET_IS_LOGGING_IN', isLoggingIn});
    };
}

export function setIsLoggingOut(isLoggingOut) {
    return (dispatch) => {
        dispatch({type: 'SET_IS_LOGGING_OUT', isLoggingOut});
    };
}

export function setMfaOptIn(mfaOptIn) {
    return (dispatch) => {
        dispatch({type: 'SET_MFA_OPT_IN', mfaOptIn});
    };
}

export function setHideInitialSpinner(hide) {
    return (dispatch) => {
        dispatch({type: 'HIDE_INITIAL_SPINNER', hide});
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer = (state = _.cloneDeep(defaultNetworkState), action = null) => {
    switch (action.type) {
        case 'LOGIN_REQ':
        case 'REGISTER_ZIP_REQ':
        case 'REGISTER_REQ':
        case 'FORGOT_PASS_REQ':
        case 'CHANGE_PASS_REQ':
        case 'FORGOT_USER_REQ':
            return {...state, isBusy: true, isLoggedIn: false};
        case 'LOGOUT_REQ':
            clearTokenInfo();
            return {...state, isBusy: true, isLoggedIn: false};
        case 'SET_USER_ID': {
            const updatedTokenInfo = _.merge({}, state.tokenInfo, action.tokenInfo);
            return {...state, tokenInfo: updatedTokenInfo, isLoggedIn: true};
        }
        case 'SET_ADMIN_USER': {
            let admin = state.admin;
            admin = action.tokenInfo;
            return {...state, admin: admin};
        }
        case 'SET_TOKEN_INFO': {
            state.tokenInfo = action.tokenInfo;
            return {...state, state};
        }
        case 'SET_INITIAL_PAGE': {
            state.initialPage = action.initialPage;
            return {...state, state};
        }
        case 'SET_HAS_ERROR': {
            state.hasError = action.hasError;
            return {...state, state};
        }
        case 'REGISTER_ZIP_RESP':
        case 'FORGOT_PASS_RESP':
        case 'FORGOT_USER_RESP':
            return {...state, isLoggedIn: false, isBusy: false};
        case 'REGISTER_RESP': {
            const updatedTokenInfo = _.merge({}, state.tokenInfo, {loginId: 0, userId: 0, firstName: null, lastName: null, passwordExpired: false});
            return {...state, tokenInfo: updatedTokenInfo, isLoggedIn: false, isBusy: false};
        }
        case 'RESET_PASS_RESP':
        case 'RESET_PASS_ERR': {
            const updatedTokenInfo = _.merge({}, state.tokenInfo, {loginId: 0, userId: 0, firstName: null, lastName: null, passwordExpired: false});
            return {...state, tokenInfo: updatedTokenInfo, isLoggedIn: false, isBusy: false};
        }
        case 'CHANGE_PASS_RESP': {
            const {status, data: {result}} = action.response;
            if (status === 200 && result === 0) {
                return {...state, isBusy: false, tokenInfo: _.merge({}, state.tokenInfo, {passwordExpired: false})};
            }

            const updatedTokenInfo = _.merge({}, state.tokenInfo, {loginId: 0, userId: 0, firstName: null, lastName: null, passwordExpired: false});
            return {...state, tokenInfo: updatedTokenInfo, isLoggedIn: false, isBusy: false};
        }
        case 'LOGIN_RESP': {
            Cookies.remove('ak1');
            Cookies.remove('ak2');
            Cookies.remove('employee');
            Cookies.remove('impersonate');
            const {data, status, data: {token_type, access_token, refresh_token, expires_in, subscriptionId, eulaRequired}} = action.response;

            if (status === 200) {
                const bearerToken = `${token_type} ${access_token}`;
                if (Cookies.get('rememberMe') === 'true' && window.location.hostname !== 'localhost') {
                    Cookies.set('id', action.userId, {'secure': window.location.hostname !== 'localhost', sameSite: 'None', 'expires': 30});
                }
                setToken(bearerToken, refresh_token, expires_in);

                if (data.isEmployee) {
                    const roles = data.roles.map(r => r.toLowerCase());
                    let tokenInfo = {
                        isLoggedIn: true,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        userId: data.loginId, // This needs to be the numeric value always
                        username: action.userId,
                        isCADisabled: false,
                        permissions: {
                            fullAdmin: roles.includes('apolloadmin'.toLowerCase()),
                            readOnly: roles.includes('apolloreadonly'.toLowerCase()),
                            requestForQuoteManager: roles.includes('apollorfqmanagement'.toLowerCase()),
                            contractOrderManager: roles.includes('apollocontractordermanagement'.toLowerCase()),
                            contractManager: roles.includes('apollocontractmanagement'.toLowerCase()),
                            vendorReqAdmin_Dev: roles.includes('vendorreqadmin_dev'.toLowerCase()),
                            customerReqAdmin_Dev: roles.includes('customerreqadmin_dev'.toLowerCase()),
                            vendorReqAdmin_Prod: roles.includes('vendorreqadmin_prod'.toLowerCase()),
                            customerReqAdmin_Prod: roles.includes('customerreqadmin_prod'.toLowerCase()),
                            customerTeamContactCenterAdmin: roles.includes('customerteamcontactcenteradmin'.toLowerCase()),
                            vendorCertAdmin: roles.includes('apollo_vendorcert'.toLowerCase()),
                            variableShipMargin_Dev: roles.includes('variableshipmargin_dev'.toLowerCase()),
                            variableShipMargin_Prod: roles.includes('variableshipmargin_prod'.toLowerCase()),
                            alertManagementAdmin_Dev: roles.includes('alertManagement_dev'.toLowerCase()),
                            alertManagementAdmin_Prod: roles.includes('alertManagement_prod'.toLowerCase()),
                        },
                    };

                    switch (data.apolloAdminRole) {
                        case 1:
                            tokenInfo.role = AdminRole.FullAdmin;
                            break;
                        case 2:
                            tokenInfo.role = AdminRole.ReadOnly;
                            break;
                        case 3:
                            tokenInfo.role = AdminRole.Impersonation;
                            break;
                        case 4:
                            tokenInfo.role = AdminRole.RequestForQuoteManger;
                            break;
                        case 5:
                            tokenInfo.role = AdminRole.ContractOrderManager;
                            break;
                        case 6:
                            tokenInfo.role = AdminRole.ContractManager;
                            break;
                        case 7:
                            tokenInfo.role = AdminRole.VendorReqAdmin_Dev;
                            break;
                        case 8:
                            tokenInfo.role = AdminRole.CustomerReqAdmin_Dev;
                            break;
                        case 9:
                            tokenInfo.role = AdminRole.VendorReqAdmin_Prod;
                            break;
                        case 10:
                            tokenInfo.role = AdminRole.CustomerReqAdmin_Prod;                            
                            break;
                        case 11:
                            tokenInfo.role = AdminRole.CustomerTeamContactCenterAdmin;
                            break;
                        case 12:
                            tokenInfo.role = AdminRole.VendorCertAdmin;
                            break;
                        case 13:
                            tokenInfo.role = AdminRole.VariableShipMargin_Dev;
                            break;
                        case 14:
                            tokenInfo.role = AdminRole.VariableShipMargin_Prod;
                            break;
                        case 15:
                            tokenInfo.role = AdminRole.AlertManagementAdmin_Dev;
                            break;
                        case 16:
                            tokenInfo.role = AdminRole.AlertManagementAdmin_Prod;
                            break;
                        default:
                            tokenInfo.role = AdminRole.ReadOnly;
                    }
                    
                    const isNotLocalhost = window.location.hostname !== 'localhost';
                    Cookies.set('employee', JSON.stringify(tokenInfo), 
                        {
                            'secure': isNotLocalhost, 
                            sameSite: isNotLocalhost ? 'None' : undefined,
                        });
                    return {...state, tokenInfo: {}, admin: tokenInfo, isLoggedIn: false, isBusy: false};
                } else {
                    const tokenInfo = _.merge(_.omit(data, ['psAuthenticationKey', 'psAuthorizationKey']),
                        {
                            userId: data.loginId, // This needs to be the numeric value always
                            subscriptionId: subscriptionId || false,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            username: action.userId,
                            passwordExpired: data.status & !data.isCADisabled & AccountStatus.PasswordExpired ? true : false,
                            eulaRequired: eulaRequired ? eulaRequired : false,
                            isCADisabled: data.isCADisabled,
                        });
                    Cookies.set('subscriptionId', subscriptionId || false, {'secure': window.location.hostname !== 'localhost', sameSite: 'None'});
                    return {...state, initialPage: window.location.pathname, tokenInfo: tokenInfo, admin: {}, isLoggedIn: true, isBusy: false};
                }

            }

            return {...state, tokenInfo: {loginId: 0, userId: 0, firstName: null, lastName: null}, token: null, admin: {}, isLoggedIn: false, isBusy: false};
        }
        case 'SSO_RESP': {
            return {...state, isLoggedIn: true, isBusy: false};
        }

        case 'SESSION_STORAGE_AVAILABLE': {
            const isAvailable = isSessionStorageAvailable();
            return {...state, sessionStorageAvailable: isAvailable};
        }

        case 'IMPERSONATE_RESP': {
            const {data, status, data: {token_type, access_token, refresh_token, expires_in, error, isImpersonation}} = action.response;

            if (status === 200 && !error) {
                const bearerToken = `${token_type} ${access_token}`;
                setToken(bearerToken, refresh_token, expires_in);

                const tokenInfo = _.omit(data, ['psAuthenticationKey', 'psAuthorizationKey']);
                tokenInfo.userId = action.userId;
                tokenInfo.subscriptionId = false;
                tokenInfo.passwordExpired = false;
                tokenInfo.eulaRequired = false;
                tokenInfo.isImpersonation = true;
                const isNotLocalhost = window.location.hostname !== 'localhost';
                Cookies.set('impersonate', JSON.stringify(tokenInfo), 
                    {
                        'secure': isNotLocalhost, 
                        sameSite: isNotLocalhost ? 'None' : undefined,
                    });
                
                return {...state, tokenInfo: tokenInfo, admin: {}, isLoggedIn: true, isBusy: false};
            }

            return {...state}
        }

        case 'OKTA_LOGIN_USER': {
            const {userInfo, access_token} = action;

            const isNotLocalhost = window.location.hostname !== 'localhost';
            const tokenType = access_token.tokenType;
            const token = access_token.value || access_token.accessToken;
            const in30Days = new Date(new Date().getTime() + 24 * 30 * 60 * 60 * 1000);
            Cookies.set('token', `${tokenType} ${token}`, {
                expires: in30Days,
                secure: isNotLocalhost,
                sameSite: isNotLocalhost ? 'None' : undefined,
            });

            const userId = parseInt(access_token.claims.userId);
            const isEmployee = access_token.claims.isEmployee || false;
            const isImpersonation = access_token.claims.isImpersonation || false;
            const status = parseInt(access_token.claims.status) || 0;
            const eulaRequired = access_token.claims.eulaExpired;
            const groups = access_token.claims.Groups || [];
            const subscriptionId = access_token.claims.subscriptionId; 
            const psFirstName = access_token.claims.psFirstName || ''; 
            const psLastName = access_token.claims.psLastName || ''; 
            const mfaOptIn = access_token.claims.mfaOptIn || false;

            if (!isImpersonation) {
                if (isEmployee) {
                    let tokenInfo = {
                        isLoggedIn: true,
                        firstName: userInfo.given_name,
                        lastName: userInfo.family_name,
                        userId: userId, // This needs to be the numeric value always
                        username: action.userId,
                        permissions: {
                            fullAdmin: _.findIndex(groups, g => g.toLowerCase().includes('apolloadmin')) !== -1,
                            readOnly: _.findIndex(groups, g => g.toLowerCase().includes('apolloreadonly')) !== -1,
                            requestForQuoteManager: _.findIndex(groups, g => g.toLowerCase().includes('apollorfqmanagement')) !== -1,
                            contractOrderManager: _.findIndex(groups, g => g.toLowerCase().includes('apollocontractordermanagement')) !== -1,
                            contractManager: _.findIndex(groups, g => g.toLowerCase().includes('apollocontractmanagement')) !== -1,
                            vendorReqAdmin_Dev: _.findIndex(groups, g => g.toLowerCase().includes('vendorreqadmin_dev')) !== -1,
                            customerReqAdmin_Dev: _.findIndex(groups, g => g.toLowerCase().includes('customerreqadmin_dev')) !== -1,
                            vendorReqAdmin_Prod: _.findIndex(groups, g => g.toLowerCase().includes('vendorreqadmin_prod')) !== -1,
                            customerReqAdmin_Prod: _.findIndex(groups, g => g.toLowerCase().includes('customerreqadmin_prod')) !== -1,
                            customerTeamContactCenterAdmin: _.findIndex(groups, g => g.toLowerCase().includes('customerteamcontactcenteradmin')) !== -1,
                            vendorCertAdmin: _.findIndex(groups, g => g.toLowerCase().includes('apollo_vendorcert')) !== -1,
                            variableShipMargin_Dev: _.findIndex(groups, g => g.toLowerCase().includes('variableshipmargin_dev')) !== -1,
                            variableShipMargin_Prod: _.findIndex(groups, g => g.toLowerCase().includes('variableshipmargin_prod')) !== -1,
                        }
                    };

                    const isFullAdmin = _.findIndex(groups, g => g.toLowerCase().includes('apolloadmin')) !== -1;
                    const isReadOnly = _.findIndex(groups, g => g.toLowerCase().includes('apolloreadonly')) !== -1;
                    const isRfqManager = _.findIndex(groups, g => g.toLowerCase().includes('apollorfqmanagement')) !== -1;
                    const isContractProOrderManager = _.findIndex(groups, g => g.toLowerCase().includes('apollocontractordermanagement')) !== -1;
                    const isContractProManager = _.findIndex(groups, g => g.toLowerCase().includes('Apollocontractmanagement')) !== -1;

                    if (isFullAdmin)
                        tokenInfo.role = AdminRole.FullAdmin;
                    else if (isReadOnly)
                        tokenInfo.role = AdminRole.RequestForQuoteManger;
                    else if (isRfqManager)
                        tokenInfo.role = AdminRole.RequestForQuoteManger;
                    else if (isContractProOrderManager)
                        tokenInfo.role = AdminRole.ContractOrderManager;
                    else if (isContractProManager)
                        tokenInfo.role = AdminRole.ContractManager;
                    else 
                        tokenInfo.role = AdminRole.ReadOnly;

                    Cookies.set('employee', JSON.stringify(tokenInfo), {'secure': window.location.hostname !== 'localhost', sameSite: 'None'});
                    return {...state, tokenInfo: {}, admin: tokenInfo, isLoggedIn: false, isBusy: false};
                } else {
                    const tokenInfo = {
                        userId: userId, // This needs to be the numeric value always
                        loginId: userId,
                        subscriptionId: subscriptionId || false,
                        firstName: userInfo.given_name,
                        lastName: userInfo.family_name,
                        username: userId,
                        passwordExpired: status & AccountStatus.PasswordExpired ? true : false,
                        eulaRequired: eulaRequired ? eulaRequired : false,
                        isImpersonation,
                        mfaOptIn
                    };
                    Cookies.set('subscriptionId', subscriptionId || false, {'secure': window.location.hostname !== 'localhost', sameSite: 'None'});
                    return {...state, initialPage: window.location.pathname, tokenInfo: tokenInfo, admin: {}, isLoggedIn: true, isBusy: false};
                }
            } else {
                let tokenInfo = {
                    userId: userId, // This needs to be the numeric value always
                    loginId: userId,
                    firstName: userInfo.given_name,
                    lastName: userInfo.family_name,
                    username: userId,
                    subscriptionId: false,
                    passwordExpired: false,
                    eulaRequired: false,
                    isImpersonation: true,
                    psFirstName: psFirstName,
                    psLastName: psLastName,
                    mfaOptIn: mfaOptIn,
                    role: AdminRole.Impersonation,
                }
                Cookies.set('impersonate', JSON.stringify(tokenInfo), {'secure': window.location.hostname !== 'localhost', sameSite: 'None'});
                return {...state, tokenInfo: tokenInfo, admin: {}, isLoggedIn: true, isBusy: false};
            }
        }

        case 'SET_OKTA_AUTH_STATE': {
            const {authState} = action;

            if (authState && !authState.isAuthenticated)
                Cookies.remove('token');
            else if (authState && authState.isAuthenticated && authState.accessToken) {
                if(!Cookies.get('impersonate') && !Cookies.get('ssoLogin')) {
                    const {accessToken} = authState;
                    const isNotLocalhost = window.location.hostname !== 'localhost';
                    const tokenType = accessToken.tokenType;
                    const token = accessToken.value || accessToken.accessToken;
                    const in30Days = new Date(new Date().getTime() + 24 * 30 * 60 * 60 * 1000);
                    Cookies.set('token', `${tokenType} ${token}`, {
                        expires: in30Days,
                        secure: isNotLocalhost,
                        sameSite: isNotLocalhost ? 'None' : undefined,
                    });
                }        
            }
            return {...state, authState};
        }

        case 'SET_OKTA_AUTH': {
            const {oktaAuth} = action;
            return {...state, oktaAuth};
        }

        case 'SET_USE_OKTA_AUTHENTICATION': {
            const {useOktaAuthentication} = action;
            return {...state, useOktaAuthentication};
        }

        case 'SET_IS_LOGGING_IN': {
            return {...state, isLoggingIn: action.isLoggingIn};
        }
        
        case 'SET_IS_LOGGING_OUT': {
            return {...state, isLoggingOut: action.isLoggingOut};
        }

        case 'SET_MFA_OPT_IN': {
            const updatedTokenInfo = _.merge({}, state.tokenInfo, {mfaOptIn: action.mfaOptIn});
            return {...state, tokenInfo: updatedTokenInfo};
        }

        case 'HIDE_INITIAL_SPINNER': {
            return {...state, hideInitialSpinner: action.hide};
        }

        case 'LOGOUT_RESP':
        case 'LOGOUT_ERR':
        case 'LOGIN_ERR':
        case 'REGISTER_ZIP_ERR':
        case 'REGISTER_ERR':
        case 'FORGOT_PASS_ERR':
        case 'CHANGE_PASS_ERR':
        case 'FORGOT_USER_ERR':
        case 'SSO_ERR':
            return {...state, tokenInfo: defaultNetworkState.tokenInfo, token: null, isLoggedIn: false, isBusy: false};
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state;
};
