import React, {useContext} from 'react';
import queryString from 'query-string';
import {BrComponentContext, BrPageContext} from '@bloomreach/react-sdk';
import {RichContent} from '@partssourceinc/react-ui-core/cms';
import {isPsPartNumber} from 'utility';

const ProductDetail = (props) => {
    const component = useContext(BrComponentContext);
    const page = useContext(BrPageContext);

    const models = component.getModels() || {};
    const resources = models && models.resources || [];

    const renderItem = (item) => {
        const {pspn: psPartNumber} = queryString.parse(location.search);

        if (item.name.toLowerCase() !== psPartNumber.toLowerCase() && isPsPartNumber(item.name))
            return;

        const left = item.left && item.left.content && item.left.content.value
            ? page.rewriteLinks(item.left.content.value) : null;
        const right = item.right && item.right.content && item.right.content.value
            ? page.rewriteLinks(item.right.content.value) : null;
        const itemList = (item.bottoms || []).map(i => page.rewriteLinks(i.content.value));

        return (<RichContent
            bottomContainerTitle={item.bottomContainerTitle || ''}
            hideInMobile={false}
            showBorder={true}
            bottomRowColumnWidth={''}
            itemList={itemList}
            left={left}
            right={right}
        />);
    }

    return resources.map(item => renderItem(page.getContent(item).getData()));
}

export default ProductDetail;
