import {createStore, applyMiddleware, compose} from 'redux';
import thunkMiddleware from 'redux-thunk';
import {createLogger} from 'redux-logger';
import appReducer from '../store';

export default function createReduxStore({preloadedState, server} = {}) {
    let enhancer = applyMiddleware(thunkMiddleware);

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    return createStore(appReducer, preloadedState, composeEnhancers(enhancer));
}
