import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {Link} from 'react-router-dom';
import {getPartCondition} from 'productUtility';
import {Popup, Button} from '@partssourceinc/react-ui-core';
import {GridPagination} from '@partssourceinc/react-ui-core';
import {GridPaginationMobile} from 'components/GridPaginationMobile';
import OnCreditHoldModal from 'components/OnCreditHoldModal';
import {ProductImage} from '@partssourceinc/react-ui-core';
import {formatUnitOfMeasurement, getPartConditionDescription} from 'productUtility';
import * as UserStore from 'stores/User';
import * as NetworkStore from 'stores/Network';
import {getFormattedPhoneNumber, formatMoney, getCookie} from 'utility';
import {defaultPhoneNumber} from 'data/DefaultPhoneNumber';
import {BrComponent} from '@bloomreach/react-sdk';

@withRouter
@connect((state) => ({user: state.user, network: state.network, bloomReach: state.bloomReach}), UserStore.actionCreators)
export default class CatalogResults extends React.Component {
    static propTypes = {
        products: PropTypes.arrayOf(PropTypes.object).isRequired,
        productCrumbs: PropTypes.arrayOf(PropTypes.any).isRequired,
        totalResults: PropTypes.number,
        campaign: PropTypes.shape({}),
        isBusy: PropTypes.bool,
        showNoResults: PropTypes.bool,
        pageSize: PropTypes.number,
        page: PropTypes.number,
        viewAll: PropTypes.bool,
        user: UserStore.StateShape,
        network: NetworkStore.StateShape,
        searchTerm: PropTypes.string,
        ...UserStore.ActionShape,
    }

    constructor(props) {
        super(props);
        this.state = {
            showCompatibles: null,
            showRequestQuote: false,
            showCreditHoldModal: false,
        };

        this.onPageChange = this.onPageChange.bind(this);
        this.onPageSizeChange = this.onPageSizeChange.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.pushHistory = this.pushHistory.bind(this);
    }

    componentWillReceiveProps({searchTerm}) {
        // a new search has been performed
        const {searchTerm: currentSearchTerm} = this.props;
        if (searchTerm !== currentSearchTerm) {
            this.submitAdobeSiteSearch(searchTerm, 'search');
        }
    }

    submitAdobeSiteSearch = (searchTerm, searchType) => {
        let {term} = this.state;
        window.appEventData = window.appEventData || [];
        window.appEventData.push({
            'event': 'Onsite Search Performed',
            'onsiteSearch': {
                'keyword': {
                    'searchTerm': term ? term : searchTerm,
                    'searchTermCorrected': searchTerm,
                    'searchType': searchType,
                },
            },
        });
    }

    renderAttribute(name, value) {
        return name.toLowerCase().startsWith('feature')
            ? (<li key={`${name}`}>{value}</li>)
            : (<li key={`${name}`}>{`${name}: ${value}`}</li>);
    }

    onPageChange(newPage) {
        const pageExpression = `_page=${newPage}`;
        const {location: {pathname, search}} = this.props;
        const newSearch = search.replace(/_page=(\d+)/, pageExpression);

        if (newSearch.indexOf('_page=') !== -1) {
            this.pushHistory(`${pathname}${newSearch}`);
        } else {
            const pathCh = search.indexOf('?') >= 0 ? '&' : '?';
            const newPath = (`${pathname}${search}${pathCh}${pageExpression}`).replace('&&', '&').replace('?&', '?');
            this.pushHistory(newPath);
        }
    }

    onPageSizeChange(value) {
        const pageSizeExpression = `_pageSize=${value}`;
        const pageExpression = `_page=1`;

        const {location: {pathname, search}} = this.props;
        const newSearch = search.replace(/_page=(\d+)/, '').replace(/_pageSize=(\d+)/, '').replace('&&', '&');

        const pathCh = search.indexOf('?') >= 0 ? '&' : '?';
        const newPath = (`${pathname}${newSearch}${pathCh}${pageSizeExpression}&${pageExpression}`).replace('&&', '&').replace('?&', '?');
        this.pushHistory(newPath);
    }

    // this is a wrapper for history.push() so I can add back global parameters
    pushHistory(url) {
        const {history} = this.props;
        const {viewAll} = this.state;

        if (viewAll && url.indexOf('_view=all') === -1) {
            const appendChar = url.indexOf('?') > 0 ? '&' : '?';
            history.push(url + appendChar + '_view=all');
        } else {
            history.push(url);
        }
    }

    onProductClick(product, price) {
        const {location: {pathname, search}, productCrumbs} = this.props;
        let category;

        if (search.indexOf('&category=') > 0 || pathname.indexOf('/shop') === 0) {
            const query = productCrumbs.filter(x => x.url.indexOf('/shop') === 0).map(x => x.label).slice(0, 5);
            category = query.join('/');
        } else {
            category = '';
        }

        this.submitAdobeListingProductClicked(product, price, category);

        dataLayer.push({
            'event': 'productClick',
            'ecommerce': {
                'click': {
                    'products': [{
                        'name': product.partNumber,
                        'id': product.id,
                        'price': price,
                        'brand': product.brand,
                        'category': category,

                    }],
                },
            },
        });
    }

    submitAdobeListingProductClicked(product, price, category) {
        const {products} = this.props;
        const productIndex = products.findIndex(p => p.id === product.id);
        
        window.appEventData = window.appEventData || [];
        window.appEventData.push({
            'event': 'Product Listing Item Clicked',
            'listingItemClicked': {
                'listing': [
                    {
                        'itemPosition': productIndex,
                        'price': {
                            'basePrice': product.options.length > 0 ? product.options[0].oemListPrice.toFixed(2).toString() : product.outrightListPrice.toFixed(2).toString(),
                            'priceTier': '',
                            'priceType': product.options.length > 0 ? getPartCondition(product.options[0].lineItemCondition) : '',
                            'sellingPrice': price ? price.toFixed(2).toString() : '',
                        },
                        'productInfo': {
                            'brand': product.brand,
                            'name': product.id,
                            'productID': product.id,
                            'productLine': category,
                            'sku': product.options.length > 0 ? product.options[0].vendorItemNumber : '',
                        },
                    },
                ],
            },
        });
    }

    renderItem(item) {
        const {user} = this.props;
        const {attributes, thumbnailUrl, thumbnailAltText, id, detailUrl, title, partNumber, matchReason, compatibleWith, options, isVariantProduct, hasDefaultVariant, isDiscontinued} = item;
        const hideOemPricing = user && user.settings && user.settings.hideOEMPricing;
        const {productCrumbs} = this.props;
       
        let vendorIdsFromCookie = getCookie('eoem');

        // Find count of only the options with Exclusive Vendor (if any) using the id stored in the cookie
        let countOptions = 0;

        if (vendorIdsFromCookie != '') {
            if (options) {
                _.each(options, function(option) {
                    if (vendorIdsFromCookie.includes(option.vendorId)) {
                        countOptions++;
                    }
                });
            }
        } else {
            countOptions = options && options.length;
        }

        let sortedOptions = _.orderBy(options.filter(x => x.price > 0 || (x.isContractProOption && x.price >= 0)), ['price'], ['asc']);
        let option = sortedOptions[0];
        let contractProOption = sortedOptions.filter(o => o.isContractProOption === true);

        let sortedLeadTimeOptions = _.orderBy(options, ['leadTimeDays'], ['asc']);
        let  isReadyToShip = sortedLeadTimeOptions.some(s => s.leadTimeDays===0);
        
        let leadTimeOption;
        if (!sortedOptions.every(o => o.backorderDateEta)) {
            leadTimeOption = sortedLeadTimeOptions.filter(o => !o.backorderDateEta)[0];
        } else {
            leadTimeOption = sortedLeadTimeOptions[0];
        }
        let pplOption = sortedOptions.some(o => o.lineItemCondition === 40);

        return (<div key={id} className="catalog_search-result no-right-gutter">
            <div className="image-wrapper">
                <Link to={{pathname: detailUrl, breadCrumbs: productCrumbs, showBackButton: true}} key={id} onClick={() => this.onProductClick(item, (option || {}).price)}>
                    <ProductImage url={thumbnailUrl} altText={thumbnailAltText || title} style={{width: '240px'}} />
                </Link>
            </div>
            <div className="catalog_search-result_details">
                {
                    pplOption===true ?
                        <div className="ppl_logo" ><img src="/images/ppl_banner_top.png" style={{maxWidth: '250px'}} alt="Performance Line" />
                            <span className="ppl_tooltipText">
                                <span className="ppl_text">{getPartConditionDescription(40)}</span>
                            </span>
                        </div> :
                        null
                }                
                {
                    contractProOption && contractProOption.length > 0 ?
                        <div style={{marginBottom: '10px'}} className="contract-badge"><span>CONTRACT</span></div> :
                        null
                }
                <Link
                    to={{
                        pathname: detailUrl,
                        breadCrumbs: productCrumbs,
                        showBackButton: true,
                    }}
                    key={id} onClick={() => this.onProductClick(item, (option || {}).price)}>
                    {title}
                </Link>
                <div className="result-part-info">
                    <div className="catalog_search-result_oem-info">
                        {partNumber ? <div>OEM #: {partNumber}</div> : null}
                        {matchReason ? <div className="bold">{matchReason}</div> : null}
                    </div>
                    <ul>
                        {Object.keys(attributes || {}).map(k => this.renderAttribute(k, attributes[k]))}
                    </ul>
                    {compatibleWith && Object.keys(compatibleWith).length > 0 ? <a href={true} className="compatible-with" onClick={() => this.setState({showCompatibles: id})}>Equivalents</a> : null}
                </div>
                <div className="result-price-info">
                    {isDiscontinued && <span className="lead-time">This item may have limited availability</span>}
                    {sortedOptions && ((sortedOptions.length > 1 && option.price > .01 && !isVariantProduct) || (isVariantProduct && !hasDefaultVariant)) ? <div className="starting-at">Starting at</div> : null}

                    {sortedOptions && sortedOptions.length > 0 && option.price > .01 ?
                        <div>
                            <span className="price">
                                {formatMoney(option.price)}
                                <span className="uom"> {option.unitOfMeasurement ? formatUnitOfMeasurement(option.unitOfMeasurement) : null}</span>
                                    
                            </span>
                            {item.outrightListPrice > option.price && !hideOemPricing ?
                                <span className="savings-wrapper">
                                    <span className="oem-price">{formatMoney(item.outrightListPrice)}</span>
                                        Savings {formatMoney(item.outrightListPrice - option.price)}
                                    <span className="percent">({Math.round(((item.outrightListPrice - option.price) / item.outrightListPrice) * 100)}%)</span>
                                </span> : null}

                            {countOptions > 1 ? <div className="buying-options">Total Buying Options: <span>{countOptions}</span></div> : null}
                            {isReadyToShip && <div style={{marginTop: '3px', marginRight: '8px', display:'block'}}><img src="/images/ppl_ready_to_ship_green.svg" alt="Ready to ship" />
                                <span style={{color: '#17AB78', marginLeft: '5px'}}>Ready to Ship</span></div> }
                            {
                                !option.suppressLeadTime && sortedOptions.length > 1
                                    ? <span className="lead-time">
                                       Ship dates may vary – See buying options for details
                                    </span>
                                    : null
                            }

                        </div> : null
                    }
                </div>
            </div>
        </div>);
    }

    closeCHModal = () => {
        this.setState({showCreditHoldModal: false});
        return;
    }
    
    render () {
        const {showCompatibles, showCreditHoldModal} = this.state;
        const {
            network: {isLoggedIn}, 
            user: {facilities, settings, facility}, 
            bloomReach: {isPreview},
            products, 
            campaign, 
            showNoResults, 
            totalResults, 
            isBusy, 
            page, 
            pageSize, 
            onShowRequestQuote, 
            location,
        } = this.props;
        const enableTfyp = facilities.filter(x => x.showTfyp).length > 0;
        const compatProduct = showCompatibles ? _.find(products, {id: showCompatibles}) : null;
        const compatibleWith = compatProduct ? compatProduct.compatibleWith : [];
        const isCatalog = /^\/catalog/.test(window.location.pathname);
        const dedicatedPhoneNumber = settings.dedicatedPhoneNumber ? 
            getFormattedPhoneNumber(settings.dedicatedPhoneNumber) : defaultPhoneNumber;
        const restrictRequestQuote = (facility && parseInt(facility.facilityId) > 0) ? facility.creditHold : settings.creditHold;
        const compatOems = [... new Set(compatibleWith.map(c => c.oemName))];
        return (
            <React.Fragment>
                {compatProduct && compatOems.length > 0 ?

                    <Popup show={true} cancelText="Cancel" hideButtons={true} onCancel={() => this.setState({showCompatibles: null})}>
                        <h3>{`Equivalents ${compatProduct.title}:`}</h3>
                        <ul style={{overflow: 'auto'}}>
                            {compatOems.map((key, index) => <li key={index}>{`${key}: ${[... new Set(compatibleWith.filter(x => x.oemName ===key).map(p => p.displayPartNumber))].join(', ')}`}</li>)}
                        </ul>
                    </Popup> : null}
                {showNoResults ?
                    <div className="catalog_right-column">
                        <div className="no-results ">
                            {isPreview && <BrComponent path={'main/top'} />}

                            {isLoggedIn && enableTfyp ?
                                <div className="no-result-section">
                                    <div>Can&apos;t find what you&apos;re looking for?</div>
                                    <Button secondary={true} title="Request a Quote" onClick={() => (restrictRequestQuote) ? 
                                        this.setState({showCreditHoldModal: true}) : onShowRequestQuote()}>REQUEST A QUOTE</Button>

                                </div> :
                                <div className="no-result-section">
                                    There are no results for that search. Please try your search again.
                                </div>
                            }
                            <div className="no-result-section">
                                <div className="help">Need help? Give us a call</div>
                                <div className="phone">
                                    <i className="fa fa-phone" /><span style={{marginLeft: '5px'}}><a href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a></span>
                                </div>
                            </div>
                        </div>
                    </div> :
                    <div className="catalog_right-column">
                        <div className="catalog_results">

                            {isCatalog && <BrComponent path={'main/top'} />}

                            {campaign && campaign.htmlText ? <div className="campaign-wrapper"><div dangerouslySetInnerHTML={{__html: campaign.htmlText}} /></div> : null}
                            {products.map(this.renderItem)}
                            {!isBusy && !showNoResults ?
                                <div className="catalog_search-result ">
                                    {isLoggedIn && enableTfyp ?
                                        <React.Fragment>
                                            Can&apos;t find what you need? <span className="catalog_search-result_request-quote" onClick={() => (restrictRequestQuote) ? 
                                                this.setState({showCreditHoldModal: true}) : onShowRequestQuote()}>Request a Quote</span> or call us <a style={{fontSize: '16px', textDecoration: 'underline'}} href={'tel:+1' + dedicatedPhoneNumber}>{dedicatedPhoneNumber}</a>
                                        </React.Fragment>
                                        : null}
                                </div> : null}
                        </div>
                        {totalResults > 0 ?
                            <React.Fragment>
                                <div className="ps-hidden-sm ps-hidden-xs ps-hidden-md">
                                    <GridPagination
                                        displayPages={10}
                                        totalRecords={totalResults}
                                        pageSize={pageSize}
                                        onPageSizeChange={(data) => this.onPageSizeChange(data.value)}
                                        onSelect={(data) => this.onPageChange(data.value)}
                                        currentPage={page}
                                        pageSizeOptions={[{value: 15}, {value: 30}, {value: 50}]}
                                        isCatalog={true}
                                        location={location}
                                    />
                                </div>
                                <div className="ps-hidden-md ps-hidden-lg ps-hidden-xl">
                                    <GridPaginationMobile
                                        totalRecords={totalResults}
                                        pageSize={pageSize}
                                        onPageSizeChange={this.onPageSizeChange.bind(this)}
                                    />
                                </div>
                            </React.Fragment> : null}
                    </div>}
                {showCreditHoldModal && <OnCreditHoldModal onSubmit={this.closeCHModal} />}
            </React.Fragment>);
    }
}
