import io from 'socket.io-client';
import Cookies from 'js-cookie';

const initialState = {
    socketId: null,
    socket: null,
    newMessage: null,
    messages: [],
    connected: false,
};

export const OPENING_WEBSOCKET_CONNECTION = 'OPENING_WEBSOCKET_CONNECTION';
export const WEBSOCKET_CONNECTED = 'WEBSOCKET_CONNECTED';
export const WEBSOCKET_NEW_MESSAGE_RECEIVED = 'WEBSOCKET_NEW_MESSAGE_RECEIVED';
export const WEBSOCKET_NEW_MESSAGE_SEND = 'WEBSOCKET_NEW_MESSAGE_SEND';
export const DISCONNECT_WEBSOCKET = 'DISCONNECT_WEBSOCKET';

export const actionCreators = {
    connectToWebsocket: (showTechNames, showAllRecords, showAllRepairs, showOtherTechQuotes) => connectToWebsocket(showTechNames, showAllRecords, showAllRepairs, showOtherTechQuotes),
    disconnectWebsocket: () => disconnectWebsocket(),
};

export const connectToWebsocket = (showTechNames, showAllRecords, showAllRepairs, showOtherTechQuotes) => dispatch => {
    dispatch({type: OPENING_WEBSOCKET_CONNECTION});

    let settingsUrlParams = `&showTechNames=${showTechNames}&showAllRecords=${showAllRecords}&showAllRepairs=${showAllRepairs}&showOtherTechQuotes=${showOtherTechQuotes}`

    let keepAliveInterval;
    const socket = io(process.env.REACT_APP_DASHBOARD_API + '?token=' + (Cookies.get('token') || '').split(' ')[1] + settingsUrlParams, {transports: ['websocket']});
    socket.on('connect', () => {
        console.log('connected');
        dispatch({type: WEBSOCKET_CONNECTED, id: socket.id, socket});
        keepAliveInterval = setInterval(() => {
            if (socket && socket.connected)
                socket.send('keep-alive')
        }, 60000);
    });
    socket.on('disconnect', () => {
        clearInterval(keepAliveInterval);
    });
    socket.on('action', message => {
        message = typeof message === 'string' ? JSON.parse(message) : message;
        message.forward = false;
        dispatch(message);
    });

    socket.on('databoard_data', message => {
        const stats = _.mapValues(message.data, v => typeof v === 'string' && v[0] === '[' ? JSON.parse(v) : v);
        if (typeof message === 'object') message.forward = false;
        dispatch({
            type: 'UPDATE_STATS',
            stats,
        });
    });

    socket.on('calendar_view_data', message => {
        const calendarView = JSON.parse(message.data, (k, v) => typeof v === 'string' && v[0] === '[' ? JSON.parse(v) : v)
        if (typeof message === 'object') message.forward = false;
        dispatch({
            type: 'UPDATE_CALENDAR_VIEW',
            calendarView,
        });
    })

    socket.on('updates_panel_data', message => {
        const updatesPanel = JSON.parse(message.data, (k, v) => typeof v === 'string' && v[0] === '[' ? JSON.parse(v) : v)
        if (typeof message === 'object') message.forward = false;
        dispatch({
            type: 'UPDATE_UPDATES_PANEL',
            updatesPanel: Object.keys(updatesPanel).map(k => updatesPanel[k]),
        });
    })

};

export const sendWebsocketMessage = (
    socket,
    message,
    action = 'action'
) => dispatch => {
    if (socket) {
        socket.emit(action, message);
    }
};

export const disconnectWebsocket = () => ({
    type: 'DISCONNECT_WEBSOCKET',
});

const websockets = (state = initialState, action) => {
    switch (action.type) {
        case WEBSOCKET_NEW_MESSAGE_RECEIVED:
            return {...state};
        case DISCONNECT_WEBSOCKET:
            console.log('disconnecting.....');
            if (state.socket) {
                state.socket.disconnect();
            }
            return {...state};
        case OPENING_WEBSOCKET_CONNECTION:
            console.log('opening websocket...');
            return {...state};
        case WEBSOCKET_CONNECTED:
            console.log('Connected...', action.id);
            return {...state, socketId: action.id, socket: action.socket};
        default:
            return {...state};
    }
};

export default websockets;
