import axios from 'axios';
import React ,{useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Popup, Button} from '@partssourceinc/react-ui-core';
import {isSessionStorageAvailable} from 'utility';
import styled from 'styled-components';
import 'less/priorityOrderModal.less';

const StyledPopup = styled(Popup)`
    .modal-header {
        display: none;
    }
`;

const OnCreditHoldModal = ({onSubmit}) => {
    const history = useHistory();
    const [modal, setModal] = useState({});
    const [isLoaded, setIsLoaded] = useState(false);
    const sessionStorageAvailable = isSessionStorageAvailable();

    useEffect(() => {
        if (sessionStorageAvailable && sessionStorage.creditHoldModal && sessionStorage.creditHoldModal.alertMessage) {
            setModal(sessionStorage.creditHoldModal || {});
        } else {
            const alertTypeCode = 'CreditHoldModal';
            axios.get(`/ShoppingService/api/v1/company/creditholdmessage/${alertTypeCode}`).then((x) => {
                sessionStorage.setItem('creditHoldModal', x.data[0]);
                setModal(x.data[0] || {}); 
            });                           
        }
        setIsLoaded(true);        
    }, []);

    const continueRedirect = () => {
        onSubmit?.();
        
        if (location.pathname === '/request-service' || location.pathname === '/quote/request')
            history.push('/');
    }

    return (
        <React.Fragment>
            {isLoaded && <StyledPopup
                className="widePopup"
                show={true}
                onCancel={() => continueRedirect()}
                hideButtons={true}
            >
                <div style={{paddingBottom: '25px'}}>
                    <h1>Credit Hold</h1>
                </div>
                <div>
                    <>
                        <p style={{overflowWrap: 'break-word'}} dangerouslySetInnerHTML={{__html: modal.alertMessage}} />
                    </>
                    <div className="priority-shipping-footer" style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Button ternary={true} onClick={() => continueRedirect()}>
                            Continue
                        </Button>
                        
                        <Button onClick={() => window.location.href=`mailto:${modal.actionButtonUrl}`}>
                            {modal.actionButtonText}
                        </Button>
                    </div>
                </div>
            </StyledPopup>}
        </React.Fragment>
    );

}

export default OnCreditHoldModal;
