import $ from 'jquery';
import _ from 'lodash';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import CatalogResults from 'components/CatalogResults';
import {Checkbox, Button, Popup, NavMenu} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import * as NetworkStore from 'stores/Network';
import * as UserStore from 'stores/User';
import * as SystemStore from 'stores/System';
import * as ParamsStore from 'stores/Params';
import * as CompaniesStore from 'stores/Companies';
import * as CatalogStore from 'stores/Catalog';
import {getLocationQuery, getCookie, addToCookieIfExclusiveSupplier, logEvent, queryString} from 'utility';
import {getPartCondition} from 'productUtility';
import 'less/catalog.less';
import * as BloomReachStore from 'stores/BloomReach';
import {BrComponent} from '@bloomreach/react-sdk';

const facetExpansionCutoff = 5; // Show More link when a facet has more than this number of selections
const facetSearchCutoff = 40; // Show edit rather than checks when a facet has more than this number of selections
const showMorePageSize = 10;
const ignoredFacets = [
    'category', 'utm_source', 'ctm', 'utm_medium', 
    'utm_campaign', 'utm_term', 'utm_content'
];
const id_ins = getCookie('id_ins');
@withRouter
@connect((state) => ({network: state.network, user: state.user, params: state.params, categories: state.companies.categories, catalog: state.catalog, bloomReach: state.bloomReach, system: state.system}), _.merge({}, CompaniesStore.actionCreators, CatalogStore.actionCreators, BloomReachStore.actionCreators, UserStore.actionCreators))
export default class Catalog extends React.Component {
    static propTypes = {
        ...ReactRouterPropTypes,
        network: NetworkStore.StateShape,
        system: SystemStore.StateShape,
        user: UserStore.StateShape,
        params: ParamsStore.StateShape,
        categories: CompaniesStore.CategoryShape,
        ...CompaniesStore.ActionShape,
    };

    constructor(props) {
        super(props);
        this.state = {
            isBusy: false,
            showAllFilter: false,
            isMobileMenuVisible: false,
            filtersMenuVisibleSections: [],
            facetFilters: {},
            filterStatus: {},
        };

        this.renderFacetSection = this.renderFacetSection.bind(this);
        this.onFacetCheckChange = this.onFacetCheckChange.bind(this);
        this.onCategoryClick = this.onCategoryClick.bind(this);
        this.expandFacet = this.expandFacet.bind(this);
    }

    componentWillUnmount() {
        const {resetCatalog} = this.props;
        resetCatalog();
        $('body,html').css({overflowY: 'visible', position: ''});
    }

    componentDidMount() {
        const {categories, getCategories, catalog: {pathname, search}, resetPath} = this.props;

        if (pathname != location.pathname || (!search && location.search != '') || search != location.search) {
            if ((categories || []).length === 0) {
                getCategories().then(() => {
                    this.performSearch(this.props, id_ins);
                    window.scrollTo(0, 0);
                });
            } else {
                this.performSearch(this.props, id_ins);
                window.scrollTo(0, 0)
            }
        } else {
            resetPath();
        }

        // check if OEM is exclusive supplier and add it to the user's cookie if so
        let pathParts = location.pathname.split('/');
        let oemName = pathParts[2];

        if (!_.isNil(oemName)) {
            addToCookieIfExclusiveSupplier(oemName);
        }
    }

    componentWillReceiveProps(newProps) {
        const {location: {pathname, search}, catalog: {facilityId}, saveCatalogState} = this.props;
        if (newProps.location.pathname !== pathname || newProps.location.search !== search) {
            if (newProps.location.pathname !== pathname) {
                this.setState({facetFilters: {}});
            }
            this.performSearch(newProps, id_ins);
            $('html, body').scrollTop(0);
        } else if (newProps.user.facility.facilityId && newProps.user.facility.facilityId !== facilityId && newProps.user.settings.hasFacilityLevelPricing) {
            saveCatalogState({facilityId: newProps.user.facility.facilityId});
            this.performSearch(newProps, id_ins);
            $('html, body').scrollTop(0);
        }
    }

    performSearch(request, id_ins) {
        const {performSearch, history, location} = this.props;

        if (location.pathname === '/catalog' && (!location.search || location.search === '?_view=all')) {
            history.push('/');
        } else {
            performSearch(request, id_ins).then(x => {
                this.submitAdobeListingDisplayed();
                const {response: {data: {redirectUrl}}} = x;
                if (redirectUrl) history.push(redirectUrl);
            });
        }
    }

    submitAdobeListingDisplayed() {
        const {catalog: {filters, products, pageSize, totalResults, searchTerm}} = this.props;
        const {filterStatus} = this.state;
        window.appEventData = window.appEventData || [];
        window.appEventData.push({
            'event': 'Product Listing Displayed',
            'listingDisplayed': {
                'displayCount': pageSize,
                'filterList': filters.map(x => `${x.name}~${x.value.replace(/%20/g, ' ')}`).join('|'),
                'filterListLength': filters.length,
                'keyword': {
                    'searchTermCorrected': searchTerm,
                },
                'listing': products.map((product, index) => ({
                    'itemPosition': index,
                    'price': {
                        'basePrice': product.options.length > 0 ? product.options[0].oemListPrice.toFixed(2).toString() : product.outrightListPrice.toFixed(2).toString(),
                        'priceTier': '',
                        'priceType': product.options.length > 0 ? getPartCondition(product.options[0].lineItemCondition) : '',
                        'sellingPrice': product.options.length > 0 ? product.options[0].price.toFixed(2).toString() : product.outrightListPrice.toFixed(2).toString(),
                    },
                    'productInfo': {
                        'brand': product.brand,
                        'name': product.id,
                        'productID': product.id,
                        'sku': product.options.length > 0 ? product.options[0].vendorItemNumber : '',
                    },
                })),
                'listingContext': filters.length === 0 ? '' : filterStatus.checked ? 'Filter Added' : 'Filter Removed',
                'listingType': 'Product',
                'resultsCount': totalResults,
            },
        });
    }

    // this is a wrapper for history.push() so I can add back global parameters
    pushHistory(url) {
        const {history, catalog: {viewAll}} = this.props;

        if (viewAll && url.indexOf('_view=all') === -1) {
            const appendChar = url.indexOf('?') > 0 ? '&' : '?';
            history.push(url + appendChar + '_view=all');
        } else {
            history.push(url);
        }
    }

    onRemoveAllFiltersClick() {
        const {location, location: {pathname}, catalog: {filters}} = this.props;
        const urlParams = getLocationQuery(location);
        let clearAllUrl = pathname.indexOf('/shop') === 0 ? pathname : '/';
        let nextSearchUrl = urlParams && urlParams.q ? `/catalog?q=${encodeURIComponent(urlParams.q)}` : clearAllUrl;
        document.activeElement.blur();
        this.submitAdobeFilterAddRemove({checked: false}, filters);
        this.pushHistory(nextSearchUrl);
    }

    onSearchWithinKeyPress(event) {
        let code = event.keyCode || event.which;
        if (code === 13) {
            this.onSearchWithinSearch();
        }
    }

    onSearchWithinSearch() {
        const {filterSearchText} = this.state;
        const {location} = this.props;
        const urlParams = getLocationQuery(location);
        let uriValue = decodeURIComponent(urlParams.sq || '');
        this.onFacetCheckChange({checked: true}, {name: 'sq', value: filterSearchText}, {range: uriValue});
        this.setState({filterSearchText: ''});
    }

    onCategoryClick(item, removeAll) {
        const {location, location: {pathname}, catalog: {filters, isCategoryLandingPage}} = this.props;
        const urlParams = getLocationQuery(location);

        if (item.value && item.value.indexOf('/') === 0) {
            this.pushHistory(item.value);
            return;
        }

        if (item.url)
            item.value = item.url;

        if (item.value === '/') {
            this.pushHistory('/');
            return;
        } else if (isCategoryLandingPage) {
        // switching categories, so drop the page number
            let newPath = item.value !== 'clear-all'
                ? '/shop/' + item.crumb + this.removePage(location.search)
                : '/catalog' + location.search;
            this.pushHistory(newPath);
            return;
        }

        const newFacet = {
            name: 'category',
            value: item.value,
        };
        _.remove(filters, {name: 'category'});

        if (!removeAll && item.value !== 'clear-all')
            filters.push(newFacet);

        let nextSearchUrl = urlParams && urlParams.q ? `${pathname}?q=${encodeURIComponent(urlParams.q)}` : pathname;
        if (filters && filters.length > 0) {
            const pathCh = nextSearchUrl.indexOf('?') >= 0 ? '&' : '?';
            nextSearchUrl = nextSearchUrl + `${pathCh}${filters.map(f => `${f.name}=${f.value}`).join('&')}`;
        }
        this.pushHistory(nextSearchUrl);
    }

    onFacetCheckChange(checkArgs, facet, item) {
        let {catalog: {filters, routeFacets}} = this.props;
        const {location, location: {pathname}} = this.props;
        const urlParams = getLocationQuery(location);

        this.setState({facetFilters: {}});

        let newPath;
        // check for oem route facet
        if (facet.name.toLowerCase() === 'oem_search' || (facet.name.toLowerCase() === 'oem' && (routeFacets || []).some(x => x.name === 'oem_search'))) {
            newPath = location.pathname.replace('/' + routeFacets.find(x => x.name === 'oem_search').value, '') + location.search;
            document.activeElement.blur();
            this.pushHistory(newPath == '/catalog' ? '/' : newPath);
            return
        }

        // check for model facet
        if (facet.name.toLowerCase() === 'model_search' || (facet.name.toLowerCase() === 'models' && (routeFacets || []).some(x => x.name === 'model_search'))) {
            newPath = location.pathname.replace('/' + routeFacets.find(x => x.name === 'model_search').value, '') + location.search;
            document.activeElement.blur();
            this.pushHistory(newPath == '/catalog' ? '/' : newPath);
            return
        }

        // check for search within facet
        if (facet.name.toLowerCase() === 'sq') {
            let sqFacets = item.range ? item.range.split('|') : [];

            if (checkArgs.checked) {
                sqFacets.push(facet.value);
            } else {
                _.remove(sqFacets, x => x === facet.value);
            }

            if (sqFacets.length > 0) {
                _.remove(filters, x => x.name.toLowerCase() === 'sq');
                checkArgs.checked = true;
                item.range = sqFacets.join('|');
            }
        }

        const newFacet = {
            name: facet.name,
            value: encodeURIComponent(item.range),
        };

        if (checkArgs.checked) {
            filters.push(newFacet);
        } else {
            _.remove(filters, newFacet);
        }

        let nextSearchUrl = urlParams && urlParams.q ? `${pathname}?q=${encodeURIComponent(urlParams.q)}` : pathname;

        if (nextSearchUrl.indexOf('/shop/') === 0 && filters.find(x => x.name === 'sq')) {
            let cat = nextSearchUrl.replace('/shop/', '');
            nextSearchUrl = `/catalog?category=${cat}`;
        }

        if (filters && filters.length > 0) {
            const pathCh = nextSearchUrl.indexOf('?') >= 0 ? '&' : '?';
            nextSearchUrl = nextSearchUrl + `${pathCh}${filters.map(f => `${f.name}=${f.value}`).join('&')}`;
        }

        if (checkArgs.checked === false) {
            logEvent('Facet Remove', {
                'Search Keyword': urlParams.q,
                'URL': nextSearchUrl,
                'Referrer': window.location.pathname + window.location.search,
                'Nav Path': /^\/shop/.test(window.location.pathname) ? 'Category' : 'Search',
                'Facet Name': facet.value,
            });
        }

        document.activeElement.blur();
        this.setState({filterStatus: checkArgs});
        this.submitAdobeFilterAddRemove(checkArgs, filters, item);
        this.pushHistory(nextSearchUrl == '/catalog' ? '/' : nextSearchUrl);
    }

    submitAdobeFilterAddRemove(checkArgs, filters, item) {
        // listingType = The type of listings shown (Product, Location, Event, Room, Content)
        // left as product by default, need clarification if this is what we want
        window.appEventData = window.appEventData || [];
        if (checkArgs.checked) {
            window.appEventData.push({
                'event': 'Listing Filter Added',
                'listingRefined': {
                    'filterList': item.range,
                    'listingType': 'Product',
                },
            });
        } else {
            window.appEventData.push({
                'event': 'Listing Filter Removed',
                'listingRefined': {
                    'filterList': item ? item.range : filters.map(x => `${x.name}~${x.value.replace(/%20/g, ' ')}`).join('|'),
                    'listingType': 'Product',
                },
            });
        }
    }

    onFacetFilterInput(e) {
        const {catalog: {facets}, saveCatalogState} = this.props;
        let {facetFilters} = this.state;
        facetFilters[e.target.id] = e.target.value.toLowerCase();

        let facet = _.find(facets, {name: e.target.id});
        facet.limit = facetExpansionCutoff;

        this.setState({facetFilters});
        saveCatalogState(facets);
    }

    onClearFacetFilter(name) {
        let {catalog: {facets}, saveCatalogState} = this.props;
        let {facetFilters} = this.state;
        facetFilters[name] = '';

        let facet = _.find(facets, {name: name});
        facet.limit = facetExpansionCutoff;

        this.setState({facetFilters});
        saveCatalogState(facets);
    }

    removePage(search) {
        return _.trimEnd(search.replace(/_page=(\d+)/, ''), '?&');
    }

    renderAttribute(name, value) {
        return name.toLowerCase().startsWith('feature')
            ? (<li key={`${name}`}>{value}</li>)
            : (<li key={`${name}`}>{`${name}: ${value}`}</li>);
    }

    expandFacet(facet) {
        let {catalog: {facets}, saveCatalogState} = this.props;
        let target = _.find(facets, {name: facet.name});
        target.limit = facet.limit + showMorePageSize;
        saveCatalogState({facets});
    }

    renderFacetPill(facet) {
        if (!facet) return null;
        
        // List of facets to ignore
        
        if (ignoredFacets.includes(facet.name.toLowerCase())) {
            return null;
        }
        
        if (facet.name.toLowerCase() === 'sq') {
            let sqFacets = decodeURIComponent(facet.value).split('|');
            return sqFacets.map((x, i) =>
                (<div className="filter-pill" key={i}>
                    <span>{x}</span>
                    <span onClick={() => this.onFacetCheckChange({checked: false}, {name: 'sq', value: x}, {range: decodeURIComponent(facet.value)})}>
                        <i className="fa fa-remove" />
                    </span>
                </div>)
            )
        }
        
        return (
            <div className="filter-pill">
                <span>{decodeURIComponent(facet.value)}</span>
                <span onClick={() => this.onFacetCheckChange({checked: false}, facet, {range: decodeURIComponent(facet.value)})}>
                    <i className="fa fa-remove" />
                </span>
            </div>
        )
    }

    // changed to now create the category path
    renderCategoryPill(facet) {
        const {facets} = this.state;
        let selectedCat = facets.filter(x => x.name === 'Category')[0].items.filter(x => x.value === facet.value)[0];

        let catPathArray = this.getCategoryPathArray(selectedCat);
        let catPathDisplay = '';

        for (let i = 0; i < catPathArray.length; i++)
            catPathDisplay += catPathDisplay ? ' > ' + catPathArray[i] : catPathArray[i];

        return (
            <div className="filter-pill">
                <span>{decodeURIComponent(catPathDisplay)}</span>
                <span onClick={() => this.onCategoryClick(selectedCat, true)}>
                    <i className="fa fa-remove" />
                </span>
            </div>
        )
    }

    getCategoryPathArray(facet) {
        const {catalog: {facets}} = this.props;

        let catPath = [];
        catPath.push({label: facet.range, url: facet.value, crumb: facet.crumb});

        if (facet.parentId) {
            let search = true;
            let parentId = facet.parentId;

            while (search) {
                let parent = facets.find(x => x.name === 'Category').items.find(x => x.value === parentId);

                if (parent) {
                    catPath.unshift({label: parent.range, url: parent.value, crumb: parent.crumb});
                    parentId = parent.parentId;
                } else
                    search = false;
            }
        }

        return catPath;
    }

    handleFilterMenuSectionSelected(index) {
        const {filtersMenuVisibleSections} = this.state;
        if (filtersMenuVisibleSections.includes(index)) {
            this.setState({
                filtersMenuVisibleSections: filtersMenuVisibleSections.filter(s => s !== index),
            });
        } else {
            filtersMenuVisibleSections.push(index);
            this.setState({filtersMenuVisibleSections});
        }
    }

    renderFacetSection(facet) {
        if (facet.name === 'Category') {
            const {catalog: {filters, routeFacets, isCategoryLandingPage}} = this.props;
            let selectedFilter = isCategoryLandingPage ?
                routeFacets.filter(x => x.name === 'category')[0] || null :
                filters.filter(x => x.name === 'category')[0] || null;

            if (selectedFilter) {
                let selectedFacet = facet.items.filter(x => x.value === selectedFilter.value)[0] || null;
                if (selectedFacet) {
                    selectedFacet.items = facet.items.filter(x => x.parentId === selectedFacet.value);
                    if (selectedFacet.name)
                        return this.renderCategoryFacetSection(selectedFacet);
                    else
                        return this.renderCategoryNode(selectedFacet);
                } else {
                    return null;
                }
            }

            facet.items = facet.items.filter(x => !x.parentId);
            return this.renderCategoryFacetSection(facet);

        }
        const {facetFilters, filtersMenuVisibleSections} = this.state;
        const title = facet.name;

        let filterText = facetFilters[title];
        const filteredItems = filterText ? facet.items.filter(x => _.includes(x.range.toLowerCase(), filterText))
            : facet.items;

        const overFlowAmt = filteredItems.length > facet.limit ? filteredItems.length - facet.limit : 0;
        const overflow = overFlowAmt > 0 ? (<div className="lbl lbl-info"><span className="category-show-more" onClick={() => this.expandFacet(facet)}>Show more</span><span className="count category-show-more"> ({`${overFlowAmt}`})</span></div>) : null;
        const overflowMobile = overFlowAmt > 0 ? (<div className="checkbox-row"><a className="category-show-more" onClick={() => this.expandFacet(facet)}>Show more</a>&nbsp;<span className="count category-show-more"> ({`${overFlowAmt}`})</span></div>) : null;

        return (<>
            <div>
                <div className="catalog_facet-section ps-hidden-sm ps-hidden-xs ps-hidden-md" key={title}>
                    <h3>{title}</h3>
                    {facet.items.length > facetSearchCutoff ? <div className="search-input">
                        <input className="form-control" placeholder={`Search ${title}`} value={facetFilters[title] || ''}
                            id={title} onChange={this.onFacetFilterInput.bind(this)} />
                        {filterText ? <i className="fa fa-times-circle" aria-hidden="true" onClick={() => this.onClearFacetFilter(title)} /> : null}
                    </div> : null}
                    {_.slice(filteredItems, 0, facet.limit)
                        .map((f, i) => (<div key={i}><Checkbox id={`${title}_${i}`} key={`${title}_${i}`}
                            label={`${f.range}<span class="lbl lbl-subtle">&nbsp;&nbsp;(${f.count})</span>`}
                            onChange={(args) => this.onFacetCheckChange(args, facet, f)}
                            checked={f.checked ? true : false} className={'custom-checkbox-container'} />
                        </div>))}{overflow}</div>
            </div>
            <div>
                <div className="catalog-tabs_header ps-hidden-lg ps-hidden-xl" key={title} onClick={() => this.handleFilterMenuSectionSelected(title)}>
                    <div className={`catalog-tabs_tab lbl-bold ${filtersMenuVisibleSections.includes(title) ? 'catalog-tabs_tab--active' : ''}`} >
                        {title}
                    </div>
                </div>
                {filtersMenuVisibleSections.includes(title) ? (
                    <div className="catalog-tabs_content">
                        {facet.items.length > facetSearchCutoff ? <div className="search-input" >
                            <input className="form-control" placeholder={`Search ${title}`} value={facetFilters[title] || ''}
                                id={title} onChange={this.onFacetFilterInput.bind(this)} />
                            {filterText ? <i className="fa fa-times-circle clear_facet" aria-hidden="true" onClick={() => this.onClearFacetFilter(title)} /> : null}
                        </div> : null}
                        {_.slice(filteredItems, 0, facet.limit)
                            .map((f, i) => (<div className="checkbox-row" key={i}><div className="checkbox-row-text">{`${f.range}`}<span className="lbl lbl-subtle">&nbsp;&nbsp;({`${f.count}`})</span></div><div key={i} className="filter_checkbox"><Checkbox id={`${title}_${i}`} key={`${title}_${i}`}
                                onChange={(args) => this.onFacetCheckChange(args, facet, f)}
                                checked={f.checked} className={f.checked ? 'checked' : ''} />
                            </div></div>))}{overflowMobile}
                    </div>) : ''}
            </div>
        </>)
    }

    renderCategoryFacetSection(facet) {
        const {facetFilters, filtersMenuVisibleSections} = this.state;
        const title = 'Category';

        let filterText = facetFilters[title];
        const filteredItems = filterText ? facet.items.filter(x => _.includes(x.range.toLowerCase(), filterText))
            : facet.items;

        const overFlowAmt = filteredItems.length > facet.limit ? filteredItems.length - facet.limit : 0;
        const overflow = overFlowAmt > 0 ? (<div className="lbl lbl-info fix-category-padding"><span className="category-show-more" onClick={() => this.expandFacet(facet)}>Show more</span><span className="count"> ({`${overFlowAmt}`})</span></div>) : null;

        if (!facet.items || facet.items.length === 0)
            return null;

        return (<>
            <div className="ps-hidden-sm ps-hidden-xs ps-hidden-md">
                <div className="catalog_facet-section" key={title}>
                    <h3 style={{marginBottom: '5px'}}>Categories</h3>
                    {_.slice(filteredItems, 0, facet.limit).map((f, i) =>
                        <div className="catalog-facet" id={`${title}_${i}`} key={`${title}_${i}`} onClick={() => this.onCategoryClick(f)}><span>{`${f.range}`}</span></div>)}
                    {overflow}
                </div>
            </div>
            <div className="ps-hidden-lg ps-hidden-xl">
                <div className="catalog-tabs_header" key={title} onClick={() => this.handleFilterMenuSectionSelected(title)}>
                    <div className={`catalog-tabs_tab lbl-bold ${filtersMenuVisibleSections.includes(title) ? 'catalog-tabs_tab--active' : ''}`} >
                        {title}
                    </div>
                </div>
                {filtersMenuVisibleSections.includes(title) ? (
                    <div className="catalog-tabs_content category_content">

                        {_.slice(filteredItems, 0, facet.limit).map((f, i) =>
                            <div className="catalog-facet" id={`${title}_${i}`} key={`${title}_${i}`} onClick={() => this.onCategoryClick(f)}>{`${f.range}`}</div>)}
                        {overflow}
                    </div>) : ''}
            </div>
        </>);
    }

    renderCategoryNode(facet) {
        const {filtersMenuVisibleSections} = this.state;
        let catPath = this.getCategoryPathArray(facet);
        return (
            <>
                <div className="ps-hidden-sm ps-hidden-xs ps-hidden-md">
                    <div className="catalog_facet-section" key="title">
                        <h3 style={{marginBottom: '5px'}}>Categories</h3>
                        <div className="catalog-facet node" onClick={() => this.onCategoryClick({url: 'clear-all'})}>All Categories</div>
                        {catPath.map((x, i) => <div key={i} onClick={() => this.onCategoryClick(x)} className={i === catPath.length - 1 ? 'catalog-facet node last' : 'catalog-facet node'}>{x.label}</div>)}
                        {facet.items.map(x => <div key={x.range} className="catalog-facet child" onClick={() => this.onCategoryClick(x)}>{x.range}</div>)}
                    </div>
                </div>
                <div className="ps-hidden-lg ps-hidden-xl">
                    <div className="catalog-tabs_header" key="Category" onClick={() => this.handleFilterMenuSectionSelected('Category')}>
                        <div className={`catalog-tabs_tab lbl-bold ${filtersMenuVisibleSections.includes('Category') ? 'catalog-tabs_tab--active' : ''}`} >
                            Category
                        </div>
                    </div>
                    {filtersMenuVisibleSections.includes('Category') ? (
                        <div className="catalog-tabs_content"><div className="catalog_facet-section">
                            <div className="catalog-facet node" onClick={() => this.onCategoryClick({url: 'clear-all'})}>All Categories</div>
                            {catPath.map((x, i) => <div key={i} onClick={() => this.onCategoryClick(x)} className={i === catPath.length - 1 ? 'catalog-facet node last' : 'catalog-facet node'}>{x.label}</div>)}
                            {facet.items.map((x, i) => <div key={i} className="catalog-facet child" onClick={() => this.onCategoryClick(x)}>{x.range}</div>)}
                        </div></div>) : ''}
                </div>
            </>)
    }

    slideFacetMenu() {
        this.setState({isMobileMenuVisible: true});
        $('body,html').css({overflowY: 'hidden', position: 'fixed'});
        $('#mobile-menu').addClass('mobile-menu-view');
    }

    clearFacetMenu() {

        this.setState({isMobileMenuVisible: false});
        $('#mobile-menu').removeClass('mobile-menu-view');
        $('body,html').css({overflowY: 'visible', position: ''});
    }

    renderFacetSearch() {
        const {filterSearchFocused, filterSearchText} = this.state;
        const buttonClass = filterSearchFocused || filterSearchText ? 'show-button' : '';

        return (
            <div className="catalog_facet-section" key="facet-search">
                <div className="search-input">
                    <div className="facet-search-wrapper">
                        <input className="form-control" placeholder="Search Within" value={filterSearchText}
                            onChange={(e) => this.setState({filterSearchText: e.target.value})}
                            onFocus={() => this.setState({filterSearchFocused: true})}
                            onBlur={() => this.setState({filterSearchFocused: false})}
                            onKeyUp={this.onSearchWithinKeyPress.bind(this)} />
                        <i className="glyphicon glyphicon-search" />
                        <Button secondary={true} inlineButton={true} className={buttonClass} onClick={this.onSearchWithinSearch.bind(this)}>Filter</Button>
                    </div>
                </div>
            </div>
        )
    }

    renderCompatiblePopup(compatProduct) {
        return (<Popup show={true} cancelText="Cancel" hideButtons={true} onCancel={() => this.setState({showCompatibles: null})}>
            <h3>{`Equivalents ${compatProduct.title}:`}</h3>
            <ul>
                {Object.keys(compatProduct.compatibleWith).map((key, index) => <li key={index}>{`${key}: ${compatProduct.compatibleWith[key].join(',')}`}</li>)}
            </ul>
        </Popup>);
    }

    renderBreadCrumb() {

        const {catalog: {facets, breadCrumbs, filters, totalResults, routeFacets, searchTerm}} = this.props;

        let categoryCrumbs = [];
        let combinedFilters = (filters || []).concat(routeFacets || []);

        let categoryFilter = combinedFilters.filter(x => x.name === 'category')[0];
        if (categoryFilter) {
            let selectedCat = facets.filter(x => x.name === 'Category')[0].items.filter(x => x.value === categoryFilter.value)[0];
            if (selectedCat) {
                categoryCrumbs = this.getCategoryPathArray(selectedCat);

                if (breadCrumbs.length === 0 && categoryCrumbs.length > 0) {
                    categoryCrumbs.unshift({url: '/', label: 'Home'});
                }
            }
        }

        return (<>
            <div className="ps-hidden-sm ps-hidden-xs ps-hidden-md">
                {(searchTerm || (breadCrumbs && breadCrumbs.length > 0)) &&
            <NavMenu
                options={breadCrumbs.concat(categoryCrumbs)}
                searchTerm={searchTerm }
                className="catalog-nav-menu"
                totalResults={totalResults}
                handleClick={this.onCategoryClick} />
                }
            </div>
            <div className="ps-hidden-lg ps-hidden-xl">
                <div className="catalog-tabs" style={{borderBottom: '2px solid #dcdcdc', paddingTop: '0'}}>
                    <div className="left-column">
                        <span className="catalog-sort results-menu">{totalResults} Results</span>
                    </div>
                    <div className="right-column">
                        <span className="catalog-sort filter-menu" onClick={this.slideFacetMenu.bind(this)}>FILTER</span>
                    </div>
                </div>
            </div>
        </>
        )
    }

    render() {
        const {
            isBusy,
            showAllFilter,
            showCompatibles,
            isMobileMenuVisible} = this.state;

        const {
            catalog: {
                campaign, 
                totalResults, 
                products, 
                facets, 
                filters, 
                routeFacets, 
                productCrumbs, 
                pageTitle, 
                page,
                pageSize, 
                viewAll, 
                pageDescription, 
                showNoResults,
                searchTerm,
            },
            location: {search},
            history,
            bloomReach: {isPreview},
        } = this.props;

        const displayFacets = showAllFilter ? facets : facets.slice(0, 8);
        const compatProduct = showCompatibles ? _.find(products, {id: showCompatibles}) : null;
        const combinedFilters = filters.concat(routeFacets || []);
        let showFilterPills = combinedFilters.filter(x => !ignoredFacets.includes(x.name.toLowerCase())).length > 0;
        const meta = /^\/catalog/.test(window.location.pathname) ? {name: {robots: 'noindex'}} : undefined;
        const {_page, _view} = queryString(window.location.search || search || '');
        const searchParams = '?' + [_page ? `_page=${_page}` : '', _view ? `_view=${_view}` : ''].filter(x => !!x).join('&');
        const canonical = window.location.origin + window.location.pathname + (_view || _page ? searchParams : '');

        return (
            <>
                <div className="catalog">
                    <PageMetaData
                        title={pageTitle}
                        description={pageDescription}
                        pageType="other"
                        trackAnalytics={false}
                        extend={true}
                        rootPage={true}
                        canonical={canonical}
                        meta={meta}
                    />
                    <div className="catalog_header">
                        {this.renderBreadCrumb()}
                    </div>
                    <div className="catalog_body">

                        <div className="catalog_left-column ps-hidden-sm ps-hidden-xs ps-hidden-md">
                            {displayFacets.length > 0 ? <div className="filter-title">Filter</div> : null}
                            {showFilterPills ? <div>
                                <div className="catalog_facet-section">
                                    <h3>Search Terms<span className="clear-all" onClick={this.onRemoveAllFiltersClick.bind(this)}>Clear All</span></h3>
                                    {combinedFilters.map(x => this.renderFacetPill(x))}
                                </div>
                            </div> : null}
                            {displayFacets.length > 0 ? this.renderFacetSearch() : null}
                            {displayFacets.map(this.renderFacetSection)}
                            {showAllFilter || facets.length < 9 ? null : <span onClick={() => this.setState({showAllFilter: true})} className="show-all-filter">Show All Filters</span>}
                            <div style={{marginRight: '10px', marginTop: '15px'}}>
                                <BrComponent path={'main/left'} />
                            </div>
                        </div>

                        <div key="tabs" id="mobile-menu" className="mobile-menu">
                            <div className="catalog_cart">
                                <div className="addToListContainer" style={{textDecoration: 'underline'}}><a onClick={this.onRemoveAllFiltersClick.bind(this)}>Clear All</a></div>
                                <div className="addToListContainer" style={{textAlign: 'right', fontWeight: 'bold'}}><span onClick={this.clearFacetMenu.bind(this)}>SHOW RESULTS ({totalResults}) </span></div>
                            </div>
                            <div key="tabs" className="catalog-tabs">

                                {displayFacets.map(this.renderFacetSection)}
                                {showAllFilter || facets.length < 9 ? null : <div className="row"><span onClick={() => this.setState({showAllFilter: true})} className="show-all-filter">Show All Filters</span></div>}
                            </div>
                        </div>

                        {!isMobileMenuVisible ?
                            <CatalogResults products={products} productCrumbs={productCrumbs} totalResults={totalResults} campaign={campaign} isBusy={isBusy} showNoResults={showNoResults} page={page} pageSize={pageSize} 
                                viewAll={viewAll} onShowRequestQuote={() => history.push('/quote/request')} searchTerm={searchTerm} />
                            : null}
                    </div>
                    {(!isMobileMenuVisible && products && products.length > 0 || isPreview) ?
                        <div className="catalog_bottom-cms-container">
                            <BrComponent path={'main/bottom'} />
                        </div>
                        : null}
                    {compatProduct ? this.renderCompatiblePopup(compatProduct) : null}
                </div>
            </>
        );
    }
}