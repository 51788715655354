import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import * as PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import {setSigSessionId} from 'utility'
import { getShipItemOptions } from 'productUtility';
import { isURL, sortByKey, getCookie, setCookie, getPersonalizationCookiesEnabled } from 'utility';

export const defaultCartState = {
    isBusy: false,
    loadingPricing: false,
    itemCount: 0,
    items: [],
    cartItems: [],
    savedItems: [],
    uniqueFacilities: [],
    selectedCartItemId: null,
    assetDetails: {},
    itemHistory: {},
    checkout: {
        items: [],
        facility: {},
        holdForPo: false,
        promoCode: '',
        promoDisplay: '',
        totals: {
            taxes: 0,
            creditCardFees: 0,
            processingFees: 0,
            minOrderFees: 0,
            exchangeFees: 0,
            programFees: 0,
            oemFees: 0,
        },
        shippingLocation: {
            includePo: false,
            attentionTo: '',
            shippingAttentionDisplay: '',
        },
        shippingMethod: {
            carrier: '',
            carrierId: -1,
            shipAccountNo: '',
            useCustomerAccount: false,
            shippingInsurance: true,
            selectedPriorityId: '',
            isGsa: false,
        },
        paymentInformation: {
            poNumber: '',
            paymentMethod: '',
            saveCC: true,
        },
        shippingAddress: {},
        billingAddress: {},
        selectedShipOption: {},
        facilitySettings: {},
        shippingAddresses: [],
        billingAddresses: [],
        paymentMethods: [],
        shipCarriers: [],
        shipOptions: [],
        initialRateResult: [],
        savedCards: [],
        cybersourceResponse: {},
        shipCosts: [],
        shipments: [],
    },
};

const CartItem = PropTypes.shape({
    cartId: PropTypes.number,
    orderId: PropTypes.number,
    contactId: PropTypes.number,
    lineItemId: PropTypes.number,
    vendorResearchId: PropTypes.number,
    purchaseChoice: PropTypes.string,
    oItemNumber: PropTypes.number,
    oemName: PropTypes.string,
    uomId: PropTypes.number,
    uomCode: PropTypes.string,
    uomQuantity: PropTypes.number,
    imagePath: PropTypes.string,
    isSavedForLater: PropTypes.bool,
    isChecked: PropTypes.bool,
    quantity: PropTypes.number,
    partNumber: PropTypes.string,
    description: PropTypes.string,
    facilityName: PropTypes.string,
    facilityId: PropTypes.number,
    vendorId: PropTypes.number,
    price: PropTypes.number,
    requestorId: PropTypes.number,
    requestorName: PropTypes.string,
    groupId: PropTypes.number,
    weight: PropTypes.number,
    vendorShipMethodType: PropTypes.number,
    hasBulkPricing: PropTypes.bool,
    oemItemNumber: PropTypes.string,
    vendorHasRepairSurcharge: PropTypes.bool,
    isImaging: PropTypes.bool,
    isActualWeight: PropTypes.bool,
    hazmatTypeId: PropTypes.number,
    conditionId: PropTypes.number,
    isGsa: PropTypes.bool,
    vString: PropTypes.string,
    listPrice: PropTypes.number,
    orderAttempt: PropTypes.number,
    category: PropTypes.string,
    differentiatingAttributes: PropTypes.arrayOf(PropTypes.shape({
        attributeName: PropTypes.string,
        attributeValue: PropTypes.string,
    })),
    formularyBehavior: PropTypes.number,
    formularyRuleId: PropTypes.number,
    fields: PropTypes.arrayOf(PropTypes.shape({
        lineItemId: PropTypes.number,
        orderId: PropTypes.number,
        fieldUid: PropTypes.string,
        prompt: PropTypes.string,
        fieldType: PropTypes.number,
        isDefault: PropTypes.bool,
        isRequired: PropTypes.bool,
        value: PropTypes.string,
    })),
    vendorAddress: PropTypes.shape({
        addressId: PropTypes.number,
        addressTypeId: PropTypes.number,
        companyId: PropTypes.number,
        description: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        street1: PropTypes.string,
        street2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        postalCode: PropTypes.string,
        country: PropTypes.string,
        responsibleCompanyId: PropTypes.number,
        validated: PropTypes.object, // TODO - find a state where this field is populated
        validatedOverrideUserId: PropTypes.number,
        isValidated: PropTypes.bool,
        userId: PropTypes.number,
    }),
    isApproval: PropTypes.bool,
    originalPromotionPrice: PropTypes.number,
    promotionDiscount: PropTypes.number,
    purchaseChoiceId: PropTypes.number,
    formularySetting: PropTypes.shape({
        rootCompanyId: PropTypes.number,
        facilityId: PropTypes.number,
        vendorItemNumber: PropTypes.number,
        conditionId: PropTypes.number,
        condition: PropTypes.number,
        showBadge: PropTypes.bool,
        showCompanyLogo: PropTypes.bool,
        showAdditionalOptionsLink: PropTypes.bool,
        approvalRequired: PropTypes.bool,
        approvalLimit: PropTypes.number,
        fields: PropTypes.any, // TODO - find a state where this field is populated
    }),
    isFormularyOption: PropTypes.bool,
    formularyFields: PropTypes.array, // TODO - find a state where this field is populated
    urgencyId: PropTypes.number,
    leadTimeDays: PropTypes.number,
    shipCutoffUtc: PropTypes.string,
    isPastCutOff: PropTypes.bool,
    leadTimeDisplay: PropTypes.string,
});

export const CheckoutShape = PropTypes.shape({
    items: PropTypes.array,
    facility: PropTypes.object,
    holdForPo: PropTypes.bool,
    promoCode: PropTypes.string,
    promoDisplay: PropTypes.string,
    totals: PropTypes.shape({
        taxes: PropTypes.number,
        creditCardFees: PropTypes.number,
        processingFees: PropTypes.number,
        minOrderFees: PropTypes.number,
        exchangeFees: PropTypes.number,
        programFees: PropTypes.number,
        oemFees: PropTypes.number,
    }),
    shippingLocation: PropTypes.shape({
        includePo: PropTypes.bool,
        attentionTo: PropTypes.string,
        shippingAttentionDisplay: PropTypes.string,
    }),
    shippingMethod: PropTypes.shape({
        carrier: PropTypes.string,
        carrierId: PropTypes.number,
        shipAccountNo: PropTypes.string,
        useCustomerAccount: PropTypes.bool,
        shippingInsurance: PropTypes.bool,
        selectedPriorityId: PropTypes.string,
        isGsa: PropTypes.bool,
    }),
    paymentInformation: PropTypes.shape({
        poNumber: PropTypes.string,
        paymentMethod: PropTypes.string,
        saveCC: PropTypes.bool,
    }),
    shippingAddress: PropTypes.object, // TODO - find a state where all these fields are populated
    billingAddress: PropTypes.object,
    selectedShipOption: PropTypes.object,
    facilitySettings: PropTypes.object,
    shippingAddresses: PropTypes.array,
    billingAddresses: PropTypes.array,
    paymentMethods: PropTypes.array,
    shipCarriers: PropTypes.array,
    savedCards: PropTypes.array,
    cybersourceResponse: PropTypes.object,
});

export const StateShape = PropTypes.shape({
    isBusy: PropTypes.bool,
    loadingPricing: PropTypes.bool,
    itemCount: PropTypes.number,
    items: PropTypes.arrayOf(CartItem),
    cartItems: PropTypes.arrayOf(CartItem),
    savedItems: PropTypes.arrayOf(CartItem),
    uniqueFacilities: PropTypes.arrayOf(PropTypes.shape({
        facilityId: PropTypes.number,
        facilityName: PropTypes.string,
        cartItemId: PropTypes.string,
    })),
    selectedCartId: PropTypes.string,
    assetDetails: PropTypes.object,
    itemHistory: PropTypes.shape({
        purchasedCount: PropTypes.number,
        views: PropTypes.arrayOf(PropTypes.shape({
            dateViewed: PropTypes.string,
            productId: PropTypes.number,
            product: PropTypes.shape({
                title: PropTypes.string,
                partNumber: PropTypes.string,
                description: PropTypes.string,
                detailUrl: PropTypes.string,
                thumbnailUrl: PropTypes.string,
                brand: PropTypes.string,
                id: PropTypes.string,
                models: PropTypes.arrayOf(PropTypes.string),
                attributes: PropTypes.object,
                matchReason: PropTypes.any,
                compatibleWith: PropTypes.object,
                options: PropTypes.array,
                outrightListPrice: PropTypes.number,
            }),
            option: PropTypes.any,
            datePurchased: PropTypes.string,
            orderDetailUri: PropTypes.string,
        })),
    }),
    checkout: CheckoutShape,
});

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    addToCart: (product, quantity, option, id_ins) => addToCart(product, quantity, option, id_ins),
    loadUserCart: () => loadUserLegacyCart(),
    loadGuestCart: () => loadGuestCart(),
    selectFacility: cartItemId => selectFacility(cartItemId),
    saveItemForLater: (cartItemId, saveForLater) => saveItemForLater(cartItemId, saveForLater),
    addItemToPurchase: cartItemId => addItemToPurchase(cartItemId),
    saveCartItem: cartItem => saveCartItem(cartItem),
    removeCartItem: lineItemId => removeCartItem(lineItemId),
    saveCart: cart => saveCart(cart),
    saveCheckout: checkout => saveCheckout(checkout),
    updateQuantity: (lineItemId, quantity, hasBulkPricing) => updateQuantity(lineItemId, quantity, hasBulkPricing),
    saveFields: fields => saveFields(fields),
    loadFacilityCheckoutSettings: facilityId => loadFacilityCheckoutSettings(facilityId),
    loadFacilityAddresses: facilityId => loadFacilityAddresses(facilityId),
    resetCheckout: () => resetCheckout(),
    getShippingOptions: () => getShippingOptions(),
    getGroupedShippingOptions: (preloaded) => getGroupedShippingOptions(preloaded),
    getOrderTotals: calculateTaxes => getOrderTotals(calculateTaxes),
    submitOrder: () => submitOrder(),
    generateCreatePaymentRequest: (token) => generateCreatePaymentRequest(token),
    getSavedCards: (contactId, facilityId) => getSavedCards(contactId, facilityId),
    saveReduxCartItem: cartItem => saveReduxCartItem(cartItem),
    getBrowsingHistory: () => getBrowsingHistory(),
    getBrowsingHistoryFromCookieProductsData: () => getBrowsingHistoryFromCookieProductsData(),
    setReduxStateFromCookie: (browsingCookie) => setReduxStateFromCookie(browsingCookie),
    emptyItemHistory: () => emptyItemHistory(),
    proceedToCheckout: (lineItemIds) => proceedToCheckout(lineItemIds),
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

function addToCart(product, quantity, option, id_ins) {
    if (!id_ins || (typeof document === undefined)) {
        id_ins = getCookie('id_ins');
    }
    return (dispatch, getState) => {
        const {
            network: { tokenInfo },
            user: { facility },
        } = getState();
        dispatch({
            type: 'ADD_TO_CART',
            product: product,
            quantity: quantity,
            tokenInfo: tokenInfo,
            facility: facility,
            option: option,
            id_ins: id_ins,
        });
    };
}

export function emptyItemHistory() {
    let products = {};
    return (dispatch) => dispatch({ type: 'SET_BROWSING_HISTORY', products })
}

export function setReduxStateFromCookie(browsingCookie) {
    browsingCookie = browsingCookie.filter(x => x.dateViewed); // toss out non compatible cookies
    const isNotLocalhost = window.location.hostname !== 'localhost';

    if (browsingCookie.length > 0) {
        browsingCookie = browsingCookie.sort(function (x, y) {
            return new Date(y.dateViewed) - new Date(x.dateViewed);
        });
    }

    browsingCookie = browsingCookie.slice(0, 10);

    if (typeof document !== 'undefined' && getPersonalizationCookiesEnabled()) {
        setCookie('browsingHistory', JSON.stringify(browsingCookie), {
            'secure': window.location.hostname !== 'localhost',
            sameSite: isNotLocalhost ? 'None' : undefined,
            'expires': 30,
        });
    }

    let products = {};
    products.views = browsingCookie;

    return (dispatch) => dispatch({ type: 'SET_BROWSING_HISTORY', products });
}

export function loadUserLegacyCart() {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'GET_CART_REQ' }),
            response: response =>
                dispatch({ type: 'GET_CART_RESP', response: response }),
            error: error => dispatch({ type: 'GET_CART_ERR', response: error }),
        };
        const {
            network: {
                tokenInfo: { userId },
            },
        } = getState();
        bound.request();
        return axios
            .get(`/ShoppingService/api/v0.5/cart?userId=${userId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function saveCartItem(cartItem) {
    return dispatch => {
        const bound = {
            request: () => dispatch({ type: 'SAVE_ITEM_REQ' }),
            response: response =>
                dispatch({
                    type: 'SAVE_ITEM_RESP',
                    item: cartItem,
                    response: response,
                }),
            error: error => dispatch({ type: 'SAVE_ITEM_ERR', response: error }),
        };

        bound.request();
        return axios
            .post(`/ShoppingService/api/v1.0/cart`, cartItem)
            .then(bound.response)
            .catch(bound.error);
    };
}

function selectFacility(cartItemId) {
    return (dispatch) => {
        dispatch({
            type: 'SET_FACILITY_RESP',
            cartItemId: cartItemId,
        });
    }
}

function saveItemForLater(cartItemId, saveForLater) {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'SAVE_ITEM_LATER_REQ' }),
            response: response => dispatch({ type: 'SAVE_ITEM_LATER_RESP' }),
            error: error => dispatch({ type: 'SAVE_ITEM_LATER_ERR', response: error }),
        };

        bound.request();
        return axios
            .post(`/ShoppingService/api/v1.0/cart/saveForLater/${cartItemId}/${saveForLater}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function updateQuantity(lineItemId, quantity, hasBulkPricing) {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'UPDATE_QUANTITY_REQ' }),
            response: response =>
                dispatch({
                    type: 'UPDATE_QUANTITY_RESP',
                    response: response,
                }),
            error: error => dispatch({ type: 'UPDATE_QUANTITY_ERR', response: error }),
        };

        bound.request();
        return axios
            .post(
                `/ShoppingService/api/v1.0/cart/updateQuantity/${lineItemId}/${quantity}/${hasBulkPricing}`
            )
            .then(bound.response)
            .catch(bound.error);
    };
}

function addItemToPurchase(cartItemId) {
    return (dispatch, getState) => {
        let items = getState().cart.items;

        let cartItem = _.merge({}, _.find(items, { cartId: cartItemId }));
        cartItem.isSaved = false;

        const bound = {
            request: () => dispatch({ type: 'SAVE_ITEM_REQ' }),
            response: response =>
                dispatch({
                    type: 'SAVE_ITEM_RESP',
                    item: cartItem,
                    response: response,
                }),
            error: error => dispatch({ type: 'SAVE_ITEM_ERR', response: error }),
        };

        bound.request();
        return axios
            .post(`/ShoppingService/api/v1.0/cart`, cartItem)
            .then(bound.response)
            .catch(bound.error);
    };
}

function removeCartItem(lineItemId) {
    return dispatch => {
        const bound = {
            request: () => dispatch({ type: 'REMOVE_ITEM_REQ' }),
            response: response =>
                dispatch({
                    type: 'REMOVE_ITEM_RESP',
                    lineItemId: lineItemId,
                    response: response,
                }),
            error: error => dispatch({ type: 'REMOVE_ITEM_ERR', response: error }),
        };

        bound.request();
        return axios
            .delete(`/ShoppingService/api/v1.0/cart/delete/${lineItemId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function saveFields(fields) {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'SAVE_FIELD_REQ' }),
            response: response =>
                dispatch({
                    type: 'SAVE_FIELD_RESP',
                    response: response,
                }),
            error: error => dispatch({ type: 'SAVE_FIELD_ERR', response: error }),
        };

        const {
            network: {
                tokenInfo: { userId },
            },
        } = getState();

        bound.request();
        return axios
            .post(`/ShoppingService/api/v1.0/cart/saveFields//${userId}`, fields)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function getBrowsingHistory() {
    return dispatch => {
        const bound = {
            request: () => dispatch({ type: 'GET_BROWSING_HISTORY_REQ' }),
            response: response =>
                dispatch({
                    type: 'GET_BROWSING_HISTORY_RESP',
                    response: response,
                }),
            error: error => dispatch({ type: 'GET_BROWSING_HISTORY_ERR', response: error }),
        };

        bound.request();

        return axios.get('/CatalogService/api/v1/recent?limit=4&includePurchaseInfo=false')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function getBrowsingHistoryFromCookieProductsData() {
    return dispatch => {
        const bound = {
            request: () => dispatch({ type: 'GET_BROWSING_HISTORY_REQ' }),
            response: response =>
                dispatch({
                    type: 'GET_BROWSING_HISTORY_RESP',
                    response: response,
                }),
            error: error => dispatch({ type: 'GET_BROWSING_HISTORY_ERR', response: error }),
        };

        bound.request();

        let browsingHistoryCookie = Cookies.get('browsingHistory');
        let data = browsingHistoryCookie ? JSON.parse(browsingHistoryCookie) : [];

        return axios.post('/CatalogService/api/v1/browsingHistory', data)
            .then(bound.response)
            .catch(bound.error);
    };
}

function saveCart(cart) {
    return dispatch => dispatch({ type: 'SAVE_CART', cart: cart });
}

function saveCheckout(changes) {
    return (dispatch, getState) => {
        let checkout = _.merge(getState().checkout, changes);
        dispatch({ type: 'SAVE_CHECKOUT', checkout: checkout });
    };
}

function loadGuestCart() {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'GET_GUESTCART_REQ' }),
            response: response =>
                dispatch({ type: 'GET_GUESTCART_RESP', response: response }),
            error: error => dispatch({ type: 'GET_GUESTCART_ERR', response: error }),
        };

        bound.request();
        return axios
            .get('/ShoppingService/api/v1/cart')
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadFacilityCheckoutSettings(facilityId) {
    return (dispatch, getState) => {

        const bound = {
            request: () => dispatch({ type: 'GET_FACILITY_CHECKOUT_SETTINGS_REQ' }),
            response: response =>
                dispatch({
                    type: 'GET_FACILITY_CHECKOUT_SETTINGS_RESP',
                    response: response,
                }),
            error: error =>
                dispatch({ type: 'GET_ACILITY_CHECKOUT_SETTINGS_ERR', response: error }),
        };

        bound.request();
        return axios
            .get(`/ShoppingService/api/v1/company/checkoutsettings/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadFacilityAddresses(facilityId) {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'GET_FACILITY_ADDRESSES_REQ' }),
            response: response =>
                dispatch({ type: 'GET_FACILITY_ADDRESSES_RESP', response: response }),
            error: error =>
                dispatch({ type: 'GET_FACILITY_ADDRESSES_ERR', response: error }),
        };

        bound.request();
        return axios
            .post(`/ShoppingService/api/v1/company/addresses/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function saveReduxCartItem(cartItem) {
    return (dispatch, getState) => {
        let { cart } = getState();
        dispatch({ type: 'SAVE_CART', cart: cart });
    };
}

function updateCartState(oldState, newItems) {
    const cartItems = newItems.filter(x => !x.isSavedForLater);
    const savedItems = newItems.filter(x => x.isSavedForLater);

    let uniqueFacilities = [];
    for (let i = 0; i < cartItems.length; i++) {
        if (
            uniqueFacilities
                .map(function (i) {
                    return i.cartItemId;
                })
                .indexOf(cartItems[i].cartItemId) === -1
        ) {
            uniqueFacilities.push({
                facilityId: cartItems[i].facilityId,
                facilityName: cartItems[i].facilityName,
                cartItemId: cartItems[i].cartItemId,
            });
        }
    }

    let selectedCartItemId = null;
    if (uniqueFacilities.length === 1)
        selectedCartItemId = uniqueFacilities[0].cartItemId;
    else selectedCartItemId = oldState.selectedCartItemId;

    // use this else instead if we want to auto expand the selected facility
    // else if(checkedItems.length > 0)
    //  selectedFacilityId = checkedItems[0].FacilityId;

    const sortedFacilities = sortByKey(uniqueFacilities, 'facilityName');

    return {
        ...oldState,
        items: newItems,
        cartItems: cartItems,
        savedItems: savedItems,
        uniqueFacilities: sortedFacilities,
        selectedCartItemId: selectedCartItemId,
        itemCount: _.sumBy(cartItems || [], function (i) {
            return parseInt(i.quantity);
        }),
        isBusy: false,
    };
}

function resetCheckout() {
    return dispatch => dispatch({ type: 'RESET_CHECKOUT' });
}

function getShippingOptions() {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'GET_SHIPPING_REQ' }),
            response: response =>
                dispatch({ type: 'GET_SHIPPING_RESP', response: response }),
            error: error => dispatch({ type: 'GET_SHIPPING_ERR', response: error }),
        };

        const {
            cart: { checkout },
        } = getState();
        const shipAddress = checkout.shippingAddress;

        // This results in a unique set of ids
        let vendorIds = [...new Set(checkout.items.filter(x => !x.isContractProOption).map(item => item.vendorId))];

        let request = {};
        let SupplierRatingRequests = [];

        vendorIds.forEach(function (vendorId) {
            const cartItems = checkout.items.filter(x => !x.isContractProOption && x.vendorId === vendorId);
            const ci = cartItems[0];
            let vendorAddress = ci.vendorAddress || {};

            const shipItems = [];
            cartItems.forEach(function (x) {
                shipItems.push({
                    Cost: x.price,
                    PartNumber: x.partNumber,
                    Quantity: x.quantity,
                    UnitWeight: x.weight,
                    Options: getShipItemOptions(x, checkout.facilitySettings.repairShippingCharge),
                    lineItemId: x.lineItemId,
                });
            });

            SupplierRatingRequests.push({
                OriginatingCompanyId: vendorId,
                VendorShipMethod: ci.vendorShipMethodType,
                ShipDate: moment().format(),
                ShipFrom: {
                    Address1: vendorAddress.street1,
                    Address2: vendorAddress.street2,
                    City: vendorAddress.city,
                    State: vendorAddress.state,
                    Zip: vendorAddress.postalCode,
                    Country: vendorAddress.country,
                },
                ShipTo: {
                    Address1: shipAddress.street1,
                    Address2: shipAddress.street2,
                    City: shipAddress.city,
                    State: shipAddress.state,
                    Zip: shipAddress.postalCode,
                    Country: shipAddress.country,
                },
                ShippingItems: shipItems,
            });
        });

        request.SupplierRatingRequest = SupplierRatingRequests;
        request.ShippingAccount = 'PartsSource';
        request.FacilityId = checkout.facility.facilityId;

        bound.request();
        return axios
            .post('/ShipIntegrationService/api/v1/rating/rate', request)
            .then(bound.response)
            .catch(bound.error);
    };
}

function getGroupedShippingOptions(preloaded) {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'GET_SHIPPING_REQ' }),
            response: response =>
                dispatch({ type: 'GET_GROUP_SHIPPING_RESP', response: response, preloaded: preloaded }),
            error: error => dispatch({ type: 'GET_SHIPPING_ERR', response: error }),
        };

        const {
            cart: {checkout},
            network: {
                tokenInfo: {userId},
            },
            user: {
                settings: {allowSplitShipping},
            },
        } = getState();
        const shipAddress = checkout.shippingAddress;

        // This results in a unique set of ids
        let vendorIds = [...new Set(checkout.items.filter(x => !x.isContractProOption).map(item => item.vendorId))];

        let request = {};
        let SupplierRatingRequests = [];

        vendorIds.forEach(function (vendorId) {
            const cartItems = checkout.items.filter(x => !x.isContractProOption && x.vendorId === vendorId);
            const ci = cartItems[0];
            let vendorAddress = ci.vendorAddress || {};

            const shipItems = [];
            cartItems.forEach(function (x) {
                shipItems.push({
                    Cost: x.price,
                    PartNumber: x.partNumber,
                    Quantity: x.quantity,
                    UnitWeight: x.weight,
                    Options: getShipItemOptions(x, checkout.facilitySettings.repairShippingCharge),
                    lineItemId: x.lineItemId,
                });
            });

            SupplierRatingRequests.push({
                OriginatingCompanyId: vendorId,
                VendorShipMethod: ci.vendorShipMethodType,
                ShipDate: moment().format(),
                ShipFrom: {
                    Address1: vendorAddress.street1,
                    Address2: vendorAddress.street2,
                    City: vendorAddress.city,
                    State: vendorAddress.state,
                    Zip: vendorAddress.postalCode,
                    Country: vendorAddress.country,
                },
                ShipTo: {
                    Address1: shipAddress.street1,
                    Address2: shipAddress.street2,
                    City: shipAddress.city,
                    State: shipAddress.state,
                    Zip: shipAddress.postalCode,
                    Country: shipAddress.country,
                },
                ShippingItems: shipItems,
            });
        });

        request.SupplierRatingRequest = SupplierRatingRequests;
        request.ShippingAccount = 'PartsSource';
        request.FacilityId = checkout.facility.facilityId;
        request.UseSplitShipping = (allowSplitShipping && !checkout.overrideSplitShipping) || (preloaded && checkout.shipments.length > 1)
        request.UserId = userId;
        bound.request();
        return axios
            .post('/ShipIntegrationService/api/v1/rating/grouped/rate', request)
            .then(bound.response)
            .catch(bound.error);
    };
}

function getOrderTotals(calculateTaxes) {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'GET_TOTALS_REQ' }),
            response: response =>
                dispatch({ type: 'GET_TOTALS_RESP', response: response }),
            error: error => dispatch({ type: 'GET_TOTALS_ERR', response: error }),
        };

        const {
            cart: { checkout },
            network: {
                tokenInfo: { userId },
            },
            user: {
                settings: { allowSplitShipping },
            },
        } = getState();

        let shipments = checkout.shipments.map(x => {
            return {
                shipCost: x.selectedShipOption.netShippingCharge,
                isGsa: x.shippingMethod.isGsa,
                shipAccountNo: x.shipAccountNo,
                includeTransitCoverage: x.shippingMethod.shippingInsurance,
                useCustomerShippingAccount: x.shippingMethod.useCustomerAccount,
                shipOption: x.selectedShipOption,
                lineItemIds: x.lineItemIds,
                shipOptions: [],
                initialRateResult: [],
                shipmentNo: x.shipmentNo,
            }
        });

        let request = {
            CartItems: checkout.items,
            ShippingAddress: checkout.shippingAddress,
            BillingAddress: checkout.billingAddress,
            UserId: userId,
            PromoCode: checkout.promoCode,
            CalculateTaxes: calculateTaxes,
            ShipCosts: checkout.shipCosts,
            ReadOnlyShipping: checkout.readOnlyShipping,
            PaymentMethod: checkout.paymentInformation.paymentMethod.value === 'PO' ? 'PO' : 'CC',
            Shipments: shipments,
            UseSplitShipping: allowSplitShipping,
        };

        bound.request();
        return axios
            .post('/ShoppingService/api/v1/cart/totals', request)
            .then(bound.response)
            .catch(bound.error);
    };
}

function submitOrder() {
    return (dispatch, getState) => {                
        const bound = {
            request: () => dispatch({type: 'SUBMIT_ORDER_REQ'}),
            response: response => dispatch({type: 'SUBMIT_ORDER_RESP', response: response}),
            error: error => dispatch({type: 'SUBMIT_ORDER_ERR', response: error}),            
        };

        const {
            cart: {
                checkout,
                checkout: {
                    shippingLocation,
                    paymentInformation,
                    totals,
                    savedCards,
                },
            },
            network: {tokenInfo: {userId}},
            user: {info},
        } = getState();
        let matchingCard = savedCards.filter(
            x => x.paymentToken === paymentInformation.paymentMethod.value
        );
        const id_ins = getCookie('id_ins');

        let shipments = checkout.shipments.map(x => {
            return {
                ShipCost: x.selectedShipOption.netShippingCharge,
                IsGsa: x.shippingMethod.isGsa,
                ShipAccountNo: x.shippingMethod.shipAccountNo,
                IncludeTransitCoverage: x.shippingMethod.shippingInsurance,
                UseCustomerShippingAccount: x.shippingMethod.useCustomerAccount,
                ShipOption: x.selectedShipOption,
                LineItemIds: x.lineItemIds,
            }
        });

        let sigOrderSessionId = document.querySelector('#sig-api').getAttribute('data-order-session-id');
        setSigSessionId();

        let data = {
            CartItems: checkout.items,
            ShippingAddress: checkout.shippingAddress,
            BillingAddress: checkout.billingAddress.addressId ? checkout.billingAddress : checkout.billingAddresses[0],
            UserId: userId,
            PoNumber: paymentInformation.poNumber,
            PaymentMethod: paymentInformation.paymentMethod.value === 'PO' ? 'PO' : 'CC',
            AttentionTo: shippingLocation.attentionTo,
            IncludePo: shippingLocation.includePo,
            ShippingAttentionDisplay: shippingLocation.shippingAttentionDisplay,
            Totals: totals,
            CreditCard: matchingCard.length ? matchingCard[0] : { PaymentToken: '' },
            CybersourceResponse: checkout.cybersourceResponse,
            EmailAddress: info && info.email ? info.email : '',
            saveCC: paymentInformation.saveCC,
            ShipCosts: checkout.shipCosts,
            ReadOnlyShipping: checkout.readOnlyShipping,
            Id_ins: id_ins,
            Shipments: shipments,
            sigSessionId: sigOrderSessionId,
        };

        let request = {
            IsApprovalOrder: checkout.isApprovalOrder,
            Checkout: data,
        };

        bound.request();
        return axios
            .post('/ShoppingService/api/v1/cart/checkout', request)
            .then(bound.response)
            .catch(bound.error)
            .finally(bound.finally)
    };
}

function generateCreatePaymentRequest(token) {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'CREATE_PAYMENT_TOKEN_REQ' }),
            response: response =>
                dispatch({ type: 'CREATE_PAYMENT_TOKEN_RESP', response: response }),
            error: error =>
                dispatch({ type: 'CREATE_PAYMENT_TOKEN_ERR', response: error }),
        };

        const {
            cart: {
                checkout,
                checkout: {
                    shippingLocation,
                    shippingMethod,
                    paymentInformation,
                    totals,
                    savedCards,
                },
            },
            network: {
                tokenInfo: { userId },
            },
            user: { info },
        } = getState();
        let matchingCard = savedCards.filter(
            x => x.paymentToken === paymentInformation.paymentMethod.value
        );

        let shipments = checkout.shipments.map(x => {
            return {
                ShipCost: x.selectedShipOption.netShippingCharge,
                IsGsa: x.shippingMethod.isGsa,
                ShipAccountNo: x.shippingMethod.shipAccountNo,
                IncludeTransitCoverage: x.shippingMethod.shippingInsurance,
                UseCustomerShippingAccount: x.shippingMethod.useCustomerAccount,
                ShipOption: x.selectedShipOption,
                LineItemIds: x.lineItemIds,
            }
        });

        let data = {
            CartItems: checkout.items,
            ShippingAddress: checkout.shippingAddress,
            BillingAddress: checkout.billingAddress,
            ShipCost: checkout.selectedShipOption.netShippingCharge,
            UserId: userId,
            PoNumber: paymentInformation.poNumber,
            PaymentMethod: paymentInformation.paymentMethod.value === 'PO' ? 'PO' : 'CC',
            AttentionTo: shippingLocation.attentionTo,
            IncludePo: shippingLocation.includePo,
            ShippingAttentionDisplay: shippingLocation.shippingAttentionDisplay,
            ShipAccountNo: shippingMethod.shipAccountNo,
            UseCustomerShippingAccount: shippingMethod.useCustomerAccount,
            IncludeTransitCoverage: shippingMethod.shippingInsurance,
            Totals: totals,
            CreditCard: matchingCard.length ? matchingCard[0] : { PaymentToken: '' },
            EmailAddress: info && info.email ? info.email : '',
            ReadOnlyShipping: checkout.readOnlyShipping,
            Shipments: shipments,
        };

        let request = {
            IsApprovalOrder: checkout.isApprovalOrder,
            Checkout: data,
            Token: token,
        };

        bound.request();
        return axios
            .post('/ShoppingService/api/v1/cart/createpaymenttoken', request)
            .then(bound.response)
            .catch(bound.error);
    };
}

function getSavedCards(contactId, facilityId) {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({ type: 'GET_SAVED_CARDS_REQ' }),
            response: response =>
                dispatch({ type: 'GET_SAVED_CARDS_RESP', response: response }),
            error: error => dispatch({ type: 'GET_SAVED_CARDS_ERR', response: error }),
        };

        bound.request();
        return axios
            .get(`/ShoppingService/api/v1/cart/savedcreditcards/${contactId}/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function proceedToCheckout(lineItemIds) {
    return async (dispatch, getState) => {
        const {
            user: {facilities, settings, info},
            cart,
        } = getState();

        try {
            await dispatch({type: 'PROCEED_TO_CHECKOUT_REQ'});
            await dispatch(resetCheckout());
            const checkoutResponse = await axios.post('/ShoppingService/api/v1/cart/approval/checkout/items', lineItemIds);
            if (checkoutResponse.data) {
                let d = checkoutResponse.data;

                if (d.checkout.cartItems.length !== lineItemIds.length) {
                    return await dispatch({type: 'PROCEED_TO_CHECKOUT_ERR'});
                }

                d.checkout.shipments.forEach(s => {
                    s.selectedShipOption = s.shipOption;
                    s.shipOptions = [];
                    s.initialRateResult = [];
                    s.shippingMethod = {
                        carrier: '',
                        carrierId: s.shipOption.carrierId,
                        shipAccountNo: s.shipAccountNo,
                        useCustomerAccount: s.useCustomerShippingAccount,
                        shippingInsurance: s.includeTransitCoverage,
                        selectedPriorityId: s.shipPriorityId,
                        isGsa: s.isGsa,
                    };
                    s.complete = true;
                });
    
                const checkout = {
                    isApprovalOrder: true,
                    readOnlyShipping: !d.modify,
                    readOnlyPaymentInformation: d.checkout.readOnlyPaymentInformation,
                    items: d.checkout.cartItems,
                    facility: facilities.find(x => x.facilityId === d.facilityId),
                    canCheckout: true,
                    shippingAddress: d.checkout.shippingAddress,
                    billingAddress: d.checkout.billingAddress,
                    shippingLocation: {
                        attentionTo: d.checkout.attentionTo,
                        includePo: d.checkout.includePo,
                    },
                    shipments: d.checkout.shipments,
                    overrideSplitShipping: d.checkout.overrideSplitShipping,
                };

                if (d.checkout.poNumber) {
                    checkout.paymentInformation = {
                        poNumber: d.checkout.poNumber,
                        paymentMethod: {
                            value: 'PO',
                        },
                    }
                }
      
                let facilityId = d.facilityId;
                const [facilitiesCheckoutSettingsResponse, facilityAddressesResponse] = await Promise.all([dispatch(loadFacilityCheckoutSettings(facilityId)), dispatch(loadFacilityAddresses(facilityId))]);
                const facilitySettings = facilitiesCheckoutSettingsResponse.response.data;
                const companyAddresses = facilityAddressesResponse.response.data;
  
                cart.checkout.paymentInformation.poNumber = facilitySettings.blanketPoNumber || '';
                cart.checkout.shippingAddresses = companyAddresses.shippingAddresses;
                cart.checkout.billingAddresses = companyAddresses.billingAddresses;
  
                if ((facilitySettings.canUsePo && !facilitySettings.siteLvlCC) || facilitySettings.holdForPo || facilitySettings.submitForPo)
                    cart.checkout.paymentMethods.push({value: 'PO', text: 'Purchase Order #'});
  
                if (facilitySettings.holdForPo || facilitySettings.submitForPo) {
                    cart.checkout.paymentInformation.paymentMethod = cart.checkout.paymentMethods[0];
                }
  
                if (facilitySettings.prePaidServiceLevelId)
                    cart.checkout.shippingMethod.isGsa = true;

                if (facilitySettings.canUseCC && settings.canUseCC) {
                    if (facilitySettings.siteLvlCC) {
                        const savedCardsResponse = await dispatch(getSavedCards(info.contactId, facilityId));
                        savedCardsResponse.response.data.forEach(cc => {
                            cart.checkout.paymentMethods.push({value: cc.paymentToken, text: cc.dropDownDisplay});
                        });
    
                        // fall back to regular logic because no site lvl credit card is available
                        if (savedCardsResponse.response.data.length === 0) {
                            if (facilitySettings.canUsePo || facilitySettings.holdForPo || facilitySettings.submitForPo)
                                cart.checkout.paymentMethods.push({value: 'PO', text: 'Purchase Order #'});

                            cart.checkout.paymentMethods.push({value: 'CC', text: 'ADD NEW CREDIT CARD'});
                        }
    
                        cart.checkout.paymentInformation.paymentMethod = cart.checkout.paymentMethods[0];
                        await dispatch(saveCheckout(_.merge({}, cart.checkout, checkout)));
                        await dispatch(getOrderTotals(false));
                    } else {
                        const savedCardsResponse = await dispatch(getSavedCards(info.contactId, -1)); 
                        savedCardsResponse.response.data.forEach(cc => {
                            cart.checkout.paymentMethods.push({value: cc.paymentToken, text: cc.dropDownDisplay});
                        });

                        cart.checkout.paymentMethods.push({value: 'CC', text: 'ADD NEW CREDIT CARD'});
                        cart.checkout.paymentInformation.paymentMethod = cart.checkout.paymentMethods[0];
                        await dispatch(saveCheckout(_.merge({}, cart.checkout, checkout)));
                        await dispatch(getOrderTotals(false));
                    }
                } else {
                    cart.checkout.paymentInformation.paymentMethod = cart.checkout.paymentMethods[0];
                    await dispatch(saveCheckout(_.merge({}, cart.checkout, checkout)));
                    await dispatch(getOrderTotals(false));
                }
            }
            return await dispatch({type: 'PROCEED_TO_CHECKOUT_RESP'});
        } catch (e) {
            console.log(e);
            return await dispatch({type: 'PROCEED_TO_CHECKOUT_ERR'});
        }
    }
}

function getCarrierDescription(carrierId) {
    switch (carrierId) {
        case 0:
            return 'Not Specified';
        case 1:
            return 'FedEx';
        case 2:
            return 'UPS';
    }
}

export function reducer(state = _.cloneDeep(defaultCartState), action = null) {
    switch (action.type) {
        case 'SAVE_ITEM_REQ':
        case 'GET_CART_REQ':
        case 'GET_GUESTCART_REQ':
        case 'REMOVE_ITEM_REQ':
        case 'SET_FACILITY_REQ':
        case 'GET_BROWSING_HISTORY_REQ':
            return {
                ...state,
                isBusy: true,
            };

        case 'GET_GUESTCART_RESP':
            return {
                ...state,
                isBusy: false,
                items: [],
            };

        case 'GET_BROWSING_HISTORY_RESP':
            return { ...state, isBusy: false, itemHistory: action.response.data }

        case 'SET_BROWSING_HISTORY':
            return { ...state, itemHistory: action.products };
        case 'SAVE_FIELD_RESP':
            return loadUserLegacyCart();
        case 'REMOVE_ITEM_RESP': {
            // The server has removed the item, so now we remove from our local instance
            const { items } = state;
            const { lineItemId } = action;

            _.remove(items, function (e) {
                return e.lineItemId === lineItemId;
            });
            return updateCartState(state, items);
        }

        case 'ADD_TO_CART': {
            const { items } = state;
            const { product, facility, tokenInfo, quantity, option } = action;

            const item = {
                CartId: 0,
                OrderId: 0,
                LineItemId: 0,
                VendorResearchId: -1,

                IsChecked: true,
                IsSavedForLater: false,

                ContactId: tokenInfo.userId,

                OemName: product.AlternativeOemName,
                ImagePath: product.PartImages.length > 0 && isURL(product.PartImages[0])
                    ? product.PartImages[0]
                    : product.PartImages.length > 0
                        ? 'https://assets.partsfinder.com/image/' + product.PartImages[0]
                        : null,

                PurchaseChoice: option.PurchaseChoice === 1 ? 'Outright' : 'Exchange',
                UomCode: option.UnitOfMeasurement,
                UomQuantity: 1,

                PartNumber: product.DisplayPartNumber,
                Description: product.Description,
                Quantity: quantity,

                FacilityName: facility.FacilityName,
                FacilityId: parseInt(facility.FacilityId),
                VendorId: option.VendorId,
                Price: option.Price,

                RequestorId: parseInt(tokenInfo.userId),
                RequestorName: `${tokenInfo.firstName} ${tokenInfo.lastName}`,
                Fields: [],
            };
            items.push(item);
            return updateCartState(state, items);
        }

        case 'SAVE_ITEM_RESP': {
            const { items } = state;
            const { item } = action;

            let matchIndex = _.findIndex(items, { CartId: item.CartId });
            items.splice(matchIndex, 1);
            items.splice(matchIndex, 0, item);

            return updateCartState(state, items);
        }

        case 'SET_FACILITY_RESP': {
            const { items } = state;
            const { cartItemId } = action;

            items.forEach(p => p.IsChecked = p.cartItemId === cartItemId);
            return updateCartState(
                { ...state, selectedCartItemId: cartItemId },
                items
            );
        }

        case 'GET_CART_RESP': {
            // TODO: remove this
            // If we manually add cart items, they get blown away by the "real" cart items from the server
            // we want to preserve them
            const {
                response: { data },
            } = action;
            const { items } = state;

            // Locally added cart items do not have LineItemIds
            return updateCartState(
                state,
                _.union(data, items.filter(i => i.lineItemId === 0))
            );
        }

        case 'SAVE_CART': {
            const newState = _.merge(state, action.cart);
            return updateCartState(newState, action.cart.items);
        }

        case 'SAVE_CHECKOUT':
            return { ...state, checkout: action.checkout };

        case 'GET_FACILITY_CHECKOUT_SETTINGS_RESP': {
            const {
                response: { data },
            } = action;
            const updatedCheckout = state.checkout;
            updatedCheckout.facilitySettings = data;
            return { ...state, checkout: updatedCheckout };
        }

        case 'GET_FACILITY_ADDRESSES_RESP': {
            const {
                response: { data },
            } = action;

            const updatedCheckout = state.checkout;
            updatedCheckout.billingAddresses = data.billingAddresses;
            updatedCheckout.shippingAddresses = data.shippingAddresses;
            return { ...state, checkout: updatedCheckout };
        }

        case 'RESET_CHECKOUT': {
            let newState = state;
            newState.checkout = _.cloneDeep(defaultCartState.checkout);
            return { ...newState };
        }

        case 'GET_SHIPPING_RESP': {
            const {
                response: { data },
            } = action;
            const carrierIds = [
                ...new Set(
                    data.carrierServices
                        .filter(x => x.carrierId !== 0)
                        .map(item => item.carrierId)
                ),
            ];

            let shippingCarriers = [];

            carrierIds.forEach(function (x) {
                shippingCarriers.push({ value: x, text: getCarrierDescription(x) });
            });
            let updatedCheckout = state.checkout;
            updatedCheckout.shipOptions = data.carrierServices.filter(x => x.carrierId !== 0);
            updatedCheckout.initialRateResult = data.initialRateResult;
            updatedCheckout.shipCarriers = shippingCarriers;
            updatedCheckout.defaultShipOption = data.defaultService;
            updatedCheckout.alternateShipOptionSelected = data.alternateShipOptionSelected;
            return { ...state, checkout: updatedCheckout };
        }

        case 'GET_GROUP_SHIPPING_RESP': {
            const { response: { data: { shipments } }, preloaded } = action;
            let updatedCheckout = state.checkout;

            shipments.forEach(s => {

                let shipment = updatedCheckout.shipments.find(f => f.id === s.id);
                const carrierIds = [
                    ...new Set(
                        s.carrierServices
                            .filter(x => x.carrierId !== 0)
                            .map(item => item.carrierId)
                    ),
                ];

                let shippingCarriers = [];

                carrierIds.forEach(function (x) {
                    shippingCarriers.push({ value: x, text: getCarrierDescription(x) });
                });

                shipment.shipOptions = s.carrierServices.filter(x => x.carrierId !== 0);
                shipment.initialRateResult = s.initialRateResult;
                shipment.shipCarriers = _.sortBy(shippingCarriers, 'value').reverse();
                shipment.defaultShipOption = s.defaultService;
                shipment.alternateShipOptionSelected = s.alternateShipOptionSelected;
                shipment.complete = preloaded;
            });

            if (updatedCheckout.shipments.length > 0 && !preloaded)
                updatedCheckout.shipments[0].open = true;

            return { ...state, checkout: updatedCheckout };
        }

        case 'GET_TOTALS_RESP': {
            const {
                response: { data: { totals, shipments } },
            } = action;

            const updatedCheckout = state.checkout;
            updatedCheckout.totals = totals;

            if (updatedCheckout.shipments.length === 0) {
                updatedCheckout.shipments = shipments;

                shipments.forEach(shipment => {
                    shipment.shipOptions = [];
                    shipment.shipCarriers = [];
                    shipment.defaultShipOption = {};
                    shipment.shippingMethod = {
                        carrier: '',
                        carrierId: -1,
                        shipAccountNo: '',
                        useCustomerAccount: false,
                        shippingInsurance: updatedCheckout.shippingMethod.shippingInsurance,
                        selectedPriorityId: '',
                        isGsa: state.checkout.shippingMethod.isGsa,
                    };
                    shipment.selectedShipOption = {};
                });
            }

            return { ...state, checkout: updatedCheckout };
        }

        case 'SUBMIT_ORDER_RESP': {
            const {
                response: {data},
            } = action;
            const updatedCheckout = state.checkout;
            if (data.success) {
                updatedCheckout.paperwork = data.paperwork;
                updatedCheckout.htmlForm = data.htmlForm;
                updatedCheckout.submittedForPO = data.submittedForPO;
                updatedCheckout.submittedForApproval = data.submittedForApproval;
                updatedCheckout.submittedForPunchout = data.submittedForPunchout;
            } else {
                updatedCheckout.CartItems = data.cartItems;
            }

            return {...state, checkout: updatedCheckout};
        }

        case 'GET_SAVED_CARDS_RESP': {
            const {
                response: {data},
            } = action;
            const updatedCheckout = state.checkout;
            updatedCheckout.savedCards = data;
            return {...state, checkout: updatedCheckout};
        }

        case 'GET_GUESTCART_ERR':
        case 'GET_CART_ERR':
        case 'REMOVE_ITEM_ERR':
        case 'SAVE_ITEM_ERR':
        case 'SAVE_ITEM_LATER_ERR':
        case 'SET_FACILITY_ERR':
        case 'GET_BROWSING_HISTORY_ERR':
        case 'PROCEED_TO_CHECKOUT_ERR': {
            console.log('Cart Redux Error');
            console.log(action);
            return {
                ...state,
                isBusy: false,
            };
        }
    }
    return state;
}
