import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {TextField, Button, Checkbox, TextFieldSelect, Popup, RadioButtonList} from '@partssourceinc/react-ui-core';
import AddressDialog, {AddressFormType} from 'components/AddressDialog';
import * as CompaniesStore from 'stores/Companies';
import * as UserStore from 'stores/User';
import * as CartStore from 'stores/Cart';
import 'less/assetinformationform.less';
import classNames from 'classnames';

export const AssetFormType = {
    Required: 'Required',
    Optional: 'Optional',
    Both: 'Both',
};

@connect(state => ({user: state.user, oems: state.companies.oems, cart: state.cart}), 
    _.merge({},CompaniesStore.actionCreators, CartStore.actionCreators))
export default class AssetInformationForm extends React.Component {
  static propTypes = {
      fields: PropTypes.array,
      // formType: PropTypes.oneOf(Object.keys.AssetFormType),
      onCancel: PropTypes.func,
      onSubmit: PropTypes.func,
      readOnly: PropTypes.bool,
      showRequester: PropTypes.bool,
      requesterId: PropTypes.number,
      requesterFacilityId: PropTypes.number,
      allowMissingFields: PropTypes.bool,
      hideSaveButton: PropTypes.bool,
      hasOnSiteService: PropTypes.bool,
      hideRequiredTitle: PropTypes.bool,
      loading: PropTypes.bool,
      showErrors: PropTypes.bool,
      onFieldUpdate: PropTypes.func,
      onCheckBoxUpdate: PropTypes.func,
      priority: PropTypes.bool,
      submitDisabled: PropTypes.bool,
      autoFocus: PropTypes.bool,
      showFormulary: PropTypes.bool,
      oem: PropTypes.object,
      hadContractOptions: PropTypes.bool,
      ...CompaniesStore.ActionShape,
      ...CartStore.ActionShape,
      user: UserStore.StateShape,
      cart: CartStore.StateShape,
      showShippingFields: PropTypes.bool,
      shippingAddresses: PropTypes.object,
      preventCriticalCheckbox: PropTypes.bool,
      hasLoanerAdded: PropTypes.bool,
      invalidField: PropTypes.array,
  };

  constructor(props) {
      super(props);

      const {user: {settings}, priority, fields, hasOnSiteService, preventCriticalCheckbox, isRepairOption, option} = this.props;
      let localFields = _.cloneDeep(fields);
    
      if (hasOnSiteService) {
          localFields.filter(x => x.fieldUid === '11111111-1111-1111-1111-111111111111')
              .forEach(x => x.isRequired = true);
      }

      const hasOptionalFieldValues = localFields ?
          localFields.filter(x => !x.isRequired && x.value).length > 0 : false;
      const hasRequiredFields = localFields && localFields.filter(x => x.isRequired).length > 0;
      this.renderAssetContent = this.renderAssetContent.bind(this);
      this.renderExchangeNotification = this.renderExchangeNotification.bind(this);
      this.state = {
          fields: localFields,
          showOptionalFields: hasOptionalFieldValues || !hasRequiredFields,
          showErrors: false,
          requesterList: [],
          selectedRequester: {},
          loading: false,
          oems: [],
          models: [],          
          showStatCheckBox: settings.statOrder,
          isUrgency: priority,
          preventCriticalCheckbox,
          formularyFields: [],
          selectedCategory: {},
          selectedModel: {},
          serviceType: '',
          turnaroundTime: '',
          serviceNeeded: '',
          locationDetail: '',
          showShippingFields: false,
          shippingAddresses: {}, 
          selectedShipSpeedId: '4',
          selectedShipAddress: {},
          ShowAddressDialog: false,
          isRepairOption,
          AddressEdit: {},
          AddressFormDisplay: AddressFormType.AddEdit,
          ProceedAfterValidation: false, 
          rfqSetup: {
              serviceCategories: [],
              serviceTypes: [],
              turnaroundTimes: [],
          },
          showShipMethodDescription: false,
          loanerTermsAcknowledged: false,
          invalidField: [],
          showExchangeDetails: false,
          isExchangeTermsChecked: false,
      };
      this.onAddressSave = this.onAddressSave.bind(this);
  }

  componentWillMount() {
      const {showRequester, requesterFacilityId, requesterId, showFormulary, hasOnSiteService, showShippingFields} = this.props;

      if (showFormulary) this.loadFormularyFields();

      if (showRequester && requesterFacilityId) {
          axios.get('/ShoppingService/api/v1/company/contacts/' + requesterFacilityId).then(x => {
              const selectedRequester = x.data.filter(
                  x => x.contactId === requesterId
              )[0];
        
              this.setState({requesterList: x.data, selectedRequester, showShippingFields});
          });
      }
    
  }

  componentWillReceiveProps(props) {
      const {showRequester, requesterFacilityId, requesterId, showShippingFields} = props;

      if (props.showErrors !== undefined) {
          this.setState({showErrors: props.showErrors});
      }
      if (props.invalidField !== undefined) {
          this.setState({invalidField: props.invalidField});
      }
      if (showRequester && requesterFacilityId) {
          axios.get(`/ShoppingService/api/v1/company/contacts/${requesterFacilityId}`).then(x => {
              const selectedRequester = x.data.filter(
                  x => x.contactId === requesterId
              )[0];
              this.setState({requesterList: x.data, selectedRequester, loading: false, showShippingFields});
          });
      }
  }

  loadFormularyFields() {
      const {oem, oems, showFormulary, getOems, onFormularyFieldUpdate, psPartNumber} = this.props;
      axios.get('/ShoppingService/api/v1/companies/formulary/approval/config').then(x => {
          const fieldUids = x.data.fieldUid;
          let oemField = fieldUids.filter(f => f === 'Formulary-OEM')[0];
          let modelField = fieldUids.filter(f => f === 'Formulary-Model')[0];

          if (oemField) {
              if ((oems || []).length === 0) {
                  getOems().then(f => {
                      this.setState({oems: f.response.data.manufacturers});
                  });
              } else {
                  this.setState({oems: oems});
              }
          }

          if (oemField && modelField) this.loadModels(oemField.value);
          else if (showFormulary && oem && oem.id) this.loadModels(oem.id);

          let formularyFields = x.data.fieldUid.map(m => {
              return {fieldUid: m}
          });
          let overrideReasons = x.data.overrideReasons.map(m => {
              return {label: m, value: m}
          });

          if (oem) {
              let oemField = formularyFields.filter(x => x.fieldUid === 'Formulary-OEM')[0];
              if (oemField) {
                  oemField.id = oem.id;
                  oemField.value = oem.name;
                  oemField.prompt = 'OEM';
              }
          }

          formularyFields.push({fieldUid: 'Formulary-Override', value: overrideReasons[0].value, prompt: overrideReasons[0].label});
          formularyFields.push({fieldUid: 'Formulary-Reason'});

          this.setState({formularyFields, overrideReasons});
          if (onFormularyFieldUpdate) onFormularyFieldUpdate(formularyFields, psPartNumber);
      });
  }

  componentDidMount() {
      if (this.props.hasOnSiteService) {
          this.loadData();
          this.loadModels(this.props.oem.id)
      } 
  }
    
  updateState(event, data) {
      let {onFieldUpdate} = this.props;
      let {fields} = this.state;
      fields.filter(x => x.fieldUid === data.id)[0].value = data.value;
      this.setState({fields});
      if (onFieldUpdate) onFieldUpdate(fields);
  }

  updateUrgencyId(elmt) {
      this.setState({isUrgency: elmt.checked});
      let {onCheckBoxUpdate} = this.props;
      if (onCheckBoxUpdate) onCheckBoxUpdate(elmt);
  }
  
  onUpdateFormularyReason(e, data) {
      let {formularyFields} = this.state;
      let field = formularyFields.find(x => x.fieldUid === data.id);
      field.value = data.value;
      field.prompt = 'Override Reason Details';
      this.setState({formularyFields});
      const {onFormularyFieldUpdate, psPartNumber} = this.props;
      if (onFormularyFieldUpdate) onFormularyFieldUpdate(formularyFields, psPartNumber);
  }

  onUpdateOverrideReason(e, data) {
      let {formularyFields} = this.state;
      let field = formularyFields.find(x => x.fieldUid === 'Formulary-Override');
      field.value = data.value;
      field.prompt = 'Override Request Reason';
      this.setState({formularyFields});
      const {onFormularyFieldUpdate, psPartNumber} = this.props;
      if (onFormularyFieldUpdate) onFormularyFieldUpdate(formularyFields, psPartNumber);
  }

  onSelectOem(data) {
      let {formularyFields} = this.state;
      let field = formularyFields.find(x => x.fieldUid === 'Formulary-OEM');
      field.value = data.name;
      field.id = data.id;
      field.prompt = 'OEM';
      this.setState({formularyFields});
      const {onFormularyFieldUpdate, psPartNumber} = this.props;
      if (onFormularyFieldUpdate) onFormularyFieldUpdate(formularyFields, psPartNumber);
      this.loadModels(data.id);
  }

  loadModels(oemId) {
      axios.get(`CatalogService/api/v1/${oemId}/models`).then(x => {
          this.setState({models: x.data});
      });
  }

  onSelectModel(data) {
      let {formularyFields} = this.state;
      let field = formularyFields.find(x => x.fieldUid === 'Formulary-Model');
      field.value = data.model;
      field.id = data.modelId;
      field.prompt = 'Model';
      this.setState({formularyFields});
      const {onFormularyFieldUpdate, psPartNumber} = this.props;
      if (onFormularyFieldUpdate) onFormularyFieldUpdate(formularyFields, psPartNumber);
  }

  getFields() {
      const {fields} = this.state;
      return fields;
  }

  loadData() {
      axios.get(`/ShoppingService/api/v1/quotes/ods`).then(x => {
          this.setState(
              {rfqSetup: x.data});
      });
  }

  submitFields() {

      // this.setState({showErrors: true});

      let {fields, selectedRequester, isUrgency, formularyFields, turnaroundTime, locationDetail,
          serviceNeeded, selectedCategory, serviceType, selectedModel, selectedShipSpeedId, selectedShipAddress, loanerTermsAcknowledged, invalidField} = this.state;
      const {allowMissingFields, formType, onSubmit, hasLoanerAdded, showShippingFields, user: {settings}} = this.props;
      let hasInvalidFormat = false;
      let invalidFormatField = [];
      if (fields) {
          fields.forEach(f => {
              if (f.formatRegex) {
                  let rg = new RegExp(`^${f.formatRegex}$`, '');
                  if (!rg.test(f.value)) {
                      hasInvalidFormat = true;
                      invalidFormatField.push(f);
                      return;
                  }
              }
          })
      }
      if (hasInvalidFormat) {
          this.setState({showErrors: true, invalidField: invalidFormatField});
          return;
      }
      let onSiteService = null;
    
      let shippingFields = {};
      if (showShippingFields) {
          if (!selectedShipAddress) {
              this.setState({showErrors: true});
              return;
          }    
          if (!selectedShipAddress.isValidated && settings.canAddAddress) {
              this.setState({
                  ShowAddressDialog: true,
                  AddressFormDisplay: AddressFormType.Validate,
                  AddressEdit: selectedShipAddress,
                  ProceedAfterValidation: true});
              return;
          }
          shippingFields = {selectedShipSpeedId: selectedShipSpeedId,selectedShipAddress: selectedShipAddress};
      }
      const hasMissingFields = fields.filter(x => !x.value && x.isRequired).length > 0;

      // const hasMissingFormularyFields = formularyFields.filter(x => x.fieldUid === 'Formulary-OEM' && x.fieldUid !== 'Formulary-Model' && !x.value).length > 0;

      this.addRepairNote();

      if (!loanerTermsAcknowledged && hasLoanerAdded) {
          this.setState({showErrors: true});
          alert('Please accept the Terms and Conditons');
          return;
      }
      
      if ((hasMissingFields) && !allowMissingFields)
          this.setState({showErrors: true});
      else {
          if (formularyFields.length > 0) {
              let oemField = formularyFields.find(x => x.fieldUid === 'Formulary-OEM') || {};
              let modelField = formularyFields.find(x => x.fieldUid === 'Formulary-Model') || {};
              let overrideField = formularyFields.find(x => x.fieldUid === 'Formulary-Override') || {};

              overrideField.prompt = 'Override Request Reason';

              if (!oemField.value) {
                  oemField.value = '(OEM NOT LISTED)';
                  oemField.prompt = 'OEM';
              }

              if (!modelField.value) {
                  modelField.value = '(MODEL NOT LISTED)'
                  modelField.prompt = 'Model';
              }

              formularyFields = formularyFields.filter(x => x.value);
          }
          this.setState({loading: true});  
          
          onSubmit(
              fields,
              formType,
              selectedRequester,
              isUrgency,
              formularyFields,
              onSiteService,
              shippingFields,
              loanerTermsAcknowledged,
            
          );      
      }
  }

  onAddressSave(address, isNew) {
      const {loadFacilityAddresses, cart: {selectedCartItemId}} = this.props;
      let {shippingAddresses,selectedShipAddress} = this.state;
      if (isNew) {
          loadFacilityAddresses(selectedCartItemId).then(x => {
              this.setState({ShowAddressDialog: false,shippingAddresses: x.response.data.shippingAddresses,selectedShipAddress: address});
          });
      } else {
          this.setState({ShowAddressDialog: false, selectedShipAddress: address});
      }
  }

  renderField(f, i) {
      const {showErrors, invalidField, fields} = this.state;
      const {autoFocus, submitDisabled} = this.props;
      const showRequiredFieldWarning = showErrors && f.isRequired && (!f.value || f.value === '');
      const showInvalidFieldWarning = (showErrors && invalidField.length > 0 && invalidField.find(i => i.fieldUid === f.fieldUid));

      const _maxLength =
          f.fieldUid === '11111111-1111-1111-1111-111111111111' ||
          f.fieldUid === '22222222-2222-2222-2222-222222222222' ||
          f.fieldUid === '33333333-3333-3333-3333-333333333333' ||
          f.fieldUid === '44444444-4444-4444-4444-444444444444' ? 32 : 150;
      if (f.prompt.length > 35 && f.fieldType === 1)    
          f.fieldType = 2;
      let formattedField = fields && fields.find(a => a.fieldUid === f.fieldUid && a.formatRegex !== '') ? 
          fields.filter(u => u.fieldUid === f.fieldUid && u.formatRegex !== '')
          : sessionStorage.userFields ? 
              JSON.parse(sessionStorage.userFields).filter(u =>         
                  u.fieldUid === f.fieldUid && u.formatRegex !== '') : null;
      
      if (formattedField && formattedField.length > 0 && !f.formatRegex) {
          f.placeholder = formattedField[0].placeholder;
          f.formatRegex = formattedField[0].formatRegex;
          f.errorMsg = formattedField[0].errorMsg;
      } else {
          f.placeholder = f.placeholder || 'Enter ' + f.prompt;
          f.formatRegex = f.formatRegex || '';
          f.errorMsg = f.errorMsg || '';
      }
      return !f.fieldType || f.fieldType === 1 ? 
          <TextField
              className="txbFields"
              text={f.value}
              autoFocus = {i === 0 && autoFocus }
              id={`${f.fieldUid}`}
              key={`${f.fieldUid}_${i}`}
              tabIndex={0}
              label={f.prompt}
              placeholder={f.placeholder}
              pattern = {f.formatRegex}
              errorMessage={f.errorMsg}
              maxLength={_maxLength}
              showErrorMessage={showRequiredFieldWarning || showInvalidFieldWarning}
              onChange={::this.updateState}
              disabled={submitDisabled}
          />
          : 
          <TextField
              className="txaFields"
              text={f.value}
              autoFocus = {i === 0 && autoFocus }
              id={`${f.fieldUid}`}
              key={`${f.fieldUid}_${i}`}
              multiLine={true}
              tabIndex={0}
              maxLength={_maxLength}
              label={f.prompt}
              placeholder={``}
              errorMessage=""
              showErrorMessage={showRequiredFieldWarning}
              onChange={::this.updateState}
              disabled={submitDisabled}
          />
      ;
  }

  renderReadOnlyField(f, i) {
      const {showErrors} = this.state;
      const {autoFocus, submitDisabled} = this.props;
      const showRequiredFieldWarning =
      showErrors && f.isRequired && (!f.value || f.value === '');
      return f.fieldType === 1 ? 
          <TextField
              className="txbFields"
              text={f.value}
              id={`${f.fieldUid}`}
              key={`${f.fieldUid}_${i}`}
              tabIndex={0}
              autoFocus = {i === 0 && autoFocus }
              label={f.prompt}
              readOnly={true}
              placeholder={`Enter ${f.prompt}`}
              errorMessage=""
              showErrorMessage={showRequiredFieldWarning}
              onChange={::this.updateState}
              disabled={submitDisabled}
          />
          : 
          <TextField
              className="txbFields"
              text={f.value}
              id={`${f.fieldUid}`}
              key={`${f.fieldUid}_${i}`}
              autoFocus = {i === 0 && autoFocus }
              multiLine={true}
              readOnly={true}
              tabIndex={0}
              label={f.prompt}
              placeholder={`Enter ${f.prompt}`}
              errorMessage=""
              showErrorMessage={showRequiredFieldWarning}
              onChange={::this.updateState}
              disabled={submitDisabled}
          />
      ;
  }

  renderSection(fields, type, showRequester) {
      const {requesterList, selectedRequester, showStatCheckBox, isUrgency, showErrors, preventCriticalCheckbox, isRepairOption} = this.state;
      const {formType, readOnly, submitDisabled, showRequester: defaultShowRequester} = this.props;
      if (submitDisabled) 
          fields = fields.filter(f => f.value && f.value !== undefined && f.value.trim() !== '');
      
          const renderFields = fields.map((f, i) =>
          readOnly ? this.renderReadOnlyField(f, i) : this.renderField(f, i)
      );
    
      return (
          <div className="asset-text-field">
              {(type == 'r' && (showRequester || defaultShowRequester) || type == 'o' && showRequester) ? 
                  <TextFieldSelect
                      keyField="contactId"
                      valueField="fullName"
                      onSelect={contact => this.setState({selectedRequester: contact})}
                      listItems={requesterList}
                      label="Requester"
                      placeHolder="Requester"
                      selectedItem={selectedRequester}
                      disableClear={true}
                      submitDisabled={submitDisabled}
                  />
                  : null}
              {!isRepairOption && (showStatCheckBox && formType !== AssetFormType.Both && !preventCriticalCheckbox) ? <Checkbox label="Critical Hard Down" name="urgencyId" checked={isUrgency} onChange={:: this.updateUrgencyId} disabled={submitDisabled} /> : null}
              {renderFields}
          </div>
      );
  }

  renderLoanerCheckbox = (id, hasLoanerAdded) => { 
      const {loanerTermsAcknowledged} = this.state;

      const onBoxChecked = (e) => {
          this.setState({loanerTermsAcknowledged: e.checked});
          let {onCheckBoxUpdate} = this.props;
      }

      if (hasLoanerAdded) {
          return (
              <Checkbox
                  id = {id}
                  label = {'<div>Loaner units are subject to availability.  Loaner units returned beyond contract date are subject to additional fees.  I have read and agree to PartsSource’s <a href=https://www.partssource.com/policies/termsandconditionofsale>Terms and Conditions</a><a>.</a></div>'}
                  checked = {loanerTermsAcknowledged}
                  onChange = {onBoxChecked}
                  className = "checkbox" 
              />
          );
      }
  }

  addRepairNote = () => {
      const {fields} = this.state; 
      const {condition} = this.props;  

      const repairTypes = [
          'Aftermarket Quoted Repair',
          'OEM Quoted Repair',
      ]
    
      if (repairTypes.includes(condition)) { 
          let loanerNote = fields.filter(x => x.fieldUid === '99999999-9999-9999-9999-999999999999');
          if (!loanerNote[0].value.includes('A quote for'))
              loanerNote[0].value += ` A quote for ${condition} was requested`;  
      }   
  }

  renderFormularyFields() {
      const {showErrors, models, formularyFields, overrideReasons, loading} = this.state;
      const {oems, submitDisabled, hadContractOptions} = this.props;

      let oemField = formularyFields.filter(x => x.fieldUid === 'Formulary-OEM')[0];
      let modelField = formularyFields.filter(x => x.fieldUid === 'Formulary-Model')[0];
      let overrideField = formularyFields.filter(x => x.fieldUid === 'Formulary-Override')[0];
      let reasonField = formularyFields.filter(x => x.fieldUid === 'Formulary-Reason')[0];

      // let selectedOm = oemField ? oems.filter(x=>x.id === oemField.value)[0] : {};
      let selectedModel = modelField ? models.filter(x => x.modelId === modelField.id)[0] : {};

      return (
          <div style={{maxWidth: '100%'}}>
              {oemField ?
                  <div style={{width: '280px'}}>
                      <TextFieldSelect
                          id={oemField.fieldUid}
                          keyField="id"
                          valueField="name"
                          listItems={oems}
                          label="OEM"
                          placeHolder="OEM"
                          onSelect={::this.onSelectOem}
                          required={true} disableClear={false} tabIndex={0}
                          selectedItem={oemField.value ? oems.find(x => x.id === oemField.id) : {id: 26799, name: '(OEM NOT LISTED)'}} />
                      {oemField.value && !loading ?
                          <TextFieldSelect keyField="modelId" valueField="model" selectedItem={selectedModel ? selectedModel : {modelId: -1, model: '(MODEL NOT LISTED)'}} onSelect={::this.onSelectModel} listItems={models} label="Model" placeHolder="Model" /> : null}
                  </div> : null}
              {reasonField && <div>
                  <div style={{marginBottom: '15px', marginTop: '15px'}}>
              Based on your { hadContractOptions ? <React.Fragment>contract</React.Fragment> : <React.Fragment>formulary</React.Fragment> } settings, this is a non-preferred choice.
            Please select a reason for selecting this purchase condition type.
                  </div>
                  <RadioButtonList name="overrideReasons" tabIndex="0" selectedValue={overrideField.value} options={overrideReasons} onChange={::this.onUpdateOverrideReason} style={{marginTop: 0, marginBottom: 0}} />
                  <TextField
                      className="txbMultiField"
                      text={reasonField.value}
                      id={reasonField.fieldUid}
                      key={reasonField.fieldUid}
                      multiLine={true}
                      tabIndex={0}
                      label="Please Explain (optional)"
                      placeholder="Please Explain (optional)"
                      errorMessage=""
                      showErrorMessage={showErrors && !reasonField.value}
                      onChange={::this.onUpdateFormularyReason}
                      disabled={submitDisabled}
                  /></div>}
          </div>
      );
  }

  closePopup() {
      const {onCancel} = this.props;
      onCancel();
  }

  onModelChange(model) {
      this.setState({selectedModel: model});
  }

  updateStatePart(event, data) {
      this.setState({[data.id]: data.value});
  }

  renderAssetContent() {
      const {fields, showOptionalFields, showStatCheckBox, models, showErrors, serviceType,turnaroundTime, locationDetail, preventCriticalCheckbox,
          selectedCategory, serviceNeeded, selectedModel, selectedShipAddress,ShowAddressDialog,
          AddressEdit, AddressFormDisplay, showShipMethodDescription, rfqSetup: {serviceTypes,serviceCategories,turnaroundTimes}, isRepairOption} = this.state;
      const {showFormulary,hasOnSiteService, hideTitle, hideRequiredTitle, submitDisabled,
          showShippingFields, shippingAddresses, hasLoanerAdded, user: {settings}, cart: {selectedCartItemId}} = this.props;
      const reqFields = fields && fields.filter && fields.filter(x => x.isRequired && !x.formularyField);
      const optFields = (!submitDisabled) ? fields && fields.filter && fields.filter(x => !x.isRequired) :
          fields && fields.filter && fields.filter(x => !x.isRequired && x.value && x.value.trim() !== '');
      const hasRequiredFields = reqFields && reqFields.length > 0;
      let shipSpeeds = [
          {id: '1', name: 'Ground'},
          {id: '2', name: '5+'},
          {id: '3', name: '3 Day'},
          {id: '4', name: '2 Day'},
          {id: '5', name: 'Overnight'},
      ];
      let selectedShipSpeed = shipSpeeds[0];
      let shipMethodDescription = "We'll do our best to quote based on your selected shipping, and we'll provide the closest alternative when the same is not available. All available shipping options for the order will be available in the cart when you checkout should you prefer a different method then.";
      return (
          <div className="asset-information-dialog">
              {!hideTitle && <h1 className="title">Asset Information</h1>}
              {hasRequiredFields || showFormulary ? (
                  <div style={{marginTop: '20px'}}>
                      {!hideRequiredTitle ? <h3 style={{fontWeight: '400'}}>Required</h3> : null}
                      {hasRequiredFields ? this.renderSection(reqFields, 'r') : null}
                      {showFormulary ? this.renderFormularyFields() : null}
                  </div>
              ) : null}

              {hasLoanerAdded ? (
                  <div className="loaner-terms-section">
                      {this.renderLoanerCheckbox('loanerTermsAcknowledged', hasLoanerAdded)}
                  </div>
              ) : null}
              {optFields && optFields.length > 0 ? (
                  <div style={{marginTop: '20px'}}>
                      <h3 style={{display: 'inline-block', fontWeight: '400'}}>
                Optional
                      </h3>
                      {!showOptionalFields ? (
                          <span
                              onClick={() => this.setState({showOptionalFields: true})}
                              style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  color: '#949494',
                                  marginLeft: '5px',
                              }}
                          >
                  Add/Edit
                          </span>
                      ) : (
                          this.renderSection(optFields, 'o', false)
                      )}
                  </div>
              ) : null}
              {!isRepairOption && showStatCheckBox && !preventCriticalCheckbox ? this.renderStatCheckBox() : null}       
              {showShippingFields ? <div style={{width: '320px'}}>
                  <h3 style={{fontWeight: '400',marginTop: '15px'}}>Shipping Location</h3>
                  <TextFieldSelect
                      id="shippingAddresses"
                      keyField="addressId"
                      valueField="description"
                      listItems={shippingAddresses}
                      label="Shipping Address"
                      placeHolder="Select an address"
                      className="tfs-request-quote"
                      onSelect={(sa) => this.setState({selectedShipAddress: sa})}
                      showErrorMessage={showErrors && !selectedShipAddress.addressId}
                      selectedItem={selectedShipAddress}
                      required={true} disableClear={true} tabIndex={0} />
                  {settings.canAddAddress ? <div style={{marginTop: '15px'}}>
                      <h3 style={{cursor: 'pointer',fontWeight: '400', textDecoration: 'underline'}}
                          onClick={() => this.setState({ShowAddressDialog: true, AddressEdit: {country: 'US', addressTypeId: 6}, AddressFormDisplay: AddressFormType.AddEdit})}>+ Add Shipping Address</h3></div> : null}
                  {ShowAddressDialog ? <AddressDialog onSave={this.onAddressSave} controlInline={true} onCancel={() => this.setState({ShowAddressDialog: false})} address={AddressEdit} addressFormType={AddressFormDisplay} facilityId={selectedCartItemId} /> : null}
                  <h3 style={{fontWeight: '400',marginTop: '15px'}}>Shipping Method <i id="ttConditionDescription" style={{cursor: 'pointer'}} onMouseOver={() => this.setState({showShipMethodDescription: true})} 
                      onMouseOut={() => this.setState({showShipMethodDescription: false})} className="fa fa-question-circle product_tooltip">
                      {showShipMethodDescription ? <span>{shipMethodDescription}</span> : null}   
                  </i></h3>
                  <TextFieldSelect
                      id="shippingSpeed"
                      keyField="id"
                      valueField="name"
                      listItems={shipSpeeds}
                      alwaysShowKey={true}
                      label="Shipping Method"
                      placeHolder="Shipping Method"
                      className="tfs-request-quote"
                      onSelect={(ss) => this.setState({selectedShipSpeedId: ss.id})}
                      selectedItem={selectedShipSpeed}
                      required={true} disableClear={true} tabIndex={0} /></div> : null}       
          </div>
      );
  }

  renderExchangeNotification() {
      const {showExchangeDetails, isExchangeTermsChecked} = this.state;
      const {isExchangeTermsRequired, onExchangeTermsAccepted} = this.props;

      if (!isExchangeTermsRequired) return null;

      return (
          <>
              <h1 className="title">Exchange Item for Purchase</h1>
              <div className="exchange-notification">
                  <div className="image-column">
                      <img src="/images/exchange.svg" alt="Exchange Item" />
                  </div>
                  <div className="information-column">
                      <div className="info-header">Exchange Program Agreement</div>
                      <p>By checking &quot;Yes, I agree&quot; you are agreeing to the exchange program <a href="/policies/termsandconditionofsale" target="_blank">Terms and Conditions</a>.</p>
                      {showExchangeDetails &&
                        <>
                            <p>Return of a non-repairable core will result in outright refurbished price listed, Non-repairablecore will be disposed not returned.</p>
                            <p>Goods sold on an &quot;Exchange&quot; basis require that Buyers return a like, repairable item withing 15 days (or the sellers specified time frame) in order to receive the price quoted.</p>
                            <p className="bold">Non-compliance will result in additional core charges.</p>
                        </>  
                      }
                      <div className={classNames('info-toggle', {open: showExchangeDetails})} onClick={() => this.setState({showExchangeDetails: !showExchangeDetails})}>
                          {showExchangeDetails ? 'See less details' : 'See more details'}
                      </div>
                      <div className={classNames('exchange-checkbox-wrapper', {selected: isExchangeTermsChecked})}>
                          <Checkbox className="exchange-checkbox" label="Yes, I Agree" name="exchangeTOC" checked={isExchangeTermsChecked} onChange={() => {
                              onExchangeTermsAccepted(!isExchangeTermsChecked);
                              this.setState({isExchangeTermsChecked: !isExchangeTermsChecked});
                          } 
                          } />
                      </div>
                  </div>
              </div>
          </>
        
      )
  }

  renderStatCheckBox() {
      const {isUrgency} = this.state;
      const {submitDisabled, hasOnSiteService} = this.props;
      return (<Checkbox label="Critical Hard Down" name="urgencyId" checked={isUrgency} onChange={:: this.updateUrgencyId} disabled = { submitDisabled } />);
  }
  
  renderGroupedFields() {
      const {loading, isExchangeTermsChecked} = this.state;
      const {readOnly, displayInline, backToInfoCenter, hideTitle, isExchangeTermsRequired, hasOnSiteService} = this.props;

      let confirmationText = 'Save';
      if (hasOnSiteService) {
          confirmationText = 'REQUEST QUOTE & ADD TO CART';
      } else if (isExchangeTermsRequired)
          confirmationText = 'PROCEED WITH EXCHANGE';

      if (displayInline) {
          return (<div className="asset-information-form">
              {!hideTitle && <div className="goBack" onClick={(event) => backToInfoCenter(event, null, null)}>
                  <span className="counterSink">⌵</span>
                  <span className="backToInfoCenter">Back to Info Center</span>
              </div>}
              {this.renderAssetContent()}
          </div>);
      } else {
          return (
              <Popup
                  confirmText={confirmationText}
                  cancelText="Cancel"
                  show={true}
                  onConfirm={::this.submitFields}
                  onCancel={::this.closePopup}
                  className={isExchangeTermsRequired ? 'asset-information-dialog exchange-note' : 'asset-information-dialog'}
                  hideButtons={readOnly}
                  loading={loading}
                  disableConfirm={isExchangeTermsRequired && !isExchangeTermsChecked}
              >
                  {this.renderExchangeNotification()}
                  {this.renderAssetContent()}
              </Popup>
          );
      }
  }

  render() {
      const {formType, hideSaveButton, submitDisabled} = this.props;
      const {fields, loading} = this.state;
      let type = (formType === AssetFormType.Required) ? 'r' : 'o';

      return (
          <div>
              { formType === AssetFormType.Both
                  ? this.renderGroupedFields()
                  : this.renderSection(fields, type)}
              {hideSaveButton ? null : 
                  <Button disabled={!fields || fields.some(x => x.isRequired && (!x.value || !x.value.trim())) || submitDisabled} style={{'margin-top': '15px'}} secondary={true} onClick={::this.submitFields} loading={loading} tabIndex={0}>
                    Save
                  </Button>
              }
          </div>
      );
  }
}
