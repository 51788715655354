import axios from 'axios';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import * as SignalR from '@aspnet/signalr';
import Cookies from 'js-cookie';
import {objectToPropTypes} from 'utility';

const REFRESH = 'REFRESH';
const TOGGLE_AUTO_REFRESH = 'TOGGLE_AUTO_REFRESH';
const SET_SHOW_UPDATES_PANEL = 'SET_SHOW_UPDATES_PANEL';
const SET_SELECTED_MODALITIES = 'SET_SELECTED_MODALITIES';
const SET_SELECTED_CATEGORIES = 'SET_SELECTED_CATEGORIES';
const SET_SELECTED_FACILITIES = 'SET_SELECTED_FACILITIES';
const SET_SELECTED_REQUESTERS = 'SET_SELECTED_REQUESTERS';
const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
const SET_CARD_SELECTED = 'SET_CARD_SELECTED';
const SET_SELECTED_LINE_ITEMS = 'SET_SELECTED_LINE_ITEMS';
const REMOVE_LINE_ITEM = 'REMOVE_LINE_ITEM';
const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';
const SET_GRID_SETTINGS = 'SET_GRID_SETTINGS';
const SET_UPDATESPANEL_SETTINGS = 'SET_UPDATESPANEL_SETTINGS';

const defaultStats = {
    est_outstanding_exchanges: '$0.00',
    est_outstanding_returns: '$0.00',
    combined_quote_total: 0,
    combined_quote_total_data: [],
    new_quotes: 0,
    new_quotes_data: [],
    expiring_quotes: 0,
    expiring_quotes_data: [],
    total_quotes: 0,
    total_quotes_data: [],
    awaiting_quote: 0,
    awaiting_quote_data: [],
    approval_past_cutoff: 0,
    approval_past_cutoff_data: [],
    approval_overnight: 0,
    approval_overnight_data: [],
    approval_priority: 0,
    approval_priority_data: [],
    approval: 0,
    approval_data: [],
    approval_close_to_cutoff: 0,
    approval_close_to_cutoff_data: [],
    approval_critical_down: 0,
    approval_critical_down_data: [],
    awaiting_po_past_cutoff: 0,
    awaiting_po_past_cutoff_data: [],
    awaiting_close_to_cutoff: 0,
    awaiting_close_to_cutoff_data: [],
    awaiting_po_hard_down: 0,
    awaiting_po_hard_down_data: [],
    awaiting_po: 0,
    awaiting_po_data: [],
    orders: 0,
    orders_data: [],
    orders_shipped: 0,
    orders_shipped_data: [],
    orders_delivered: 0,
    orders_delivered_data: [],
    orders_awaiting_approval: 0,
    orders_awaiting_approval_data: [],
    orders_awaiting_po: 0,
    orders_awaiting_po_data: [],
    orders_ordered: 0,
    orders_ordered_data: [],
    repairs: 0,
    repairs_data: [],
    repairs_initiated: 0,
    repairs_initiated_data: [],
    repairs_shipped_outbound: 0,
    repairs_shipped_outbound_data: [],
    repairs_evaluating: 0,
    repairs_evaluating_data: [],
    repairs_quoted: 0,
    repairs_quoted_data: [],
    repairs_repairing: 0,
    repairs_repairing_data: [],
    repairs_shipped_inbound: 0,
    repairs_shipped_inbound_data: [],
    repairs_delivered: 0,
    repairs_delivered_data: [],
    exchanges_and_returns: 0,
    exchanges_and_returns_data: [],
    exchanges_and_returns_open_returns: 0,
    exchanges_and_returns_open_returns_data: [],
    exchanges_and_returns_returns_past_due: 0,
    exchanges_and_returns_returns_past_due_data: [],
    exchanges_and_returns_open_exchanges: 0,
    exchanges_and_returns_open_exchanges_data: [],
    exchanges_and_returns_exchanges_past_due: 0,
    exchanges_and_returns_exchanges_past_due_data: [],
    awaiting_approval_seven_days: 0,
    awaiting_approval_thirty_days: 0,
    awaiting_approval_total: 0,
    awaiting_po_seven_days: 0,
    awaiting_po_thirty_days: 0,
    awaiting_po_total: 0,
};

const defaultState = {
    isBusy: false,
    taskItems: [],
    myAccountFilter: {
        lineItemIds: [],
    },
    gridSettings: {
        page: 1,
        pageSize: 25,
        sort: 'lineitemid',
        sortDir: 'desc',
        searchTerm: '',
    },
    notificationConnection: null,
    autoRefresh: true,
    showUpdatesPanel: false,
    updatedStats: defaultStats,
    updatedCalendarView: {},
    updatesPanel: [],
    updatedUpdatesPanel: [],
    stats: defaultStats,
    calendarView: {},
    selectedModalities: {'-1': 'All Modalities'},
    selectedCategories: {'-1': 'All Categories'},
    selectedFacilities: {'-1': 'All Facilities'},
    selectedRequesters: {'-1': 'All Requesters'},
    selectedCategory: '',
    selectedDate: null,
    selectedLineItems: [],
    removedLineItems: [],
    cardSelected: false,
    activeSection: null,
    updatesPanelFilters: {
        shippingUpdates: true,
        customerActionNeeded: true,
        backorderUpdate: true,
        serviceUpdate: true,
        timeframeToday: true,
        timeframeYesterday: true,
        timeframeTwoDaysAgo: true,
    },
};

export const StateShape = objectToPropTypes(defaultState);

export const actionCreators = {
    getTaskBarItems,
    saveMyAccountFilter,
    resetMyAccountFilter,
    startNotifications,
    stopNotifications,
    updateStats,
    updateCalendarView,
    toggleAutoRefresh,
    refresh,
    setSelectedModalities,
    setSelectedCategories,
    setSelectedFacilities,
    setSelectedRequesters,
    setSelectedCategory,
    setSelectedDate,
    setCardSelected,
    setSelectedLineItems,
    removeLineItem,
    setActiveSection,
    setGridSettings,
    setUpdatesPanelFilters,
    setShowUpdatesPanel
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

export function getTaskBarItems() {
    return (dispatch, getState) => {
        const taskList = [];
        const {dashboard: {stats: {
            combined_quote_total,
            approval,
            exchanges_and_returns_open_returns,
            exchanges_and_returns_open_exchanges,
            repairs_initiated,
            awaiting_po,
            orders_shipped_data: [nextDelivery],
            orders_delivered,
            repairs_evaluating, // TODO: Is this correct?
        }}} = getState();

        if (combined_quote_total > 0) {
            taskList.push({
                Rank: 1,
                Url: '/quotes?tab=quoted',
                Caption: 'Quotes for Review',
                Display: `${combined_quote_total} ${total_quotes === 1 ? 'Quote' : 'Quotes'}`,
            });
        }

        if (approval > 0) {
            taskList.push({
                Rank: 2,
                Url: '/approvals',
                Caption: 'Approvals for Review',
                Display: `${approval} ${approval === 1 ? 'Order' : 'Orders'}`,
            });
        }

        if (exchanges_and_returns_open_returns > 0) {
            taskList.push({
                Rank: 3,
                Url: '/orders/rga?tab=returns',
                Caption: 'Returns to be Sent',
                Display: `${exchanges_and_returns_open_returns} ${exchanges_and_returns_open_returns === 1 ? 'Return' : 'Returns'}`,
            });
        }

        if (exchanges_and_returns_open_exchanges > 0) {
            taskList.push({
                Rank: 4,
                Url: '/orders/rga?tab=exchanges',
                Caption: 'Exchanges to be Sent',
                Display: `${exchanges_and_returns_open_exchanges} ${exchanges_and_returns_open_exchanges === 1 ? 'Exchange' : 'Exchanges'}`,
            });
        }

        if (repairs_initiated > 0) {
            taskList.push({
                Rank: 5,
                Url: '/repairs',
                Caption: 'Repairs to be Sent',
                Display: `${repairs_initiated} ${repairs_initiated === 1 ? 'Repair' : 'Repairs'}`,
            });
        }

        if (awaiting_po >= 0) {
            taskList.push({
                Rank: 6,
                Url: '/orders/po',
                Caption: 'Awaiting PO #',
                Display: `${awaiting_po} ${awaiting_po === 1 ? 'Order' : 'Orders'}`,
            });
        }

        if (nextDelivery != null) {
            taskList.push({
                Rank: 7,
                Url: '/orders?tab=shipped',
                Caption: 'Next Delivery',
                Display: `${nextDelivery}`, // TODO: Format this
            });
        }

        if (orders_delivered > 0) {
            taskList.push({
                Rank: 8,
                Url: '/orders?tab=delivered',
                Caption: 'Recently Delivered',
                Display: `${orders_delivered} ${orders_delivered === 1 ? 'Order' : 'Orders'}`,
            });
        }

        if (repairs_evaluating > 0) {
            taskList.push({
                Rank: 11,
                Url: '/repairs',
                Caption: 'Repair Items Received',
                Display: `${repairs_evaluating} ${repairs_evaluating === 1 ? 'Item' : 'Items'}`,
            });
        }

        dispatch({
            type: 'GET_TASK_ITEMS',
            taskList,
        });
    };
}

export function saveMyAccountFilter(filter) {
    return (dispatch, getState) => {
        let myAccountFilter = _.merge(getState().myAccountFilter, filter);
        myAccountFilter.lineItemIds = filter.lineItemIds;

        dispatch({type: 'SAVE_MY_ACCOUNT_FILTER', myAccountFilter: myAccountFilter});
    };
}

export function resetMyAccountFilter() {
    return (dispatch) => {
        let myAccountFilter = _.cloneDeep(defaultState).myAccountFilter;
        dispatch({type: 'SAVE_MY_ACCOUNT_FILTER', myAccountFilter: myAccountFilter});
    };
}

export function updateStats(stats) {
    return (dispatch) => {
        dispatch({type: 'UPDATE_STATS', stats});
    }
}

export function updateCalendarView(calendarView) {
    return (dispatch) => {
        dispatch({type: 'UPDATE_CALENDAR_VIEW', calendarView});
    }
}

export function refresh() {
    return (dispatch) => {
        dispatch({type: REFRESH});
    }
}

export function toggleAutoRefresh() {
    return (dispatch, getState) => {
        const {autoRefresh} = getState();
        
        if (!autoRefresh) dispatch(refresh());
        dispatch({type: TOGGLE_AUTO_REFRESH});
    }
}

export function setShowUpdatesPanel(value) {
    return (dispatch) => {
        dispatch({type: SET_SHOW_UPDATES_PANEL, value});
    }
}

export function startNotifications() {
    return (dispatch, getState) => {
        const connection = new SignalR.HubConnectionBuilder().withUrl(process.env.REACT_APP_HERMES_API + '/NotificationsService/hubs/notification', {
            accessTokenFactory: () => {
                // The token is already stored with 'Bearer: ' for axios so we only want the token
                // since SignalR automatically adds 'Bearer: '
                const token = Cookies.get('token').split(' ')[1];
                return token;
            },
        }).configureLogging(SignalR.LogLevel.Information).build();

        connection.on('PostMessage', data => {
            if (data.title === 'Heartbeat') return;

            const newData = JSON.parse(data.body, (k, v) => typeof v === 'string' && v[0] === '[' ? JSON.parse(v) : v);
            updateStats(newData)(dispatch);
        });
        connection.start()
            .then(function () {
                console.log('signalR connected');
            })
            .catch(function(err) {
                console.log('signalR error');
                console.dir(err);
            });
        dispatch({type: 'SET_NOTIFY_CONNECTION', connection: connection});
    };
}

export function stopNotifications() {
    return (dispatch, getState) => {
        const {dashboard: {notificationConnection}} = getState();
        const bound = {
            logout: () => dispatch({type: 'LOGOUT_REQ'}),
            response: () => dispatch({type: 'SET_NOTIFY_CONNECTION', connection: null}),
            
        };
        notificationConnection && notificationConnection.stop && notificationConnection.stop();
        return axios.delete('/ShoppingService/api/v1/account/connections')
            .then(bound.response)
            .catch(_.noop);
    };
}

export function setSelectedModalities(selectedModalities) {
    return {type: SET_SELECTED_MODALITIES, selectedModalities};
}

export function setSelectedCategories(selectedCategories) {
    return {type: SET_SELECTED_CATEGORIES, selectedCategories};
}

export function setSelectedFacilities(selectedFacilities) {
    return {type: SET_SELECTED_FACILITIES, selectedFacilities};
}

export function setSelectedRequesters(selectedRequesters) {
    return {type: SET_SELECTED_REQUESTERS, selectedRequesters};
}

export function setSelectedCategory(selectedCategory) {
    return {type: SET_SELECTED_CATEGORY, selectedCategory};
}

export function setSelectedDate(selectedDate) {
    return {type: SET_SELECTED_DATE, selectedDate};
}

export function setCardSelected(cardSelected) {
    return {type: SET_CARD_SELECTED, cardSelected};
}

export function setSelectedLineItems(selectedLineItems) {
    return {type: SET_SELECTED_LINE_ITEMS, selectedLineItems};
}

export function removeLineItem(lineItem) {
    return {type: REMOVE_LINE_ITEM, lineItem};
}

export function setActiveSection(activeSection) {
    return {type: SET_ACTIVE_SECTION, activeSection};
}

export function setGridSettings(gridSettings) {
    return (dispatch) => {
        dispatch({type: SET_GRID_SETTINGS, gridSettings});
    }
}

export function setUpdatesPanelFilters(updatesPanelFilters) {
    return (dispatch) => {
        dispatch({type: SET_UPDATESPANEL_SETTINGS, updatesPanelFilters});
    }
}

export function reducer(state = _.cloneDeep(defaultState), action = null) {
    switch (action.type) {
        case 'UPDATE_STATS': {
            if (state.autoRefresh) {
                return {...state, stats: action.stats, updatedStats: action.stats};
            }
            return {...state, updatedStats: action.stats};
        }
        case 'UPDATE_CALENDAR_VIEW': {
            if (state.autoRefresh) {
                return {...state, calendarView: action.calendarView, updatedCalendarView: action.calendarView};
            }
            return {...state, updatedCalendarView: action.calendarView};
        }
        case 'UPDATE_UPDATES_PANEL': {
            // Logic to process the updates
            let newState = _.cloneDeep(state.updatedUpdatesPanel);
            if (action.updatesPanel && action.updatesPanel.length > 0) {
                for (let i = 0; i < action.updatesPanel.length; i++) {
                    let currentRow = newState.find(r => r.line_item_id === action.updatesPanel[i].line_item_id);
                    if (currentRow)
                        currentRow = action.updatesPanel[i];
                    else {
                        newState.push(action.updatesPanel[i]);
                    }
                }
            }
            
            if (state.autoRefresh) {
                return {...state, updatesPanel: newState, updatedUpdatesPanel: newState};
            }
            return {...state, updatedUpdatesPanel: newState};
        }
        case TOGGLE_AUTO_REFRESH:
            return {...state, autoRefresh: !state.autoRefresh};
        case SET_SHOW_UPDATES_PANEL:
            return {...state, showUpdatesPanel: action.value};
            
        case REFRESH:
            return {...state, calendarView: state.updatedCalendarView, stats: state.updatedStats, updatesPanel: state.updatedUpdatesPanel};

        case 'SET_NOTIFY_CONNECTION':
            return {...state, notificationConnection: action.connection};

        case 'GET_TASK_ITEMS': {
            const {taskItems} = action;
            return {...state, taskItems};
        }
        case 'SAVE_MY_ACCOUNT_FILTER':
            return {...state, myAccountFilter: action.myAccountFilter};
        case SET_SELECTED_MODALITIES:
            return {...state, selectedModalities: action.selectedModalities};
        case SET_SELECTED_CATEGORIES:
            return {...state, selectedCategories: action.selectedCategories};
        case SET_SELECTED_FACILITIES:
            return {...state, selectedFacilities: action.selectedFacilities};
        case SET_SELECTED_REQUESTERS:
            return {...state, selectedRequesters: action.selectedRequesters};
        case SET_SELECTED_CATEGORY:
            return {...state, selectedCategory: action.selectedCategory};
        case SET_SELECTED_DATE:
            return {...state, selectedDate: action.selectedDate};
        case SET_CARD_SELECTED:
            return {...state, cardSelected: action.cardSelected};
        case SET_SELECTED_LINE_ITEMS:
            return {...state, selectedLineItems: action.selectedLineItems};
        case REMOVE_LINE_ITEM:
            return {...state, removedLineItems: [...state.removedLineItems, action.lineItem]};
        case SET_ACTIVE_SECTION:
            return {...state, activeSection: action.activeSection};
        case SET_GRID_SETTINGS:
            return {...state, gridSettings: action.gridSettings};
        case SET_UPDATESPANEL_SETTINGS:
            return {...state, updatesPanelFilters: action.updatesPanelFilters};

    }

    return state;
}
