import axios from 'axios';
import _ from 'lodash';
import {initialize} from '@bloomreach/spa-sdk';

export const defaultBloomReachState = {
    isBusy: false,
    path: '',
    pageModel: undefined,
    request: null,
    showcaseActiveIndex: '0',
    isPreview: false,
};

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    setPageModel: (page) => setPageModel(page),
    getPageModel: (path, req) => getPageModel(path, req),
    resetPath: () => resetPath(),
    setRequest: (request) => setRequest(request),
};

export function setPageModel(page) {
    return (dispatch) => {
        dispatch({type: 'SET_PAGE_MODEL', page: page});
    }
}

export function setRequest(request) {
    return (dispatch) => {
        dispatch({type: 'SET_REQUEST', request: request});
    }
}

export function getPageModel(path, req) {
    return (dispatch, getState) => {
        dispatch({type: 'GET_PAGE_MODEL_REQ'});

        let instance = axios.create({headers: {}});
        instance.defaults.headers.common.Authorization = '';

        return initialize({
            path: path,
            httpClient: instance,
            endpoint: `${process.env.REACT_APP_CMS_URL}/site/resourceapi`,
            baseUrl: process.env.REACT_APP_CMS_URL,
            spaBaseUrl: '',
        }).then((response) => {
            dispatch({type: 'GET_PAGE_MODEL_RESP', response: response, path: path});
        }).catch((error) => {
            dispatch({type: 'GET_PAGE_MODEL_ERR', response: error})
        });
    };
}

export function resetPath() {
    return dispatch => dispatch({type: 'RESET_PATH'});
}

export function reducer(state = _.cloneDeep(defaultBloomReachState), action = null) {
    switch (action.type) {
        case 'GET_PAGE_MODEL_REQ':
            return {
                ...state,
                isBusy: true,
            };
        case 'GET_PAGE_MODEL_RESP': {
            const {response} = action;
            const isPreview = response && response.meta && !!response.meta.preview;
            return {...state, isBusy: false, pageModel: response, isPreview: isPreview, path: action.path};
        }
        case 'GET_PAGE_MODEL_ERR':
        {
            console.log(`BloomReach Redux Error for route ${action.response.config.url}: ${action.response.toString()}`);
            return {
                ...state,
                isBusy: false,
            };
        }
        case 'SET_SHOWCASE_ACTIVE_INDEX':
            return {...state, showcaseActiveIndex: action.showcaseActiveIndex}
        case 'RESET_PATH':
        {
            const {request} = state;
            return {...state, path: '', request: {...request, path: ''}};
        }
        case 'SET_REQUEST':
            return {...state, request: action.request};
        case 'SET_PAGE_MODEL':
            return {...state, pageModel: action.page};
    }
    return state;
}
