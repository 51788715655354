import * as React from 'react';
import {useSelector} from 'react-redux';
import {classNames, formatMoney, slugify} from 'utility';
import {getPartCondition, getPurchaseChoice, getLineItemWarranty, formatUnitOfMeasurement, getVendorAttribute} from 'productUtility';

const ProductOption = (props) => {
    const {hidePricing, product, option: displayOption, onOptionSelected, selected, companyLogo, quantity, facility} = props;
    const preferredLogo = useSelector(state => state.user.preferredLogo);
    const classes = {'option': true, 'option--selected': selected};
    const option = displayOption.option || displayOption;
    const variantsSelected = !product.isVariantProduct || !option.minPrice;
    const displayPrice = option.minPrice || option.price;
    const showFormularyBadge = option.isFormularyOption && option.formularySetting && option.formularySetting.showBadge && !option.isContractProOption;
    const showCompanyLogo = option.formularySetting && option.formularySetting.showCompanyLogo || option.formularySetting && option.formularySetting.showCompanyLogo == undefined;
    const priceAboveApprovalLimit = variantsSelected && !option.isFormularyOption && option.formularySetting && option.formularySetting.approvalRequired && (displayPrice * quantity) > option.formularySetting.approvalLimit && facility.purchaseLimit !== 999999;
    const isPpl = option.lineItemCondition === 40;
    const renderCerts = (classPrefix) => {
        return (
            <div className={`${classPrefix}_certs`}>
                {Object.keys(option.certifications).map((c, index) => (
                    <img key={`img_cert_${index}`} src={`/images/${getVendorAttribute(c)}`} alt={option.certifications[c]} />
                ))}
            </div>
        );
    };

    return (<div className={classNames(classes)} onClick={() => onOptionSelected()}>
        {showFormularyBadge ? (
            <div className="formulary-logo">
                {showCompanyLogo ? (
                    companyLogo ? (<img src={`data:image/jpeg;base64,${companyLogo}`} />) : null
                    ) : null }
                   <img src={`${preferredLogo}`} style={{width: '125px'}} alt="Preferred" />
                   {isPpl ? (<div className="ppl-logo">
                        <img src="/images/ppl_check.png" alt="Performance Line" />
                    </div>) : null }
            </div>
        ) : null
        }
        <div className="description">
            <span className="condition">
                {option.sendForStockCheck ? 'Alternative Options' : getPartCondition(option.lineItemCondition)}
            </span>
            {(!showFormularyBadge && isPpl) ? (<span className="ppl-logo">
                        <img src="/images/ppl_check.png" alt="Performance Line" />
            </span>) : null }
        </div>

        {!option.sendForStockCheck && !option.isRepairOption && option.purchaseChoice ? <div className="lineItemCondition">
            {option.purchaseChoice === 2 && <img src="/images/blk_sm_exchange.svg" alt="Exchange Item" style={{height: '20x', marginRight: '2px'}} />}
            {getPurchaseChoice(option.purchaseChoice)}
            <span className="ps-hidden-md ps-hidden-sm ps-hidden-xs"> Purchase</span>
        </div> : null}

        {!hidePricing && option.price && !variantsSelected ? (
            <div className="price-info">
                <span className="your-price">Starting at:</span>
            </div>
        ) : null}

        {!hidePricing && displayPrice && displayPrice >= 0 ? <span className="lbl-bold price">
            {formatMoney(displayPrice)}
            {option.unitOfMeasurement ? <span className="ps-hidden-md ps-hidden-sm ps-hidden-xs"> {formatUnitOfMeasurement(option.unitOfMeasurement)}</span> : null}
        </span> : null}

        {option.isContractProOption ? <div style={{marginTop: '5px'}}><div className="contract-badge"><span>CONTRACT</span></div></div> : null}
        {option.badges ? <div className="ps-hidden-md ps-hidden-sm ps-hidden-xs"><div className={`product_badge product_badge--${slugify(option.badges)}`}>{option.badges}</div></div> : null}
        {option.isProStockItem ? <div className="ps-hidden-md ps-hidden-sm ps-hidden-xs"><div className={`product_badge product_badge--pro-stock`}><b>PRO</b> Dedicated Stock</div></div> : null}
        {!option.isContractProOption && (option.lineItemWarranty || option.fakeRefurb) && !option.sendForStockCheck ? <div className="lbl ps-hidden-md ps-hidden-sm ps-hidden-xs warranty">
            <span>Warranty: </span>
            {option.lineItemWarranty ? getLineItemWarranty(option.lineItemWarranty) : `Warranty and Terms of Sale may vary based on option`}
        </div> : null}
        {option.sendForStockCheck ? <div className="lbl ps-hidden-md ps-hidden-sm ps-hidden-xs warranty">
            Price varies dependent on condition and availability
        </div> : null}

        {facility.showVendorCertifications && option.certifications && Object.keys(option.certifications).length ? <div className="ps-hidden-md ps-hidden-sm ps-hidden-xs">{renderCerts('option')}</div> : null}
        {priceAboveApprovalLimit && !option.isContractProOption ? <span className="approval-required">Approval Required</span> : null}
    </div>);
};

export default ProductOption;
