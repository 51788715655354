import axios from 'axios'
import _ from 'lodash';

const defaultState = {
    wasListCreated: false,
    listCreation: {
        id: null,
        facilityId: null,
        userId: null,
        privacy: 0,
        type: 0,
        description: '',
        name: '',
        items: []
    }
}

export const actionCreators = {
    saveList: (request) => saveList(request),
    importItems: (items) => importItems(items),
    preloadList: (list) => preloadList(list),
    resetListCreated: () => resetListCreated()
}


function resetListCreated() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'RESET_LIST_CREATED' }),
        };

        bound.request();
    }

}

function preloadList(list) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'PRELOAD_LIST', response: list }),
        };

        bound.request();
    }

}

function importItems(items) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'IMPORT_ITEMS', response: items })
        };

        bound.request();
    }

}

function saveList(request) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({ type: 'SAVE_DATACONF_LIST_REQ' }),
            response: (response) => dispatch({
                type: 'SAVE_DATACONF_LIST_RESP',
                response: response.data,
            }),
            error: (error) => dispatch({
                type: 'SAVE_DATACONF_LIST_ERR',
                response: error,
            }),
        };

        bound.request();

        axios.post(`${process.env.REACT_APP_DFA_API}/api/lists`, request)
            .then(bound.response)
            .catch(c => console.log(c))
    }
}

export const reducer = (state = _.cloneDeep(defaultState), action = null) => {
    switch (action.type) {
        case 'SAVE_DATACONF_LIST_RESP':
            const savedList = _.merge({}, state.listCreation, action.response);
            savedList.items = action.response.items;
            return { ...state, listCreation: savedList, wasListCreated: true }
        case 'FILTER_ITEM_LIST':
            {
                const list = _.cloneDeep(state.listCreation);
                list.items = action.response;
                return { ...state, listCreation: list }
            }
        case 'IMPORT_ITEMS':
            {
                let list = _.cloneDeep(state.listCreation);
                list.items = action.response.items;
                return { ...state, listCreation: list }
            }
        case 'PRELOAD_LIST':
            {
                let clonedList = _.cloneDeep(state.listCreation);
                clonedList = action.response
                return { ...state, listCreation: clonedList }
            }
        case 'RESET_LIST_CREATED':
            {
                let clonedList = _.cloneDeep(state.listCreation);
                clonedList.items = [];
                clonedList.id = null;
                return { ...state, wasListCreated: false, listCreation: clonedList }
            }
        default:
            return { ...state };
    }
}