import axios from 'axios';
import * as PropTypes from 'prop-types';
import _ from 'lodash';

import {slugify} from 'utility';

const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST';
const GET_CATEGORIES_RESPONSE = 'GET_CATEGORIES_RESPONSE';
const GET_CATEGORIES_ERR = 'GET_CATEGORIES_ERR';
const GET_OEMS_REQUEST = 'GET_OEMS_REQUEST';
const GET_OEMS_RESPONSE = 'GET_OEMS_RESPONSE';
const GET_OEMS_ERR = 'GET_OEMS_ERR';
const GET_ONESOURCE_MAPPINGS_REQUEST = 'GET_ONESOURCE_MAPPINGS_REQUEST';
const GET_ONESOURCE_MAPPINGS_RESPONSE = 'GET_ONESOURCE_MAPPINGS_RESPONSE';
const GET_ONESOURCE_MAPPINGS_ERR = 'GET_ONESOURCE_MAPPINGS_ERR';
const REFRESH_ONESOURCE_OEMS_REQUEST = 'REFRESH_ONESOURCE_OEMS_REQUEST';
const REFRESH_ONESOURCE_OEMS_RESPONSE = 'REFRESH_ONESOURCE_OEMS_RESPONSE';
const REFRESH_ONESOURCE_OEMS_ERR = 'REFRESH_ONESOURCE_OEMS_ERR';
const DELETE_ONESOURCE_MAPPING_REQUEST = 'DELETE_ONESOURCE_MAPPING_REQUEST';
const DELETE_ONESOURCE_MAPPING_RESPONSE = 'DELETE_ONESOURCE_MAPPING_RESPONSE';
const DELETE_ONESOURCE_MAPPING_ERR = 'DELETE_ONESOURCE_MAPPING_ERR';

const defaultState = {
    isBusy: false,
    oems: [],
    oemsWithoutDefault: [],
    categories: [],
    oneSourceMappings: [],
    oneSourceOems: [],
};

export const OemsShape = PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    hasProducts: PropTypes.bool,
    hasModels: PropTypes.bool,
}));

export const CategoryShape = PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    slugName: PropTypes.string,
}));

export const StateShape = PropTypes.shape({
    isBusy: PropTypes.bool,
    oems: OemsShape,
    oemsWithoutDefault: OemsShape,
    categories: CategoryShape,
    oneSourceMappings: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        partsSourceRefs: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        })),
        source: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
        }),
    })),
});

export const actionCreators = {
    getOems,
    getCategories,
    getOneSourceOemMappings,
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

export function getCategories() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: GET_CATEGORIES_REQUEST,
            }),
            response: response => dispatch({
                type: GET_CATEGORIES_RESPONSE,
                response: response,
            }),
            error: error => dispatch({
                type: GET_CATEGORIES_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.get(`/CatalogService/api/v1.0/departments`)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function getOems() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: GET_OEMS_REQUEST,
            }),
            response: response => dispatch({
                type: GET_OEMS_RESPONSE,
                response: response,
            }),
            error: error => dispatch({
                type: GET_OEMS_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.get(`/ShoppingService/api/v1/companies/oems?limit=100000`)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function getOneSourceOemMappings() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: GET_ONESOURCE_MAPPINGS_REQUEST,
            }),
            response: response => dispatch({
                type: GET_ONESOURCE_MAPPINGS_RESPONSE,
                response: response,
            }),
            error: error => dispatch({
                type: GET_ONESOURCE_MAPPINGS_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.get('/OneSourceService/api/v1/oems')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function refreshOneSourceOems() {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: REFRESH_ONESOURCE_OEMS_REQUEST,
            }),
            response: () => dispatch({
                type: REFRESH_ONESOURCE_OEMS_RESPONSE,
            }),
            error: error => dispatch({
                type: REFRESH_ONESOURCE_OEMS_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.post('/OneSourceService/api/v1/oems/refresh')
            .then(bound.response)
            .catch(bound.error);
    };
}

export function deleteOneSourceOemMapping(oneSourceOemId, partsSourceOemId) {
    return (dispatch) => {
        const bound = {
            request: () => dispatch({
                type: DELETE_ONESOURCE_MAPPING_REQUEST,
            }),
            response: () => dispatch({
                type: DELETE_ONESOURCE_MAPPING_RESPONSE,
            }),
            error: error => dispatch({
                type: DELETE_ONESOURCE_MAPPING_ERR,
                response: error,
            }),
        };

        bound.request();

        return axios.delete(`/OneSourceService/api/v1/oems/${oneSourceOemId}/psOemId/${partsSourceOemId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

export function reducer(state = _.cloneDeep(defaultState), action = null) {
    switch (action.type) {
        case GET_OEMS_REQUEST:
        case GET_CATEGORIES_REQUEST:
        case GET_ONESOURCE_MAPPINGS_REQUEST:
        case REFRESH_ONESOURCE_OEMS_REQUEST:
        case DELETE_ONESOURCE_MAPPING_REQUEST:
            return {...state, isBusy: true};

        case GET_OEMS_ERR:
        case GET_ONESOURCE_MAPPINGS_ERR:
        case GET_CATEGORIES_ERR:
        case REFRESH_ONESOURCE_OEMS_RESPONSE:
        case REFRESH_ONESOURCE_OEMS_ERR:
        case DELETE_ONESOURCE_MAPPING_RESPONSE:
        case DELETE_ONESOURCE_MAPPING_ERR:
            return {...state, isBusy: false};

        case GET_OEMS_RESPONSE: {
            const sortedOems = _.sortBy(action.response.data, 'name');
            return {...state, isBusy: false, oems: sortedOems, state, oemsWithoutDefault: sortedOems.filter(x => x.id !== 26799)};
        }
        case GET_CATEGORIES_RESPONSE: {
            const mappedCategories = action.response.data.map(c => ({
                sluggedPath: c.name.split('|').map(slugify).join('/'),
                name: c.name,
            }));
            return {...state, isBusy: false, categories: mappedCategories};
        }
        case GET_ONESOURCE_MAPPINGS_RESPONSE: {
            return {...state, isBusy: false, oneSourceMappings: action.response.data};
        }
    }

    return state;
}
