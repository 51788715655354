import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {getPartCondition, getPurchaseChoice, formatUnitOfMeasurement, leadTimeDescription, leadTimeDescriptionRepair} from 'productUtility';
import {formatMoney, isQuotedRepair} from 'utility';
import {useSelector} from 'react-redux';
import {theme} from '@partssourceinc/react-ui-core';

const Container = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    background: #fff;
    box-shadow: 0 0 5px 0 rgb(0 0 0 / 25%);
    z-index: 999;
    transition: all 500ms;
    z-index: 1049;
    display: ${props => props.isVisible ? 'fixed' : 'none'};
`;

const Content = styled.div`
    width: 100%;

    @media (max-width: ${theme.screenMDMax}) {
        padding: 8px 20px;
    }
    
    @media (min-width: ${theme.screenLGMin}) {
        padding: 16px 50px;
    }
`;

const Flex = styled.div`
    display: flex;

    @media (min-width: ${theme.screenMDMin}) {
        justify-content: space-between;
        align-items: center;
    }

    @media (max-width: ${theme.screenSMMax}) {
        flex-direction: column;
    }
`;

const Left = styled.div`

`;

const Right = styled.div`

`;

const Title = styled.span`
    color: #000;
    font-size: 16px;
    margin-bottom: 2px;
    font-weight: 400;

    @media (max-width: ${theme.screenSMMax}) {
        display: none;
    }
`;

const Option = styled.span`
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #000;

    @media (max-width: ${theme.screenSMMax}) {
        display: none;
    }
`;

const Price = styled.span`
    display: block;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;

    strong {
        font-size: 24px;
        font-weight: 700;
    }
`;

const LeadTime = styled.span`
    font-weight: 600;
    font-size: 14px;
    color: ${theme.grey1};
`;

const PriceInfo = styled.div`
    span {
        font-size: 14px;
        font-weight: 600;
        color: #767676;
    }
`;

const YourSavings = styled.div`
    margin-top: 7px;

    span {
        font-weight: 600;
        font-size: 14px;
        color: @grey1;
    }

    @media (max-width: ${theme.screenSMMax}) {
        display: none;
    }
`;

const StickyHeader = (props) => {
    const {product, option, quantity, variants} = props;
    const [isVisible, setIsVisible] = useState(false);
    const oemName = option.lineItemCondition === 1 && product.manufacturer ? `by ${product.manufacturer}` : '';
    const productDescription = option.description || product.description;
    const displayPartNumberInHeader = [1].indexOf(option.lineItemCondition) > -1 && product.displayPartNumber;
    const displayAltPartNumberFormat = [6, 2, 4].indexOf(option.lineItemCondition) > -1;
    const partCondition = getPartCondition(option.lineItemCondition);
    const purchaseChoice = getPurchaseChoice(option.purchaseChoice);
    const unitOfMeasurement = option.unitOfMeasurement ? formatUnitOfMeasurement(option.unitOfMeasurement) : null;
    const hidePricing = useSelector(state => state.user.settings.hidePricing);
    const hideOEMPricing = useSelector(state => state.user.settings.hideOEMPricing);
    const outrightListPrice = useSelector(state => state.currentProduct.outrightListPrice);
    const hideNoEsdMessaging = useSelector(state => state.system.siteSettings.hideNoEsdMessaging);

    const displayPartNumber = displayPartNumberInHeader ? `OEM#: ${product.displayPartNumber}` : 
        displayAltPartNumberFormat ? `Replaces ${product.manufacturer} ${product.displayPartNumber}` : '';
    const variantsSelected = variants.length === 0 || !option.minPrice;
    let bulkPricingSchedule = option && option.bulkPricingSchedule ? option.bulkPricingSchedule.filter((bp) => bp.tierPrice < option.price) : null;
    let displayPrice = option.price;

    if (bulkPricingSchedule != null && variantsSelected) {
        let bulkPrice = bulkPricingSchedule.filter((x) => x.minQtyRange <= quantity && (x.maxQtyRange >= quantity || x.minQtyRange === x.maxQtyRange));
        if (bulkPrice.length > 0) displayPrice = bulkPrice[0].tierPrice;
    } else if (!variantsSelected) {
        displayPrice = option.minPrice;
    }

    useEffect(() => {   
        const toggleVisibility = () => {
            let heightToHideFrom = 120;
            const scrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
               
            if (scrollPosition > heightToHideFrom) { 
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }  
        };

        window.addEventListener('scroll', toggleVisibility);

        return () => window.removeEventListener('scroll', toggleVisibility); 
    }, []);

    return (
        <Container isVisible={isVisible}>
            <Content>
                <Flex>
                    <Left>
                        <Title>{displayPartNumber}</Title>
                        <Option>
                            {partCondition && <strong>{partCondition}</strong>}
                            {purchaseChoice && <span>{` ${purchaseChoice}`}</span>}
                        </Option>
                        <h3>{productDescription} {oemName}</h3>
                    </Left>
                    <Right>
                        {!hidePricing && option.price && !option.isContractProOption && !isQuotedRepair(option.lineItemCondition) ? (
                            <PriceInfo>
                                {variantsSelected ? <span>Your price: </span>
                                    : <span>Starting at </span>}
                            </PriceInfo>
                        ) : null}

                        {!hidePricing && option.price && !isQuotedRepair(option.lineItemCondition) ? <Price>
                            <strong>{formatMoney(displayPrice)} </strong>
                            {unitOfMeasurement}
                        </Price> : null}

                        {variantsSelected && !hidePricing && !hideOEMPricing && option.price && outrightListPrice > option.price ? (
                            <YourSavings>
                                <span className="lbl-info">
                                    <span style={{textDecoration: 'line-through'}}> {formatMoney(parseFloat(outrightListPrice))} </span>
                                    {'  '}Savings:{'  '}
                                    {formatMoney(outrightListPrice - option.price)} ({Math.round(((outrightListPrice - option.price) / outrightListPrice) * 100)}%)
                                </span>
                            </YourSavings>
                        ) : null}

                        {variantsSelected && (!option.isContractProOption && !option.suppressLeadTime && !hidePricing && option.price) && <LeadTime>
                            {option.purchaseChoice === 3
                                ? leadTimeDescriptionRepair(option.leadTimeDays)
                                : leadTimeDescription(option.estimatedShipDate, option.backorderDateEta, option.shipCutoffUtc, hideNoEsdMessaging)}
                        </LeadTime>}
                    </Right>
                </Flex>
            </Content>
        </Container>
    );
}

export default StickyHeader;
