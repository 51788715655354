import axios from 'axios';
import * as PropTypes from 'prop-types';
import React, {Component} from 'react';
import {Popup, Checkbox} from '@partssourceinc/react-ui-core';

import 'less/userAcknowledgement.less';

export default class Acknowledgement extends Component {
    static propTypes = {
        itemNumbers: PropTypes.array.isRequired,
        onCancel: PropTypes.func.isRequired,
        acknowledgeRisk: PropTypes.func.isRequired,
    };
    
    constructor(props) {
        super(props);

        this.state = {
            isChecked: false,
        };
    }

    componentDidMount() {
        const {itemNumbers} = this.props;
        let request = {ItemNumbers: itemNumbers};
        axios.post('SettingsService/api/v1/saveAcknowledgement', request);
    }

    render() {
        const {onCancel, acknowledgeRisk} = this.props;
        const {isChecked} = this.state;
        
        const ackText = '<p>Proposition 65, also called the Safe Drinking Water and Toxic Enforcement Act, was enacted by the State of California in 1986. The act requires "clear and reasonable" warnings about potential exposures to certain chemicals that California has determined cause cancer, birth defects or other reproductive harm.</p>' +
        '<p>Proposition 65 has recently been amended to include "pre-purchase" warning requirements. The amendments take effect on August 30, 2018. In addition to on-product warnings, catalogs and webpages must now carry the same warnings so that consumers in California can see the warnings prior to purchase.</p>' + 
        '<p>Because of this requirement, we need to have all customers acknowledge the receipt and understanding of this information. Therefore, PartsSource requires your confirmation electronically, of receipt of this notice. Check the box below for acknowledgement. If PartsSource does not receive confirmation, we will be unable to process orders containing products which contain certain substances, as so listed on <a href="https://oehha.ca.gov/proposition-65/proposition-65-list"> https://oehha.ca.gov/proposition-65/proposition-65-list data-ea-exit-link="Prop65" </a> </p>' +
        'PartsSource will provide the necessary warning on the product labels, on its websites, and in all printed publications. Resellers are expected to convey identical warning language to the end user. </p>' +
        '<p>Affected product list: <a href="#">PartsSource Prop 65 Products</a></p>' +
        '<p>For further questions concerning Prop 65, refer to: <a href="https://oehha.ca.gov/proposition-65/law/proposition-65-law-and-regulations"> https://oehha.ca.gov/proposition-65/law/proposition-65-law-and-regulations</a></p>' +
        '<p>For any additional questions, contact us at: <a href="jtweed@partssource.com">jtweed@partssource.com</a>.</p>' +
        '<p>In accordance with California Proposition 65 regulations, if I am a reseller I hereby acknowledge the Reseller Letter and my corresponding reseller obligations located <a href="https://www1.partssource.com/hubfs/Supplier ePF Documentation/Prop 65 Letter 061722.pdf">here</a> and represent that I am an authorized agent for my organization, designated to receive and acknowledge such notices.</p>';

        return (
            <Popup confirmText="Confirm"
                cancelText="Cancel"
                show={true}
                onCancel={onCancel}
                className="add-group-dialog"
                disableConfirm={!isChecked}
                onConfirm={acknowledgeRisk}
            >
                <p className="title">Prop 65 Acknowledgement</p>
                <div className="content" dangerouslySetInnerHTML={{__html: ackText}} />
                <p>
                    <span className="accept">
                        <Checkbox label="I acknowledge" checked={isChecked} 
                            onChange={() => this.setState({isChecked: !isChecked})}
                        />
                    </span>
                </p>
            </Popup>
        );
    }
}
