import * as PropTypes from 'prop-types';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import Loadable from 'react-loadable';
import Product from 'pages/Product';
import Catalog from 'pages/Catalog';

function Loading(props) {
    const {error} = props;

    if (error && location.hostname !== 'localhost') {
        location.reload(true);
    }
    return null;
}

Loading.propTypes = {
    error: PropTypes.string,
};

const MasterLayout = Loadable({loader: () => import(/* webpackChunkName: "MasterLayout" */'pages/MasterLayout'), loading: Loading});

export const dataRoutes = [
    {path: '/parts/:oemName/:partNumber/:psPartNumber?', component: Product},
    {path: '/catalog/:oemName?/:modelName?', component: Catalog},
    {path: '/shop/:cat1/:cat2?/:cat3?/:cat4?/:cat5?', component: Product},
];

const Router = () => {
    return (
        <Switch>
            <Route path="*" component={MasterLayout} />
        </Switch>
    );
}
export default Router;
