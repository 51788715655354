import axios from 'axios';
import * as PropTypes from 'prop-types';
import _ from 'lodash';
import {sortByKey, clearToken} from 'utility';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
const defaultSettingsState = {
    facility: {},
    users: [],
    groups: [],
    companyHierarchy: [],
    approvalRules: [],
    settings: {
        companyId: -1,
        settings: {},
        paymentSettings: {},
        savedCreditCards: [],
    },
    customCatalogRules: [],
    formularyLists: [],
    subscriptions: [],
    allOrdersPagination: 50,
    dataConfiguratorSelectedUserId: null,
};

export const StateShape = PropTypes.shape({
    facility: PropTypes.shape({
        facilityId: PropTypes.string,
        facilityName: PropTypes.string,
        hasDirectAccess: PropTypes.bool,
        hasDelegatedAccess: PropTypes.bool,
        isSmartPriceOnly: PropTypes.bool,
        groupId: PropTypes.number,
        healthSystemId: PropTypes.number,
        purchaseLimit: PropTypes.number,
        myOEM: PropTypes.bool,
        phoneNumber: PropTypes.string,
    }),
    users: PropTypes.arrayOf(PropTypes.shape({
        companyId: PropTypes.number,
        contactId: PropTypes.number,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        email: PropTypes.string,
        phoneNumber: PropTypes.string,
        isApprovalDelegate: PropTypes.bool,
        salesRepId: PropTypes.number,
        salesRepFirstName: PropTypes.string,
        salesRepLastName: PropTypes.string,
        groupId: PropTypes.number,
        searchDisplayName: PropTypes.string,
        rootCompanyId: PropTypes.number,
        fullName: PropTypes.string,
    })),
    groups: PropTypes.arrayOf(PropTypes.shape({
        groupId: PropTypes.number,
        groupCode: PropTypes.string,
        displayName: PropTypes.string,
        description: PropTypes.string,
        isReadOnly: PropTypes.bool,
    })),
    companyHierarchy: PropTypes.arrayOf(PropTypes.shape({
        facilityId: PropTypes.number,
        facilityName: PropTypes.string,
        parentFacilityId: PropTypes.number,
        purchasingWorkflow: PropTypes.bool,
        prependShipTo: PropTypes.bool,
        hasSubscription: PropTypes.bool,
    })),
    approvalRules: PropTypes.arrayOf(PropTypes.shape({
        submitGroupId: PropTypes.number,
        submitGroupName: PropTypes.string,
        groupId: PropTypes.number,
        displayName: PropTypes.string,
        purchaseLimit: PropTypes.number,
    })),
    settings: {
        companyId: PropTypes.number,
        settings: PropTypes.shape({
            companyId: PropTypes.number,
            settings: PropTypes.object,
            paymentSettings: PropTypes.object,
            savedCreditCards: PropTypes.array,
            allowLoaner: PropTypes.bool,
            prominentQuoteRequests: PropTypes.bool,
            proofOfDeliveryLinks: PropTypes.bool,
        }),
        paymentSettings: PropTypes.object,
        savedCreditCards: PropTypes.array,
    },
    customCatalogRules: PropTypes.arrayOf(PropTypes.object),
    subscriptions: PropTypes.arrayOf(PropTypes.object),
    allOrdersPagination: PropTypes.number,
    dataConfiguratorSelectedUserId: PropTypes.number
});

export const actionCreators = {
    saveReduxSettings: (settings) => saveReduxSettings(settings),
    loadInitialAdminData: () => loadInitialAdminData(),
    loadUsers: () => loadUsers(),
    loadGroups: () => loadGroups(),
    loadCompanyHierarchy: () => loadCompanyHierarchy(),
    loadSubscriptions: () => loadSubscriptions(),
    loadCompanySettings: () => loadCompanySettings(),
    loadCompanyPaymentSettings: () => loadCompanyPaymentSettings(),
    loadApprovalRules: () => loadApprovalRules(),
    loadCatalogRules: () => loadCatalogRules(),
    loadFormularyLists: () => loadFormularyLists(),
    loadFormularyRules: () => loadFormularyRules(),
    loadContracts: () => loadContracts(),
    loadRfqRules: () => loadRfqRules(),
    setAllOrdersPagination: (value) => setAllOrdersPagination(value),
    setDataConfiguratorSelectedUserId: (userId) => setDataConfiguratorSelectedUserId(userId),
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

export function saveReduxSettings(settings) {
    return (dispatch, getState) => {
        let newSettings = _.merge(getState().settings, settings);
        dispatch({type: 'SAVE_REDUX_SETTINGS', settings: newSettings});
    };
}

export function setAllOrdersPagination(newValue) {
    return (dispatch, getState) => {
        dispatch({type: 'SAVE_ORDERS_PAGINATION', value: newValue})
    }
}

export function setDataConfiguratorSelectedUserId(userId) {
    return (dispatch, getState) => {
        dispatch({type: 'SAVE_DATA_CONFIG_USER_ID', value: userId})
    }
}

function loadInitialAdminData() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_INITIAL_ADMIN_DATA_REQ'}),
            response: (response) => dispatch({type: 'LOAD_INITIAL_ADMIN_DATA_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_INITIAL_ADMIN_DATA_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/companyAdminSettingsInitialLoad/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadUsers() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_USERS_ADMIN_SETTINGS_REQ'}),
            response: (response) => dispatch({type: 'LOAD_USERS_ADMIN_SETTINGS_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_USERS_ADMIN_SETTINGS_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/healthsystem/contacts/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadGroups() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_GROUPS_ADMIN_SETTINGS_REQ'}),
            response: (response) => dispatch({type: 'LOAD_GROUPS_ADMIN_SETTINGS_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_GROUPS_ADMIN_SETTINGS_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/groups/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadCompanyHierarchy() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_COMPANY_HIERARCHY_REQ'}),
            response: (response) => dispatch({type: 'LOAD_COMPANY_HIERARCHY_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_COMPANY_HIERARCHY_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/companyhierarchy/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadSubscriptions() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_SUBSCRIPTIONS_REQ'}),
            response: (response) => dispatch({type: 'LOAD_SUBSCRIPTIONS_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_SUBSCRIPTIONS_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/companySubscriptions/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadCompanySettings() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_COMPANY_SETTINGS_REQ'}),
            response: (response) => dispatch({type: 'LOAD_COMPANY_SETTINGS_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_COMPANY_SETTINGS_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/companySettings/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadCompanyPaymentSettings() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_COMPANY_PAYMENT_SETTINGS_REQ'}),
            response: (response) => dispatch({type: 'LOAD_COMPANY_PAYMENT_SETTINGS_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_COMPANY_PAYMENT_SETTINGS_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/companyPaymentSettings/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadApprovalRules() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_APPROVAL_RULES_REQ'}),
            response: (response) => dispatch({type: 'LOAD_APPROVAL_RULES_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_APPROVAL_RULES_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/approvalRules/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadCatalogRules() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_CATALOG_RULES_REQ'}),
            response: (response) => dispatch({type: 'LOAD_CATALOG_RULES_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_CATALOG_RULES_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/customCatalogRules/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadFormularyLists() {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({type: 'LOAD_FORMULARY_LISTS_REQ'}),
            response: (response) => dispatch({type: 'LOAD_FORMULARY_LISTS_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_FORMULARY_LISTS_ERR', response: error}),
        };

        bound.request();
        return axios.get('/FormularyService/api/v1/formulary/lists')
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadFormularyRules() {
    return (dispatch, getState) => {
        const {settings: {facility}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_FORMULARY_RULES_REQ'}),
            response: (response) => dispatch({type: 'LOAD_FORMULARY_RULES_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_FORMULARY_RULES_ERR', response: error}),
        };

        bound.request();

        if (facility && facility.facilityId > 0) {
            return axios.get(`/SettingsService/api/v1/formularyRules/${facility.facilityId}`)
                .then(bound.response)
                .catch(bound.error);
        }
    };
}

function loadContracts() {
    return (dispatch, getState) => {
        const {settings: {facility: {facilityId}}} = getState();
        const bound = {
            request: () => dispatch({type: 'LOAD_CONTRACTS_REQ'}),
            response: (response) => dispatch({type: 'LOAD_CONTRACTS_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_CONTRACTS_ERR', response: error}),
        };

        bound.request();
        return axios.get(`/SettingsService/api/v1/contracts/${facilityId}`)
            .then(bound.response)
            .catch(bound.error);
    };
}

function loadRfqRules() {
    return (dispatch, getState) => {
        const bound = {
            request: () => dispatch({type: 'LOAD_RFQ_RULES_REQ'}),
            response: (response) => dispatch({type: 'LOAD_RFQ_RULES_RESP', response: response}),
            error: (error) => dispatch({type: 'LOAD_RFQ_RULES_ERR', response: error}),
        };

        bound.request();
        return axios.get('/SettingsService/api/v1/rfq/rules')
            .then(bound.response)
            .catch(bound.error);
    };
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer = (state = _.cloneDeep(defaultSettingsState), action = null) => {
    switch (action.type) {
        case 'SAVE_REDUX_SETTINGS': {
            return {...action.settings};
        }
        case 'SAVE_ORDERS_PAGINATION': {
            return {...state, allOrdersPagination: action.value};
        }
        case 'LOAD_INITIAL_ADMIN_DATA_RESP': {
            const {response: {data}} = action;
            const sortedCompanies = sortByKey(data.companyHierarchy, 'facilityName');
            return {
                ...state,
                users: data.users,
                groups: data.groups,
                approvalRules: data.approvalRules,
                companyHierarchy: sortedCompanies,
                subscriptions: data.subscriptions,
                settings: data.settings,
                customCatalogRules: data.customCatalogRules,
                formularyRules: data.formularyRules,
                purchaseContracts: data.purchaseContracts,
            };
        }
        case 'LOAD_USERS_ADMIN_SETTINGS_RESP': {
            const {response: {data}} = action;
            return {...state, users: data};
        }
        case 'LOAD_GROUPS_ADMIN_SETTINGS_RESP': {
            const {response: {data}} = action;
            return {...state, groups: data.groups};
        }
        case 'LOAD_COMPANY_HIERARCHY_RESP': {
            const {response: {data}} = action;
            const sortedCompanies = sortByKey(data, 'facilityName');
            return {...state, companyHierarchy: sortedCompanies};
        }
        case 'LOAD_SUBSCRIPTIONS_RESP': {
            const {response: {data}} = action;
            return {...state, subscriptions: data};
        }
        case 'LOAD_COMPANY_SETTINGS_RESP': {
            const {response: {data}} = action;
            const settings = state.settings;
            settings.settings = data.settings;
            settings.oneSourceCredentials = data.oneSourceCredentials;
            settings.fullyBurdenedQuoting = data.fullyBurdenedQuoting;
            return {...state, settings: settings};
        }
        case 'LOAD_COMPANY_PAYMENT_SETTINGS_RESP': {
            const {response: {data}} = action;
            const settings = state.settings;
            settings.paymentSettings = data.paymentSettings;
            settings.savedCreditCards = data.savedCreditCards;
            return {...state, settings: settings};
        }
        case 'LOAD_APPROVAL_RULES_RESP': {
            const {response: {data}} = action;
            return {...state, approvalRules: data};
        }
        case 'LOAD_CATALOG_RULES_RESP': {
            const {response: {data}} = action;
            return {...state, customCatalogRules: data};
        }
        case 'LOAD_FORMULARY_LISTS_RESP': {
            const {response: {data}} = action;
            return {...state, formularyLists: data};
        }
        case 'LOAD_FORMULARY_RULES_RESP': {
            const {response: {data}} = action;
            return {...state, formularyRules: data};
        }
        case 'LOAD_CONTRACTS_RESP': {
            const {response: {data}} = action;
            return {...state, purchaseContracts: data};
        }
        case 'LOAD_RFQ_RULES_RESP': {
            const {response: {data}} = action;
            const rules = data.map((x) => {
                x.nameSort = x.name.toLowerCase(); return x; 
            });
            return {...state, rfqRules: rules};
        }
        case 'SAVE_DATA_CONFIG_USER_ID': {
            return {...state, dataConfiguratorSelectedUserId: action.value};
        }
        case 'LOAD_RFQ_RULES_ERR':
        case 'LOAD_INITIAL_ADMIN_DATA_ERR':
        case 'LOAD_FORMULARY_RULES_ERR': {
            clearToken();
        }

    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state;
};
