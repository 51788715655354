import _ from 'lodash';
import moment from 'moment';
import {defaultFilters} from 'data/DefaultReportingPortalFilters';
import * as PropTypes from 'prop-types';

const SET_FILTERS = 'SET_FILTERS';
const CLEAR_FILTERS = 'CLEAR_FILTERS';

const defaultState = {
    filters: {},
    filterTypes: [
        {id: 1, name: 'OEM', code: 'oems'},
        {id: 2, name: 'Modality', code: 'modalities'},
        {id: 3, name: 'Facility', code: 'facilities'},
        {id: 4, name: 'Requester', code: 'requesters'},
        {id: 5, name: 'Date', code: 'date'},
    ],
};

export const actionCreators = {
    setFilters: (filters) => setFilters(filters),
    clearFilters: (filters) => clearFilters(filters),
};

export const ActionShape = _.mapValues(actionCreators, () => PropTypes.func);

export function setFilters(filters) {
    return (dispatch) => {
        dispatch({type: SET_FILTERS, filters});
    };
}

export function clearFilters() {
    return (dispatch) => {
        dispatch({type: CLEAR_FILTERS, filters: {}});
    };
}

export function getJaqlFilters(selectedFilters) {
    let filtersToApply = _.cloneDeep(defaultFilters);
    const filters = defaultState.filterTypes;

    filters.forEach((f) => {
        switch (f.id) {
            case 1:
                if (selectedFilters.oems) {
                    filtersToApply[0].jaql.filter.members = selectedFilters.oems.map(x => x.name);
                    filtersToApply[0].jaql.filter.all = undefined;
                } 
                break;
            case 2:
                if (selectedFilters.modalities) {
                    filtersToApply[1].jaql.filter.members = selectedFilters.modalities.map(x => x.name);
                    filtersToApply[1].jaql.filter.all = undefined;
                } 
                break;
            case 3: 
                if (selectedFilters.facilities) {
                    filtersToApply[2].jaql.filter.members = selectedFilters.facilities.map(x => x.name);
                    filtersToApply[2].jaql.filter.all = undefined;
                } 
                break;
            case 4:
                if (selectedFilters.requesters) {
                    filtersToApply[3].jaql.filter.members = selectedFilters.requesters.map(x => x.name.toUpperCase());
                    filtersToApply[3].jaql.filter.all = undefined;
                    // Backup in case of [N_Requestors.Requestor_Name] vs [REQUESTORS.REQUESTOR_NAME]
                    // filtersToApply[12].jaql.filter.members = selectedFilters.requesters.map(x => x.name.toUpperCase());
                    // filtersToApply[12].jaql.filter.all = undefined;
                } 
                break;
            case 5:
                if (selectedFilters.date) {
                    filtersToApply[4].jaql.filter = {from: moment(selectedFilters.date.from).format('YYYY/MM/DD'), to: moment(selectedFilters.date.to).format('YYYY/MM/DD')};
                } 
        }
    });

    return filtersToApply;
}

export const reducer = (state = _.cloneDeep(defaultState), action = null) => {
    switch (action.type) {
        case 'SET_FILTERS':
            return {...state, filters: action.filters}
        case 'CLEAR_FILTERS':
            return {...state, filters: action.filters}
    }

    return state;
};
