import React, {useEffect, useState} from 'react';
import DocumentMeta from 'react-document-meta';
import {pageView, sha256} from 'utility'; 
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

export default function PageMetaData ({title, description, canonical, meta, extend, rootPage, children, pageType, trackAnalytics, trackAdobeAnalytics}) {
    const network = useSelector(state => state.network);
    const user = useSelector(state => state.user);
    const params = useSelector(state => state.params);
    const sessionStorageAvailable = network.sessionStorageAvailable;
    const history = useHistory();
    const {pathname, search} = history.location;
    const [pageData, setPageData] = useState({['pageTitle']: '', ['pageType']: '', ['subsections']: []});
    const width = window.innerWidth;
    const pageExperience = width >= 1024 ? 'Desktop' : width >= 768 ? 'Tablet' : 'Mobile';
    const canonicalTag = (canonical || '').toLowerCase().replace('apollo.partsfinder.com', 'www.partssource.com').replace('http:','https:');
    const track = typeof(trackAdobeAnalytics) === 'undefined' ? true : false;
    let pageTitle = title ? rootPage ? title : `${title} : PartsSource - Healthcare Products and Solutions` : undefined;

    useEffect(() => {
        const prevUrl = sessionStorageAvailable ? sessionStorage.prevUrl : '/';
        window.appEventData = window.appEventData || [];

        setPageData(getPageData());
        let htmlPageTitle = document.getElementsByTagName('title').item(0).innerText.split(' : ')[0];
        if (pageTitle.includes('undefined') || htmlPageTitle.includes('undefined')) pageTitle = pageData.pageTitle;

        if (sessionStorageAvailable) {
            if (location.pathname !== '/login') {
                sessionStorage.prevUrl = `${pathname}${search}`;
            }
            if (typeof(prevUrl) === 'undefined' || prevUrl !== sessionStorage.prevUrl) {
                pageView(params.referer);
            }
        }

    }, []);

    const getPageData = () => {
        const catalogSubsections = ['catalog', 'shop', 'parts'];
        const cartSubsections = ['checkout', 'cart', 'confirmation']; 
        let pathArray = pathname.split('/').filter(Boolean);
        
        if (pathArray.some(subsection => catalogSubsections.includes(subsection))) {
            pageData.pageTitle = pathArray[pathArray.length - 1];
            pageData.pageType = 'Catalog';
            for (let i = 0; i < pathArray.length; i++) {
                pageData.subsections.push(pathArray[i]);
            }
        } else if (pathArray.some(subsection => cartSubsections.includes(subsection))) {
            pageData.pageTitle = pathArray[pathArray.length - 1];
            pageData.pageType = 'Cart';
            for (let i = 0; i < pathArray.length; i++) {
                pageData.subsections.push(pathArray[i]);
            }
        } else if (pathArray.length === 0) {
            pageData.pageType = 'Home';
        } else {
            pageData.pageTitle = pathArray[pathArray.length - 1];
            pageData.pageType = 'Other';
            for (let i = 0; i < pathArray.length; i++) {
                pageData.subsections.push(pathArray[i]);
            }
        }

        return pageData;
    }

    useEffect(() => {
        let info = user.info;
        let isImpersonation = network.isImpersonation;
        let isLoggedIn = network.isLoggedIn;
        let loginId = network.tokenInfo.loginId;
        
        if (!track) return;
        sha256(info.email).then(hashedEmail => {
        // Adobe Tag Manager calls
            window.appEventData.push({
                'event': 'Page Load Started',
                'page': {
                    'pageCategory': pageData.pageType === 'Home' ? 'Home' : pageData.subsections.length > 0 ? pageData.subsections[0] : '',
                    'pageExperience': pageExperience,
                    'pageName': `partssource|${pageData.pageType === 'Home' ? 'Home' : pathname.split('/').filter(Boolean).join('|')}`,
                    'pageTitle': pageTitle ? pageTitle : pageData.pageTitle,
                    'pageType': pageData.pageType,
                    'platform': 'Apollo',
                    'siteCurrency': 'USD',
                    'siteLanguage': 'en',
                    'siteName': 'partssource',
                    'subsection': pageData.subsections.length > 1 ? pageData.subsections[1] : '',
                    'subsection2': pageData.subsections.length > 2 && pageData.subsections[0] !== 'parts' ? pageData.subsections[2] : '',
                    'subsection3': '',
                },
            });

            window.appEventData.push({
                'event': 'User Detected',
                'user': {
                    'assignedSalesRepId': info.salesRepId ? info.salesRepFirstName + ' ' + info.salesRepLastName : '',
                    'custKey': loginId ? loginId.toString() : '',
                    'hashedEmail': hashedEmail !== '' ? hashedEmail : '',
                    'loginStatus': isLoggedIn ? 'logged in' : 'anonymous',
                    'rootCompanyId': info.rootCompanyId ? info.rootCompanyId.toString() : '',
                    'rootCompanyName': info.rootCompanyName ? info.rootCompanyName : '',
                    'userType': isImpersonation ? 'impersonation' : 'standard',
                    'impersonationStatus': isImpersonation ? 'impersonation' : 'standard',
                },
            });
            
            window.appEventData.push({
                'event': 'Page Load Completed',
            });
        });

        dataLayer.push({
            brPageType: pageType ? pageType : pageData.pageType,
            brDomainKey: 'partssource',
            brViewId: isLoggedIn ? 'full' : 'guest',
            brUserId: loginId,
            brIsConversion: 0,
        }, {
            event: 'fsPageView',
        });

        if (trackAnalytics) {
            dataLayer.push({
                event: 'brPageView',
            });
        }

        if (isLoggedIn && sessionStorageAvailable && window.FS) {
            window.FS.identify(loginId.toString(), {
                displayName: `${network.tokenInfo.firstName} ${network.tokenInfo.lastName}`,
                userId_int: loginId,
                companyName_str: user.facility && user.facility.facilityId > 0 ? user.facility.facilityName : '',
            })
        }

    }, [pathname]);

    return (<DocumentMeta
        title={pageTitle}
        description={description}
        canonical={canonicalTag}
        meta={meta}
        extend={extend}
    >
        {children}
    </DocumentMeta>);
}
