import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {TextField, Button, Popup} from '@partssourceinc/react-ui-core';
import * as UserStore from 'stores/User';

import 'less/warranty.less';

@withRouter
@connect(state => ({user: state.user}))
export default class WarrantyPopUpForm extends React.Component {
    static propTypes = {
        warrantydata: PropTypes.object.isRequired,
        onCancel: PropTypes.func,
        onConfirm: PropTypes.func,
        showConfirmation: PropTypes.func,
        getCompanyFields: PropTypes.func,
        user: UserStore.StateShape,
    };
    
    static defaultProps = {
       
        show: false,
        inlineHeader: false,
    };
    
    constructor(props) {
        super(props);
        this.SendWarrantyRequestEmail = this.SendWarrantyRequestEmail.bind(this);
        this.state = {
            oemId: null,
            selectedRequester: null,
            requesterList: [],
            selectedFacility: null,
            partNum: '',
            partDescr: '',
            partQty: 1,
            showErrors: false,
            reqFields: [],
            isFileSelected: false,
            showRequestForm: true,
            showAddToCartConfirmation: false,
            showWarrantyPopUp: true,
            product: {},
            warrantynote: '',
            showConfirmationPopup: false,
           
        };
    }

    handleOnCancel() {
        const {onCancel} = this.props;
        onCancel();
    }
    
    updateStatePart(event, data) {
        let i = data.id;
        this.setState({[i]: data.value});
    }

    handleOnConfirm() {
        const {onConfirm} = this.props;
        this.SendWarrantyRequestEmail();
        onConfirm();
        
    }

    closePopUp() {
        const {onConfirm} = this.props;
        onConfirm();
    }
    
    SendWarrantyRequestEmail() {
        const {user: {facility}, warrantydata, showConfirmation} = this.props;
        const {warrantynote} = this.state;
        warrantydata.notes = warrantynote;
        // this.setState({ showWarrantyPopUp: false });
        
        if (!warrantydata.partSerialNumber)
            warrantydata.partSerialNumber = 'N/A';
        if (!warrantydata.notes)
            warrantydata.notes = 'N/A';
        
        axios.post(`/CatalogService/api/v1/SendWarrantyRequestedMessage/ ${facility.facilityId}`, warrantydata).then((response) => {
            if (response.status === 200)
                showConfirmation();
        });
    }

    render() {
        const {warrantydata} = this.props;
        const {showWarrantyPopUp} = this.state;
        const title = 'Our records indicate that the requested item could be covered under the original warranty period.';

        return (<div>
            {showWarrantyPopUp && (
                <Popup confirmText="" cancelText="" show={true} hideButtons={true} onConfirm={:: this.handleOnConfirm}
                    onCancel={:: this.closePopUp} disableClose={false} inlineHeader={true} className = "cart-confirmation_popup warranty-dialog" >
                    
                    <div>
                        <div>
                            <div className="heading">{title}</div>
                        </div>
                        
                        <div>
                            <span className="bold">Ref # : </span>
                            <span>{warrantydata.lineItemId}</span>
                        </div>
                        <div>
                            <span className="bold" >PO Date : </span>
                            <span> {new Date(warrantydata.poDate).toLocaleDateString('en-US')}</span>
                        </div>

                        <div>
                            <span className="bold">PO # : </span>
                            <span>{warrantydata.lineItemPo}</span>
                        </div>
                        <div>
                            <span className="bold">Asset ID : </span>
                            <span>{warrantydata.assetId}</span>
                        </div>
                
                        <div>
                            <span className="bold">Part S/N : </span>
                            <span>{warrantydata.partSerialNumber}</span>
                        </div>
             
                        <div>
                            <span className="bold">Warranty : </span>
                            <span>{warrantydata.warrantyDescription}</span>
                    
                        </div>
                        <div>
                            <span className="bold">Part number : </span>
                            <span>{warrantydata.partNumber}</span>
                        </div>
                
                        <div>
                            <span className="bold">Description : </span>
                            <span>{warrantydata.partDescription} </span>
                        </div>
                
                        <div>
                            <span className="bold">Condition : </span>
                            <span>{warrantydata.conditionDescription}</span>
                        </div>

                        <TextField
                            id="warrantynote"
                            keyfield="note"
                            className="request-quote_required_textarea"
                            multiLine={true}
                            rows={4}
                            required={true}
                            label="Notes"
                            placeholder="Notes"
                            onBlur={:: this.updateStatePart} 
                            tabIndex={0} />
                    </div>   

                    <Button className="buttons" onClick={::this.handleOnCancel}>
                        Continue Purchase
                    </Button>

                    <Button className="buttons"onClick={::this.handleOnConfirm}>
                        Submit Warranty Replacement
                    </Button>
                </Popup>)}
        </div>);
    }
}
