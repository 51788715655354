import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import Router from '../universal/routes';
import createReduxStore from '../universal/createReduxStore';
import Cookies from 'js-cookie';
import Loadable from 'react-loadable'
import {refreshToken} from '../utility';
import {OptanonWrapper} from '../scripts';
import {uuidv4} from '../utility';

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

// Create Redux store with initial state
const store = createReduxStore({preloadedState});
// Setup axios defaults
axios.defaults.baseURL = process.env.REACT_APP_ORION_API;
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common.Pragma = 'no-cache';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['X-br-uuid'] = Cookies.get('_br_uid_2');

let onDispatchStorages = (function() {
    let executed = false;
    return function() {
        if (!executed) {
            executed = true;
            store.dispatch({type: 'SESSION_STORAGE_AVAILABLE'})
        }
    };
})();

onDispatchStorages();

axios.interceptors.request.use(function (config) {
    let id_ins = Cookies.get('id_ins');
    if (!id_ins) {
        id_ins = uuidv4();
    }
    Cookies.set('id_ins', id_ins, {expires: 364, secure: window.location.hostname !== 'localhost', sameSite: 'None'});

    if (Cookies.get('eoem')) {
        config.headers.eoem = Cookies.get('eoem');
    }

    // Do something before request is sent
    let token = Cookies.get('token');
    if (token) config.headers.authorization = token;
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    let refreshT = Cookies.get('refreshToken');
    if(Cookies.get('impersonate') || Cookies.get('subscriptionId') != undefined) refreshT = null;
    if (error.response && error.response.status === 401 && !refreshT) {
        store.dispatch({type: 'LOGOUT_REQ'});
        console.log('Unauthorized API Call');
        location.reload(true);
    } else if (error.response && error.response.status === 401 && refreshT) {
        refreshToken(true).then(() => {
            let token = Cookies.get('token');
            if (token) {
                return new Promise((resolve) => {
                    resolve(axios(error.config));
                });
            } else {
                store.dispatch({type: 'LOGOUT_REQ'});
                console.log('Unauthorized API Call');
                location.reload(true);
            }
        });
    } else if (error.response &&
        error.response.status === 400 &&
        (error.config.url.indexOf('/company/contact/settings') > 0 || error.config.url.indexOf('/token') > 0)) {
        store.dispatch({type: 'LOGOUT_REQ'});
        console.log('Unauthorized API Call');
        if (location.href.indexOf('/login') === -1)
            location.reload(true);
    } else {
        return Promise.reject(error);
    }
});

OptanonWrapper();
window.onload = () => {
    Loadable.preloadReady().then(() => {
        ReactDOM.hydrate(<Provider store={store}>
            <BrowserRouter>
                <Router />
            </BrowserRouter>
        </Provider>, document.getElementById('react-app'));
    });
};
