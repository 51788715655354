import _ from 'lodash';
import moment from 'moment';

import {mergeByProperty, isSessionStorageAvailable} from 'utility';

const sessionStorageAvailable = isSessionStorageAvailable();

export function formatUnitOfMeasurement(unitOfMeasurement) {
    let lower = unitOfMeasurement.toLowerCase();
    if (lower.includes('each')) {
        return 'each';
        // add per and remove left and right parenthesis. ex.  (pair) --> Per pair
    } else if (!lower.includes('/')) {  
        return 'Per ' + lower.replace(/[()]/g, '');
    } else {
        // split on slash, remove left and right parenthesis from quanitiy and UOM. ex (4)/(box) --> Per box of 4
        return 'Per ' + lower.split('/')[1].replace(/[()]/g, '') + ' of ' + lower.split('/')[0].replace(/[()]/g, '');  
    }
}

export function getProps65Message(chemicals) {
    const hasAllUnknownChemicals = chemicals.length > 1 && chemicals.every(c => c.value.toLowerCase() !== 'unknown');
    const hasSomeUnknownChemicals = chemicals.length > 1 && chemicals.some(c => c.value.toLowerCase() === 'unknown');
    const unknownChemical = chemicals.length === 1 && chemicals[0].value.toLowerCase() === 'unknown';

    const unknownChemicalMessage = ' This product can expose you to chemicals known to the State of California to cause cancer and reproductive harm.';
    const knownChemicalMessage = ` This product can expose you a chemical called ${chemicals[0].value},
        which is [are] known to the State of California to cause ${chemicals[0].name.toLowerCase() === 'prop65c' ? 'cancer' : 'birth defects or other reproductive harm.'}`;

    const chemicalListMessage = chemicals.map((item, index) => {
        let isProp65c = item.name.toLowerCase() === 'prop65c';
        let isProp65r = item.name.toLowerCase() === 'prop65r';
        return index === 0 ?
            `${item.value} which is [are] known to the State of California to cause ${isProp65c ? 'cancer' : isProp65r ? 'birth defects or other reproductive harm' : ''}`
            : `${chemicals.length == 2 ? ' and ' : ''}${item.value} which is [are] known to cause ${isProp65c ? 'cancer' : isProp65r ? 'birth defects or other reproductive harm' : ''}`;
    });

    return `WARNING:${hasAllUnknownChemicals
        ? ` This product can expose you to chemicals including ${chemicalListMessage.map(c => {
            return c 
        }).join('')}.`
        : hasSomeUnknownChemicals
            ? unknownChemicalMessage
            : unknownChemical ? unknownChemicalMessage : knownChemicalMessage}`;
}

export function getBreadCrumb(breadCrumbs, partNumber, oemName, manufacturer) {
    let breadCrumb = [];

    if (breadCrumbs) {
        breadCrumb = _.cloneDeep(breadCrumbs);
        for (let i = 0; i < breadCrumb.length; i++) {
            let bc = breadCrumb[i];
            bc.url = `${bc.label !== manufacturer ? bc.url + '?q=' + partNumber : bc.url}`;
        }

        if (breadCrumb.length > 0) {
            breadCrumb.push({label: partNumber, url: '/'});
            return breadCrumb;
        }
    }

    if (partNumber && manufacturer) {
        breadCrumb.push({label: 'Home', url: '/'});
        breadCrumb.push({label: manufacturer, url: '/catalog/' + oemName});
        breadCrumb.push({label: partNumber, url: '/'});
    }

    return breadCrumb;
}

export function getPartCondition(conditionId) {
    const partConditions = {
        0: '',
        1: 'New OEM Original',
        2: 'Refurbished',
        3: 'Used',
        4: 'Tested',
        5: 'Unknown',
        6: 'New Aftermarket',
        7: 'Demo',
        18: 'Aftermarket Flat Rate Repair',
        19: 'Aftermarket Minor Repair',
        20: 'Aftermarket Major Repair',
        21: 'OEM Minor Repair',
        22: 'OEM Major Repair',
        23: 'OEM Quoted Repair',
        24: 'OEM Flat Rate Repair',
        25: 'Aftermarket Quoted Repair',
        38: 'OEM Refurbished',
        39: 'Open Box',
        40: 'Performance Line'
    };
    return partConditions[conditionId];
}

export function getPartConditionDescription(conditionId) {
    const conditionDescriptions = {
        0: '',
        1: 'The part is brand new in OEM packaging.',
        2: 'Pre-owned part that has been restored to working condition and system tested, restored to OEM original specifications.',
        3: 'Pre-owned part that has not had any components replaced or testing completed. "As-Is“ , typically Non-returnable unless otherwise documented.',
        4: 'Pre-owned part that has been system tested on site at supplier.',
        5: '',
        6: 'The brand new part built to OEM fit, form, and function; designed and manufactured by a third party.',
        7: 'A Supplier item that was used for sales demonstration purposes (typically minor equipment).',
        18: 'Customer-owned unit sent to third-party depot for repair. Price includes performance of any repairs.',
        19: 'Customer-owned unit sent to third-party depot for repair. Price includes performance of any minor repairs.',
        20: 'Customer-owned unit sent to third-party depot for repair. Price includes performance of any minor or major repairs.',
        21: 'Customer-owned unit sent to OEM depot for repair. Price includes performance of any minor repairs.',
        22: 'Customer-owned unit sent to OEM depot for repair. Price includes performance of any minor or major repairs.',
        23: 'Customer-owned unit sent to OEM depot for repair. Price is quoted following incoming evaluation at depot.',
        24: 'Customer-owned unit sent to OEM depot for repair. Price includes performance of any repairs.',
        25: 'Customer-owned unit sent to third-party depot for repair. Price is quoted following incoming evaluation at depot.',
        38: 'Pre-owned part that has been repaired by the OEM to factory conditions.',
        39: 'Item was removed from the box to photograph, and now being sold otherwise unused.',
        40: 'Shop with confidence purchasing PERFORMANCE LINE products. These items are always in stock and ready to ship. They have been reviewed extensively for quality and come with extended warranty.'
    };
    return conditionDescriptions[conditionId] || '';
}

export function getVendorAttribute(attributeId) {
    const vendorAttributes = {
        1: 'vendor-attributes-minority-owned@2x.png',
        2: 'vendor-attributes-women-owned@2x.png',
        3: 'vendor-attributes-veteran-owned@2x.png',
        4: 'vendor-attributes-service-disabled-veteran-owned@2x.png',
        5: 'vendor-attributes-lgbt-owned@2x.png',
        6: 'vendor-attributes-historically-underutilized-business@2x.png',
        9: 'vendor-attributes-leed@2x.png',
        10: 'vendor-attributes-green-building-council-certification@2x.png',
        11: 'vendor-attributes-zero-energy@2x.png',
        12: 'vendor-attributes-fda-certified@2x.png',
        13: 'vendor-attributes-iso-9000-quality-management@2x.png',
        14: 'vendor-attributes-iso-13485-medical-devices@2x.png',
        15: 'vendor-attributes-iso-17025-calibration@2x.png',
        17: 'vendor-attributes-sba@2x.png',
    };
    return vendorAttributes[attributeId];
}

export function getPurchaseChoice(purchaseChoiceId) {
    return purchaseChoiceId === 1 ? 'Outright' : purchaseChoiceId === 2 ? 'Exchange' : 'Repair';
}

export function saveAssetInformationToStorage(fields) {
    if (sessionStorageAvailable) {
        let storageData = JSON.parse(sessionStorage.fields);
        fields.forEach(d => {
            let field = storageData.find(
                x => x.fieldUid && x.fieldUid === d.fieldUid
            );
            if (field) field.value = d.value;
            else storageData.push(d);
        });
        sessionStorage.fields = JSON.stringify(storageData);
    }
}

export function getLineItemWarranty(warrantyId) {
    const warranties = {
        0: 'Unknown',
        1: 'As Is',
        2: '30 Day',
        3: '60 Day',
        4: '90 Day',
        5: '12 Month',
        6: '24 Month',
        7: 'Other (See Notes)',
        8: '6 Month',
        9: '120 Day',
        20: '30 Day Pro',
        21: '60 Day Pro',
        22: '90 Day Pro',
        23: '120 Day Pro',
        24: '6 Month Pro',
        25: '12 Month Pro',
        26: '21 Month Pro',
        27: '24 Month Pro',
        28: '30 Day Full 60 Pro',
        29: '30 Day Full 90 Pro',
        30: '30 Day Full 120 Pro',
        31: '30 Day Full 6mo Pro',
        32: '30 Day Full 1yr Pro',
        33: '30 Day Full 18mo Pro',
        34: '30 Day Full 2yr Pro',
        35: '3 Mo Full 21 Pro',
        36: 'Mfr Warranty',
        37: 'Limited Repair',
        100: 'Not Set',
    };
    return warranties[warrantyId === -1 ? 100 : warrantyId];
}

export function isPsPartNumber(str) {
    let pattern = new RegExp(/^(PS)[a-zA-Z0-9_.-]*$/, 'i');
    return pattern.test(str);
}

export function leadTimeDescription(estimatedShipDate, backOrderDate, vendorCutoffTime, hideNoEsdMessaging) {
    const backOrderYear = backOrderDate ? new Date(backOrderDate).getFullYear() : null;

    if (backOrderDate && backOrderYear !== 1754 && backOrderYear !== 1753)
        return `Estimated to ship by ${moment(backOrderDate).format('MM/DD')}`;
    else if (backOrderYear === 1754 || backOrderYear === 1753)
        return hideNoEsdMessaging ? 'We are currently unable to provide a shipping date' : 'Ship date will be provided after ordering';

    if (estimatedShipDate == null) return 'Ship date will be provided after ordering';

    if (vendorCutoffTime == null && estimatedShipDate != null) {
        return `Estimated to ship by ${moment(estimatedShipDate).format('MM/DD')} if ordered before vendor cut off`;
    } else if (estimatedShipDate != null) {

        const vendorUtc = moment(vendorCutoffTime).toDate();

        const vendorCutoff = moment(vendorUtc).subtract(30, 'minutes').toDate();

        return `Estimated to ship by ${moment(estimatedShipDate).format('MM/DD')} if ordered before ${moment(vendorCutoff).format('h:mm A')} EST`;
    }

    return hideNoEsdMessaging ? 'We are currently unable to provide a shipping date' : 'Ship date will be provided after ordering';
}

export function leadTimeDescriptionRepair(leadTime) {
    if (leadTime != null && leadTime != 0)
        return `Repairs are generally completed within ${leadTime} days once received by the vendor`;

    return 'Estimated completion will be provided once the item been received by the vendor';
}

export function esdPresentedPart(estimatedShipDate, backOrderDate, vendorCutoffTime, hideNoEsdMessaging) {
    const backOrderYear = backOrderDate ? new Date(backOrderDate).getFullYear() : null;

    if (backOrderDate && backOrderYear !== 1754 && backOrderYear !== 1753)
        return `Estimated to ship by ${moment(backOrderDate).format('MM/DD')}`;
    else if (backOrderYear === 1754 || backOrderYear === 1753)
        return hideNoEsdMessaging ? 'We are currently unable to provide a shipping date' : 'Ship date will be provided after ordering';

    if (estimatedShipDate == null) return null;

    if (vendorCutoffTime == null && estimatedShipDate != null) {
        return `Estimated to ship by ${moment(estimatedShipDate).format('MM/DD')} if ordered before vendor cut off`;
    } else if (estimatedShipDate != null) {

        const vendorUtc = moment(vendorCutoffTime).toDate();

        const vendorCutoff = moment(vendorUtc).subtract(30, 'minutes').toDate();

        return `Estimated to ship by ${moment(estimatedShipDate).format('MM/DD')} if ordered before ${moment(vendorCutoff).format('h:mm A')} EST`;
    }

    return null;
}

export function esdPresentedRepair(leadTime) {
    if (leadTime != null && leadTime != 0)
        return `Repairs are generally completed within ${leadTime} days once received by the vendor`;

    return null;
}

export function leadTimeDescriptionCartView(estimatedShipDate, backOrderDate, vendorCutoffTime, multipleOptions) {
    const backOrderYear = backOrderDate ? new Date(backOrderDate).getFullYear() : null;

    if (backOrderDate && backOrderYear !== 1754 && backOrderYear !== 1753)
        return `${moment(backOrderDate).format('MM/DD')}`;
    else if (backOrderYear === 1754 || backOrderYear === 1753)
        return 'Pending';

    if (estimatedShipDate == null) return 'Pending';
    else return `${moment(estimatedShipDate).format('MM/DD')}`
}

export function leadTimeDescriptionRepairCartView(leadTime, isConfirmation) {
    if (leadTime != null && leadTime != 0 && !isConfirmation)
        return `Repairs are generally completed within ${leadTime} days once received by the vendor`;

    return 'Pending';
}

export function getShipItemOptions(cartItem, repairShippingCharge) {
    // If these get deleted, there will be a very confused dev in the future asking what the statuses mean
    /* eslint-disable no-unused-vars */
    const Flag_None = 0;
    const Flag_ActualWeight = 0x01;
    const Flag_Freight = 0x02;
    const Flag_Heavy = 0x04;
    const Flag_MdisImaging = 0x08;
    const Flag_Repair = 0x10;
    const Flag_RepairWithSurcharge = 0x20;
    const Flag_Hazmat = 0x40;
    const Flag_Li_Ion = 0x80;
    /* eslint-enable no-unused-vars */

    let shipItemOptions = 0;

    if (cartItem.purchaseChoice === 'Repair') shipItemOptions |= Flag_Repair;

    if (cartItem.vendorHasRepairSurcharge && repairShippingCharge) shipItemOptions |= Flag_RepairWithSurcharge;

    if (cartItem.isActualWeight) shipItemOptions |= Flag_ActualWeight;

    if (cartItem.isImaging) shipItemOptions |= Flag_MdisImaging;

    if (cartItem.hazmatTypeId === 1) shipItemOptions |= Flag_Hazmat;

    if (cartItem.hazmatTypeId === 2) shipItemOptions |= Flag_Li_Ion;

    return shipItemOptions;
}

export function getCarrierDescription(carrierId) {
    switch (carrierId) {
        case 0:
            return 'Not Specified';
        case 1:
            return 'FedEx';
        case 2:
            return 'UPS';
    }
}

export function getCompanyAndVendorFields(omitVendorFields, omitContractFields, option, selectedFacility) {
    let fieldUsage = option && option.isRepairOption ? 32 : 1;    
    let oemItemNumber = option ? option.oemItemNumber : '';
    let companyFields = sessionStorageAvailable && sessionStorage.fields ? JSON.parse(sessionStorage.fields) : [];
    let reqFields = _.cloneDeep(companyFields.filter(x => !x.vendorField && !x.isUserField));
        
    let userFields = sessionStorageAvailable && sessionStorage.userFields ? JSON.parse(sessionStorage.userFields) : [];
    if (userFields.length > 0) {        
        let applicableFields = userFields.filter(x => 
            (x.facilityIds.length === 0 || x.facilityIds.includes(parseInt(selectedFacility))) &&
            (x.oStrings.length === 0 || x.oStrings.includes(oemItemNumber)) &&
            (x.usage === 0 || x.usage === fieldUsage)
        );

        let userFieldsToAdd = [];
        if (applicableFields.length > 0) {
            _.each(applicableFields, f => { 
                let field = reqFields.find(rf => rf.fieldUid === f.fieldUid);
                let storedField = companyFields.find(sf => sf.fieldUid === f.fieldUid) || {value: ''};
                let existingUserField = userFieldsToAdd.find(uf => uf.fieldUid === f.fieldUid);

                if (field) {
                    field.isUserField = !field.isRequired;
                    field.isRequired = true;      
                    field.value = storedField.value;
                    field.formatRegex = f.formatRegex;
                    field.errorMsg = f.errorMsg;
                    field.placeholder = f.placeholder;  
                } else {
                    f.isUserField = true;
                    f.isRequired = true;
                    f.value = storedField.value;
                    if (!existingUserField) userFieldsToAdd.push(f);
                }
            });                            
        }
        if (userFieldsToAdd.length > 0)
            reqFields = reqFields.concat(userFieldsToAdd);
    }

    // TODO: Look at field usage instead?
    if (option && option.purchaseChoice !== 3) {        
        reqFields = reqFields.filter(f => f.fieldUid !== '77777777-7777-7777-7777-777777777777');
    }

    if (!reqFields.find(rf => rf.fieldUid === '99999999-9999-9999-9999-999999999999')) {
        reqFields.push({
            fieldType: 2,
            fieldUid: '99999999-9999-9999-9999-999999999999',
            isDefault: true,
            isRequired: false,
            lineItemId: 0,
            orderId: 0,
            prompt: 'Notes',
            value: '',
        });
    }

    let vendorFields = [];

    if (!omitVendorFields) {                    
        _.forEach(option && option.vendorFieldDefinitions || [], function (item) {
            if (!(omitContractFields && item.fieldDefinitionUid.startsWith('Contract/'))) {
                if (item.value) {
                    // set value to the field from the Raven data
                    vendorFields.push({
                        fieldUid: item.fieldDefinitionUid,
                        prompt: item.prompt,
                        isRequired: item.isRequired,
                        vendorField: true,
                        value: item.value,
                        formatRegex: item.formatRegex,
                        errorMsg: item.errorMsg,
                        placeholder: item.placeholder,

                    });
                } else {
                    let storedField = companyFields.find(sf => sf.fieldUid === item.fieldUid) || {value: ''};
                    vendorFields.push({
                        fieldUid: item.fieldDefinitionUid,
                        prompt: item.prompt,
                        isRequired: item.isRequired,
                        vendorField: true,
                        value: storedField.value,
                        formatRegex: item.formatRegex,
                        errorMsg: item.errorMsg,
                        placeholder: item.placeholder,                        
                    });
                }
            }
        });

        // TODO: Verify this use case is no longer needed
        /*
        _.forEach(fields || [], function (item) {
            vendorFields.push({
                fieldUid: item.fieldDefinitionUid,
                prompt: item.prompt,
                isRequired: item.isRequired,
                vendorField: true,
            });
        });
        */

        _.forEach((option && option.formularySetting && option.formularySetting.fields) || [], function (item) {
            reqFields.push({
                fieldUid: item,
                prompt: 'Formulary Field',
                isRequired: true,
                formularyField: true,
            });
        });
    }

    mergeByProperty(reqFields, vendorFields, 'fieldUid');

    return reqFields;
}

export function getFormularyDisplayType(suppressionBehavior, formularyId) {
    if (typeof(formularyId) === 'undefined' || formularyId === null || formularyId === '') {
        return 'No Rule Set';
    }
    switch (suppressionBehavior) {
        case 0:
            return `${formularyId} : Show Non-Formulary`;
        case 1:
            return `${formularyId} : Suppress Non-Formulary Behind Link`;
        case 2:
            return `${formularyId} : Suppress Non-Formulary`;
        default:
            return '';
    }
}

export function getOptionBasePrice(option) {
    if (option.oemListPrice === 0 || typeof(option.oemListPrice) !== 'undefined' || null) {
        return option.oemListPrice.toFixed(2).toString();
    } else if (typeof(option.oemListPrice) === 'undefined' && typeof(option.outrightListPrice) !== 'undefined' || null) {
        return option.outrightListPrice.toFixed(2).toString();
    } else {
        let optionPrice = typeof(option.price) !== 'undefined' || null ? option.price.toFixed(2).toString() : '';
        return optionPrice;
    }
}
