import axios from 'axios';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';
import * as React from 'react';
import {connect} from 'react-redux';
import {Popup, TextField} from '@partssourceinc/react-ui-core';
import {isValidListName, getCookie, logEvent} from 'utility';

import * as UserStore from 'stores/User';

@connect(state => ({user: state.user}), UserStore.actionCreators)
export default class ListMenu extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        option: PropTypes.object.isRequired,
        onClose: PropTypes.object.isRequired,
        onAddToList: PropTypes.object.isRequired,
        onToggleItemToList: PropTypes.object.isRequired,
        user: UserStore.StateShape,
        ...UserStore.ActionShape,
    };

    constructor(props) {
        super(props);

        this.state = {
            showNewList: false,
            newListName: '',
            invalidListName: false,
            isDuplicateListName: false,            
            selectedList: null,
            showMoreLists: false,                
        }
    }

    addProductToList(list) {
        const {quantity, onAddItemToList, psPartNumber, getLists, product: {id, isVariantProduct},
            option: {purchaseChoice, lineItemCondition, vendorItemNumber, moq}} = this.props;
        
        let lineItem = {
            listRavenId: list.ravenId,
            psPartNumber: psPartNumber,
            quantity: moq && quantity < moq ? 1 : quantity,
            productId: id,
            purchaseChoice: purchaseChoice,
            condition: lineItemCondition,
            isVariantProduct: isVariantProduct,
            vString: isVariantProduct ? vendorItemNumber : '',
        };
        
        if (!list.hasPartNumber) {
            axios.post(`ShoppingService/api/v1/lists/addItem`, lineItem).then(() => {                                                            
                list.hasPartNumber = true;      
                onAddItemToList(list.hasPartNumber, list); 
                logEvent('ADD TO LIST', {
                    'PS Part Number': psPartNumber,
                    'List Name': list.name,
                    'V String': vendorItemNumber,
                });      

                getLists();                          
            });
        } else {
            onAddItemToList(!list.hasPartNumber, list);
        }
    }

    createNewList() {        
        const {history} = this.props;
        history.push('/lists/create')
    }

    addToGroup(grp) {
        
        const {user: {lists}, option: {psPartNumber}, onAddToList} = this.props;
        const grpName = grp || 'My Favorites';
 
        if (psPartNumber) {
            const existing = _.find(lists[grpName], psPartNumber);

            // We want the existing part at the start of the list, so remove it and add we'll add it again
            if (existing) {
                _.remove(lists[grpName], psPartNumber);
            }

            let newList = _.clone(lists);
            newList[grpName].push(psPartNumber);
            // setUserLists(newList);

            this.setState({listName: grpName});
            onAddToList(grpName, existing);
        }
    }

    createNewGroup() {
        const {newListName} = this.state;
        const {user: {lists}, option: {psPartNumber}, onClose} = this.props;

        if (!isValidListName(newListName)) {
            this.setState({invalidListName: true, isDuplicateListName: false});
            return;
        }

        if (lists[newListName] !== undefined) {
            this.setState({isDuplicateListName: true, invalidListName: false});
            return;
        }

        if (psPartNumber) {
            let newList = _.clone(lists);
            newList[newListName] = [psPartNumber];
            // setUserLists(newList);
        }
        this.setState({showNewList: false});
        onClose();

        logEvent('CREATE LIST', {'List Name': newListName});
        logEvent('ADD TO LIST', {'PS Part Number': psPartNumber, 'List Name': newListName});
    }

    renderListItem(list) {
        return ( 
            <div key={list.ravenId} className="left-menu-item" onClick={() => this.addProductToList(list)}><div>{list.name}</div> 
                {list.hasPartNumber ? <i className="fa fa-check left-menu-item-checked fa-fw" aria-hidden="true" /> : null}
            </div>
        )
    }

    render() {        
        const {user: {lists, listItems, settings: {isListAdmin}}, onClose, product, history, quantity, psPartNumber, option: {lineItemCondition, purchaseChoice, vendorItemNumber}} = this.props;
        const {newListName, showNewList, invalidListName, isDuplicateListName, selectedList, showMoreLists} = this.state;                                              
        let allLists = [];  
            
        lists.forEach(list => {
            if (list.groupName == 'mylist' || isListAdmin) {              
                let hasPartNumber = false;
                let newList = _.clone(list);
                let matches = list.items.filter(x => x.condition === lineItemCondition && x.purchaseChoice === purchaseChoice && x.productId === product.id); 
                // let matches = list.items.filter(x => x.psPartNumber === psPartNumber);
                if (matches.length > 0 && matches[0].isVariantProduct === false) {
                    hasPartNumber = true;
                } else {
                    if (matches.find(x => x.vString === vendorItemNumber) != null)
                        hasPartNumber = true;
                }                                             
                newList.hasPartNumber = hasPartNumber;
                allLists.push(newList);
            }             
        });              
                
        const topListsCount = allLists.length > 10 ? 10 : allLists.length;
        let listRows = '';
        if (!showMoreLists) {
            listRows = allLists.slice(0, topListsCount).map(list => this.renderListItem(list));
        } else {
            listRows = allLists.map(list => this.renderListItem(list));
        }
        const showMoreListsButton = lists.length > 10 && !showMoreLists;

        const {product: {id, isVariantProduct}} = this.props;                                 
        let addToListOption = {
            productId: id,
            purchaseChoice: purchaseChoice,
            condition: lineItemCondition,
            isVariantProduct: isVariantProduct,
            vString: isVariantProduct ? vendorItemNumber : '',
        };
                
        return (<React.Fragment> 
            {!showNewList && <div ref={(container) => {
                this.container = container; 
            }} className="product-dropdown-menu" style={{display: 'block'}}>
                <div className="left-menu">
                    {listRows}                          
                    {showMoreListsButton ?
                        <div className="left-menu-item"><div onClick={() => this.setState({showMoreLists: true})} className="show-more">Show More Lists</div></div> 
                        : null}  
                    <div className="left-menu-item"><NavLink to=
                        {
                            {
                                pathname: `/lists/create/${product.oemSeo}/${product.partNumber}/${quantity}/${psPartNumber}`, 
                                state: {option: addToListOption},
                            }
                        } className="create-new-list"> + Create New List </NavLink></div>                    
                </div>
            </div>}
            
            {showNewList && 
                <Popup
                    confirmText="Save"
                    cancelText="Cancel"
                    show={true}
                    hideButtons={false}
                    onCancel={onClose}
                    className="add-group-dialog"
                    onConfirm={::this.createNewGroup}
                    disableConfirm={!newListName || newListName === ' '}>
                    <h1 style={{'font-family': 'Source Sans Pro', 'font-weight': '300', 'font-size': '24px'}}> Create New List </h1>
                    {invalidListName && <div style={{color: '#FF0000', fontWeight: '600'}}>{"The list name can't contain special characters."}</div>}
                    {isDuplicateListName && <div style={{color: '#FF0000', fontWeight: '600'}}>{'This list name already exists.'}</div>}
                    <TextField onChange={::this.onNewListFieldChange} label="List Name" placeholder="New List Name" text={newListName} className="group-name" tabIndex={0} />
                </Popup>}
        </React.Fragment>);
    }

    componentWillMount() {
        document.addEventListener('mousedown', this.handleClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClick, false);
    }

    handleClick = (e) => {
        const {onClose} = this.props;

        if ((this.container && !this.container.contains(e.target))) {
            onClose();
        }
    }

    onNewListFieldChange(e) {
        this.setState({newListName: (e.target.value || '').trim()});
    }
}
