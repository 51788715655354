import React, {useState} from 'react';
import {Popup} from '@partssourceinc/react-ui-core';
import AsyncSelect from 'react-select/async';
import axios from 'axios';

export default function ContractPricingDialog({rootCompanyId, manufacturerId, onCancel, onConfirm}) {
    const [, setAssetIdData] = useState(null);
    const searchAssetId = useState(null);
    const [, setSearchAssetData] = useState(null);

    const searchAssetIdsUrl = `/FormularyService/api/v1/contracts/assetIds/search`;

    const getAssetIds = (inputValue, callback) => {
        if (!inputValue) {
            return [];
        }

        if (rootCompanyId) {
            let request = {
                rootCompanyId: rootCompanyId,
                manufacturerId: manufacturerId,
                searchTerm: inputValue,
            };
    
            axios.post(searchAssetIdsUrl, request)
                .then((x) => {
                    return callback(x.data);
                });
        }
    }

    const onSearchAssetIdChange = (data) => {
        if (!data) return;

        let currAssetId = data.assetName;

        if (currAssetId == null || currAssetId === '') {
            setAssetIdData(null);

            return;
        }

        setSearchAssetData(data);
        onConfirm(data);
    }

    return (<Popup
        className="contract-pricing-dialog"
        show={true}
        disableClose={false}
        hideButtons={true}
        onCancel={onCancel}>
        <div style={{height: '450px'}}>
            <h1 style={{fontSize: '24px'}}>Contract Verification</h1>
            <div>Search contracted devices by their asset id. Any assets not listed are not currently covered.</div>
            <div 
                className="asset-id-wrapper {searchAssetId ? 'custom-react-select-wrapper populated' : 'custom-react-select-wrapper'}" 
                style={{alignItems: 'baseline', marginTop: '20px'}}>
                <AsyncSelect
                    cacheOptions={true}
                    loadOptions={getAssetIds}
                    getOptionValue={option => option.assetId}
                    value={searchAssetId}
                    getOptionLabel={option => option.assetName}
                    noOptionsMessage={() => null}
                    placeholder="Your Asset Id"
                    className="custom-multi-select"
                    classNamePrefix="select"
                    onChange={x => onSearchAssetIdChange(x)}
                    isClearable={true}
                />
            </div>
        </div>
    </Popup>)
}
