import PropTypes from 'prop-types';
import * as React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as ReactRouterPropTypes from 'react-router-prop-types';
import {AddToCartButton, DisplayType} from 'components/AddToCartButton';
import AssetInformationForm, {AssetFormType} from 'components/AssetInformationForm';
import RepairQuantitySelector from 'components/RepairQuantitySelector'
import {QuantitySelector, TextField, Button, Popup, Checkbox} from '@partssourceinc/react-ui-core';
import Datepicker from 'components/Datepicker';
import FacilityDialog from 'components/FacilitySearchDialog';
import ContractPricingDialog from 'components/ContractPricingDialog';
import {formatUnitOfMeasurement, getCompanyAndVendorFields, getProps65Message, leadTimeDescription, leadTimeDescriptionRepair, saveAssetInformationToStorage, getPartCondition} from 'productUtility';
import * as UserStore from 'stores/User';
import {formatMoney, isQuotedRepair, isSessionStorageAvailable} from 'utility';
import Description from './Product/Description';
import Variants from './Product/Variants';
const sessionStorageAvailable = isSessionStorageAvailable();

@withRouter
@connect((state) => ({isLoggedIn: state.network.isLoggedIn, user: state.user, hideNoEsdMessaging: state.system.siteSettings.hideNoEsdMessaging}), UserStore.actionCreators)
export default class CartPanel extends React.Component {
    static propTypes = {
        product: PropTypes.object.isRequired,
        option: PropTypes.object.isRequired,
        isQuote: PropTypes.bool,
        outrightListPrice: PropTypes.number,
        onQuantityChanged: PropTypes.func,
        showOemConnect: PropTypes.bool,
        oemPrice: PropTypes.number,
        oemPriceDate: PropTypes.string,
        onSaveOemPrice: PropTypes.func,
        hadContractOptions: PropTypes.bool,
        ...ReactRouterPropTypes,
        ...UserStore.ActionShape,
        user: UserStore.StateShape,
        isLoggedIn: PropTypes.bool,
        quoteSource: PropTypes.string,
        showContractPricingDescription: PropTypes.bool,
        showContractPricingDialog: PropTypes.bool,
        assetSiteId: PropTypes.string,
        assetFacilityId: PropTypes.string,
    };

    static defaultProps = {
        isQuote: false,
        outrightListPrice: 0,
        showOemConnect: false,
        oemPrice: null,
        oemPriceDate: null,
        onSaveOemPrice: null,
    };

    constructor(props) {
        super(props);

        const {oemPrice, oemPriceDate, option, quantity} = this.props;
        
        this.state = {
            quantity: option.moq && option.moq > quantity ? option.moq : quantity,
            showAssetInformationPopup: false,
            showFacilityDialog: false,
            showOemConnectPopup: false,
            newOemPrice: oemPrice || null,
            newOemPriceDate: oemPriceDate || null,
            hasOnSiteService: false,
            showShippingFields: props.showShippingFields && props.isQuote || false,
            hasLoanerAdded: false,
            showContractPricingDescription: false,
            showContractPricingDialog: false,
            assetSiteId: '',
            assetFacilityId: '',
        };
    }

    componentWillReceiveProps = () => {
        if (typeof(qty) !== 'undefined') {
            this.setState({quantity: qty.value});
        }
    }

    render() {
        const {
            user: {
                settings: {hidePricing, noBuy, hideOEMPricing, enableOnSiteService, requiredFields},
                facility,
                facilities,
                settings,
                info: {rootCompanyId},
            },
            option,
            isLoggedIn,
            isQuote,            
            product,
            showOemConnect,
            history,
            quoteSource,
            hadContractOptions,
            shippingAddresses,
            variants,
            hideNoEsdMessaging
        } = this.props;
        
        const outrightListPrice = option != null ? option.oemListPrice : this.props.outrightListPrice;

        const {
            quantity, 
            showAssetInformationPopup, 
            showFacilityDialog, 
            showOemConnectPopup, 
            newOemPrice, 
            newOemPriceDate, 
            hasOnSiteService, 
            showShippingFields, 
            hasLoanerAdded,
            showContractPricingDescription,
            showContractPricingDialog,
            assetSiteId,
            assetFacilityId,
        } = this.state;
      
        let hasUnvalidatedContract = option && option.formularySetting && option.formularySetting.hasUnvalidatedContract;
        const sendForStockCheck = ((option.formularySetting && option.formularySetting.sendForStockCheck) && (option.price * quantity) > option.formularySetting.submitToSourcingLimit) || option.sendForStockCheck;
        let selectedFacilityId = (facility && facility.facilityId) ? parseInt(facility.facilityId) : 0;
        let customFields = getCompanyAndVendorFields(false, hasUnvalidatedContract, option, selectedFacilityId) || [];
        let bulkPricingSchedule = option && option.bulkPricingSchedule ? option.bulkPricingSchedule.filter((bp) => bp.tierPrice < option.price) : null;
        let chemicals = (option.attributes && option.attributes.filter((a) => a.name.toLowerCase() === 'prop65r' || a.name.toLowerCase() === 'prop65c')) || null;
        let buttonText = sendForStockCheck ? 'Check Stock and Alternate Options' : !noBuy && !isQuote ? (!option.isRepairOption ? 'Add To Cart' : 'Initiate Repair') : isQuote && !option.showCustomCatalogMsg ? 'Request Quote' : null;
        const condition = getPartCondition(option.lineItemCondition);
        const variantsSelected = !product.isVariantProduct || !option.minPrice;
        let notContractZeroPrice = !option.isContractProOption && (option.price == null || option.price == 0);
        let showMinQtyError = option && option.moq > quantity;

        if (!sendForStockCheck && (isQuotedRepair(option.lineItemCondition) && isQuote && !option.showCustomCatalogMsg || notContractZeroPrice)) {
            buttonText = 'Request Quote';
        }

        let displayPrice = option.price;

        if (bulkPricingSchedule != null && variantsSelected) {
            let bulkPrice = bulkPricingSchedule.filter((x) => x.minQtyRange <= quantity && (x.maxQtyRange >= quantity || x.minQtyRange === x.maxQtyRange));
            if (bulkPrice.length > 0) displayPrice = bulkPrice[0].tierPrice;
        } else if (!variantsSelected) {
            displayPrice = option.minPrice;
        }

        return (
            <React.Fragment>
                <div className="cart-panel">
                    {option.purchaseChoice === 2 && (
                        <div className="note" style={{marginTop: '40px'}}>
                            <p className="lbl-info">
                                {`Goods sold on an “Exchange” basis require that Buyer return a like, 
                        repairable item within 15 days (or the seller's specified time frame) 
                        in order to receive the price quoted. For full details, see Terms and Conditions.`}
                            </p>
                        </div>
                    )}

                    {!hidePricing && option.price != null && !isQuotedRepair(option.lineItemCondition) && !notContractZeroPrice ? (
                        <div className="price-info">
                            {variantsSelected ? <span className="your-price">Your price: </span>
                                : <span className="your-price">Starting at </span>}
                        </div>
                    ) : null}

                    {!hidePricing && option.price != null && !isQuotedRepair(option.lineItemCondition) && !notContractZeroPrice ? (
                        <div className="price">
                            <span className="lbl-bold"> {formatMoney(displayPrice)} </span>
                            {option.unitOfMeasurement ? formatUnitOfMeasurement(option.unitOfMeasurement) : null}
                        </div>
                    ) : null}

                    {!hidePricing && isQuotedRepair(option.lineItemCondition) && option.evaluationFee != null ? (
                        <>
                            <div className="price-info">
                                <span className="your-price">Evaluation fee: </span>
                            </div>
                            <div className="price">
                                <span className="lbl-bold"> {formatMoney(option.evaluationFee)} </span>
                                / + Plus the Repair Cost
                            </div>
                        </>
                    ) : null}
                    {option.purchaseChoice === 2 && <div className="eligible-exchange-message">With ELIGIBLE Exchange Core SHIPPED BACK </div>}
                    
                    {option.isContractProOption && 
                    <div>
                        <div 
                            style={{marginTop: '7px', borderRadius: '10px', width: 'auto', paddingRight: '11px'}} 
                            className="contract-badge">
                            {!hasUnvalidatedContract ? 
                                <span style={{lineHeight: 'normal'}} >
                                    <i className="fa fa-check product_tooltip"
                                        style={{backgroundColor: 'transparent', color: '#14727d', 'paddingRight': '2px', 'margin': 0}} />
                                    CONTRACT
                                </span> :
                                <span style={{lineHeight: 'normal'}}>CONTRACT PRICING</span>}
                        </div>
                        {hasUnvalidatedContract && <i
                            id="contractPricingDescription"
                            style={{cursor: 'pointer'}}
                            onMouseEnter={() => this.setState({showContractPricingDescription: true})}
                            onMouseLeave={() => this.setState({showContractPricingDescription: false})}
                            onClick={() => this.setState({showContractPricingDialog: true})}
                            className="fa fa-question-circle-o product_tooltip">
                            {showContractPricingDescription ? <span>Click to see if contract pricing is available for this item</span> : null}
                        </i>}
                    </div>}

                    {variantsSelected && !hidePricing && !hideOEMPricing && option.price != null && !notContractZeroPrice && outrightListPrice > option.price ? (
                        <div className="info-message">
                            <span className="lbl-info">
                                <span style={{textDecoration: 'line-through'}}> {formatMoney(parseFloat(outrightListPrice))} </span>
                                {'  '}Savings:{'  '}
                                {formatMoney(outrightListPrice - option.price)} ({Math.round(((outrightListPrice - option.price) / outrightListPrice) * 100)}%)
                            </span>
                        </div>
                    ) : null}

                    {option.longDescription && <Description>{option.longDescription}</Description>}

                    {!option.isContractProOption ? (
                        !isLoggedIn || option.backorderDateEta ? null : option.inventory > 0 && option.inventory < 5 ? (
                            <div className="info-message">
                                <span className="lbl-info" style={{color: '#FF0000'}}>{`Only ${option.inventory} left in stock`}</span>
                            </div>
                        ) : option.inventory >= 5 && option.inventory < 30000 ? (
                            <div className="info-message">
                                <div style={{marginRight: '8px', display: 'inline-block', verticalAlign: 'middle'}}>
                                    <i className="fa fa-check-circle" style={{fontSize: '20px', color: '#17AB78'}} />
                                </div>
                                <span className="lbl-info" style={{color: '#17AB78'}}>
                                    In Stock
                                </span>
                            </div>
                        ) : null
                    ) : null}
                   {option.leadTimeDays===0 && <div style={{marginRight: '8px', display:'block'}}><img src="/images/ppl_ready_to_ship_green.svg" alt="Ready to ship" />
                                <span className="lbl-info" style={{color: '#17AB78', marginLeft: '5px'}}>Ready to Ship</span></div> }
                    {option.leadTimeDays===0 || (variantsSelected && !option.isContractProOption && !option.suppressLeadTime && !hidePricing && option.price != null) ? (
                        <div className="info-message">
                            <span className="lbl-info">
                                {option.purchaseChoice === 3
                                    ? leadTimeDescriptionRepair(option.leadTimeDays)
                                    : leadTimeDescription(option.estimatedShipDate, option.backorderDateEta, option.shipCutoffUtc, hideNoEsdMessaging)}
                            </span>
                        </div>
                    ) : null}
                    {customFields.length ? (
                        <div className="asset-information" onClick={() => this.setState({showAssetInformationPopup: true})}>
                            Asset Information
                        </div>
                    ) : null}

                    {bulkPricingSchedule && bulkPricingSchedule.length && !hidePricing ? (
                        <div className="bulk-pricing">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Qty</th>
                                        <th>Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {bulkPricingSchedule.map((tier, i) => (
                                        <tr key={`bp_{i}`}>
                                            <td> {tier.minQtyRange === tier.maxQtyRange ? `${tier.minQtyRange}+` : `${tier.minQtyRange} - ${tier.maxQtyRange}`} </td>
                                            <td> {formatMoney(tier.tierPrice)} </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : null}

                    {isLoggedIn ? (
                        <div onClick={() => this.setState({showFacilityDialog: true})} className="facility-selector">
                            {facility && facility.facilityName ? facility.facilityName : 'Select Facility'}
                        </div>
                    ) : null}

                    <Variants />

                    {!option.showCustomCatalogMsg && (
                        <React.Fragment>
                            {!isLoggedIn && (
                                <Button secondary={true} title="Sign In and Add To Cart" onClick={() => history.push('/login')} data-ea-cta-link="CTA Link Clicked">
                                    Sign In and Add To Cart
                                </Button>
                            )}

                            {isLoggedIn && product.isDiscontinued && 
                                (<div style={{color: 'rgb(118, 118, 118)', fontSize: '14px', fontWeight: '600', paddingTop: '15px'}}>Discontinued.  Request a quote and a PartsSource representative will search for a potential supplier on your behalf.</div>)
                            }

                            {isLoggedIn && (
                                <AddToCartButton
                                    disabled={!variantsSelected || showMinQtyError}
                                    hasOnSiteService={this.state.hasOnSiteService}
                                    product={product}
                                    option={option}
                                    hasLoanerAdded={this.state.hasLoanerAdded}
                                    quantity={quantity}
                                    checkForWarranty={true}
                                    checkForAcknowldegement={true}
                                    buttonText={buttonText}
                                    isQuote={isQuote}
                                    condition={condition}
                                    quoteSource={quoteSource}
                                    displayType={DisplayType.CartPanel}
                                    hadContractOptions={hadContractOptions}
                                    showShippingFields={showShippingFields}
                                    shippingAddresses={shippingAddresses}
                                    noBuy={noBuy}
                                    allowQtyEdit={true}
                                    onQtyChange={(qty) => this.handleQuantityChange(qty)}
                                    assetSiteId={assetSiteId}
                                    assetFacilityId={assetFacilityId}
                                    outrightListPrice={outrightListPrice}
                                />
                            )}
                            {product && product.serviceUpsell && enableOnSiteService && (
                                <React.Fragment>
                                    <Checkbox checked={this.state.hasOnSiteService} style={{marginBottom: 20, color: '#005DA6'}} onChange={(e) => this.setState({hasOnSiteService: e.checked})}>
                                        <span className="service-text">Add On-Site Service</span>
                                    </Checkbox>
                                    {hasOnSiteService && <span className="lbl-service-info">Enter service details when you add to cart. You will receive a quote within 2 hours.</span>}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )}
                    {option.loanerAvailable ? <div className="loaner-section">{this.renderLoanerCheckbox('hasLoanerAdded', 'Add Loaner', settings)}</div> : null}
                    {option.hazMatId > 0 ? (
                        <div className="note note--warning">
                            <p className="lbl-info">
                                {`A special handling fee may apply to this item. See `}
                                <a href="/surcharge-faqs" target="_blank">
                                    {' '}
                                    FAQs{' '}
                                </a>
                                {` for details.`}
                            </p>
                        </div>
                    ) : null}

                    {chemicals && chemicals.length ? (
                        <div className="note--warning" style={{padding: '10px'}}>
                            <img style={{float: 'left', margin: '5px 5px 0 5px'}} src="/images/prop65warning.png" alt="warning" />
                            <p className="lbl-info">{getProps65Message(chemicals)}</p>
                        </div>
                    ) : null}

                    {!option.isContractProOption && option.notes && (
                        <div className="note">
                            {' '}
                            <p className="lbl-info">{option.notes}</p>{' '}
                        </div>
                    )}

                </div>

                {showOemConnect && this.renderOemConnectPanel()}
                {showAssetInformationPopup && (
                    <AssetInformationForm
                        fields={customFields}
                        onSubmit={::this.getFieldValues}
                        formType={AssetFormType.Both}
                        hasOnSiteService={this.state.hasOnSiteService}
                        hasLoanerAdded={this.state.hasLoanerAdded}
                        readOnly={false}
                        isRepairOption={option?.isRepairOption}
                        hideSaveButton={true}
                        onCancel={() => this.setState({showAssetInformationPopup: false})}
                        showRequester={false}
                        allowMissingFields={true}
                        priority={false}
                        showShippingFields={showShippingFields}
                        shippingAddresses={shippingAddresses}
                        onCheckBoxUpdate={(e) => this.setState({isUrgency: e.checked})}
                    />
                )}

                {showFacilityDialog && <FacilityDialog
                    onSubmit={::this.onSelectFacility}
                    onCancel={() => this.setState({showFacilityDialog: false})}
                    disableClose={!facility.facilityId || !(facility.facilityId > 0)} />}

                {showOemConnectPopup && <Popup
                    confirmText="Save"

                    cancelText="Cancel"
                    show={true}
                    hideButtons={false}
                    onCancel={() => this.setState({showOemConnectPopup: false})}
                    className="add-group-dialog"
                    onConfirm={() => this.handleSaveOemPrice()}>
                    <h1 style={{'font-family': 'Source Sans Pro', 'font-weight': '300', 'font-size': '24px'}}>Last Price</h1>
                    <div style={{width: '100%'}}>
                        <TextField
                            style={{width: '50%'}}
                            id="lastPrice"
                            ref={(input) => {
                                this.input = input;
                            }}
                            onChange={(e) => this.setState({newOemPrice: e.target.value})}
                            type="number"
                            label="Last Price"
                            placeholder="Last Price"
                            text={newOemPrice}
                            className="group-name"
                            tabIndex={0}
                            showErrorMessage={!newOemPrice}
                        />
                    </div>

                    <div style={{width: '100%', marginTop: '20px'}}>
                        <div className="oemPriceDateContainer" style={{width: '50%'}}>
                            <span className="text-field_title" htmlFor={newOemPriceDate}>
                                Last Price Date
                            </span>
                            <Datepicker placeholder="Date" id="newOemPriceDate" onChange={(date) => this.updateOemPriceDate(date)} value={newOemPriceDate ? new Date(newOemPriceDate) : null} />
                        </div>
                    </div>
                </Popup>}
                
                {showContractPricingDialog && <ContractPricingDialog
                    rootCompanyId={rootCompanyId}
                    manufacturerId={product.manufacturerId}
                    onCancel={() => this.setState({showContractPricingDialog: false})}
                    onConfirm={(e) => this.setContractPricing(e)}
                />}
            </React.Fragment>
        );
    }

    updateOemPriceDate(value) {
        this.setState({newOemPriceDate: value});
    }

    renderLoanerCheckbox = (id, label, settings) => {
        const {hasLoanerAdded} = this.state;
        const {option} = this.props;

        const onBoxChecked = (e) => {
            this.setState({hasLoanerAdded: e.checked});
            let {onCheckBoxUpdate} = this.props;
            if (onCheckBoxUpdate) onCheckBoxUpdate(e);
        };

        if (settings.allowLoaner && option.loanerAvailable) {
            return <Checkbox id={id} label={label} style={{marginBottom: 20, color: '#005DA6'}} checked={hasLoanerAdded} onChange={onBoxChecked} className="checkbox" />;
        }
    };

    handleSaveOemPrice() {
        const {newOemPrice, newOemPriceDate} = this.state;
        const {onSaveOemPrice} = this.props;

        if (newOemPrice && newOemPriceDate && onSaveOemPrice) {
            onSaveOemPrice(newOemPrice, newOemPriceDate);
        }
        this.setState({showOemConnectPopup: false});
    }

    showOemPopup() {
        const {oemPrice, oemPriceDate} = this.props;
        this.setState({newOemPrice: oemPrice, newOemPriceDate: oemPriceDate, showOemConnectPopup: true});
    }

    renderOemConnectPanel() {
        const {oemPrice} = this.props;

        return (
            <div className="cart-panel" style={{marginTop: '20px'}}>
                <div className="oem-connect-last-price">
                    {oemPrice ? (
                        <span onClick={::this.showOemPopup} style={{cursor: 'pointer'}} className="addToListDropdown">
                            Last Price: {formatMoney(parseFloat(oemPrice))}
                        </span>
                    ) : (
                        <span onClick={::this.showOemPopup} style={{cursor: 'pointer'}} className="addToListDropdown">
                            Enter last price
                        </span>
                    )}
                </div>

                <div onClick={::this.goToOemConnect} className="oem-connect-button">
                    <span style={{fontSize: '14px', lineHeight: '20px', fontWeight: '700'}}>
                        OEMConnect &nbsp;
                        <i style={{fontSize: '1.4em'}} className="fa fa-share-alt fa-3" />
                    </span>
                </div>
            </div>
        );
    }

    goToOemConnect() {
        const {quantity} = this.state;
        const {setOemOrder, history, product} = this.props;

        let oemPart = {};
        oemPart.partNumber = product.partNumber;
        oemPart.description = product.description;
        oemPart.quantity = quantity;
        oemPart.oemId = product.manufacturerId;

        setOemOrder(oemPart);

        history.push('/OEMConnectOrder');
    }

    onSelectFacility(facility) {
        const {saveUserReduxState} = this.props;
        saveUserReduxState({facility: facility});
        this.setState({showFacilityDialog: false});
    }

    getFieldValues(values, formType) {
        saveAssetInformationToStorage(values);
        this.setState({showAssetInformationPopup: false});
    }

    handleQuantityChange(quantity) {
        const {onQuantityChanged} = this.props;

        this.setState({quantity});
        onQuantityChanged(quantity);
    }

    setContractPricing(assetData) {
        const {onContractValidated} = this.props; 

        let assetDataPartSerialId = assetData.partSerialId;
        let assetDataAssetId = assetData.assetName;
        let assetDataContractId = assetData.contractId;
        let assetDataSiteId = assetData.siteId;
        let assetDataFacilityId = assetData.facilityId;
        
        let companyFields = sessionStorageAvailable && sessionStorage.fields ? JSON.parse(sessionStorage.fields) : [];

        if (assetDataPartSerialId) {
            let partSerialIdField = companyFields && companyFields.find(o => o.prompt === 'Equipment Serial #');
            
            if (partSerialIdField) {
                this.setStorageDataFieldNewValue(partSerialIdField, assetDataPartSerialId);
            }
        }

        if (assetDataAssetId) {
            let assetIdField = companyFields && companyFields.find(o => o.prompt === 'Asset ID');

            if (assetIdField) {
                this.setStorageDataFieldNewValue(assetIdField, assetDataAssetId);
            }
        }

        if (assetDataSiteId) {
            this.setState({assetSiteId: assetDataSiteId});
        }

        if (assetDataFacilityId) {
            this.setState({assetFacilityId: assetDataFacilityId});
        }
        
        onContractValidated(assetDataContractId, assetDataFacilityId);

        this.setState({showContractPricingDialog: false});
    }

    setStorageDataFieldNewValue (field, newValue) {
        const storageData = JSON.parse(sessionStorage.fields);
    
        let fieldToChange = storageData.find(
            x => x.fieldUid && x.fieldUid === field.fieldUid);
        if (fieldToChange) {
            fieldToChange.value = newValue;
        }

        sessionStorage.fields = JSON.stringify(storageData);
    }
}
