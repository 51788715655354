import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {actionCreators as productActions} from 'stores/Product';
import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';
import {TextFieldSelect, RadioButton} from '@partssourceinc/react-ui-core';

const Container = styled.div`
`

const VariantTitleWrapper = styled.div`
    background-color: rgb(241,241,241);
    padding: 10px 16px;
    font-size: 16px;
    margin-bottom: 20px;

    span {
        font-weight: 600;
    }
`;

const Pill = styled.div`
    border-radius: 3px;
    border: 1px solid rgb(204, 204, 204);
    padding: 10px;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    font-weight: 600;

    ${props => !props.selected && !props.disabled && css`
        &:hover {
            border-color: #000000;
        }
    `};

    ${props => props.selected && css`
        border-color: #ff9505;
    `}

    ${props => props.disabled && css`
        color: rgb(204,204,204);
        cursor: not-allowed;
    `}    
`;

const Variant = styled.div`
    margin-top: 24px;
`

const VariantRadioButton = styled(RadioButton)`
        label {
            position: absolute;
            top: 0;
        }
`;

const Variants = () => {    
    const dispatch = useDispatch();
    const {selectVariant} = productActions;
        
    const currentProduct = useSelector(state => state.currentProduct);    
    const selectedDisplayOption = currentProduct.displayOptions.find(x => x.isSelected) || {};
    const variants = selectedDisplayOption.variants || [];
    const sessionStorageAvailable = useSelector(state => state.network.sessionStorageAvailable);

    useEffect(() => {
        if (typeof window === 'undefined') return;
        const params = new URLSearchParams(window.location.search);
        const hasOption = selectedDisplayOption.option != null;
        params.delete('vrnt');
        if (hasOption) params.append('vrnt', selectedDisplayOption.option.vendorItemNumber);
        let newUrl = window.location.pathname;
        if (params.toString()) newUrl = newUrl + `?${params.toString()}`;
        window.history.replaceState(null, null, newUrl);
        if (sessionStorageAvailable) {
            sessionStorage.prevUrl = newUrl;
        }
    }, [ selectedDisplayOption.selectedVariantId]);

    const onVariantSelection = (v) => {               
        let variantPayload = [];
        
        let allOptions = _.flatMap(variants.map(o => o.options));
        allOptions.forEach(x => {
            if (x.name === v.name) {
                x.isSelected = x.value === v.value;
            }
        });
        variantPayload = allOptions.filter(x => x.isSelected);
        let request = {
            selectedVariants: variantPayload,
            productId: currentProduct.product.id,
            vStrings: currentProduct.options.filter(x => x.lineItemCondition === selectedDisplayOption.lineItemCondition && x.purchaseChoice === selectedDisplayOption.purchaseChoice).map(o => o.vendorItemNumber),
        }
        dispatch(selectVariant(request));
    };

    const renderVariant = (variant, i) => {

        let listItems = variant.options.map((o,n) => {
            return {id: n, name: o.value, disabled: (!o.isEnabled), isSelected: o.isSelected}
        });

        let options = _.orderBy(variant.options, (o) => {
            if (isNaN(o.sequence))
                return o.value
            
            return parseInt(o.sequence, 10)
        });

        const selectedItem = listItems.find(o => o.isSelected);

        return (<Variant key={`variant-${i}`}>
            <VariantTitleWrapper key={`variant-${i}-${variant.name}`}>
                {`${variant.name}: `} 
                <span>{selectedItem ? selectedItem.name : 'Select'}</span>
            </VariantTitleWrapper>
            {options.length < 3 && options.map((o,n) => (
                <VariantRadioButton 
                    id={`variant-${variant.name}-${n}`} 
                    key={`variant-${variant.name}-${n}`} 
                    name={`variant-${variant.name}-${i}`} 
                    onChange={(event, data) => onVariantSelection(options[data.value])} 
                    checked={o.isSelected}
                    disabled={!o.isEnabled}
                    value={n} 
                    label={o.value}                    
                    tabIndex="0" />
            ))}
            {(options.length > 2 && options.length <= 10) && options.map((o,n) => (
                <Pill 
                    key={`variant-option-${i}${n}`} 
                    selected={o.isSelected} 
                    disabled={!o.isEnabled}
                    onClick={() => !o.isEnabled || o.isSelected ? _.noop : onVariantSelection(o)}
                >{o.value}</Pill>
            ))}
            {options.length > 10 && <TextFieldSelect
                disableClear={true}
                keyField="id"
                valueField="name"
                style={{marginTop: '0px'}}
                selectedItem={selectedItem}
                onSelect={(item) => onVariantSelection({name: variant.name, value: item.name})}
                listItems={listItems}
                label={variant.name}
                placeHolder={variant.name}
            />
            }
        </Variant>);
    };

    return (
        <Container>
            {variants.map((x,i) => renderVariant(x, i))}
        </Container>
    )
}

export default Variants;
